// 引入购物车接口
import { listGoodsCart4user, removeGoodsCart } from '@/API/shoppingcar'
export default {
  namespaced: true,//开启命名空间
  state: {
    shopnumber: 0,
    shoppinglist: JSON.parse(localStorage.getItem('shoppingcart')) === null ? {} : JSON.parse(localStorage.getItem('shoppingcart')),
    PreviousPath: {},
    goBackToPreviousPage:{}
  },
  mutations: {
    // 同步购物车数据
    shopping_list(state, value) {
      state.shoppinglist = value
      localStorage.setItem('shoppingcart', JSON.stringify(value))
    },
    // 同步徽标数量
    shopping_number(state, value) {
      state.shopnumber = value
    },
    SET_PREVIOUS_PATH(state,value){
      state.PreviousPath=value
     },
    goBackToPreviousPage(state,value){
      state.goBackToPreviousPage=value
    }
  },
  actions: {
    // 查询购物车商品
    async inquireshoppinglist(context, value) {
      if(!localStorage.getItem('userData')){
        return false
      }
      let { data } = await listGoodsCart4user(value)
      if(!data){
          return false
      }
      let number = 0
      for (let i = 0; i < data?.goodsCartBackDTOS.length; i++) {
        number++
      }
      let c=localStorage.getItem('shoppingcart')?localStorage.getItem('shoppingcart'):'[]'
      if( c.charAt(0) === '['&&c.charAt(c.length - 1) === ']'){
        localStorage.removeItem('shoppingcart')
      }

      if( localStorage.getItem('shoppingcart')){

        let list=JSON.parse(localStorage.getItem('shoppingcart'))
        console.log(list)

            data.goodsCartBackDTOS.forEach(el=>{
              el.choice=false
            })
            list?.goodsCartBackDTOS.forEach(el=>{
              data.goodsCartBackDTOS.forEach(item => {
                if(el.id==item.id&&el.choice==true){
                  item.choice=true
                }
                if(item?.discountTypePriceDTO){
                    item.restrictedPurchase=item.discountTypePriceDTO.purchaseNum?item.discountTypePriceDTO.purchaseNum-item.discountTypePriceDTO.userNum:null
                  if(item.stock<item.restrictedPurchase){
                    item.restrictedPurchase=item.stock
                  }
                }
              });
            })
      }

      context.commit('shopping_number', number)
      context.commit('shopping_list', data)
    },
    // 删除购物车中商品
    async deletelist(context, value) {
      await removeGoodsCart(value)
    },
    //  勾选状态改变
    choicelist(context, value) {
      console.log('几次')
      context.commit('shopping_list', value)
    }
  },

}
