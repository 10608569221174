import Cookies from 'js-cookie'
// import wx from 'jweixin-module'
// const jweixin = require("jweixin-module");
// import {
//   wechatShare
// } from '@/API/user'
const TokenKey= 'Admin_token'

export function gettoken() {
  return Cookies.get(TokenKey)
}

export function settoken(value) {
  let values='Bearer '+value
  return Cookies.set(TokenKey,values)
}
export function removetoken() {
  return Cookies.remove(TokenKey)
}
