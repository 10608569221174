import request from '@/utils/request'

//蓝牙商品api
//蓝牙页面
export function listStoreSellGoods(params) {
    return request({
        url: '/userConsumer/userGoods/listStoreSellGoods',
        method: 'post',
        params
    })
}
// 商品详情
export function getStoreSellGoods(params) {
    return request({
        url: '/userConsumer/userGoods/getStoreSellGoods',
        method: 'post',
        params
    })
}
// 创建订单
export function placeStoreSellGoods(params) {
    return request({
        url: '/userConsumer/userOrder/placeStoreSellGoods',
        method: 'post',
        params
    })
}
export function updateStoreSellVoltage(params) {
    return request({
        url: '/userConsumer/userGoods/updateStoreSellVoltage',
        method: 'post',
        params
    })
}
