import request from '@/utils/request'

// 前端首页商品查询
export function listFrontGoodsUser(params) {
  return request({
    url: '/userConsumer/userGoods/listFrontGoodsUser',
    method: 'post',
    params
  })
}
// 前端店铺分类查询
export function All4Front(params) {
  return request({
    url: '/userConsumer/userStoreClass/listStoreClassAll4Front',
    method: 'post',
    params
  })
}
// 前端首页商品查询 根据分类或者名称
export function ClassOrTitle(data) {
  return request({
    url: '/userConsumer/userGoods/listFrontGoods4UserClassOrTitle',
    method: 'post',
    data
  })
}
// 根据ID查询前端展示商品
export function getFrontGoods4Id(params) {
  return request({
    url: '/userConsumer/userGoods/getFrontGoods4Id',
    method: 'post',
    params
  })
}
// 根据款式属性查询库存
export function GoodsStock(params) {
  return request({
    url: '/userConsumer/userGoods/userListFrontGoodsStock',
    method: 'post',
    params
  })
}

// 根据规格查询
export function GoodsSpecs(params) {
  return request({
    url: '/userConsumer/userGoods/getFrontGoodsStyle4GoodsId',
    method: 'post',
    params
  })
}

// 获取三方登录配置信息
export function getLoginSetting(params) {
  return request({
    url: '/userConsumer/front/auth/facebookConfig',
    method: 'get',
    params
  })
}

// 获取首页轮播图
export function listStoreBanner(params) {
  return request({
    url: '/userConsumer/userStoreBanner/listStoreBanner',
    method: 'post',
    params
  })
}
// 店铺广告点击\转化订单数增加
export function updateStoreBannerIncrement4Id(params) {
  return request({
    url: '/userConsumer/userStoreBanner/updateStoreBannerIncrement4Id',
    method: 'post',
    params
  })
}
// 查询该店铺所有导航
export function listStoreNavigation(params) {
  return request({
    url: '/userConsumer/userStoreBanner/listStoreNavigation',
    method: 'post',
    params
  })
}

// 店铺logo以及底部版权信息
export function getStoreIndexInfo(params) {
  return request({
    url: '/threeTerminalCon/sellerConsumer/store/getStoreIndexInfo',
    // url: '/sellerConsumer/sellerConsumer/store/getStoreIndexInfo',
    method: 'post',
    params
  })
}

// 电话的区域号
export function listAreaCodeByPhone() {
  return request({
    url: '/threeTerminalCon/areaCode/listAreaCodeByPhone',
    method: 'get',
  })
}

// 点击量
export function addBrowseCount(params) {
  return request({
    // url: '/sellerConsumer/sellerConsumer/store/addBrowseCount',
    url: '/threeTerminalCon/sellerConsumer/store/addBrowseCount',
    method: 'get',
    params
  })
}

// 根据id查询自定义页面
export function getStoreCustomize4Id(params) {
  return request({
    url: '/userConsumer/store/getStoreCustomize4UserAndId',
    method: 'post',
    params
  })
}



// 支付方式查询
export function getPaySettingList(params) {
  return request({
    url: '/userConsumer/users/getPaySettingList',
    method: 'get',
    params
  })
}




//  前端店铺分类查询
export function getStoreClassification(params) {
  return request({
    url: '/userConsumer/userStoreClass/listStoreClassAll4Front',
    method: 'post',
    params
  })
}

//   前端的最新六条商品

export function getLatestProducts(params) {
  return request({
    url: '/userConsumer/userGoods/listFrontGoods4Products',
    method: 'post',
    params
  })
}
