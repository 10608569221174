// lang/zh-CN.js: 中文翻译文件
export default {
    推荐商品: 'ສິນຄ້າແນະນໍາ',
    用户服务协议及法律声明:
        '《ສັນຍາການໃຊ້ບໍລິການ ຂອງຜູ້ໃຊ້ແລະ ຄຳຊີ້ແຈງທາງກົດໝາຍ》',
    用户服务协议: '《ຂໍ້ຕົກລົງບໍລິການຜູ້ໃຊ້》',
    法律声明: '《ປະກາດທາງກົດໝາຍ》',
    隐私政策: '《ນະໂຍບາຍຄວາມເປັນສ່ວນໂຕ》',
    商品分类: 'ໝວດໝູ່ສິນຄ້າ',
    暂无商品: 'ບໍ່ມີສິນຄ້າໃນຂະນະນີ້',
    留言管理: 'ການ ຄຸ້ມ ຄອງ ຂໍ້ ຄວາມ ',
    留言回复: 'ຂໍ້ຄວາມຕອບກັບ',
    我要留言: 'ຂ້ອຍຕ້ອງການຝາກຂໍ້ຄວາມ',
    没有更多了: 'ບໍ່ມີອີກແລ້ວ',
    查看全部: 'ເບິ່ງທັງໝົດ',
    查看全部xx条回复: 'ເບິ່ງການຕອບກັບທັງໝົດໃນ {num} ລາຍການ',
    条回复: 'ການຕອບກັບ',
    留言详情: 'ລາຍ ລະ ອຽດ ຂໍ້ ຄວາມ ',
    留言回复长度: 'ຂໍ້ຄວາມຕອບກັບ, ຄວາມຍາວ 2-100 ຕົວອັກສອນ',
    提交: 'ສະເຫນີ',
    邮箱: 'ອີເມວ',
    手机号: 'ເບີ ໂທ',
    留言内容: 'ເນື້ອໃນຂໍ້ຄວາມ',
    请填写留言内容: 'ກະລຸນາຂຽນເນື້ອໃນຂໍ້ຄວາມ',
    立即留言: 'ຝາກຂໍ້ຄວາມທັນທີ',
    忘记密码: 'ລືມລະຫັດຜ່ານ',
    请输入手机号: 'ກະລຸນາປ້ອນເບີໂທ',
    手机验证码: 'ຮັບລະຫັດຢືນຢັນຜ່ານໂທລະສັບມືຖື',
    请输入验证码: 'ກະລຸນາໃສ່ລະຫັດຢືນຢັນ',
    新密码: 'ລະຫັດຜ່ານໃໝ່',
    请输入新密码: 'ກະລຸນາໃສ່ລະຫັດຜ່ານໃໝ່',
    立即提交: 'ສົ່ງທັນທີ',
    登录: 'ເຂົ້າ ສູ່ ລະ ບົບ',
    欢迎登录: 'ຍິນດີຕ້ອນຮັບເຂົ້າສູ່ລະບົບ',
    密码登录: 'ເຂົ້າສູ່ລະບົບດ້ວຍລະຫັດຜ່ານ',
    验证码登录: 'ເຂົ້າສູ່ລະບົບດ້ວຍລະຫັດຢືນຢັນ',
    邮箱手机号: 'ອີເມວ/ເບີໂທລະສັບ',
    请输入邮箱手机号: 'ກະລຸນາປ້ອນອີເມວ/ເບີໂທມືຖື',
    密码: 'ລະຫັດຜ່ານ',
    请输入密码: 'ກະລຸນາປ້ອນລະຫັດ',
    验证码: 'ລະຫັດຢືນຢັນ',
    立即登录: 'ເຂົ້າສູ່ລະບົບທັນທີ',
    已阅读并同意以下协议:
        'ຂ້ອຍໄດ້ອ່ານແລະ ຕົກລົງເຫັນດີກັບຂໍ້ຕົກລົງດັ່ງລຸ່ມນີ້',
    我是新用户: 'ຂ້ອຍເປັນຜູ້ໃຊ້ໃໝ່',
    立即注册: 'ລົງທະບຽນຕອນນີ້',
    注册: 'ລົງທະບຽນ',
    确定: 'ແນ່ນອນ',
    消息通知: 'ແຈ້ງເຕືອນຂໍ້ຄວາມ',
    全部消息: 'ຂໍ້ຄວາມທັງໝົດ',
    已读消息: 'ຂໍ້ຄວາມອ່ານແລ້ວ',
    全选: 'ເລືອກ ທັງ ຫມົດ',
    标记已读: 'ຫມາຍວ່ານອ່ານແລ້ວ',
    删除: 'ລຶບ',
    消息详情: 'ລາຍລະອຽດຂໍ້ຄວາມ',
    我的订单: ' ຄໍາສັ່ງຊື້ຂອງຂ້ອຍ',
    全部: 'ທັງໝົດ',
    待付款: 'ລໍຖ້າການຊຳລະເງິນ',
    待发货: 'ບໍ່ທັນໄດ້ສົ່ງ',
    待收货: 'ລໍຖ້າຮັບສິນຄ້າ',
    已完成: 'ສໍາເລັດ',
    订单号: 'ໝາຍເລກຄຳສັ່ງຊື້',
    已关闭: 'ປິດ',
    部分订单取消: '部分订单取消',
    部分退款完成: '部分退款完成',
    部分退款: '部分退款',
    部分收货: '部分收货',
    退款中: 'ກຳລັງດຳເນິນການຄືນເງິນ',
    交易异常: 'ຂໍ້ຍົກເວັ້ນການເຮັດທຸລະກໍາ',
    部分发货: 'ການຂົນສົ່ງບາງສ່ວນ',
    发货时间: 'ເວລາຈັດສົ່ງ',
    退款成功: 'ຖອນເງີນສໍາເລັດ',
    共: 'ລວມ',
    共x件商品已选择x件: 'ສິນຄ້າ {num} ລາຍການ, ໄດ້ເລືອກເອົາ {num2} ລາຍການ',
    共x件商品: 'ສິນຄ້າ {num} ລາຍການ',
    总价: 'ລາ ຄາ ລວມ',
    联系客服: 'ຕິດຕໍ່ຝ່າຍບໍລິການລູກຄ້າ',
    删除订单: 'ລຶບ ຄໍາ ສັ່ງ ຊື້',
    取消订单: 'ຍົກ ເລີກ ຄຳສັ່ງຊື້',
    去付款: 'ໄປຈ່າຍເງິນ',
    确认收货: 'ຢືນຢັນການຮັບສິນຄ້າ',
    暂无订单信息: 'ບໍ່ມີຂໍ້ມູນການສັ່ງຊື້ໃນຂະນະນີ້~',
    客服电话: 'ສາຍດ່ວນຜູ້ບໍລິການ',
    客服邮箱: 'ອີເມວຝ່າຍບໍລິການ',
    付款时间: 'ເວລາຊໍາລະ',
    创建时间: 'ເວ ລາ ການ ສ້າງ ',
    复制: 'ກອບປີ້',
    订单编号: 'ໝາຍເລກຄຳສັ່ງຊື້',
    实付款: 'ການຈ່າຍເງິນທີ່ຕ້ອງຊຳລະ',
    运费: 'ຄ່າຂົນສົ່ງ',
    商品总额: 'ຈຳນວນສິນຄ້າທັງໝົດ',
    支付宝: 'Alipay',
    微信: 'WeChat',
    线下支付: 'ການຈ່າຍເງິນອອບໄລນ໌',
    支付方式: 'ວິທີການຊໍາລະເງິນ',
    售后详情: 'ລາຍລະອຽດຫຼັງການຂາຍ',
    售后申请: 'ຄໍາຮ້ອງຂໍບໍລິການຫລັງການຂາຍ',
    物流单号: 'ເລກການຂົນສົ່ງ：',
    购买商品: 'ຊື້ສີນຄ້າ',
    x件: '{num}ອັນ',
    收货地址: 'ທີ່ຢູ່ຈັດສົ່ງ',
    收货人: 'ຜູ້ຮັບ',
    您当前是线下支付请耐心等待店铺确认收款若:
        'ທ່ານໄດ້ຊຳລະເງິນແບບອອບໄລນ໌,ກະລຸນາລໍຖ້າໃຫ້ຮ້ານຄ້າຢືນຢັນການຮັບເງິນ ຖ້າຮ້ານບໍ່ຢືນຢັນການຈ່າຍເງິນຫຼັງຈາກ',
    后店铺未确认收款订单将自动取消: ',ຄໍາສັ່ງຈະຖືກຍົກເລີກໂດຍອັດຕະໂນມັດ!',
    请在: 'ກະລຸນາຊຳລະເງິນພາຍໃນ',
    内完成支付否则订单会被自动取消: ', ບໍ່ສະນັ້ນຄໍາສັ່ງຈະຖືກຍົກເລີກໂດຍອັດຕະໂນມັດ',
    订单详情: 'ລາຍ ລະ ອຽດ ສັ່ງຈອງ',
    客户已付款等待店铺发货: 'ລູກຄ້າໄດ້ຈ່າຍເງິນແລ້ວລໍຖ້າໃຫ້ທາງຮ້ານຈັດສົ່ງ',
    售后服务: 'ບໍ ລິ ການ ຫລັງ ການ ຂາຍ',
    该订单已取消: 'ຄຳສັ່ງນີ້ຖືກຍົກເລີກແລ້ວ',
    申请信息: 'ຂໍ້ມູນສະຫມັກ',
    退货类型: 'ປະເພດການຄືນສິນຄ້າ',
    点击选择退货类型: 'ກົດເພື່ອເລືອກປະເພດການຄືນສິນຄ້າ',
    退货金额: 'ຈໍາ ນວນ ເງິນ ຄືນ ',
    退货原因: 'ເປັນຫຍັງຈຶ່ງຍົກເລີກສິນຄ້າ(ເຫດຜົນ)',
    点击选择退货原因: 'ກົດເພື່ອເລືອກເຫດຜົນຂອງການຍົກເລີກສິນຄ້າ',
    请输入留言内容: 'ກະລຸນາປ້ອນຂໍ້ຄວາມ',
    个字: 'ພາຍໃນ',
    以内: 'ຕົວອັກສອນ',
    上传凭证: 'ອັບໂຫລດຫຼັກຖານ',
    为了帮助您更好的解决问题请上传图片:
        'ເພື່ອຊ່ວຍໃຫ້ທ່ານແກ້ໄຂບັນຫາໄດ້ດີຂຶ້ນ, ກະລຸນາອັບໂຫຼດຮູບ',
    最多可上传5张图片每张图片大小不超过5M支持xxx格式文件:
        'ສາມາດອັບໂຫລດໄດ້ເຖິງ 5 ຮູບ, ແລະຂະໜາດຂອງແຕ່ລະຮູບບໍ່ເກີນ 5M, ຮອງຮັບໄຟລ໌ຮູບແບບ bmp, gif, jpg, png, jpeg',
    联系方式: 'ຂໍ້ມູນຕິດຕໍ່',
    联系人: 'ຜູ້ ຕິດ ຕໍ່ ',
    请填写联系人: 'ກະລຸນາປ້ອນຊື່ຜູ້ຕິດຕໍ່',
    联系电话: 'ຕິດ ຕໍ່',
    请填写联系电话: 'ກະລຸນາປ້ອນເບີໂທລະສັບຂອງທ່ານ',
    确定提交: 'ຢືນຢັນການສົ່ງ',
    温馨提示: 'ເຄັດລັບດີໆ',
    商品寄回地址将在审核通过后以短信形式告知或在申请记录中查询:
        'ທີ່ຢູ່ການສົ່ງຄືນສິນຄ້າຈະຖືກແຈ້ງໃຫ້ຊາບໃນຮູບແບບຂອງຂໍ້ຄວາມ ຫຼື ສາມາດກວດສອບຈາກບັນທຶກປະຫວັດຄຳຂໍ',
    提交服务单后售后专员可能与您电话沟通请保持手机畅通:
        'ຫຼັງຈາກສົ່ງຄໍາສັ່ງຊື້ການບໍລິການແລ້ວ, ຜູ້  ບໍລິການຈະຕິດຕໍ່ຜ່ານທາງໂທລະສັບ, ກະລຸນາເປີດໂທລະສັບຂອງທ່ານໄວ້',
    退货处理成功后退款金额将原路返回到您的支持账户中:
        'ຫຼັງຈາກສົ່ງຄຳຂໍຄືນສິນຄ້າຖືກອະນຸມັດຮຽບຮ້ອຍແລ້ວ, ຍອດຄືນເງິນຈະຖືກສົ່ງຄືນໄປຫາບັນຊີເດີມຂອງທ່ານ',
    等待店铺审核: 'ກຳລັງລໍຖ້າຮ້ານຄ້າກວດສອບ',
    店铺拒绝您的申请: 'ຮ້ານຄ້າໄດ້ປະຕິເສດຄຳຂໍຂອງທ່ານ',
    客户取消售后: 'ຫຼັງຈາກທີ່ລູກຄ້າຍົກເລີກການຂາຍ',
    售后单号: 'ໝາຍເລກການສັ່ງຊື້ຫຼັງການຂາຍ',
    申请时间: 'ເວລາສົ່ງຄຳຂໍ',
    提交申请: 'ສົ່ງຄຳຂໍ',
    店铺处理: 'ຮ້ານຄ້າກຳລັງດຳເນີນການ',
    店铺退款: 'ຮ້ານຄ້າກຳລັງດຳເນີນການຄືນເງິນ',
    售后完成: 'ສໍາເລັດການໃຫ້ບໍລິການຫຼັງການຂາຍ',
    售后商品: 'ສິນຄ້າທີ່ໄດ້ຮັບບໍລິການຫຼັງການຂາຍ',
    售后信息: 'ຂໍ້ມູນບໍລິການຫລັງການຂາຍ',
    售后方式: 'ຮູບແບບບໍລິການຫຼັງການຂາຍ',
    退款金额: 'ຈຳນວນເງິນຄືນ',
    退款原因: 'ເຫດຜົນການຄືນເງິນ',
    退款说明: 'ອະທິບາຍການສົ່ງເງິນຄືນ',
    购买信息: 'ຂໍ້ມູນການສັ່ງຊື້ ',
    商品单价: 'ລາຄາຕໍ່ໜ່ວຍ',
    实付金额: 'ຈໍານວນເງິນທີ່ຈ່າຍຕົວຈິງ',
    发货件数: 'ຈໍານວນສິນຄ້າທີ່ຈັດສົ່ງ',
    发货状态: 'ສະຖານະການຈັດສົ່ງ',
    您可以在这里给店铺留言: 'ທ່ານສາມາດຝາກຂໍ້ຄວາມໃຫ້ຮ້ານໄດ້ທີ່ນີ້',
    为了保证你的权益: ' ເພື່ອປົກປ້ອງສິດ ແລະຜົນປະໂຫຍດຂອງເຈົ້າ',
    请尽可能详细的上传留言资料:
        ' ກະລຸນາອັບໂຫຼດຂໍ້ມູນຂໍ້ຄວາມໃຫ້ລະອຽດເທົ່າທີ່ຈະເປັນໄປໄດ້.',
    您可以在这里给店铺留言为了保证你的权益请尽可能详细的上传留言资料:
        'ທ່ານສາມາດຝາກຂໍ້ຄວາມໃຫ້ຮ້ານຄ້າໄດ້ທີ່ນີ້, ເພື່ອປົກປ້ອງສິດ ແລະຜົນປະໂຫຍດຂອງເຈົ້າ, ກະລຸນາອັບໂຫຼດຂໍ້ມູນ ແລະ ເອກະສານໃຫ້ລະອຽດທີ່ສຸດ',
    发表留言: 'ຝາກຂໍ້ຄວາມ',
    店: 'S',
    客: 'C',
    店铺: 'ຮ້ານຄ້າ',
    客户: 'ລູກ ຄ້າ',
    取消售后: 'ຍົກເລີກບໍລິການຫຼັງການຂາຍແລ້ວ',
    重新申请: 'ສົ່ງຄຳຂໍຄືນໃໝ່',
    退款失败: 'ຖອນເງີນລົ້ມເຫລວ',
    退款完成: 'ຄືນເງິນສຳເລັດ',
    查看详情: 'ກວດເບິ່ງລາຍລະອຽດ',
    暂无售后信息: 'ບໍ່ມີຂໍ້ມູນບໍລິການຫຼັງການຂາຍໃນຂະນະນີ້',
    修改邮箱: 'ແກ້ໄຂອີເມວ',
    手机验证: 'ການຢັ້ງຢືນໂທລະສັບ',
    邮箱修改: 'ການແກ້ໄຂອີເມວ',
    邮箱验证: 'ຢັ້ງຢືນອີເມລ',
    完成: 'ສໍາເລັດ',
    为确认身份我们需验证您的安全手机:
        ' ເພື່ອຢືນຢັນຕົວຕົນ, ພວກເຮົາຈໍາເປັນຕ້ອງຢືນຢັນຜ່ານໂທລະສັບທີ່ມີຄວາມປອດໄພຂອງທ່ານ',
    如果您当前的手机号已无法使用请联系客服:
        'ຖ້າເບີໂທລະສັບມືຖືປັດຈຸບັນຂອງເຈົ້າບໍ່ສາມາດໃຊ້ງານໄດ້, ກະລຸນາຕິດຕໍ່ຝ່າຍບໍລິການລູກຄ້າ',
    当前安全手机号: 'ເບີໂທທີ່ມີຄວາມປອດໄພ',
    短信验证码: 'ລະຫັດຢືນຢັນ SMS',
    请输入短信验证码: 'ກະລຸນາປ້ອນລະຫັດຢືນຢັນ SMS',
    下一步: 'ຕໍ່ ໄປ',
    请输入邮箱: 'ກະລຸນາປ້ອນອີເມວ',
    请填写邮箱: 'ກະລຸນາປ້ອນອີເມວ',
    请输入正确的邮箱: 'ກະລຸນາໃສ່ອີເມວຂອງທ່ານ',
    恭喜您邮箱地址验证成功: 'ຊົມເຊີຍ! ຢືນຢັນທີ່ຢູ່ອີເມວສຳເລັດແລ້ວ',
    请在下次使用邮箱地址进行登录:
        'ກະລຸນາໃຊ້ທີ່ຢູ່ອີເມວຂອງທ່ານເພື່ອເຂົ້າສູ່ລະບົບຄັ້ງຕໍ່ໄປ',
    返回: 'ກັບຄືນ',
    修改密码: 'ປ່ຽນ ລະ ຫັດ ຜ່ານ',
    原密码: 'ລະ ຫັດ ຜ່ານ ເກົ່າ',
    请输入原密码: 'ກະລຸນາປ້ອນລະຫັດຜ່ານຕົ້ນສະບັບ',
    确认密码: 'ຢືນ ຢັນ ລະ ຫັດ',
    请再次输入新密码: 'ກະລຸນາໃສ່ລະຫັດຜ່ານອີກຄັ້ງ',
    保存: 'ເກັບໄວ້',
    未登录: 'ບໍ່ໄດ້ເຂົ້າສູ່ລະບົບ',
    修改: 'ທົບທວນຄືນ',
    地址管理: 'ຈັດການທີ່ຢູ່ ',
    退出登录: 'ອອກ ຈາກ ລະ ບົບ',
    头像: 'ຮູບໂປຣໄຟລ໌',
    昵称: 'ຊື່ຫຼີ້ນ',
    请输入用户名: 'ກະລຸນາໃສ່ຊື່ຜູ້ໃຊ້',
    性别: 'ເພດ',
    男: 'ຜູ້ ຊາຍ',
    女: 'ຜູ້ຍິງ',
    保密: 'ຮັກສາຄວາມລັບ',
    手机号码: 'ເບີໂທ',
    验证: 'ກວດ ສອບ',
    个人信息: 'ຂໍ້ມູນສ່ວນບຸກຄົນ ',
    售后申请成功: 'ຄໍາຮ້ອງຂໍບໍລີການຫລັງການຂາຍສໍາເລັດ',
    售后申请提交成功: 'ສົ່ງຄຳຮ້ອງຂໍບໍລິການຫຼັງການຂາຍສຳເລັດແລ້ວ',
    售后专员可能与您电话沟通请保持手机畅通:
        'ເຈົ້າໜ້າທີ່ບໍລິການຫຼັງການຂາຍຈະຕິດຕໍ່ຜ່ານທາງໂທລະສັບ, ກະລຸນາເປີດໂທລະສັບໄວ້',
    支付成功: 'ການ ຊໍາ ລະ ສໍາ ເລັດ ',
    支付失败: 'ຊຳລະເງິນບໍ່ຊຳເລັດ',
    查看订单: 'ກວດ ສອບ ຄໍາ ສັ່ງ ຊື້',
    返回首页: 'ກັບ ຄືນ ໜ້າ',
    支付订单: 'ຊຳລະເງິນລາຍການສັ່ງຊື້',
    优惠金额: 'ຫຼຸດລາຄາ',
    确定支付: 'ຢືນຢັນການຈ່າຍເງິນ',
    线下支付不支持线上售后服务如需售后请联系店铺若已付款请耐心等待店铺确认收款并发货:
        'ການຈ່າຍເງິນແບບອອບລາຍບໍ່ຮອງຮັບການບໍລິການຫຼັງການຂາຍແບບອອນລາຍ, ກະລຸນາຕິດຕໍ່ຜູ້ຄ້າຫາກທ່ານຕ້ອງການບໍລິການຫຼັງການຂາຍ. ຖ້າທ່ານໄດ້ຈ່າຍເງິນແລ້ວ, ກະລຸນາລໍຖ້າດ້ວຍຄວາມອົດທົນເພື່ອໃຫ້ຜູ້ຄ້າຢືນຢັນການຊໍາລະແລະຈັດສົ່ງມັນ!',
    您可以参考如下线下付款方式:
        'ທ່ານສາມາດໃຊ້ວິທີການຊໍາລະແບບອອບໄລນ໌ດັ່ງຕໍ່ໄປນີ້',
    支付凭证: 'ໃບ ຊໍາ ລະ ເງິນ ',
    确定要取消支付这笔订单吗:
        'ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການຍົກເລີກການຈ່າຍເງິນສຳລັບການສັ່ງນີ້?',

    若您已按如上付款方式付款请上传相关凭证如转账截图等:
        'ຖ້າທ່ານໄດ້ຈ່າຍຕາມວິທີການຊໍາລະຂ້າງເທິງ ນີ້,  ກະລຸນາອັບໂຫລດເອກະສານທີກ່ຽວຂ້ອງ, ເຊັ່ນ: ພາບໜ້າຈໍການໂອນ ແລະ ອື່ນໆ  ',
    支持jpg和png格式文件: 'ສະຫນັບສະຫນູນ jpg, png ຮູບແບບ',
    若您已按如上付款方式付款请上传相关凭证如转账截图等支持jpg和png格式文件图片不大于3M: 'ຖ້າທ່ານໄດ້ຈ່າຍເງິນຕາມວິທີການຊໍາລະເງິນຂ້າງເທິງ, ກະລຸນາອັບໂຫຼດເອກະສານທີ່ກ່ຽວຂ້ອງ, ເຊັ່ນ: ພາບຫນ້າຈໍການໂອນ, ແລະອື່ນໆ, ຮອງຮັບຮູບແບບ jpg, png , ແລະຮູບພາບບໍ່ຄວນຈະມີຂະຫນາດໃຫຍ່ກວ່າ 3M.',
    图片不大于3M: 'ແລະຮູບພາບບໍ່ຄວນຈະມີຂະຫນາດໃຫຍ່ກວ່າ 3M',
    确认已付款: 'ຢືນຢັນການຈ່າຍເງິນ',
    您的收货地址为空点此添加收货地址:
        'ທີ່ຢູ່ຂອງທ່ານຫວ່າງເປົ່າ, ກົດທີ່ນີ້ເພື່ອເພີ່ມທີ່ຢູ່ການຮັບເຄື່ອງ',
    商品: 'ສິນຄ້າ',
    备注说明: 'ຫມາຍເຫດ',
    商品总价: 'ລາ ຄາ ລວມ',
    活动优惠: 'ສ່ວນຫລຸດຈາກໂປໂມຊັນ',
    优惠卷抵扣: 'ຄູປອງສ່ວນຫຼຸດ',
    支付金额: 'ຈຳນວນເງິນ',
    应付总额: 'ຍອດລວມທີ່ຕ້ອງຊຳລະ',
    提交订单: 'ສົ່ງຄຳສັ່ງຊື້',
    商品详情: 'ລາຍລະອຽດສິນຄ້າ',
    选择: 'ເລືອກ',
    发货: 'ຈັດສົ່ງແລ້ວ',
    剩余: 'ຍັງເຫຼືອ',
    剩余X件: 'ເຫຼືອ {num} ອັນ',
    购买数量: 'ຈຳນວນສັ່ງຊື້',
    加入购物车: 'ເພີ່ມໃສ່ກະຕ່າ',
    立即购买: 'ຊື້ທັນທີ',
    此宝贝已下架: 'ສິນຄ້າຖືກນຳອອກຈາກຊັ້ນວາງແລ້ວ',
    客服: 'ບໍ ລິ ການ ລູກ ຄ້າ',
    购物车: 'ກະຕ່າຊື້ເຄື່ອງ',
    请输入搜索关键词: 'ໃສ່ຄໍາຄົ້ນຫາ',
    搜索: 'ຄົ້ນຫາ',
    历史搜索: 'ປະຫວັດການຄົ້ນຫາ',
    姓: 'ນາມສະກຸນ',
    请填写姓: 'ກະລຸນາຂຽນນາມສະກຸນ',
    名: 'ຊື່',
    请填写名: 'ກະລຸນາຂຽນຊື່',
    请填写手机号: 'ກະລຸນາຂຽນເບີມືຖື',
    国家区域: 'ປະເທດ/ພາກພື້ນ',
    点击选择国家: 'ກົດເພື່ອເລືອກປະເທດ',
    请选择国家: 'ກະລຸນາເລືອກປະເທດ',
    城市: 'ເມືອງ',
    点击选择城市: 'ກົດເພື່ອເລືອກເມືອງ,ແຂວງ',
    请选择城市: 'ກະລຸນາເລືອກເມືອງ',
    邮政编码: 'ລະ ຫັດ ໄປ ສະ ນີ',
    请填写邮政编码: 'ກະລຸນາຕື່ມລະຫັດໄປສະນີ',
    详细地址: 'ທີ່ຢູ່ລະອຽດ',
    请输入详细地址信息如道路门牌号小区楼层号等:
        'ກະລຸນາໃສ່ຂໍ້ມູນທີ່ຢູ່ລະອຽດເຊັ່ນ : ຖະຫນົນ, ເຮືອນເລກທີ, ຊຸມຊົນ,  ເລກທີ່ຊັ້ນ ແລະ ອື່ນໆ  ',
    设为默认收货地址: 'ຕັ້ງເປັນທີ່ຢູ່ຈັດສົ່ງເລີ່ມຕົ້ນ',
    选中地址: 'ເລືອກທີ່ຢຸ່',
    选择新地址: 'ເລືອກທີ່ຢູ່ໃໝ່',
    设为默认: 'ຕັ້ງເປັນຄ່າເລີ່ມຕົ້ນ',
    默认地址: 'ທີ່ຢູ່ເລີ່ມຕົ້ນ',
    未添加收货地址: 'ບໍ່ໄດ້ເພີ່ມທີ່ຢູ່ຈັດສົ່ງ',
    添加新地址: 'ເພີ່ມທີ່ຢູ່ໃໝ່',
    您的购物车还没有商品哟: 'ບໍ່ມີສິນຄ້າຢູ່ໃນກະຕ່າຊື້ເຄື່ອງຂອງທ່ານ~',
    继续逛逛: 'ສືບຕໍ່ຊື້ເຄື່ອງ',
    失效宝贝: 'ສິນຄ້າໝົດອາຍຸ',
    删除失效商品: 'ລົບສິນຄ້າທີ່ຫມົດອາຍຸ',
    已选择: 'ເລືອກແລ້ວ ',
    商品合计: 'ສິນຄ້າທັງໝົດ',
    下单结算: 'ຊຳລະລາຍການສັ່ງຊື້',
    件商品: 'ລາຍການ',
    首页: 'ໜ້າຫຼັກ',
    分类: 'ການຈັດປະເພດ',
    我的: 'ຂອງຂ້ອຍ',
    请填写完整必填项: 'ກະລຸນາຕື່ມຂໍ້ມູນໃຫ້ຄົບຖ້ວນ',
    销量最高: 'ຍອດຂາຍສູງສຸດ',
    销量最低: 'ຍອດຂາຍຕ່ຳສຸດ',
    价格最高: 'ລາຄາສູງສຸດ',
    价格最低: 'ລາຄາຕ່ຳສຸດ',
    评论成功: 'ສະແດງຄຳເຫັນສຳເລັດ',
    秒后重新发送: 'ສົ່ງອີກຄັ້ງໃນ {seconds} ວິນາທີ',
    发送验证码: 'ສົ່ງລະຫັດຢືນຢັນ',
    密码最少8位最多16位:
        'ລະຫັດຜ່ານຕ້ອງມີຢ່າງໜ້ອຍ 8 ຕົວອັກສອນ ແລະ ບໍ່ເກີນ 16 ຕົວອັກສອນ',
    留言回复长度XX字符: 'ຂໍ້ຄວາມຕອບກັບ, ຄວາມຍາວ 2-100 ຕົວອັກສອນ',
    请输入正确的密码格式必须包含两种字符:
        'ກະລຸນາໃສ່ຮູບແບບລະຫັດຜ່ານທີ່ຖືກຕ້ອງຕ້ອງມີສອງປະເພດ',
    修改成功: 'ແກ້ໄຂສຳເລັດແລ້ວ',
    发送成功: 'ສົ່ງສຳເລັດແລ້ວ',
    需勾选协议: 'ຈໍາເປັນຕ້ອງໄດ້ໝາຍຕິກຂໍ້ຕົກລົງ',
    注册成功: 'ລົງທະບຽນສໍາເລັດ',
    请勾选信息: 'ກະລຸນາໝາຍຕິກຂໍ້ມູນ',
    请选择删除项需已读才能删除: 'ກະລຸນາເລືອກລາຍການທີ່ຈະລົບ, ຕ້ອງອ່ານກ່ອນແລ້ວລົບ',
    删除订单成功: 'ລົບຄຳສັ່ງຊື້ສຳເລັດ',
    订单已超时: 'ຄຳສັ່ງຊື້ໝົດເວລາ',
    确认收货成功: 'ຢືນຢັນການຮັບສິນຄ້າສຳເລັດ',
    取消订单成功: 'ຍົກເລີກຄຳສັ່ງຊື້ສຳເລັດ',
    部分商品正在退款中: 'ບາງລາຍການກຳລັງດຳເນີນການຄືນເງິນ',
    复制成功: 'ສຳເນົາສຳເລັດແລ້ວ',
    天: 'ມື້',
    时: 'ເວລາ',
    分: 'ນາທີ',
    秒: 'ວິນາທີ',
    我要退款: 'ຂ້ອຍຕ້ອງການຄືນເງິນ',
    我要退货退款: 'ຂ້ອຍຕ້ອງການຄືນສິນຄ້າແລະ ຄືນເງິນ',
    最多上传五张: 'ອັບໂຫຼດສູງສຸດໄດ້5ໃບ',
    不可修改最多: 'ບໍ່ສາມາດແກ້ໄຂໄດ້, ຫຼາຍທີ່ສຸດ',
    含发货邮费: 'ລວມຄ່າສົ່ງຈັດສົ່ງແລ້ວ',
    上传成功: 'ອັບໂຫຼດສຳເລັດແລ້ວ',
    上传中: 'ກຳລັງອັບໂຫຼດ...',
    图片上传成功: 'ອັບໂຫຼດຮູບພາບສຳເລັດ',
    图片上传失败请重试: 'ອັບໂຫຼດຮູບລົ້ມເຫຼວ, ກະລຸນາລອງໃໝ່ອີກຄັ້ງ',
    文件过大: 'ໄຟລ໌ໃຫຍ່ເກີນໄປ',
    取消成功: 'ຍົກເລີກສຳເລັດ',
    请输入反馈内容: 'ກະລຸນາໃສ່ເນື້ອໃນຄໍາຄິດຄໍາເຫັນ',
    重新发送验证码: 'ສົ່ງລະຫັດອີກຄັ້ງ',
    两次输入密码不一致: 'ລະຫັດຜ່ານທີ່ໃສ່ທັງ  2ຄັ້ງແມ່ນບໍ່ກົງກັນ!',
    新密码不能和旧密码相同: 'ລະຫັດຜ່ານໃໝ່ບໍ່ສາມາດໃສ່ຄືກັບໂຕເກົ່າໄດ້',
    上传头像图片只能是JPG格式: 'ໂປຣຟາຍທີ່ອັບລົງຈະຕ້ອງເປັນ JPG ເທົ່ານັ້ນ!',
    上传头像图片大小不能超过2MB: 'ຂະໜາດຟາຍທີ່ອັບລົງຕ້ອງບໍ່ເກີນ2MB!',
    昵称不能为空: 'ຊື່ຫຼີ້ນຫ້າມວ່າງເປົ່າ',
    添加超时: 'ເພີ່ມລາຍການເກີນເວລາ',
    请选择付款方式: 'ກະລຸນາເລືອກວິທີການຊຳລະເງິນ',
    请填写收货地址: 'ກະລຸນາໃສ່ທີ່ຢູ່ຮັບສິນຄ້າ',
    请选择: 'ກະລຸນາເລືອກ',
    请选择规格: 'ກະລຸນາເລືອກຂໍ້ມູນລາຍລະອຽດຂອງສິນຄ້າ',
    添加成功: 'ເພີ່ມລາຍການສຳເລັດ',
    添加失败库存不足: 'ເພີ່ມສິນຄ້າບໍ່ສຳເລັດ ສິນຄ້າໃນຄັງບໍ່ພຽງພໍ',
    确定要清空历史记录吗: 'ທ່ານແນ່ໃຈວ່າຕ້ອງການລຶບປະຫວັດ?',
    综合排序: 'ການຈັດລຽງລຳດັບໂດຍລວມ',
    销量高低: 'ຍອດຂາຍສູງຕ່ຳ',
    价格高低: 'ລາຄາສູງຕ່ຳ',
    编辑地址: 'ແກ້ໄຂທີ່ຢູ່',
    新增地址: 'ເພີ່ມທີ່ຢູ່',
    是否选择该地址: 'ຕ້ອງການເລືອກທີ່ຢູ່ນີ້ບໍ',
    是否删除地址: 'ຕ້ອງການລຶບທີ່ຢູ່ຫຼືບໍ່',
    删除成功: 'ລຶບສຳເລັດແລ້ວ',
    已取消删除: 'ຍົກເລີກການລຶບ',
    未选择地址或没有地址: 'ບໍ່ໄດ້ເລືອກທີ່ຢູ່ ຫຼື ບໍ່ມີທີ່ຢູ່',
    选择成功: 'ເລືອກສຳເລັດ',
    确定删除选中的商品吗: 'ແນ່ໃຈບໍ່ວ່າຕ້ອງການລົບລາຍການທີ່ເລືອກ？',
    已取消: 'ຍົກເລີກແລ້ວ',
    提示: 'ສະເໜີແນະ',
    请勾选删除商品: 'ກະລຸນາກວດເບິ່ງລາຍການສິນຄ້າທີ່ຈະລົບ',
    请勾选商品: 'ກະລຸນາໝາຍຕິກສິນຄ້າ',
    加载中: 'ກຳລັງດາວໂຫຼດ',
    全部分类: 'ໝວດໝູ່ທັງໝົດ',
    客户服务: 'ບໍ ລິ ການ ລູກ ຄ້າ',
    退货数量: 'ຈຳນວນສົ່ງສິນຄ້າຄືນ',
    未读消息: 'ຂໍ້ຄວາມທີ່ຍັງບໍ່ທັນອ່ານ',
    确定放弃支付吗: 'ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການຍົກເລີກການຈ່າຍເງິນ?',
    确认订单: 'ຢືນຢັນລາຍການສັ່ງຊື້',
    请输入内容: 'ກະລຸນາໃສ່ເນື້ອໃນ',
    未发货: 'ບໍ່ທັນຈັດສົ່ງ',
    已发货: 'ຈັດສົ່ງແລ້ວ',
    取消时间: 'ເວລາຍົກເລີກ',
    请输入下方图形验证码: 'ກະລຸນາໃສ່ລະຫັດການຢັ້ງຢືນຮູບພາບຂ້າງລຸ່ມນີ້',
    看不清换一张: 'ປ່ຽນອັນຫນຶ່ງ',
    留言成功: 'ຝາກຂໍ້ຄວາມສຳເລັດ',
    长度在1到8个字符: 'ຄວາມຍາວ 1-8 ຕົວອັກສອນ',
    OnePay支付: 'ຊຳລະOnePay',
    OnePay支付二维码: 'ຊຳລະດ້ວຍຄິວອາໂຄດOnePay',
    扫码或者点击去支付: 'ສະແກນຄິວອາໂຄດ ຫຼື ກົດ “ໄປຊຳລະເງິນ”',
    去支付: 'ໄປຊຳລະເງິນ',
    通过BCEL银行完成支付后务必返回当前页面确认支付是否成功:
        'ຊຳລະເງິນດ້ວຍທະນາຄານBCELສຳເລັດແລ້ວ，ຈະຕ້ອງໄດ້ຍ້ອນກັບໄປໜ້າຫຼັກເພື່ອກວດສອບວ່າຊຳລະເງິນສຳເລັດແລ້ວຫຼືຍັງ',
    请输入留言内容若您使用BCEL等支付请在此输入用来接收退款的银行卡号及户名:
        'ກະລຸນາຂຽນຂໍ້ຄວາມຂອງທ່ານປະໄວ້, ຖ້າທ່ານຊໍາລະຜ່ານ BCEL ຫຼື KBZPayກະລຸນາພິມເລກບັນຊີ ແລະ ຊື່ບັນຊີທະນາຄານຂອງທ່ານ ສຳລັບໄຊ້ໃນການຈ່າຍ ແລະ ຮັບເງິນຄື',
    查看支付是否成功: 'ກວດເບິ່ງວ່າການຈ່າຍເງິນສຳເລັດຫຼືບໍ່>>>',
    查询: 'ສອບຖາມ',
    KBZ支付: 'KBZpay',
    BCEL支付: 'BCEL',
    查询中: 'ສອບຖາມ',
    语言: 'ພາສາ',
    货币: 'ເງິນຕາ',
    切换语言和货币: 'ສະຫຼັບພາສາ ແລະສະກຸນເງິນ',
    您未支付请重新支付: 'ທ່ານຍັງບໍ່ໄດ້ຈ່າຍ, ກະລຸນາຈ່າຍອີກເທື່ອຫນຶ່ງ',
    确定已输入接收退款的银行卡号和户名:
        'ໃຫ້ແນ່ໃຈວ່າທ່ານໄດ້ໃສ່ເລກບັດທະນາຄານ ແລະຊື່ບັນຊີເພື່ອຮັບເງິນຄືນ',
    绑定: 'ຜູກມັດ',
    绑定邮箱: 'ຜູກມັດອີເມວ',
    分享店铺: 'ແບ່ງປັນຮ້ານ',
    分享至: 'ແຊຣ໌ໄປທີ່',
    海报: 'ໂປສເຕີ',
    分享: 'ແບ່ງປັນ',
    推荐店铺给你: 'ສູນການຄ້າແນະນຳໃຫ້ທ່ານ',
    链接: 'ເຊື່ອມຕໍ່',

    至: 'ເຖິງ',
    币种: 'ສະກຸນເງິນ',
    变动理由: 'ເຫດຜົນໃນການປ່ຽນແປງ',
    不记得: 'ບໍ່ຈື່',
    充值: 'ເຕີມ',
    打款凭证: 'ໃບຢັ້ງຢືນການຈ່າຍເງິນ',
    打款说明: 'ຄໍາແນະນໍາການຈ່າຍເງິນ',
    打款信息: 'ຂໍ້ມູນການຊຳລະເງິນ',
    打款状态: 'ສະຖານະການຊຳລະເງິນ',
    待打款: 'ການຈ່າຍເງິນທີ່ຍັງຄ້າງຢູ່',
    待审核: 'ທີ່ຈະໄດ້ຮັບການທົບທວນຄືນ',
    到账方式: 'ວິທີການຊຳລະເງິນ',
    到账银行卡号: 'ຖອນເງິນຮອດບັດທະນາຄານ',
    冻结中: 'ອາຍັດຢູ່',
    国家: 'ຊາດ',
    获取验证码: 'ຮັບລະຫັດຢືນຢັນ',
    记得: 'ຈື່ໄວ້',
    记录时间: 'ເວລາເຂົ້າສູ່ລະບົບ',
    拒绝打款: 'ປະຕິເສດທີ່ຈະຈ່າຍ',
    开户人: 'ເປີດບັນຊີ',
    开户支行: 'ສາຂາການເປີດບັນຊີ',
    可提现: 'ສາມາດຖອນເງິນໄດ້',
    可提现金额: 'ຈໍານວນທີ່ສາມາດຖອນໄດ້',
    立即提现: 'ຖອນເງິນທັນທີ',
    流水号: 'ເລກລໍາດັບ',
    没有适合此币种的银行卡请先添加: 'ບໍ່ມີບັດສຳລັບສະກຸນເງິນນີ້, ກະລຸນາເພີ່ມກ່ອນ',
    没有收款码请先添加: 'ບໍ່ມີລະຫັດການຊຳລະເງິນ, ກະລຸນາເພີ່ມກ່ອນ',
    每天最多提现x次单次最高提现金额x: 'ຖອນສູງສຸດ*ຄັ້ງຕໍ່ວັນ，ຈຳນວນຖອນສູງສຸດຄັ້ງດຽວ*',
    您是否记得账号当前使用的支付密码: 'ທ່ານຈື່ລະຫັດຜ່ານການຊຳລະເງິນທີ່ບັນຊີຂອງທ່ານໃຊ້ຢູ່ໃນປັດຈຸບັນຫຼືບໍ່?',
    您未设置支付密码请先设置: 'ທ່ານຍັງບໍ່ໄດ້ຕັ້ງລະຫັດຜ່ານການຊຳລະເງິນ, ກະລຸນາຕັ້ງກ່ອນ',
    钱包余额: 'ຈຳນວນເງິນໃນກະເປົາ',
    请输入开户人: 'ກະລຸນາປ້ອນຊື່ເຈົ້າຂອງບັນຊີ',
    请输入开户支行: 'ກະລຸນາປ້ອນສາຂາການເປີດບັນຊີ',
    请输入收款名称: 'ກະລຸນາປ້ອນຊື່ການຊຳລະເງິນ',
    请输入提现金额: 'ກະລຸນາປ້ອນຈໍານວນການຖອນເງິນ',
    请输入新支付密码6位数字: 'ກະລຸນາປ້ອນລະຫັດຜ່ານການຊຳລະເງິນໃໝ່, 6ຫຼັກ',
    请输入新支付密码: 'ກະລຸນາປ້ອນລະຫັດການຊຳລະເງິນໃໝ່',
    请输入银行卡号: 'ກະລຸນາປ້ອນໝາຍເລກບັດທະນາຄານ',
    请输入原支付密码: 'ກະລຸນາປ້ອນລະຫັດຜ່ານການຊຳລະເງິນເກົ່າ',
    请输入支付密码6位数字: 'ກະລຸນາປ້ອນລະຫັດຜ່ານການຊຳລະເງິນ，6ຫຼັກ',
    请输入支付密码: 'ກະລຸນາປ້ອນລະຫັດການຊຳລະເງິນ',
    请选择币种: 'ກະລຸນາເລືອກສະກຸນເງິນ',
    请选择大洲: 'ກະລຸນາເລືອກທະວີບ',
    请选择结束时间: 'ກະລຸນາເລືອກວັນທີສິ້ນສຸດ',
    请选择开始时间: 'ກະລຸນາເລືອກວັນທີເລີ່ມຕົ້ນ',
    请选择收款码类型: 'ກະລຸນາເລືອກປະເພດລະຫັດການຊຳລະເງິນ',
    请选择银行: 'ກະລຸນາເລືອກທະນາຄານ',
    请选择银行名称: 'ກະລຸນາເລືອກຊື່ທະນາຄານ',
    请再次输入新支付密码: 'ກະລຸນາປ້ອນລະຫັດຜ່ານການຊຳລະເງິນໃໝ່ອີກຄັ້ງ',
    请再次输入支付密码: 'ກະລຸນາປ້ອນລະຫັດຜ່ານການຊຳລະເງິນອີກຄັ້ງ',
    去设置: 'ໄປທີ່ການຕັ້ງຄ່າ',
    全部提现: 'ຖອນເງິນທັງຫມົດ',
    确定删除收款码吗: 'ແນ່ໃຈບໍ່ວ່າຈະລຶບລະຫັດການຊຳລະເງິນ',
    确定删除银行卡吗: 'ແນ່ໃຈບໍ່ວ່າຈະລຶບບັດທະນາຄານ',
    确定提现: 'ຢືນຢັນການຖອນເງິນ',
    确认打款: 'ຢືນຢັນການຈ່າຍເງິນ',
    确认新密码: 'ກະລຸນາປ້ອນລະຫັດຜ່ານການຊຳລະເງິນໃໝ່ອີກຄັ້ງ',
    确认支付密码: 'ຢືນຢັນລະຫັດຜ່ານການຊຳລະເງິນ',
    上传的收款二维码需要清晰无遮挡: 'ລະຫັດ QR ການຊຳລະເງິນທີ່ອັບໂຫລດຈະຕ້ອງຊັດເຈນແລະ ບໍ່ມີສິ່ງກີດຂວາງ',
    审核备注: 'ການໄລ່ລຽງບັນທຶກ',
    审核不通过: 'ການກວດສອບບໍ່ຜ່ານ',
    审核结果: 'ຜົນການກວດສອບ',
    审核通过: 'ຜ່ານການໄລ່ລຽງ',
    审核信息: 'ກວດສອບຂໍ້ມູນ',
    时间: 'ເວລາ',
    实际到账金额: 'ຈຳນວນເງິນທີ່ໄດ້ຮັບຈິງ',
    收款方式: 'ວິທີການຊຳລະເງິນ',
    收款管理: 'ການຈັດການຄໍເລັກຊັນ',
    收款码: 'ລະຫັດການຊຳລະເງິນ',
    收款码管理: 'ການຈັດການລະຫັດການຊຳລະເງິນ',
    收款码类型: 'ປະເພດລະຫັດການຊຳລະເງິນ',
    收款名称: 'ຊື່ຜູ້ຮັບເງິນ',
    收款账号: 'ໝາຍເລກບັນຊີການຊຳລະເງິນ',
    手续费: 'ຄ່າທໍານຽມການຖອນເງິນ',
    提现: 'ຖອນເງິນ',
    提现币种: 'ສະກຸນເງິນທີ່ຖອນ',
    提现成功: 'ສຳເລັດການຖອນເງິນ',
    提现记录: 'ບັນທຶກການຖອນເງິນ',
    提现金额: 'ຈໍານວນການຖອນເງິນ',
    提现失败: 'ລົ້ມເຫລວການຖອນເງິນ',
    提现时间: 'ເວລາຖອນເງິນ',
    提现手续费: 'ຄ່າທຳນຽມຖອນເງິນ',
    提现详情: 'ລາຍລະອຽດຖອນເງິນ',
    提现中: 'ກຳລັງຖອນເງິນ',
    提现状态: 'ສະຖານະການຖອນເງິນ',
    添加收款码: 'ເພີ່ມລະຫັດການຊຳລະເງິນ',
    添加银行卡: 'ເພີ່ມບັດທະນາຄານ',
    退款: 'ການຄືນເງິນ',
    忘记支付密码: 'ລືມລະຫັດການຊຳລະເງິນ?',
    尾号: 'ໝາຍເລກຫາງ',
    我的钱包: 'ກະເປົາເງິນຂອງຂ້ອຍ',
    我的余额: 'ຈຳນວນເງິນຂອງຂ້ອຍ',
    详情: 'ລາຍລະອຽດ',
    消费: 'ລາຍຈ່າຍ',
    新支付密码: 'ລະຫັດຜ່ານຊຳລະເງິນໃໝ່',
    修改支付密码: 'ປ່ຽນລະຫັດຜ່ານຊຳລະເງິນ',
    选择收款方式: 'ເລືອກວິທີການຊຳລະເງິນ',
    银行卡: 'ບັດທະນາຄານ',
    银行卡管理: 'ການຈັດການບັດທະນາຄານ',
    银行卡号: 'ເລກບັດບັນຊີ',
    银行名称: 'ຊື່ທະນາຄານ',
    余额记录: 'ບັນທຶກຈຳນວນເງິນ',
    余额数据: 'ຂໍ້ມູນຈຳນວນ',
    原支付密码: 'ລະຫັດຜ່ານຊຳລະເງິນເກົ່າ',
    暂无数据: 'ບໍ່ ມີ ຂໍ້ ມູນ',
    支持币种: 'ສະ ຫນັບ ສະ ຫນູນ ສະ ກຸນ ເງິນ ',
    支付密码: 'ລະຫັດຜ່ານຊຳລະເງິນ',
    只可上传一张图片图片大小不超过5M支持jpgpngjpeg格式文件: 'ອັບໂຫລດໄດ້ພຽງຮູບດຽວ, ຂະໜາດບໍ່ເກີນ5M,ຮອງຮັບfile jpg png jpeg',
    账户余额: 'ຍອດເງິນໃນບັນຊີ',
    钱包服务: 'ບໍລິການກະເປົາເງິນ',
    每天最多提现x次单次最高提现金额xx最低提现金额xx: 'ຈໍານວນການຖອນສູງສຸດຕໍ່ມື້ແມ່ນ {num} ຄັ້ງ, ຈໍານວນການຖອນສູງສຸດຕໍ່ຄັ້ງແມ່ນ {icon}{money}, ແລະຈໍານວນການຖອນຂັ້ນຕ່ໍາແມ່ນ {icon}{money2}.',
    取消: 'ຍົກເລີກ',
    确认: 'ຢືນຢັນ',
    输入金额超过可提现余额: 'ຈໍານວນທີ່ປ້ອນເຂົ້າເກີນຍອດເງິນທີ່ຖອນໄດ້',
    日期选择: 'ການເລືອກວັນທີ',
    备注: 'ຂໍ້ສັງເກດ',
    当前没有余额记录: 'ປະຈຸບັນບໍ່ມີບັນທຶກຍອດເງິນ!',
    当前没有提现记录: 'ປະຈຸບັນບໍ່ມີບັນທຶກການຖອນເງິນ!',
    还没有绑定银行卡: 'ບໍ່ມີບັດທະນາຄານຖືກຜູກມັດເທື່ອ!',
    还没有添加收款码: 'ຍັງບໍ່ໄດ້ເພີ່ມລະຫັດການຈ່າຍເງິນເທື່ອ!',
    提示只可上传一张图片图片大小不超过5M支持xxx格式文件上传的收款二维码需要清晰无遮挡: 'ເຄັດ ລັບ : ພຽງ ແຕ່ ຫນຶ່ງ ຮູບ ພາບ ທີ່ ສາ ມາດ ອັບ ໂຫຼດ ໄດ້ , ຂະ ຫນາດ ຂອງ ຮູບ ພາບ ບໍ່ ຄວນ ຈະ ເກີນ 5M , ແລະ ໄຟລ ໌ ຮູບ ແບບ jpg , png , ແລະ jpeg ສະ ຫນັບ ສະ ຫນູນ . ລະຫັດ QR ການຈ່າຍເງິນທີ່ອັບໂຫຼດຕ້ອງຈະແຈ້ງ ແລະ ບໍ່ມີສິ່ງກີດຂວາງ.',
    请上传收款码: 'ກະລຸນາອັບໂຫລດລະຫັດການຈ່າຍເງິນ',
    请输入收款码名称: 'ກະລຸນາໃສ່ຊື່ລະຫັດການຈ່າຍເງິນ',
    暂无银行卡: 'ບໍ່ມີບັດທະນາຄານເທື່ອ',
    余额: 'ຍອດເງິນ',
    重置: 'ຣີເຊັດ',
    暂无收款码: 'ບໍ່ມີລະຫັດການຈ່າຍເງິນເທື່ອ',
    保障支付安全: 'ຮັບປະກັນຄວາມປອດໄພຂອງການຈ່າຍເງິນ',
    打款成功: 'ການຈ່າຍເງິນສຳເລັດແລ້ວ',
    请选择收款方式: 'ກະລຸນາເລືອກວິທີການຊໍາລະເງິນ',
    收款类型: 'ປະເພດການຈ່າຍເງິນ',
    收款码名称: 'ຊື່ລະຫັດການຈ່າຍເງິນ',
    编辑银行卡: 'ແກ້ໄຂບັດທະນາຄານ',
    编辑收款码: 'ແກ້ໄຂລະຫັດການຈ່າຍເງິນ',
    订单ID: 'ລະຫັດຄໍາສັ່ງ',
    如果您已支付成功请点击确认支付按钮: 'ຖ້າການຈ່າຍເງິນຂອງທ່ານປະສົບຜົນສໍາເລັດ, ກະລຸນາກົດປຸ່ມ "ຢືນຢັນການຈ່າຍເງິນ"',
    确认支付: 'ຢືນຢັນການຈ່າຍເງິນ',
    支付遇到问题: 'ພົບກັບບັນຫາ',
    对不起您访问的店铺可能已被删除名称被更改或不存在: 'ຂໍ ອະ ໄພ, ຮ້ານ ທີ່ ທ່ານ ໄດ້ ໄປ ຢ້ຽມ ຢາມ ອາດ ຈະ ໄດ້ ຮັບ ການ ລຶບ, ຊື່ ຂອງ ຕົນ ໄດ້ ຖືກ ປ່ຽນ ແປງ, ຫຼື ມັນ ອາດ ຈະ ບໍ່ ມີ ...',
    卢比支付: 'ການຈ່າຍເງິນ IDR',
    比索支付: 'ການຈ່າຍເງິນ PHP',
    泰铢支付: 'ການຈ່າຍເງິນ THB',
    越南盾支付: 'ການຈ່າຍເງິນ VND',
    银行卡编码: 'ລະຫັດທະນາຄານ',
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-0": "{currencyUnit}{money}",
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-1": "ກິໂລທຳອິດ{num}kg{currencyUnit}{money}，ກິໂລຕໍ່ໄປ{num2}kg{currencyUnit}{money2}",
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-2": "ເຄື່ອງທຳອິດ{num3}ອັນ{currencyUnit}{money3}，ເຄື່ອງຕໍ່ໄປ{num4}ອັນ{currencyUnit}{money4}",
    暂无配送地区: 'ບໍ່ມີພື້ນທີ່ຈັດສົ່ງເທື່ອ',
    该商品在当前地区暂不支持配送: 'ຜະລິດຕະພັນນີ້ບໍ່ຮອງຮັບການຈັດສົ່ງໃນພາກພື້ນປັດຈຸບັນ',
    当前商品仅支持以下地区发货如有疑问请联系店铺:
        'ຜະລິດຕະພັນໃນປະຈຸບັນພຽງແຕ່ສະຫນັບສະຫນູນການຈັດສົ່ງໃນພື້ນທີ່ຕໍ່ໄປນີ້. ຖ້າທ່ານມີຄໍາຖາມໃດໆ, ກະລຸນາຕິດຕໍ່ກັບຮ້ານ',
    运费计价说明: 'ຄໍາແນະນໍາລາຄາການຂົນສົ່ງ',
    发货地: 'ສະຖານທີ່ຈັດສົ່ງ',
    配送至: 'ສົ່ງໃຫ້',
    打款中: 'ກຳລັງດຳເນີນການຈ່າຍເງິນ',
    未添加地址请先添加: 'ບໍ່ໄດ້ເພີ່ມທີ່ຢູ່, ກະລຸນາເພີ່ມມັນກ່ອນ',
    请输入大于1的正整数: 'ກະລຸນາໃສ່ຈຳນວນເຕັມບວກທີ່ໃຫຍ່ກວ່າ 1',
    设置密码: 'ຕັ້ງລະຫັດຜ່ານ',
    登录密码: 'ລະຫັດຜ່ານເຂົ້າສູ່ລະບົບ',
    长度在8到16个字符: 'ຄວາມຍາວລະຫວ່າງ 8 ຫາ 16 ຕົວອັກສອນ',
    请输入旧密码: 'ກະລຸນາໃສ່ລະຫັດຜ່ານເກົ່າ',
    必须包含两种字符: 'ຕ້ອງມີຕົວອັກສອນສອງຕົວ',
    请再次输入密码: 'ກະລຸນາໃສ່ລະຫັດຜ່ານອີກຄັ້ງ',
    当前仅支持xxx的金额: 'ປະຈຸບັນນີ້ຮອງຮັບແຕ່ {currency} {min}-{currency} {max} ເທົ່ານັ້ນ',
    查看运费: 'ເບິ່ງຄ່າຂົນສົ່ງ',
    运费方式: 'ວິທີການຂົນສົ່ງ',
    价格: 'ລາຄາ',
    请选择配送方式: 'ກະລຸນາເລືອກວິທີການຈັດສົ່ງ',
    配送方式: 'ວິທີການຈັດສົ່ງ',
    配送费说明: 'ລາຍລະອຽດຄ່າຈັດສົ່ງ',
    我明白了: 'ຂ້ອຍເຫັນ',
    若多个商品选用同一个运费模板则运费是按同一个运费方式的规则进行计算: 'ຖ້າສິນຄ້າຫຼາຍຊະນິດໃຊ້ແມ່ແບບການຂົນສົ່ງດຽວກັນ, ການຂົນສົ່ງຈະຖືກຄິດໄລ່ຕາມກົດລະບຽບຂອງວິທີການຂົນສົ່ງດຽວກັນ.',
    若多个商品不是同一个运费模板则运费是多个模板的价格之和:
        'ຖ້າສິນຄ້າຫຼາຍອັນບໍ່ມີແມ່ແບບຄ່າຂົນສົ່ງຄືກັນ, ຄ່າຂົນສົ່ງແມ່ນຜົນລວມຂອງລາຄາຂອງຫຼາຍແມ່ແບບ.',
    快捷登录登录即表示同意协议: 'ໂດຍການເຂົ້າສູ່ລະບົບ, ທ່ານຕົກລົງເຫັນດີກັບຂໍ້ຕົກລົງ',
    促销广告: 'ການໂຄສະນາສົ່ງເສີມ',
    最近新品: 'ຜະລິດຕະພັນໃຫມ່',
    更多: 'ເພີ່ມເຕີມ',
    设置成功: 'ຕັ້ງຄ່າສຳເລັດແລ້ວ',
    更多商品:'ຜະລິດຕະພັນເພີ່ມເຕີມ',


    //     1.6
    我的优惠券:'ຄູປ໋ອງສ່ວນຫຼຸດຂອງຂ້ອຍ',
    历史记录:'ບັນທຶກປະຫວັດສາດ',
    已使用:'ໃຊ້ແລ້ວ',
    已过期:'ໝົດອາຍຸແລ້ວ',
    优惠券:'ຄູປ໋ອງ',
    领取:'ຮັບ',
    已领取:'ໄດ້ຮັບ',
    有效期:'ໄລຍະເວລາທີ່ຖືກຕ້ອງ：',
    活动优惠详情:'ລາຍລະອຽດສ່ວນຫຼຸດເຫດການ',
    可用商品:'ລາຍການທີ່ມີຢູ່',
    单独购买:'ລາຄາຕົ້ນສະບັບ',
    发起拼团:'ລາຄາຊື້ກຸ່ມ',
    拼团价:'ລາຄາຊື້ກຸ່ມ',
    可参与的拼团:'ການຊື້ກຸ່ມທີ່ມີຢູ່',
    '再邀X人即可拼单成功':'ແບ່ງປັນກັບອີກ {num} ຄົນເພື່ອການສັ່ງຊື້ທີ່ສຳເລັດ >',
    拼单详情:'ລາຍລະອຽດການຊື້ກຸ່ມ',
    待分享:'ລໍຖ້າແບ່ງປັນ',
    '若拼团失败，货款将原路退回':'ຖ້າການຊື້ກຸ່ມລົ້ມເຫລວ, ການຈ່າຍເງິນຈະຖືກສົ່ງຄືນ.',
    促销价:'ລາຄາຂາຍ',
    距离结束时间:'End in',
    满XX减XX:"ຊື້ {currencyUnit}{priceStart} ການສະຖານທີ  ່{currencyUnit}{reducePrice}{couponType==2?'%':''} ໄດ້",
    满XX减X:'ຊື້ {currencyUnit}{priceStart} ການສະຖານທີ່ {reducePrice}% ໄດ້',
    分享好友X人成团立省X:'ຊື້ເປັນກຸ່ມ <span style="color: #8D4BBB;">{num}</span>ຄົນ ປະຢັດ<span style="color: #8D4BBB;">{currencyUnit}{groupPrice}</span>',
    分享好友:'ແບ່ງປັນກັບຫມູ່ເພື່ອນ',
    '这些人正在拼，立即参与 ':'ກໍາລັງຊື້ກຸ່ມ, ເຂົ້າຮ່ວມດຽວນີ້ ',
    满x可用:'ມີໃຫ້ສັ່ງເກີນ {currencyUnit}{priceStart}',
    这些人正在拼立即参与:'ກໍາລັງຊື້ກຸ່ມ, ເຂົ້າຮ່ວມດຽວນີ້',
    限领x张:'{receiveCount} ຄູປ໋ອງຕໍ່ຂີດຈຳກັດຜູ້ໃຊ້',
    满XX送赠品:'ຂອງຂວັນຟຣີສໍາລັບຄໍາສັ່ງຫຼາຍກວ່າ {currencyUnit}{priceStart}',
    去使用:'ການ ນໍາ ໃຊ້',
    销量:'ການຂາຍ',
    赠品:'ແຈກ',
    限时优惠:'ການສະເຫນີເວລາຈໍາກັດ',
    距结束:'End in ：',
    拼团好物:'ຊື້ຄຸນສະມາຊິກ',
    当前优惠券可以购买以下商品:'ຄູປ໋ອງປະຈຸບັນສາມາດຊື້ຜະລິດຕະພັນຕໍ່ໄປນີ້:',
    以下商品:'ຜົນຜະລິດຕະພັນີ້ນ',
    满XX减XX可用:"ຊື້ {currencyUnit}{priceStart} ການສະຖານທີ່ {currencyUnit}{reducePrice} ໄດ້",
    满XX减X可用:'ຊື້ {currencyUnit}{priceStart} ການສະຖານທີ່ {reducePrice}% ໄດ້',
    再买x可用:'ຍັງຕ້ອງການຊື້ {currencyUnit}{difPrice}',
    合计:'ທັງໝົດ',
    优惠:'ສ່ວນຫຼຸດ',
    去购物车:'ກະຕ່າຊື້ເຄື່ອງ',
    拼团:'ຊື້ຄຸນສະມາຊິກ',
    满XX可用:'ມີໃຫ້ສັ່ງເກີນ {currencyUnit}{priceStart}',
    满减满赠:'ສ່ວນຫຼຸດເຕັມ/ຂອງຂວັນເຕັມ',
    成团发货:'ລໍຖ້າການຈັດສົ່ງ',
    还差xx人成团:'ຍັງຕ້ອງການ <span style="color: #FF4545;">{num}</span> ຄົນເພື່ອສັ່ງໃຫ້ສຳເລັດ',
    拼团结束:'ການຊື້ກຸ່ມສິ້ນສຸດລົງ',
    拼团成功:'ການຊື້ກຸ່ມປະສົບຜົນສໍາເລັດ',
    有成员未付款请等待付款成团:'ບາງຄົນຍັງບໍ່ໄດ້ຈ່າຍເງິນ, ກະລຸນາລໍຖ້າການຈ່າຍເງິນ',
    我正在参与的拼团:'ການ ເຂົ້າ ຮ່ວມ ໃນ ການ ຊື້ ກຸ່ມ',
    满减:'ສ່ວນຫຼຸດເຕັມ',
    满赠:'ຂອງຂວັນເຕັມ',
    凑单:'ເຮັດຄໍາສັ່ງຮ່ວມກັນ',
    切换其他优惠:'ເລືອກຂໍ້ສະເໜີອື່ນ',
    超过最大购买数:'ເກີນຈຳນວນການຊື້ສູງສຸດ',
    商品限购x件:'ຈໍາກັດ {num} ຕ່ອນ',
    参与拼团:'ເຂົ້າຮ່ວມ',
    结束:'结束',
    您还没有优惠券:'ເຈົ້າບໍ່ມີຄູປ໋ອງ',
    优惠券历史记录:'ບັນທຶກປະຫວັດສາດ',
    拼单失败:'ການຊື້ກຸ່ມລົ້ມເຫລວ',
    免费:'ຟຣີ',
    已赠完:'ສິນຄ້າໝົດ',
    此拼团已满请选择其他拼团:'ການຊື້ກຸ່ມນີ້ເຕັມແລ້ວ, ກະລຸນາເລືອກການຊື້ກຸ່ມອື່ນ',
    以下商品正在限时优惠当中:'ການສະເຫນີເວລາຈໍາກັດກ່ຽວກັບຜະລິດຕະພັນຕໍ່ໄປນີ້',
    以下商品正在限时拼团当中:'ເວລາທີ່ຈຳກັດການຊື້ສິນຄ້າຕໍ່ໄປນີ້ເປັນກຸ່ມ',
    再买X可减X:'ຊື້ອີກ {currencyUnit}{difPrice} ຮັບສ່ວນຫຼຸດ {currencyUnit}{reducePrice}',
    已领完:"ໃຊ້ແລ້ວ",
    活动未开始:'ເຫດການຍັງບໍ່ໄດ້ເລີ່ມຕົ້ນ',

    已满X可减X:'ສ່ວນຫຼຸດ {currencyUnit}{reducePrice} ສຳລັບການຊື້ຫຼາຍກວ່າ {currencyUnit}{difPrice}',
    已满X可减XX:'ສ່ວນຫຼຸດ {reducePrice}% ສຳລັບການຊື້ຫຼາຍກວ່າ {currencyUnit}{difPrice}',

    再买X可减XX:'ຊື້ອີກ {currencyUnit}{difPrice} ຮັບສ່ວນຫຼຸດ {reducePrice}%',
    再买X送赠品:'ຊື້ອີກ {currencyUnit}{difPrice} ແລະຮັບຂອງຂັວນຟຣີ',
    已满X送赠品:'ຂອງຂວັນໄດ້ຖືກມອບໃຫ້',
    缺少库存: 'ສິນຄ້າໝົດ',
    限X件:'ຈໍາກັດການຊື້  {num} ຕ່ອນ',
    房间号已失效请联系工作人员:'ເລກຫ້ອງໝົດອາຍຸແລ້ວ, ກະລຸນາຕິດຕໍ່ພະນັກງານ!',


    退货积分:'ກັບຄືນຈຸດ',
    积分:'ປະສົມປະສານ',
    用户注册:'ການລົງທະບຽນຜູ້ໃຊ້',
    手动增加:'ເພີ່ມຂຶ້ນດ້ວຍຕົນເອງ',
    积分兑换退款:'ການຄືນເງິນຄະແນນ',
    下单购物:'ສັ່ງຊື້ສິນຄ້າ',
    过期积分:'ຈຸດໝົດອາຍຸ',
    兑换商品:'ແລກຊື້ສິນຄ້າ',
    手动减少:'ການຫຼຸດຜ່ອນຄູ່ມື',
    订单退款:'ສັ່ງຄືນເງິນ',
    积分明细:'ລາຍລະອຽດຄະແນນ',
    已兑:'ແລກແລ້ວ',
    积分商城:'Points Mall',
    规则:'ກົດລະບຽບ',
    可用积分:'ຄະແນນທີ່ມີຢູ່',
    冻结积分:'ຈຸດຄ້າງ',
    我的兑换:'ການແລກປ່ຽນຂອງຂ້ອຍ',
    售后:'ຫຼັງຈາກການຂາຍ',
    分销中心:'ສູນກະຈາຍສິນຄ້າ',
    立即兑换:'ແລກດຽວນີ້',
    已兑完:'ແລກແລ້ວ',
    "积分不足，无法兑换":'ຄະແນນບໍ່ພຽງພໍບໍ່ສາມາດແລກໄດ້',
    兑换数量:'ປະລິມານການແລກປ່ຽນ',
'限兑*件':'ການ​ແລກ​ປ່ຽນ​ຈໍາ​ກັດ ​{num} ລາຍ​ການ',
    积分抵扣:'ການຫັກຄະແນນ',
'共*分':'ລວມ {num} ຈຸດ',
    全部订单:'ຄໍາສັ່ງທັງຫມົດ',

兑换须知:'ຄໍາແນະນໍາການໄຖ່',
    已达兑换上限:'ຮອດຂີດຈຳກັດການໄຖ່ແລ້ວ',
    我的积分:'ຄະແນນຂອງຂ້ອຍ',
    积分规则:'ກົດລະບຽບຄະແນນ',
    兑换时间:'ເວລາແລກ',
    我的佣金:'ຄະນະກໍາມະຂອງຂ້ອຍ',
    佣金数据:'ຂໍ້ມູນຄະນະກໍາມະ',
    分销申请协议:'ຂໍ້ຕົກລົງການສະເໜີການແຈກຢາຍ',
    申请成为分销员:'ສະໝັກເປັນຕົວແທນຈຳໜ່າຍ',
    "已申请，请等待审核":'ນຳໃຊ້ແລ້ວ, ກະລຸນາລໍຖ້າກວດສອບ',
    "审核拒绝，继续申请":'ປະຕິເສດໂດຍການກວດສອບ, ສືບຕໍ່ນຳໃຊ້',
    可提佣金:'ຄະ​ນະ​ກໍາ​ມະ​ການ​ຖອນ​ເງິນ​',
    冻结佣金:'ຢຸດຄະນະ ກຳ ມະການ',
    分销订单:'ຄໍາສັ່ງແຈກຢາຍ',
    佣金明细:'ລາຍລະອຽດຄະນະກໍາມະການ',
    我的下级:'subordinates ຂອງຂ້ອຍ',
    推广商品:'ສົ່ງເສີມຜະລິດຕະພັນ',
    推广店铺:'ສົ່ງເສີມຮ້ານ',
    暂无记录:'ບໍ່ມີບັນທຶກ',
    一级佣金收入:'ລາຍໄດ້ຄະນະກໍາມະລະດັບທໍາອິດ',
    一级佣金退款:'ການຄືນເງິນຄ່ານາຍໜ້າລະດັບທຳອິດ',
    二级佣金收入:'ລາຍໄດ້ຂອງຄະນະກໍາມະການຂັ້ນສອງ',
    二级佣金退款:'ການຄືນເງິນຄ່ານາຍໜ້າລະດັບທີສອງ',
    下单用户:'ການ​ສັ່ງ​ໃຫ້​ຜູ້​ໃຊ້​',
    订单总价:'ສັ່ງລາຄາທັງໝົດ',
    分销利润层级:'ລະດັບການແຜ່ກະຈາຍ',
    结算状态:'ສະ​ຖາ​ນະ​ການ​ຕັ້ງ​ຖິ່ນ​ຖານ​',
    佣金:'ຄະນະກໍາມະ',
    一级:'ລະດັບ 1',
    二级:'ລະດັບ 2',
    已结算:'ຕົກລົງ',
    请输入用户昵称或手机号:'ກະລຸນາໃສ່ຊື່ຜູ້ໃຊ້ ຫຼືເບີໂທລະສັບມືຖື',
    二级团队:'ທີມງານລະດັບທີສອງ',
    分享链接:'ແບ່ງປັນການເຊື່ອມຕໍ່',
    可推广至:'ສາມາດຂະຫຍາຍອອກໄປ',
    分享最高可赚x:'ຮັບສູງເຖິງ {icon}{num} ໂດຍການແບ່ງປັນ',
    分享推广:'ແບ່ງປັນແລະສົ່ງເສີມ',
    用户昵称或手机号:'ຊື່ຜູ້ໃຊ້ ຫຼືເບີໂທລະສັບ',
    商品名称:'ຊື່​ຜະ​ລິດ​ຕະ​ພັນ',
    最高可得:'ສູງສຸດທີ່ມີຢູ່',
    可获得积分:'ຄະແນນທີ່ໄດ້ຮັບ',
    总获佣金:'ໄດ້ຮັບຄ່ານາຍໜ້າທັງໝົດ',

    佣金详情:'ລາຍລະອຽດຄະນະກໍາມະການ',
    已退款:'ສົ່ງເງິນຄືນແລ້ວ',
    请输入商品名称:'ກະລຸນາໃສ່ຊື່ຜະລິດຕະພັນ',
    请选择银行卡: 'ກະລຸນາເລືອກບັດທະນາຄານ',
    请选择收款码: 'ກະລຸນາເລືອກລະຫັດການຈ່າຍເງິນ',
    不能超过最小提现额度:'ຈໍານວນການຖອນເງິນຂັ້ນຕ່ໍາບໍ່ສາມາດເກີນ!',


    货柜商品:'ສິນຄ້າບັນຈຸ',
    该浏览器不支持蓝牙:'ບຣາວເຊີນີ້ບໍ່ຮອງຮັບ Bluetooth, ກະລຸນາສຳເນົາລິ້ງໄປທີ່ Chrome, Samsung Internet browser ເພື່ອເປີດ',
    iOS系统暂不支持售货机取货请使用Android系统扫码购物:'ຕອນນີ້ລະບົບ iOS ບໍ່ຮອງຮັບການຮັບເຄື່ອງທີ່ຂາຍໄດ້ ກະລຸນາໃຊ້ລະບົບ Android ເພື່ອສະແກນລະຫັດ QR ເພື່ອຊື້ເຄື່ອງ.',
    去商城首页:'ໄປທີ່ໜ້າຫຼັກຂອງສູນການຄ້າ',
    已售完:'ຂາຍອອກ',
    蓝牙未连接:'Bluetooth ບໍ່ໄດ້ເຊື່ອມຕໍ່',
    蓝牙已连接:'Bluetooth ຖືກເຊື່ອມຕໍ່',
    重试:'ລອງອີກຄັ້ງ',
    蓝牙未授权请打开蓝牙后重试:'Bluetooth ບໍ່ໄດ້ຮັບອະນຸຍາດ, ກະລຸນາເປີດ Bluetooth ແລ້ວລອງອີກຄັ້ງ!',
    蓝牙未连接请选择蓝牙并配对:'Bluetooth ບໍ່ໄດ້ເຊື່ອມຕໍ່, ກະລຸນາເລືອກ Bluetooth ແລະຈັບຄູ່!',
    连接:'ເຊື່ອມຕໍ່',
    蓝牙已断开请重新连接:'Bluetooth ຖືກຕັດການເຊື່ອມຕໍ່, ກະລຸນາເຊື່ອມຕໍ່ຄືນໃໝ່',
    蓝牙连接中:'ການເຊື່ອມຕໍ່ Bluetooth...',
    购买成功请取出商品若柜门未打开请尝试再次打开:'ການຊື້ປະສົບຜົນສໍາເລັດ, ກະລຸນາເອົາຜະລິດຕະພັນອອກ! ຖ້າປະຕູຕູ້ບໍ່ເປີດ, ກະລຸນາລອງເປີດມັນອີກຄັ້ງ!',
    再次打开:'ເປີດອີກຄັ້ງ',
    普通订单:'ຄໍາສັ່ງທໍາມະດາ',
    售货机:'ເຄື່ອງຂາຍ',
    售货机已无货无法支付:'ຕູ້ຂາຍເຄື່ອງໝົດສະຕັອກ ແລະບໍ່ສາມາດຊໍາລະໄດ້.',
    订单来源:'ແຫຼ່ງການສັ່ງຊື້',
    连接失败请重新连接:'ການເຊື່ອມຕໍ່ລົ້ມເຫລວ, ກະລຸນາເຊື່ອມຕໍ່ຄືນໃໝ່',
    售罄:'ຂາຍອອກ',
    '房间号/桌号':'ໝາຍເລກຫ້ອງ/ຕາຕະລາງ'
}
//老挝
