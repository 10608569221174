// lang/zh-CN.js: 中文翻译文件
export default {
    推荐商品: '推荐商品',
    用户服务协议及法律声明: '《用户服务协议及法律声明》',
    用户服务协议: '《用户服务协议》',
    法律声明: '《法律声明》',
    隐私政策: '《隐私政策》',
    商品分类: '商品分类',
    暂无商品: '暂无商品',
    留言管理: '留言管理',
    留言回复: '留言回复',
    我要留言: '我要留言',
    没有更多了: '没有更多了',
    查看全部: '查看全部',
    查看全部xx条回复: '查看全部{num}条回复',
    条回复: '条回复',
    留言详情: '留言详情',
    留言回复长度: '留言回复，长度2-100个字符',
    提交: '提交',
    邮箱: '邮箱',
    手机号: '手机号',
    留言内容: '留言内容',
    请填写留言内容: '请填写留言内容',
    立即留言: '立即留言',
    忘记密码: '忘记密码',
    请输入手机号: '请输入手机号',
    手机验证码: '手机验证码',
    请输入验证码: '请输入验证码',
    新密码: '新密码',
    请输入新密码: '请输入新密码',
    立即提交: '立即提交',
    登录: '登录',
    欢迎登录: '欢迎登录',
    密码登录: '密码登录',
    验证码登录: '验证码登录',
    邮箱手机号: '邮箱/手机号',
    请输入邮箱手机号: '请输入邮箱/手机号',
    密码: '密码',
    请输入密码: '请输入密码',
    验证码: '验证码',
    立即登录: '立即登录',
    已阅读并同意以下协议: '已阅读并同意以下协议',
    我是新用户: '我是新用户',
    立即注册: '立即注册',
    注册: '注册',
    确定: '确定',
    确认: '确认',
    消息通知: '消息通知',
    全部消息: '全部消息',
    已读消息: '已读消息',
    全选: '全选',
    标记已读: '标记已读',
    删除: '删除',
    消息详情: '消息详情',
    我的订单: '我的订单',
    全部: '全部',
    待付款: '待付款',
    待发货: '待发货',
    待收货: '待收货',
    已完成: '已完成',
    订单号: '订单号',
    已关闭: '已关闭',
    部分订单取消: '部分订单取消',
    部分退款完成: '部分退款完成',
    部分退款: '部分退款',
    部分收货: '部分收货',
    退款中: '退款中',
    交易异常: '交易异常',
    部分发货: '部分发货',
    发货时间: '发货时间',
    退款成功: '退款成功',
    共: '共',
    共x件商品已选择x件: '共{num}件商品，已选择{num2}件',
    共x件商品: '共{num}件商品',
    总价: '总价',
    联系客服: '联系客服',
    删除订单: '删除订单',
    取消订单: '取消订单',
    去付款: '去付款',
    确认收货: '确认收货',
    暂无订单信息: '暂无订单信息~',
    客服电话: '客服电话',
    客服邮箱: '客服邮箱',
    付款时间: '付款时间',
    创建时间: '创建时间',
    复制: '复制',
    订单编号: '订单编号',
    实付款: '实付款',
    运费: '运费',
    商品总额: '商品总额',
    支付宝: '支付宝',
    微信: '微信',
    线下支付: '线下支付',
    支付方式: '支付方式',
    售后详情: '售后详情',
    售后申请: '售后申请',
    物流单号: '物流单号：',
    购买商品: '购买商品',
    x件: '{num}件',
    收货地址: '收货地址',
    收货人: '收货人',
    您当前是线下支付请耐心等待店铺确认收款若:
        '您当前是线下支付，请耐心等待店铺确认收款！若',
    后店铺未确认收款订单将自动取消: '后店铺未确认收款，订单将自动取消!',
    内完成支付否则订单会被自动取消: '内完成支付，否则订单会被自动取消',
    请在: '请在',
    您当前是线下支付请耐心等待店铺确认收款若x后店铺未确认收款订单将自动取消:
        '您当前是线下支付，请耐心等待店铺确认收款！若<span style="color: red;">{num}</span>后店铺未确认收款，订单将自动取消!',
    请在x内完成支付否则订单会被自动取消:
        '请在<span style="color: red;">{num}</span>内完成支付否则订单会被自动取消',
    订单详情: '订单详情',
    客户已付款等待店铺发货: '客户已付款等待店铺发货',
    售后服务: '售后服务',
    该订单已取消: '该订单已取消',
    申请信息: '申请信息',
    退货类型: '退货类型',
    点击选择退货类型: '点击选择退货类型',
    退货金额: '退货金额',
    退货原因: '退货原因',
    点击选择退货原因: '点击选择退货原因',
    请输入留言内容: '请输入留言内容',
    个字以内: '个字以内',
    上传凭证: '上传凭证',
    为了帮助您更好的解决问题请上传图片: '为了帮助您更好的解决问题，请上传图片',
    最多可上传5张图片每张图片大小不超过5M支持xxx格式文件:
        '最多可上传5张图片，每张图片大小不超过5M，支持bmp,gif,jpg,png,jpeg格式文件',
    联系方式: '联系方式',
    联系人: '联系人',
    请填写联系人: '请填写联系人',
    联系电话: '联系电话',
    请填写联系电话: '请填写联系电话',
    确定提交: '确定提交',
    温馨提示: '温馨提示',
    商品寄回地址将在审核通过后以短信形式告知或在申请记录中查询:
        '商品寄回地址将在审核通过后以短信形式告知或在申请记录中查询',
    提交服务单后售后专员可能与您电话沟通请保持手机畅通:
        '提交服务单后，售后专员可能与您电话沟通，请保持手机畅通。',
    退货处理成功后退款金额将原路返回到您的支持账户中:
        '退货处理成功后退款金额将原路返回到您的支持账户中；',
    等待店铺审核: '等待店铺审核',
    店铺拒绝您的申请: '店铺拒绝您的申请',
    客户取消售后: '客户取消售后',
    售后单号: '售后单号',
    申请时间: '申请时间',
    提交申请: '提交申请',
    店铺处理: '店铺处理',
    店铺退款: '店铺退款',
    售后完成: '售后完成',
    售后商品: '售后商品',
    售后信息: '售后信息',
    售后方式: '售后方式',
    退款金额: '退款金额',
    退款原因: '退款原因',
    退款说明: '退款说明',
    购买信息: '购买信息',
    商品单价: '商品单价',
    实付金额: '实付金额',
    发货件数: '发货件数',
    发货状态: '发货状态',
    您可以在这里给店铺留言: '您可以在这里给店铺留言',
    为了保证你的权益: '为了保证你的权益',
    请尽可能详细的上传留言资料: '请尽可能详细的上传留言资料',
    您可以在这里给店铺留言为了保证你的权益请尽可能详细的上传留言资料:
        '您可以在这里给店铺留言，为了保证你的权益，请尽可能详细的上传留言资料',
    发表留言: '发表留言',
    店: '店',
    客: '客',
    店铺: '店铺',
    客户: '客户',
    取消售后: '取消售后',
    重新申请: '重新申请',
    退款失败: '退款失败',
    退款完成: '退款完成',
    查看详情: '查看详情',
    暂无售后信息: '暂无售后信息',
    修改邮箱: '修改邮箱',
    手机验证: '手机验证',
    邮箱修改: '邮箱修改',
    邮箱验证: '邮箱验证',
    完成: '完成',
    为确认身份我们需验证您的安全手机: ' 为确认身份，我们需验证您的安全手机',
    如果您当前的手机号已无法使用请联系客服:
        '如果您当前的手机号已无法使用请联系客服',
    当前安全手机号: '当前安全手机号',
    短信验证码: '短信验证码',
    请输入短信验证码: '请输入短信验证码',
    下一步: '下一步',
    请输入邮箱: '请输入邮箱',
    请输入正确的邮箱: '请输入正确的邮箱',
    恭喜您邮箱地址验证成功: '恭喜您！邮箱地址验证成功',
    请在下次使用邮箱地址进行登录: '请在下次使用邮箱地址进行登录',
    返回: '返回',
    修改密码: '修改密码',
    原密码: '原密码',
    请输入原密码: '请输入原密码',
    确认密码: '确认密码',
    请再次输入新密码: '请再次输入新密码',
    保存: '保存',
    未登录: '未登录',
    修改: '修改',
    地址管理: '地址管理',
    退出登录: '退出登录',
    头像: '头像',
    昵称: '昵称',
    请输入用户名: '请输入用户名',
    性别: '性别',
    男: '男',
    女: '女',
    保密: '保密',
    手机号码: '手机号码',
    验证: '验证',
    个人信息: '个人信息',
    售后申请成功: '售后申请成功',
    售后申请提交成功: '售后申请提交成功',
    售后专员可能与您电话沟通请保持手机畅通:
        '售后专员可能与您电话沟通，请保持手机畅通',
    支付成功: '支付成功',
    支付失败: '支付失败',
    查看订单: '查看订单',
    返回首页: '返回首页',
    支付订单: '支付订单',
    优惠金额: '优惠金额',
    确定支付: '确定支付',
    确定要取消支付这笔订单吗: '确定要取消支付这笔订单吗?',
    线下支付不支持线上售后服务如需售后请联系店铺若已付款请耐心等待店铺确认收款并发货:
        '线下支付不支持线上售后服务，如需售后请联系店铺。若已付款请耐心等待店铺确认收款并发货！',
    您可以参考如下线下付款方式: '您可以参考如下线下付款方式',
    支付凭证: '支付凭证',
    若您已按如上付款方式付款请上传相关凭证如转账截图等支持jpg和png格式文件图片不大于3M:
        '若您已按如上付款方式付款请上传相关凭证，如转账截图等，支持jpg和png格式文件，图片不大于3M',
    支持jpg和png格式文件: '支持jpg,png格式文件',
    图片不大于3M: '图片不大于3M',
    取消: '取消',
    确认已付款: '确认已付款',
    您的收货地址为空点此添加收货地址: '您的收货地址为空,点此添加收货地址',
    商品: '商品',
    备注说明: '备注说明',
    商品总价: '商品总价',
    活动优惠: '活动优惠',
    优惠卷抵扣: '优惠卷抵扣',
    支付金额: '支付金额',
    应付总额: '应付总额',
    提交订单: '提交订单',
    商品详情: '商品详情',
    选择: '选择',
    发货: '发货',
    剩余: '剩余',
    剩余X件: '剩余{num}件',
    购买数量: '购买数量',
    加入购物车: '加入购物车',
    立即购买: '立即购买',
    此宝贝已下架: '此宝贝已下架',
    客服: '客服',
    购物车: '购物车',
    请输入搜索关键词: '请输入搜索关键词',
    搜索: '搜索',
    历史搜索: '历史搜索',
    姓: '姓',
    请填写姓: '请填写姓',
    名: '名',
    请填写名: '请填写名',
    国家区域: '国家/区域',
    点击选择国家: '点击选择国家',
    请选择国家: '请选择国家',
    城市: '城市',
    点击选择城市: '点击选择城市',
    请选择城市: '请选择城市',
    邮政编码: '邮政编码',
    请填写邮政编码: '请填写邮政编码',
    详细地址: '详细地址',
    请输入详细地址信息如道路门牌号小区楼层号等:
        '请输入详细地址信息，如道路，门牌号，小区， 楼层号等',
    请填写详细地址: '请填写详细地址',
    设为默认收货地址: '设为默认收货地址',
    选中地址: '选中地址',
    选择新地址: '选择新地址',
    设为默认: '设为默认',
    默认地址: '默认地址',
    未添加收货地址: '未添加收货地址',
    添加新地址: '添加新地址',
    您的购物车还没有商品哟: '您的购物车还没有商品哟~',
    继续逛逛: '继续逛逛',
    失效宝贝: '失效宝贝',
    删除失效商品: '删除失效商品',
    已选择: '已选择 ',
    商品合计: '商品合计',
    下单结算: '下单结算',
    件商品: '件商品',
    首页: '首页',
    分类: '分类',
    我的: '我的',
    请填写完整必填项: '请填写完整必填项',
    销量最高: '销量最高',
    销量最低: '销量最低',
    价格最高: '价格最高',
    价格最低: '价格最低',
    评论成功: '评论成功',
    秒后重新发送: '{seconds}秒后重新发送',
    发送验证码: '发送验证码',
    密码最少8位最多16位: '密码最少8位最多16位',
    留言回复长度XX字符: '留言回复，长度2-100字符',
    请输入正确的密码格式必须包含两种字符: '请输入正确的密码格式必须包含两种字符',
    修改成功: '修改成功',
    发送成功: '发送成功',
    需勾选协议: '需勾选协议',
    注册成功: '注册成功',
    请勾选信息: '请勾选信息',
    请选择删除项需已读才能删除: '请选择删除项，需已读才能删除',
    删除订单成功: '删除订单成功',
    订单已超时: '订单已超时',
    确认收货成功: '确认收货成功',
    取消订单成功: '取消订单成功',
    部分商品正在退款中: '部分商品正在退款中',
    复制成功: '复制成功',
    天: '天',
    时: '时',
    分: '分',
    秒: '秒',
    我要退款: '我要退款',
    我要退货退款: '我要退货退款',
    最多上传五张: '最多上传五张',
    不可修改最多: '不可修改，最多',
    含发货邮费: '含发货邮费',
    上传成功: '上传成功',
    上传中: '上传中...',
    图片上传成功: '图片上传成功',
    图片上传失败请重试: '图片上传失败，请重试',
    文件过大: '文件过大',
    取消成功: '取消成功',
    请输入反馈内容: '请输入反馈内容',
    重新发送验证码: '重新发送验证码',
    两次输入密码不一致: '两次输入密码不一致!',
    请输入旧密码: '请输入旧密码',
    长度在8到16个字符: '长度在 8 到 16 个字符',
    必须包含两种字符: '必须包含两种字符',
    新密码不能和旧密码相同: '新密码不能和旧密码相同',
    上传头像图片只能是JPG格式: '上传头像图片只能是 JPG 格式!',
    上传头像图片大小不能超过2MB: '上传头像图片大小不能超过 2MB!',
    昵称不能为空: '昵称不能为空',
    添加超时: '添加超时',
    请选择付款方式: '请选择付款方式',
    请填写收货地址: '请填写收货地址',
    请选择: '请选择',
    请选择规格: '请选择规格',
    添加成功: '添加成功',
    添加失败库存不足: '添加失败库存不足',
    确定要清空历史记录吗: '确定要清空历史记录吗？',
    综合排序: '综合排序',
    销量高低: '销量高低',
    价格高低: '价格高低',
    编辑地址: '编辑地址',
    新增地址: '新增地址',
    是否选择该地址: '是否选择该地址',
    是否删除地址: '是否删除地址',
    删除成功: '删除成功',
    已取消删除: '已取消删除',
    未选择地址或没有地址: '未选择地址或没有地址',
    选择成功: '选择成功',
    确定删除选中的商品吗: '确定删除选中的商品吗？',
    已取消: '已取消',
    提示: '提示',
    请勾选删除商品: '请勾选删除商品',
    请勾选商品: '请勾选商品',
    加载中: '加载中',
    全部分类: '全部分类',
    客户服务: '客户服务',
    退货数量: '退货数量',
    未读消息: '未读消息',
    确定放弃支付吗: '确定放弃支付吗？',
    未发货: '未发货',
    已发货: '已发货',
    确认订单: '确认订单',
    请输入内容: '请输入内容',
    取消时间: '取消时间',
    请输入下方图形验证码: '请输入下方图形验证码',
    看不清换一张: '看不清换一张',
    留言成功: '留言成功',
    长度在1到8个字符: '长度在1 到 8 个字符',
    OnePay支付: 'OnePay支付',
    OnePay支付二维码: 'OnePay支付二维码',
    扫码或者点击去支付: '扫码或者点击“去支付“',
    去支付: '去支付',
    通过BCEL银行完成支付后务必返回当前页面确认支付是否成功:
        '通过BCEL银行完成支付后,务必返回当前页面确认支付是否成功',
    请输入留言内容若您使用BCEL等支付请在此输入用来接收退款的银行卡号及户名:
        '请输入留言内容，若您使用BCEL或KBZPay支付，请在此输入用来接收退款的银行卡号及户名',
    KBZ支付: 'KBZpay',
    BCEL支付: 'BCEL',
    查看支付是否成功: '查看支付是否成功>>>',
    查询中: '查询中',
    语言: '语言',
    货币: '货币',
    切换语言和货币: '切换语言和货币',
    您未支付请重新支付: '您未支付，请重新支付',
    确定已输入接收退款的银行卡号和户名: '确定已输入接收退款的银行卡号和户名',
    绑定: '绑定',
    绑定邮箱: '绑定邮箱',
    分享店铺: '分享店铺',
    分享至: '分享至',
    海报: '海报',
    分享: '分享',
    推荐店铺给你: '推荐店铺给你',
    链接: '链接',

    我的钱包: '我的钱包',
    账户余额: '账户余额',
    提现中: '提现中',
    钱包服务: '钱包服务',
    提现: '提现',
    余额记录: '余额记录',
    提现记录: '提现记录',
    收款管理: '收款管理',
    支付密码: '支付密码',
    提现币种: '提现币种：',
    人民币: '人民币',
    缅币: '缅币',
    基普: '基普',
    收款方式: '收款方式:',
    选择收款方式: '选择收款方式',
    银行卡: '银行卡',
    收款码: '收款码',
    时间: '时间',
    币种: '币种',
    当前没有余额记录: '当前没有余额记录!',
    当前没有提现记录: '当前没有提现记录',
    提现详情: '提现详情',
    到账方式: '到账方式：',
    到账银行卡号: '到账银行卡号：',
    银行名称: '银行名称：',
    开户人: '开户人：',
    开户支行: '开户支行：',
    收款名称: '收款名称：',
    提现金额: '提现金额：',
    提现手续费: '提现手续费：',
    审核信息: '审核信息',
    审核结果: '审核结果：',
    打款信息: '打款信息',
    打款状态: '打款状态：',
    流水号: '流水号：',
    打款凭证: '打款凭证：',
    备注: '备注：',
    添加收款码: '添加收款码',
    收款码类型: '收款码类型:',
    请选择收款码类型: '请选择收款码类型',
    支持币种: '支持币种:',
    请输入收款名称: '请输入收款名称',
    提示只可上传一张图片图片大小不超过5M支持xxx格式文件上传的收款二维码需要清晰无遮挡:
        '提示:只可上传一张图片，图片大小不超过5M，支 持jpg,png,jpeg格式文件上传的收款二维码需要清 晰，无遮挡。',
    请输入支付密码: '请输入支付密码',
    添加银行卡: '添加银行卡',
    请输入开户支行: '请输入开户支行',
    请输入开户人: '请输入开户人',
    请输入银行卡号: '请输入银行卡号',
    银行卡号: '银行卡号:',
    请选择银行: '请选择银行',
    国家: '国家:',
    银行卡管理: '银行卡管理',
    收款码管理: '收款码管理',
    还没有绑定银行卡: '还没有绑定银行卡!',
    还没有添加收款码: '还没有添加收款码!',
    确定删除银行卡吗: '确定删除银行卡吗？',
    确定删除收款码吗: '确定删除收款码吗？',
    请输入支付密码6位数字: '请输入支付密码，6位数字',
    确认支付密码: '确认支付密码：',
    请再次输入支付密码: '请再次输入支付密码',
    确认新密码: '确认新密码',
    请输入新支付密码6位数字: '请输入新支付密码，6位数字',
    新支付密码: '新支付密码',
    原支付密码: '原支付密码',
    请输入原支付密码: '请输入原支付密码',
    修改支付密码: '修改支付密码',
    您未设置支付密码请先设置: '您未设置支付密码，请先设置',
    去设置: '去设置',
    两次密码输入不一致: '两次密码输入不一致',
    设置成功: '设置成功',
    编辑银行卡: '编辑银行卡',
    请输入收款码名称: '请输入收款码名称',
    请上传收款码: '请上传收款码',
    编辑收款码: '编辑收款码',
    可提现金额: '可提现金额：',
    请输入提现金额: '请输入提现金额',
    实际到账金额: '实际到账金额：',
    手续费: '手续费：',
    每天最多提现x次单次最高提现金额xx最低提现金额xx:
        '每天最多提现{num}次，单次最高提现金额{icon}{money},最低提现金额{icon}{money2}',
    立即提现: '立即提现',
    请选择收款方式: '请选择收款方式',
    输入金额超过可提现余额: '输入金额超过可提现余额',
    日期选择: '日期选择',
    待审核: '待审核',
    待打款: '待打款',
    提现成功: '提现成功',
    提现失败: '提现失败',
    转至: '转至',
    年: '年',
    月: '月',
    保障支付安全: '保障支付安全',
    暂无银行卡: '暂无银行卡',
    暂无收款码: '暂无收款码',
    新增成功: '新增成功',
    通过: '通过',
    不通过: '不通过',
    申请成功: '申请成功',
    退款: '退款',
    重置: '重置',
    余额: '余额',
    订单ID: '订单ID',
    打款成功: '打款成功',
    拒绝打款: '拒绝打款',
    打款说明: '打款说明',
    银行卡编码: '银行卡编码',
    '运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-0':
        '{currencyUnit}{money}',
    '运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-1':
        '首重{num}kg{currencyUnit}{money}，续重{num2}kg{currencyUnit}{money2}',
    '运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-2':
        '首件{num3}件{currencyUnit}{money3}，续件{num4}件{currencyUnit}{money4}',
    暂无配送地区: '暂无配送地区',
    该商品在当前地区暂不支持配送: '该商品在当前地区暂不支持配送',
    请选择配送方式: '请选择配送方式',
    配送费说明: '配送费说明',
    若多个商品选用同一个运费模板则运费是按同一个运费方式的规则进行计算: '若多个商品选用同一个运费模板，则运费是按同一个运费方式的规则进行计算。',
    若多个商品不是同一个运费模板则运费是多个模板的价格之和: '若多个商品不是同一个运费模板，则运费是多个模板的价格之和。',
    我明白了: '我明白了',
    当前商品仅支持以下地区发货如有疑问请联系店铺: '当前商品仅支持以下地区发货，如有疑问请联系店铺',
    运费计价说明: '运费计价说明',
    发货地: '发货地',
    配送至: '配送至',
    打款中: '打款中',
    未添加地址请先添加: '未添加地址，请先添加',
    如果您已支付成功请点击确认支付按钮: '如果您已支付成功，请点击"确认支付"按钮',
    确认支付: '确认支付',
    支付遇到问题: '支付遇到问题',
    对不起您访问的店铺可能已被删除名称被更改或不存在:
        '对不起，您访问的店铺可能已被删除、名称被更改或不存在...',
    卢比支付: 'IDR支付',
    比索支付: 'PHP支付',
    泰铢支付: 'THB支付',
    越南盾支付: 'VND支付',
    设置密码: '设置密码',
    登录密码: '登录密码',
    请输入大于1的正整数: '请输入大于1的正整数',
    请再次输入密码: '请再次输入密码',
    当前仅支持xxx的金额: '当前仅支持{currency} {min}-{currency} {max}的金额',

    更多: '更多',
    最近新品: '最近新品',
    促销广告: '促销广告',
    绑定手机号: '绑定手机号',
    微信好友: '微信好友',
    微信朋友圈: '微信朋友圈',
    请点击右上角: '请点击右上角',
    分享给好友或朋友圈: '分享给好友或朋友圈',
    查看运费: '查看运费',
    运费方式: '运费方式',
    价格: '价格',
    配送方式: '配送方式',
    快捷登录登录即表示同意协议: '快捷登录，登录即表示同意协议',
    更多商品:'更多商品',
    件:'件',

//     1.6
    我的优惠券:'我的优惠券',
    历史记录:'历史记录',
    已使用:'已使用',
    已过期:'已过期',
    优惠券:'优惠券',
    领取:'领取',
    已领取:'已领取',
    有效期:'有效期：',
    活动优惠详情:'活动优惠详情',
    可用商品:'可用商品',
    单独购买:'单独购买',
    发起拼团:'发起拼团',
    拼团价:'拼团价',
    可参与的拼团:'可参与的拼团',
    '再邀X人即可拼单成功':'再邀{num}人即可拼单成功>',
    拼单详情:'拼单详情',
    待分享:'待分享',
    '若拼团失败，货款将原路退回':'若拼团失败，货款将原路退回',
    促销价:'促销价',
    距离结束时间:'距离结束时间',
    满XX减XX:"满{currencyUnit}{priceStart}减{currencyUnit}{reducePrice}{couponType==2?'%':''}",
    满XX减X:'满{currencyUnit}{priceStart}减{reducePrice}%',
    分享好友X人成团立省X:'分享好友<span style="color: #8D4BBB;">{num}人</span>成团，立省<span style="color: #8D4BBB;">{currencyUnit}{groupPrice}</span>',
    分享好友:'分享好友',
    这些人正在拼立即参与:'这些人正在拼，立即参与 ',
    满x可用:'满{currencyUnit}{priceStart}可用',
    限领x张:'限领{receiveCount}张',
    满XX送赠品:'满{currencyUnit}{priceStart}送赠品',
    去使用:'去使用',
    销量:'销量',
    赠品:'赠品',
    限时优惠:'限时优惠',
    距结束:'距结束：',
    拼团好物:'拼团好物',
    当前优惠券可以购买以下商品:'当前优惠券可以购买以下商品:',
    以下商品:'以下商品',
    满XX减XX可用:"满{currencyUnit}{priceStart}减{currencyUnit}{reducePrice}可用",
    满XX减X可用:'满{currencyUnit}{priceStart}减{reducePrice}% 可用',
    再买x可用:'再买{currencyUnit}{difPrice}可用',
    合计:'合计',
    优惠:'优惠',
    去购物车:'去购物车',
    拼团:'拼团',
    满XX可用:'满{currencyUnit}{priceStart}可用',
    满减满赠:'满减满赠',
    成团发货:'成团发货',
    还差xx人成团:'还差<span style="color: #FF4545;">{num}</span>人成团',
    拼团结束:'拼团结束',
    拼团成功:'拼团成功',
    有成员未付款请等待付款成团:'有成员未付款，请等待付款成团',
    切换其他优惠:'切换其他优惠',
    满减:'满减',
    满赠:'满赠',
    凑单:'凑单',
    超过最大购买数:'超过最大购买数',
    商品限购x件:'商品限购{num}件',
    参与拼团:'参与拼团',
    结束:'结束',
    我正在参与的拼团:'我正在参与的拼团',
    您还没有优惠券:'您还没有优惠券',
    优惠券历史记录:'优惠券历史记录',
    拼单失败:'拼单失败',
    免费:'免费',
    已赠完:'已赠完',
    此拼团已满请选择其他拼团:'此拼团已满，请选择其他拼团',
    以下商品正在限时优惠当中:'以下商品正在限时优惠当中',
    以下商品正在限时拼团当中:'以下商品正在限时拼团当中',
    再买X可减X:'再买{currencyUnit}{difPrice}可减{currencyUnit}{reducePrice}',
    已领完:"已领完",

    已满X可减X:'已满{currencyUnit}{difPrice}可减{currencyUnit}{reducePrice}',
    已满X可减XX:'已满{currencyUnit}{difPrice}可减{reducePrice}%',

    再买X可减XX:'再买{currencyUnit}{difPrice}可减{reducePrice}%',
    再买X送赠品:'再买{currencyUnit}{difPrice}送赠品',
    已满X送赠品:'已送赠品',

    活动未开始:'活动未开始',
    不可领取:'不可领取',
    限X件:'限{num}件',
    缺少库存:'缺少库存',
    房间号已失效请联系工作人员:'房间号已失效，请联系工作人员!',



    退货积分:'退货积分',
    积分:'积分',
    变动理由:'变动理由',
    用户注册:'用户注册',
    手动增加:'手动增加',
    积分兑换退款:'积分兑换退款',
    下单购物:'下单购物',
    过期积分:'过期积分',
    兑换商品:'兑换商品',
    手动减少:'手动减少',
    订单退款:'订单退款',
    积分明细:'积分明细',
    已兑:'已兑',
    积分商城:'积分商城',
    规则:'规则',
    可用积分:'可用积分',
    冻结积分:'冻结积分',
    我的兑换:'我的兑换',
    售后:'售后',
    分销中心:'分销中心',
    立即兑换:'立即兑换',
    已兑完:'已兑完',
    "积分不足，无法兑换":'积分不足，无法兑换',
    兑换数量:'兑换数量',
    "限兑*件":'限兑{num}件',
    积分抵扣:'积分抵扣',
    "共*分":'共{num}分',
    全部订单:'全部订单',
    兑换须知:'兑换须知',
    已达兑换上限:'已达兑换上限',
    积分规则:'积分规则',
    分销申请协议:'分销申请协议',
    申请成为分销员:'申请成为分销员',
    "已申请，请等待审核":'已申请，请等待审核',
    "审核拒绝，继续申请":'审核拒绝，继续申请',
    可提佣金:'可提佣金',
    冻结佣金:'冻结佣金',
    分销订单:'分销订单',
    佣金明细:'佣金明细',
    我的下级:'我的下级',
    推广商品:'推广商品',
    推广店铺:'推广店铺',
    暂无记录:'暂无记录',
    一级佣金收入:'一级佣金收入',
    一级佣金退款:'一级佣金退款',
    二级佣金收入:'二级佣金收入',
    二级佣金退款:'二级佣金退款',
    下单时间:'下单时间',
    下单用户:'下单用户',
    订单总价:'订单总价',
    分销利润层级:'分销利润层级',
    结算状态:'结算状态',
    佣金:'佣金',
    一级:'一级',
    二级:'二级',
    冻结中:'冻结中',
    已结算:'已结算',
    请输入用户昵称或手机号:'请输入用户昵称或手机号',
    二级团队:'二级团队',
    分享链接:'分享链接',
    可推广至:'可推广至',
    分享最高可赚x:'分享最高可赚{icon}{num}',
    分享推广:'分享推广',
    可获得积分:'可获得积分',
    总获佣金:'总获佣金',
    订单总额:'订单总额',
    佣金详情:'佣金详情',
    已退款:'已退款',
    请输入商品名称:'请输入商品名称',
    兑换时间:'兑换时间',
    请选择银行卡: '请选择银行卡',
    请选择收款码: '请选择收款码',
    不能超过最小提现额度:'不能超过最小提现额度',

    货柜商品:'货柜商品',
    该浏览器不支持蓝牙:'该浏览器不支持蓝牙,请复制链接至Chrome,Samsung Internet浏览器中打开',
    iOS系统暂不支持售货机取货请使用Android系统扫码购物:'iOS系统暂不支持售货机取货请使用Android系统扫码购物',
    去商城首页:'去商城首页',
    已售完:'已售完',
    蓝牙未连接:'蓝牙未连接',
    蓝牙已连接:'蓝牙已连接',
    重试:'重试',
    蓝牙未授权请打开蓝牙后重试:'蓝牙未授权，请打开蓝牙后重试！',
    蓝牙未连接请选择蓝牙并配对:'蓝牙未连接，请选择蓝牙并配对！',
    连接:'连接',
    蓝牙已断开请重新连接:'蓝牙已断开,请重新连接',
    蓝牙连接中:'蓝牙连接中···',
    购买成功请取出商品若柜门未打开请尝试再次打开:'购买成功，请取出商品！若柜门未打开，请尝试再次打开!',
    再次打开:'再次打开',
    普通订单:'普通订单',
    售货机:'售货机',
    售货机已无货无法支付:'售货机已无货，无法支付。',
    订单来源:'订单来源',
    连接失败请重新连接:'连接失败，请重新连接',
    售罄:'售罄',
    '房间号/桌号':'房间号/桌号'
}
