const state = {
  orderdata: JSON.parse(localStorage.getItem('orderfrom')),
  ordercardata: JSON.parse(localStorage.getItem('ordercarfrom')),
  locordercardata: JSON.parse(localStorage.getItem('locordercarfrom')),
  Seaddressdata:JSON.parse(localStorage.getItem('Seaddresslist')),
  currencyUnit:localStorage.getItem('currencyUnit')//货币单位
}
const mutations = {
   // 同步下单数据
   order_list(state, value) {
    state.orderdata = value
  },
  carorder_list(state, value) {
    state.ordercardata = value
  },
  Seaddress_list(state, value) {
    state.Seaddressdata = value
  },
  loccarorder_list(state, value) {
    state.locordercardata = value
  },
   //货币单位
   currency_unit(state,value){
    state.currencyUnit=value
  }
}
const actions = {
  //货币单位
  upadtaCurrency(context,value){
    localStorage.setItem('currencyUnit', value)
    context.commit('currency_unit', value)
  },
  // 页面订单信息存储到本地
  updataorder(context, value) {
    localStorage.setItem('orderfrom', JSON.stringify(value))
    context.commit('order_list', value)
  },
  // 购物车页面订单信息存储到本地
  carpdataorder(context, value) {
    localStorage.setItem('ordercarfrom', JSON.stringify(value))
    context.commit('carorder_list', value)
  },
  // 离线购物车
  loccarpdataorder(context, value) {
    localStorage.setItem('locordercarfrom', JSON.stringify(value))
    context.commit('loccarorder_list', value)
  },
  //选择的收货地址
  Seaddress(context, value) {
    localStorage.setItem('Seaddresslist', JSON.stringify(value))
    context.commit('Seaddress_list', value)
  }
  // 离线购物车信息订单存储到本地

}
const getters = {
}
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}

