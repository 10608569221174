const getters= {
  Num: (state) => {
   return state.shoppingcar.shopnumber
  },
  locNum: (state) => {
    return state.locshoppingcar.shopnumber
   },
  List: (state) => {
    return state.shoppingcar.shoppinglist
  },
  logins: (state) => {
    return state.loginfalse.logins
  },
  tablists: (state) => {
    return state.user.tablists
  },
  currencyUnit:(state)=>{
    return state.order.currencyUnit
  },
  PreviousPath:(state)=>{
    return state.shoppingcar.PreviousPath
  },
  isItConnect:(state)=>{
    return state.bluetooth.isItConnect
  },
  connectingInProgress:(state)=>{
    return state.bluetooth.connectingInProgress
  },
  doYouWantToRefresh:(state)=>{
    return state.bluetooth.doYouWantToRefresh
  }
  }
export default getters
