// 引入购物车接口
const state = {
  shopnumber:0,
  loacshoppinglist: JSON.parse(localStorage.getItem('locshoppingcart')) === null ? {} : JSON.parse(localStorage.getItem('locshoppingcart')),
}
const mutations = {
  // 同步修改本地购物车
  locshopping_list(state,value) {
    state.loacshoppinglist=value
  },
  // 同比购物车数量
  shopping_number(state,value) {
    state.shopnumber=value
  },
  // 算购物车数量
  lookloacshoppingcarnum(state) {
    let data = state.loacshoppinglist
    let number = 0
    for (let i = 0; i < data.length; i++) {
      number++
    }
    state.shopnumber=number
  }
}
const actions = {
  // 本地购物车
  addloacshoppingcar(context, value) {
    let list = JSON.parse(localStorage.getItem('locshoppingcart'))
    if (list.goodsCartBackDTOS.length > 0) {
      let a = 0
      list.goodsCartBackDTOS.forEach(item => {
        if (value.goodsId === item.goodsId && item.goodsStyleTypeId === value.goodsStyleTypeId) {
          item.goodsNum += 1
          a=1
          return
        }
      });
      if (a===0) {
        list.push(value)
      }
    } else {
      list.push(value)
    }
    let number = 0
    for (let i = 0; i < list.length; i++) {
      number++
    }
    context.commit('shopping_number', number)
    context.commit('locshopping_list', list)
    localStorage.setItem('locshoppingcart', JSON.stringify(list))
  },
  // 删除本地购物车内容
  deletloacshoppingcar(context, value) {
    context.commit('locshopping_list', value)
    localStorage.setItem('locshoppingcart', JSON.stringify(value))
    context.commit('lookloacshoppingcarnum', value)
  },
  // 购物车勾选状态保存
  stateloacshoppingcar(context, value) {
    context.commit('locshopping_list', value)
    localStorage.setItem('locshoppingcart', JSON.stringify(value))
  },
  // 下单删除购物车中商品
  deletgoods(context) {
    let list = JSON.parse(localStorage.getItem('locshoppingcart'))
    let c=  list.filter(item => {
      return item.choice===false
    })
    let number = 0
    for (let i = 0; i < c.length; i++) {
      number++
    }
    context.commit('shopping_number', number)
    context.commit('locshopping_list', c)
    localStorage.setItem('locshoppingcart', JSON.stringify(c))
  }
}
const getters = {
}
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}

