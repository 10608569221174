const jm = require("./aes.js");

//  硬件方提供的key
//var key = "01020304050607080900010203040506";

// 加密
function encryptionData(data,key) {
  var byteKey = jm.CryptoJS.enc.Hex.parse(key);
  var byteData = jm.CryptoJS.enc.Hex.parse(data);
  var encrypt = jm.CryptoJS.AES.encrypt(byteData, byteKey, { mode: jm.CryptoJS.mode.ECB, padding: jm.CryptoJS.pad.NoPadding });
  var encryptedStr = encrypt.ciphertext.toString();
  return encryptedStr;
}
//解密
function decryptData(data,key) {
  var byteKey = jm.CryptoJS.enc.Hex.parse(key);
  var byteData = jm.CryptoJS.enc.Hex.parse(data);
  byteData = jm.CryptoJS.enc.Base64.stringify(byteData);
  var decrypt = jm.CryptoJS.AES.decrypt(byteData, byteKey, { mode: jm.CryptoJS.mode.ECB, padding: jm.CryptoJS.pad.NoPadding });
  var decryptedStr = decrypt.toString(jm.CryptoJS.enc.Hex);
  return decryptedStr.toString();
}

module.exports = {
  decryptData, //  解密
  encryptionData, //  加密
}