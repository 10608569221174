<template>
    <div class="scroll-view">
      <div class="scrollable-content" :style="{ transform: 'translateY(' + scrollPosition + 'px)' }">
        <!-- Add your content here -->
        <slot></slot>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ScrollViewY',
    data() {
      return {
        scrollPosition: 0
      };
    },
    methods: {
      handleScroll(event) {
        this.scrollPosition = event.target.scrollTop;
      }
    }
  };
  </script>
  
  <style scoped>
  .scroll-view {
    overflow-y: auto;
  }
  
  .scrollable-content {
    display: block;
  }
  </style>