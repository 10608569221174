const state = {
  logins: localStorage.getItem('loginslist'),
}
const mutations = {
   logins(state, value) {
    state.logins = value
  },

}
const actions = {
  // login存储
  updataorder(context, value) {
    localStorage.setItem('loginslist',value)
    context.commit('logins', value)
  },

}
const getters = {
}
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}

