<template>
    <div class="footBox" >
      <!-- v-if="info.copyrightInfo" -->
        {{ info?.storeIndexInfo?.copyrightInfo }}
    </div>
</template>
  
<script>
export default {
    name: 'footBox',
    props: {

    },
    data() {
        return {
            info:{}
        }
    },
    async mounted() {
        // // 发起异步请求
        await this.$store.dispatch('user/logoTable')
        //  // 等待请求完成后再访问 logotable 状态变量
         this.info=this.$store.state.user.logotable
         console.log(this.info,'1232');
    },

    methods: {
    }
}
</script>
  
  
<style scoped>
.footBox {
    background: #E0E0E0;
    padding: .2rem .32rem;
    font-size: .12rem;
    color: #666666;
    font-weight: 500;
}
</style>
  