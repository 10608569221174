// 柬埔寨
export default {
    BCEL支付: 'BCEL',
    KBZ支付: 'KBZpay',
    OnePay支付: 'OnePay',
    OnePay支付二维码: 'កូដ QR របស់ OnePay',
    保存: 'រក្សាទុក',
    保密: 'រក្សាការសម្ងាត់',
    x件: '{num}មុខ',
    备注说明: 'កំណត់ចំណាំ',
    编辑地址: 'កែសម្រួលអាសយដ្ឋាន',
    标记已读: 'ដាក់សម្គាល់ថាបានអាន',
    不可修改最多: 'មិនអាចកែប្រែបានទេ យ៉ាងច្រើន',
    部分订单取消: 'ការបញ្ជាទិញដោយផ្នែកត្រូវបានលុបចោល',
    部分发货: 'ការដឹកជញ្ជូនដោយផ្នែក',
    部分商品正在退款中: 'ទំនិញមួយចំនួនកំពុងត្រូវបានបង្វិលប្រាក់សងវិញ',
    部分收货: 'បង្កាន់ដៃផ្នែក',
    部分退款: 'ការសងប្រាក់វិញមួយផ្នែក',
    部分退款完成: 'ការសងប្រាក់វិញមួយផ្នែកបានបញ្ចប់',
    查看订单: 'ពិនិត្យមើលការបញ្ជាទិញ',
    查看全部: 'មើលទាំងអស់។',
    查看全部xx条回复: 'មើលការឆ្លើយតបទាំងអស់ {num}',
    查看详情: 'ពិនិត្យព័ត៌មានលម្អិត',
    查看支付是否成功: 'ពិនិត្យមើលថាតើការទូទាត់បានជោគជ័យ>>>',
    查询中: 'ការសាកសួរ',
    城市: 'ទីក្រុង',
    创建时间: 'កាលបរិច្ឆេទបង្កើត',
    此宝贝已下架: 'អស់ពី  ស្តុក',
    待发货: 'រង់ចាំការដឹកទំនិញចេញ',
    待付款: 'រង់ចាំការទូទាត់ប្រាក់',
    待收货: 'រង់ចាំទទួលទំនិញ',
    当前安全手机号: 'លេខទូរសព្ទសុវត្ថិភាពបច្ចុប្បន្ន',
    地址管理: 'ការគ្រប់គ្រងអាសយដ្ឋាន',
    登录: 'ចូលគណនី',
    等待店铺审核: 'រង់ចាំការពិនិត្យផ្ទៀងផ្ទាត់ដោយហាង',
    点击选择城市: 'ចុចដើម្បីជ្រើសរើសទីក្រុង',
    点击选择国家: 'ចុចដើម្បីជ្រើសរើសប្រទេស',
    点击选择退货类型: 'ចុចដើម្បីជ្រើសរើសប្រភេទបង្វិលទំនិញត្រឡប់ទៅវិញ',
    点击选择退货原因: 'ចុចដើម្បីជ្រើសរើសហេតុផលសម្រាប់ការបង្វិលទំនិញត្រឡប់ទៅវិញ',
    店: 'S',
    店铺: 'ហាង',
    店铺处理: 'ហាងដោះស្រាយ',
    店铺拒绝您的申请: 'ហាងបានបដិសេធការស្នើសុំរបស់អ្នក',
    店铺退款: 'ហាងបង្វិលប្រាក់សងវិញ',
    订单编号: 'លេខ  បញ្ជាទិញ',
    订单号: 'លេខ  បញ្ជាទិញ',
    订单详情: 'ព័ត៌មានលម្អិតបញ្ជាទិញ',
    订单已超时: 'ការបញ្ជាទិញបានផុតកំណត់ហើយ',
    短信验证码: 'លេខកូដផ្ទៀងផ្ទាត់សារ SMS',
    发表留言: 'បង្ហោះសារ',
    发货: 'ដឹកជញ្ជូនទំនិញ',
    发货件数: 'ចំនួនទំនិ  ញដឹកចេញ',
    发货时间: 'ពេលវេលាដឹកទំនិញចេញ',
    发货状态: 'ស្ថានភាពដឹកទំនិញចេញ',
    发送成功: 'បានផ្ញើដោយជោគជ័យ',
    发送验证码: 'ផ្ញើលេខកូដផ្ទៀងផ្ទាត់',
    法律声明: 'សេចក្តីជូនដំណឹងផ្លូវច្បាប់',
    返回: 'ត្រឡប់ក្រោយ',
    返回首页: 'ត្រលប់  មកទំព័រដើម',
    分: 'នាទី',
    分类: 'ចំណាត់ថ្នាក់',
    付款时间: 'កាលបរិច្ឆេទទូទាត់ប្រាក់',
    复制: 'ចម្លង',
    复制成功: 'ចម្លងដោយជោគជ័យ',
    该订单已取消: 'ការបញ្ជាទិញនេះត្រូវបានលុបចោល',
    个人信息: 'ព័ត៌មាន  ផ្ទាល់ខ្លួន',
    个字以内: 'មិនលើសពី {num} តួអក្សរ',
    恭喜您邮箱地址验证成功: 'អបអរសាទរ! ការផ្ទៀងផ្ទាត់អាសយដ្ឋានអ៊ីមែលបានជោគជ័យ',
    购买商品: 'ទិញទំនិញ',
    购买数量: 'ចំនួនទិញ',
    购买信息: 'ព័ត៌មានទិញ',
    购物车: 'រទេះ  ដើរ  ទិញ  ឥវ៉ាន់',
    国家区域: 'ប្រទេស/តំបន់',
    含发货邮费: 'រួមបញ្ចូលថ្លៃដឹកជញ្ជូន',
    欢迎登录: 'សូមស្វាគមន៍ចូលគណនី',
    活动优惠: 'កម្មវិធីបញ្ចុះ',
    货币: 'រូបិយប័ណ្ណ',
    继续逛逛: 'បន្តទិញទំនិញ',
    加入购物车: 'ដាក់ក្នុងរទេះ',
    加载中: 'កំពុងផ្ទុក',
    价格高低: 'តម្លៃលក់',
    价格最低: 'តម្លៃ  ទាប  បំផុត',
    价格最高: 'តម្លៃខ្ពស់បំផុត',
    件商品: 'ទំនិញ {num} មុខ',
    交易异常: 'ករណីប្រតិបត្តិការមិនប្រក្រតី',
    看不清换一张: 'មើលមិនច្បាស់ទេ ប្ដូរមួយសន្លឹកទៀត',
    客: 'C',
    客服: 'សេវាកម្ម  អតិថិជន',
    客服电话: 'ទូរសព្ទផ្នែកសេវាអតិថិជន',
    客服邮箱: 'អ៊ីម៉ែលផ្នែកសេវាអតិថិជន',
    客户: 'អតិថិជន',
    客户服务: 'សេវាកម្ម  អតិថិជន',
    客户取消售后: 'អតិថិជនបានលុបចោលសេវាក្រោយការលក់',
    客户已付款等待店铺发货:
        'អតិថិជនបានទូទាត់ប្រាក់ ហើយកំពុងរង់ចាំហាងដើម្បីដឹកជញ្ជូន',
    历史搜索: 'កំណត់ត្រាស្វែងរក',
    请再次输入新密码: 'សូមបញ្ចូលលេខសម្ងាត់ថ្មីម្តងទៀត',
    立即登录: 'ចូលគណនីឥឡូវនេះ',
    立即购买: 'ទិញឥឡូវនេះ',
    立即留言: 'ទុកសារឥឡូវនេះ',
    立即提交: 'ដាក់ស្នើឥឡូវនេះ',
    立即注册: 'ចុះឈ្មោះឥឡូវនេះ',
    联系电话: 'លេខ  ទំនាក់  ទំនង',
    联系方式: 'ព័ត៌មានទំនាក់ទំនង',
    联系客服: 'សេវាកម្ម  អតិថិជន',
    联系人: 'អ្នក    ទំនាក់ទំនងង  ',
    两次输入密码不一致: 'លេខសម្ងាត់  ដែល  បាន  បញ្ចូល  ពីរដង  គឺ  មិន  ដូចគ្នាទេ',
    留言成功: 'ទុកសារបានជោគជ័យ',
    留言管理: 'ការគ្រប់គ្រងសារ',
    留言回复: 'សារឆ្លើយតប',
    留言回复长度: 'ការឆ្លើយតបសារ ប្រវែង 2-100 តួអក្សរ',
    留言回复长度xxx个字符: 'ការឆ្លើយតបសារ ប្រវែង 2-100 តួអក្សរ',
    留言内容: 'ខ្លឹមសារនៃសារ',
    留言详情: 'ព័ត៌មានលម្អិតនៃសារ',
    没有更多了: 'គ្មាន  ទៀត  ទេ',
    每张图片大小不超过3M: 'ទំហំរូបភាពនីមួយៗមិនលើសពី 3M',
    密码: 'លេខសម្ងាត់',
    密码登录: 'ពាក្យសម្ងាត់ចូល',
    密码最少8位最多16位: 'លេខសម្ងាត់ត្រូវចាប់ពី 8 ដល់16 ខ្ទង់',
    秒: 'វិនាទី',
    秒后重新发送: 'ផ្ញើឡើងវិញក្នុងរយៈពេល {seconds} វិនាទី',
    名: 'នាមខ្លួន',
    默认地址: 'អាសយដ្ឋានលំនាំដើម',
    男: 'បុរស',
    昵称: 'ឈ្មោះហៅក្រៅ',
    昵称不能为空: 'ឈ្មោះហៅក្រៅមិនអាចនៅទទេរបានទេ',
    您当前是线下支付请耐心等待店铺确认收款若x后店铺未确认收款订单将自动取消:
        'បច្ចុប្បន្នអ្នកកំពុងបង់ប្រាក់ក្រៅបណ្តាញ សូមរង់ចាំដោយអត់ធ្មត់សម្រាប់ហាងដើម្បីបញ្ជាក់ការទទួល! ប្រសិនបើហាងមិនបញ្ជាក់ពីការទទួលបន្ទាប់ពី <span style="color: red;">{num}</span>នោះការបញ្ជាទិញនឹងត្រូវបានលុបចោលដោយស្វ័យប្រវត្តិ!',
    您的购物车还没有商品哟: 'មិនមានផលិតផលនៅក្នុងរទេះទំនិញរបស់អ្នកទេ~',
    您的收货地址为空点此添加收货地址:
        'អាសយដ្ឋានទទួលទំនិញរបស់អ្នកគឺទទេ សូមចុចទីនេះដើម្បីបន្ថែមអាសយដ្ឋានទទួលទំនិញ',
    您可以参考如下线下付款方式:
        'អ្នកអាចជ្រើសរើសវិធីទូទាត់ប្រាក់ក្រៅបណ្តាញដូចខាងក្រោម',
    您可以在这里给店铺留言为了保证你的权益请尽可能详细的上传留言资料:
        'អ្នកអាចទុកសារឱ្យហាងនៅទីនេះ ដើម្បីការពារសិទ្ធិ និងផលប្រយោជន៍របស់អ្នក សូមបង្ហោះព័ត៌មានសារឱ្យបានលម្អិតតាមដែលអាចធ្វើទៅបាន។',
    您未支付请重新支付: 'អ្នកមិនទាន់បានបង់ប្រាក់ទេ សូមបង់ម្តងទៀត',
    女: 'នារី',
    评论成功: 'ផ្ដល់មតិ  បាន  ជោគជ័យ',
    切换语言和货币: 'ប្តូរភាសា និងរូបិយប័ណ្ណ',
    请勾选删除商品: 'សូមជ្រើសយកទំនិញដើម្បីលុប',
    请勾选商品: 'សូម  ជ្រើសយកទំនិញ',
    请勾选信息: 'សូមជ្រើសយក',
    请上传图片: 'សូមបង្ហោះរូបភាព',
    请输入短信验证码: 'សូមបញ្ចូលលេខកូដផ្ទៀងផ្ទាត់តាមរយៈការផ្ញើសារ',
    请输入反馈内容: 'សូមបញ្ចូលមតិកែលម្អ',
    请输入留言内容: 'សូមបញ្ចូលខ្លឹមសារសារ',
    请输入留言内容若您使用BCEL等支付请在此输入用来接收退款的银行卡号及户名:
        'សូមបញ្ចូលខ្លឹមសារនៃសារ។ ប្រសិនបើអ្នកបានបង់ប្រាក់ដោយប្រើ BCEL ឬ KBZPay សូមបញ្ចូលលេខកាតធនាគារ និងឈ្មោះគណនីដែលប្រើដើម្បីទទួលបានការបង្វិលសងនៅទីនេះ។',
    请输入密码: 'សូមបំពេញលេខសម្ងាត់',
    请输入内容: 'សូមបញ្ចូលខ្លឹមសារ',
    请输入手机号: 'សូមបំពេញលេខទូរសព្ទ',
    请输入搜索关键词: 'សូមបញ្ចូលពាក្យគន្លឹះស្វែងរក',
    请输入下方图形验证码: 'សូមបញ្ចូលលេខកូដផ្ទៀងផ្ទាត់ក្រាហ្វិកខាងក្រោម',
    请输入详细地址信息如道路门牌号小区楼层号等:
        'សូមបញ្ចូលព័ត៌មានអាសយដ្ឋានលម្អិត ដូចជាផ្លូវ លេខផ្ទះ បុរី លេខជាន់អគារ។ល។',
    请输入新密码: 'សូមបញ្ចូលលេខសម្ងាត់ថ្មី',
    请输入验证码: 'សូមបំពេញលេខកូដផ្ទៀងផ្ទាត់',
    请输入用户名: 'សូមបំពេញឈ្មោះអ្នកប្រើប្រាស់',
    请输入邮箱: 'សូមបំពេញអ៊ីមែលរបស់អ្នក',
    请输入邮箱手机号: 'សូមបញ្ចូលអ៊ីមែល/លេខទូរសព្ទ',
    请输入原密码: 'សូមបញ្ចូលលេខសម្ងាត់ដើម',
    请输入正确的密码格式必须包含两种字符:
        'សូមបញ្ចូលទម្រង់លេខសម្ងាត់ត្រឹមត្រូវត្រូវតែមានទាំងអក្សរនិងលេខ',
    请输入正确的邮箱: 'សូមបញ្ចូលអ៊ីមែលក្នុងទម្រង់ត្រឹមត្រូវ',
    请填写联系电话: 'សូមវាយលេខទូរសព្ទរបស់អ្នក',
    请填写联系人: 'សូមបំពេញឈ្មោះអ្នក    ទំនាក់ទំនង',
    请填写留言内容: 'សូមបំពេញខ្លឹមសារនៃសារ',
    请填写名: 'សូមបំពេញនាមខ្លួន',
    请填写收货地址: 'សូមបំពេញអាសយដ្ឋានទទួលទំនិញ',
    请填写完整必填项: 'សូមបំពេញគ្រប់ចន្លោះដែលបានតម្រូវ',
    请填写详细地址: 'សូមបំពេញអាសយដ្ឋាន',
    请填写姓: 'សូមបំពេញនាមត្រកូល',
    请填写邮政编码: 'សូមបំពេញលេខកូដប្រៃសណីយ៍',
    请选择: 'សូម  ជ្រើសរើស',
    请选择城市: 'សូមជ្រើសរើសទីក្រុង',
    请选择付款方式: 'សូមជ្រើសរើសវិធីបង់ប្រាក់',
    请选择规格: 'សូមជ្រើសរើសការបញ្ជាក់',
    请选择国家: 'សូមជ្រើសរើសប្រទេស',
    请选择删除项需已读才能删除:
        'សូមជ្រើសរើសសារដើម្បីលុប សារដែលបានអានទើបអាចលុបបាន',
    请再次输入密码: 'សូមបញ្ចូលលេខសម្ងាត់ម្តងទៀត',
    请在x内完成支付否则订单会被自动取消:
        'សូមធ្វើការទូទាត់ប្រាក់ក្នុងរយៈពេល <span style="color: red;">{num}</span> បើមិនដូច្នេះទេការបញ្ជាទិញនឹងត្រូវបានលុបចោលដោយស្វ័យប្រវត្តិ',
    请在下次使用邮箱地址进行登录:
        'សូមប្រើអាសយដ្ឋានអ៊ីមែលរបស់អ្នក ដើម្បីចូលគណនីនៅពេលក្រោយ',
    取消: 'បោះបង់',
    取消成功: 'បោះបង់ដោយជោគជ័យ',
    取消订单: 'បោះបង់',
    取消订单成功: 'ការបញ្ជាទិញត្រូវបានលុបចោលដោយជោគជ័យ',
    取消时间: 'ពេលវេលាលុបចោល',
    取消售后: 'លុបចោលសេវាក្រោយការលក់',
    去付款: 'ការទូទាត់',
    去支付: 'ការទូទាត់',
    全部: 'ទាំងអស់',
    全部分类: 'ប្រភេទ  ទាំងអស់',
    全部消息: 'ព័ត៌មានទាំងអស់',
    全选: 'ជ្រើសរើស  ទាំងអស់',
    确定: 'ប្រាកដ',
    确定放弃支付吗: 'តើអ្នកប្រាកដថាចង់បោះបង់ការបង់ប្រាក់ទេ?',
    确定删除选中的商品吗: 'តើអ្នកប្រាកដថាចង់លុបទំនិញដែលបានជ្រើសរើសឬទេ?',
    确定提交: 'បញ្ជាក់ការបញ្ជូន',
    确定要清空历史记录吗: 'តើអ្នកប្រាកដទេថាអ្នកចង់សម្អាតកំណត់ត្រា',
    确定要取消支付这笔订单吗:
        'តើអ្នកប្រាកដថាចង់លុបចោលការទូទាត់ប្រាក់សម្រាប់ការបញ្ជាទិញនេះទេ?',
    确定已输入接收退款的银行卡号和户名:
        'សូមប្រាកដថាអ្នកបានបញ្ចូលលេខកាតធនាគារ និងឈ្មោះគណនី ដើម្បីទទួលបានការបង្វិលសង',
    确定支付: 'បញ្ជាក់ការទូទាត់ប្រាក់',
    确认订单: 'បញ្ជាក់  ការ  បញ្ជាទិញ',
    确认密码: 'បញ្ជាក់លេខសម្ងាត់',
    确认收货: 'បញ្ជាក់បានទទួលទំនិញ',
    确认收货成功: 'បញ្ជាក់បានទទួលទំនិញដោយជោគជ័យ',
    确认已付款: 'បញ្ជាក់បានទូទាត់ប្រាក់',
    如果您当前的手机号已无法使用请联系客服:
        'ប្រសិនបើលេខទូរសព្ទបច្ចុប្បន្នរបស់អ្នកមិនអាចប្រើប្រាស់បានទេ សូមទាក់ទងផ្នែកបម្រើការអតិថិជន',
    若您已按如上付款方式付款请上传相关凭证如转账截图等支持jpg和png格式文件图片不大于3M:
        'ប្រសិនបើអ្នកបានបង់ប្រាក់តាមវិធីបង់ប្រាក់ខាងលើ សូមបង្ហោះបង្ហាន់ដៃដែលពាក់ព័ន្ធ ដូចជារូបថតអេក្រង់ផ្ទេរប្រាក់ជាដើម គាំទ្រទម្រង់ jpg/png ហើយរូបភាពមិនគួរធំជាង 3M',
    扫码或者点击去支付: 'ស្កេនកូដ QR ឬចុច "ចូលទៅកាន់ការទូទាត់"',
    删除: 'លុបចោល',
    删除成功: 'លុបដោយជោគជ័យ',
    删除订单: 'លុបចោលការបញ្ជាទិញ',
    删除订单成功: 'បានលុបការបញ្ជាទិញដោយជោគជ័យ',
    删除失效商品: 'លុបចោលទំនិញផុតសុពលភាព',
    商品: 'ទំនិញ',
    商品单价: 'តម្លៃឯកតានៃទំនិញ',
    商品分类: 'ប្រភេទទំនិញ',
    商品合计: 'ទំនិញសរុប',
    商品寄回地址将在审核通过后以短信形式告知或在申请记录中查询:
        'អាសយដ្ឋានបង្វិលទំនិញត្រឡប់ទៅវិញនឹងត្រូវបានជូនដំណឹងតាមរយៈសារទូរសព្ទបន្ទាប់ពីការយល់ព្រម ឬអាចពិនិត្យមើលនៅក្នុងកំណត់ត្រាស្នើសុំ',
    商品详情: 'ព  ត៌  មាន  លម្អិត  នៃ  ផលិតផល',
    商品总额: 'តម្លៃទំនិញសរុប',
    商品总价: 'តម្លៃ  ទំនិញសរុប',
    上传成功: 'បង្ហោះឡើងដោយជោគជ័យ',
    上传凭证: 'បង្ហោះបង្កាន់ដៃ',
    上传头像图片大小不能超过2MB:
        'ទំហំ  នៃ  រូបភាព  តំណាង  ដែល  បង្ហោះ  មិន  អាច  លើស  ពី 2MB ទេ!',
    上传头像图片只能是JPG格式: 'រូបភាពតំណាងដែលបង្ហោះគាំទ្រតែទម្រង់ JPG ប៉ុណ្ណោះ',
    上传中: 'កំពុងបង្ហោះឡើង...',
    设为默认: 'កំណត់ជាលំនាំដើម',
    设为默认收货地址: 'កំណត់ជាអាសយដ្ឋានទទួលទំនិញលំនាំដើម',
    申请时间: 'ពេលវេលាដាក់ពាក្យស្នើសុំ',
    申请信息: 'ព័ត៌មានអំពីការស្នើសុំ',
    剩余X件: 'នៅសល់ {num}',
    失效宝贝: 'ទំនិញផុតសុពលភាព',
    时: 'ម៉ោង',
    实付金额: 'ចំនួនទឹកប្រាក់ដែលបានបង់ជាក់ស្ដែង',
    实付款: 'ទឹកប្រាក់ទូទាត់ជាក់ស្ដែង',
    是否删除地址: 'តើអ្នកត្រូវការលុបអាសយដ្ឋានឬទេ',
    是否选择该地址: 'តើអ្នកជ្រើសរើសអាសយដ្ឋាននេះឬទេ',
    收货地址: 'អាសយដ្ឋានដឹកជញ្ជូន',
    收货人: 'អ្នកទទួល',
    手机号: 'លេខទូរសព្ទ',
    手机号码: 'លេខទូរសព្ទ',
    手机验证: 'ការផ្ទៀងផ្ទាត់ទូរសព្ទ',
    手机验证码: 'លេខកូដផ្ទៀងផ្ទាត់ទូរសព្ទដៃ',
    首页: 'ទំព័រ  មុខ',
    售后单号: 'លេខសេវាក្រោយការលក់',
    售后方式: 'វិធីសាស្រ្តបម្រើសេវាក្រោយការលក់',
    售后服务: 'សេវាកម្មក្រោយពេលលក់',
    售后商品: 'ទំនិញក្រោយការលក់',
    售后申请: 'ស្នើសុំសេវាក្រោយការលក់',
    售后申请成功: 'កាស្នើសុំសេវាក្រោយការលក់បានជោគជ័យ',
    售后申请提交成功: 'ការស្នើសុំសរវាក្រោយការលក់ត្រូវបានបញ្ជូនដោយជោគជ័យ',
    售后完成: 'បានបញ្ចប់សេវាក្រោយការលក់',
    售后详情: 'ព័ត៌មានលម្អិតក្រោយការលក់',
    售后信息: 'ព័ត៌មានសរវាកម្មក្រោយការលក់',
    售后专员可能与您电话沟通请保持手机畅通:
        ' អ្នកឯកទេសក្រោយការលក់អាចទាក់ទងទៅកាន់អ្នកតាមទូរសព្ទ សូមរក្សាទូរសព្ទរបស់អ្នកស្ថិតនៅក្នុងស្ថានភាពទាក់ទងបាន',
    搜索: 'ស្វែងរក',
    提交: 'ដាក់ស្នើ',
    提交订单: 'ដាក់ស្នើការបញ្ជាទិញ',
    提交服务单后售后专员可能与您电话沟通请保持手机畅通:
        'បន្ទាប់ពីដាក់ស្នើសុំសេវាកម្មក្រោយការលក់ អ្នកឯកទេសក្រោយការលក់អាចទាក់ទងទៅកាន់អ្នកតាមទូរសព្ទ សូមរក្សាទូរសព្ទរបស់អ្នកស្ថិតនៅក្នុងស្ថានភាពទាក់ទងបាន',
    提交申请: 'ដាក់ស្នើពាក្យសុំ',
    提示: 'កំណត់ចំណាំ',
    天: 'ថ្ងៃ',
    添加超时: 'ការបន្ថែម  ផុតពេលកំណត់',
    添加成功: 'បានបន្ថែមដោយជោគជ័យ',
    添加失败库存不足: 'ការបន្ថែមបានបរាជ័យ ស្តុកមិនគ្រប់គ្រាន់',
    添加新地址: 'បន្ថែមអាសយដ្ឋានថ្មី',
    条回复: 'ឆ្លើយតប *',
    通过BCEL银行完成支付后务必返回当前页面确认支付是否成功:
        'បន្ទាប់ពីបញ្ចប់ការទូទាត់តាមរយៈធនាគារ BCEL ត្រូវប្រាកដថាត្រលប់ទៅទំព័របច្ចុប្បន្ន ដើម្បីបញ្ជាក់ថាការទូទាត់បានជោគជ័យឬអត់',
    头像: 'រូបតំណាង',
    图片上传成功: 'បង្ហោះរូបភាពដោយជោគជ័យ',
    图片上传失败请重试: 'ការបង្ហោះរូបភាពបានបរាជ័យ សូមព្យាយាមម្តងទៀត',
    退出登录: 'ចាកចេញ',
    退货处理成功后退款金额将原路返回到您的支持账户中:
        'បន្ទាប់ពីការបង្វិលទំនិញត្រឡប់ទៅវិញត្រូវបានដំណើរការដោយជោគជ័យ ចំនួនទឹកប្រាក់បង្វិលសងវិញនឹងត្រូវត្រលប់ទៅគណនីរបស់អ្នកវិញតាមផ្លូវដើមវិញ។',
    退货金额: 'ចំនួនទឹកប្រាក់នៃទំនិញបង្វិលត្រឡប់ទៅវិញ',
    退货类型: 'ប្រភេទបង្វិលទំនិញត្រឡប់ទៅវិញ',
    退货数量: 'ចំនួនទំនិញបង្វិលត្រឡប់មកវិញ',
    退货原因: 'ហេតុផលសម្រាប់ការបង្វិលទំនិញត្រឡប់ទៅវិញ',
    退款成功: 'បង្វិលប្រាក់សងវិញដោយជោគជ័យ',
    退款金额: 'ចំនួនទឹកប្រាក់បង្វិលសងវិញ',
    退款失败: 'ការទូទាត់បានបរាជ័យ',
    退款说明: 'សេចក្ដីលម្អិតអំពីការបង្វិលប្រាក់សងវិញ',
    退款完成: 'សងប្រាក់វិញ។',
    退款原因: 'ហេតុផលសម្រាប់ការបង្វិលប្រាក់សងវិញ',
    退款中: 'សងប្រាក់វិញ',
    完成: 'បញ្ចប់',
    忘记密码: 'ភ្លេចលេខសម្ងាត់',
    微信: 'WeChat',
    为了帮助您更好的解决问题请上传图片:
        'ដើម្បីជួយអ្នកដោះស្រាយបញ្ហាបានប្រសើរជាងមុន សូមបង្ហោះរូបភាព',
    为确认身份我们需验证您的安全手机:
        'ដើម្បីបញ្ជាក់អត្តសញ្ញាណ យើងខ្ញុំត្រូវផ្ទៀងផ្ទាត់ទូរសព្ទដែលមានសុវត្ថិភាពរបស់អ្នក',
    未登录: 'អ្នកមិន  ទាន់  ចូលគណនី  ទេ',
    未读消息: 'សារមិនទាន់អាន',
    未发货: 'រង់ចាំការដឹកទំនិញចេញ',
    未添加收货地址: 'អាសយដ្ឋានទទួលទំនិញមិនត្រូវបានបន្ថែមទេ',
    未选择地址或没有地址: 'គ្មានអាសយដ្ឋានដែលបានជ្រើសរើស ឬគ្មានអាសយដ្ឋាន',
    温馨提示: 'កំណត់ចំណាំ',
    文件过大: 'ឯកសារធំពេក',
    我的: 'របស់ខ្ញុំ',
    我的订单: 'ការបញ្ជាទិញ  របស់  ខ្ញុំ',
    我是新用户: 'ខ្ញុំជាអ្នកប្រើប្រាស់ថ្មី។',
    我要留言: 'ខ្ញុំចង់ទុកសារ',
    我要退货退款: 'ខ្ញុំចង់បង្វិលទំនិញត្រឡប់ទៅវិញនិងទទួលការបង្វិលប្រាក់សងវិញ',
    我要退款: 'ខ្ញុំចង់បានការបង្វិលប្រាក់សងវិញ',
    物流单号: 'លេខកូដដឹកជញ្ជូន：',
    下单结算: 'ទូទាត់ការបញ្ជាទិញ',
    下一步: 'ជំហាន  បន្ទាប់',
    线下支付: 'ការទូទាត់ក្រៅបណ្តាញ',
    线下支付不支持线上售后服务如需售后请联系店铺若已付款请耐心等待店铺确认收款并发货:
        'ការទូទាត់ក្រៅបណ្តាញមិនគាំទ្រដោយសេវាកម្មក្រោយការលក់លើបណ្ដាញទេ សូមទាក់ទងអ្នកលក់ ប្រសិនបើអ្នកត្រូវការសេវាកម្មក្រោយការលក់។ ប្រសិនបើអ្នកបានទូទាត់ប្រាក់រួចហើយ សូមរង់ចាំដោយអត់ធ្មត់ ដើម្បីឱ្យអ្នកលក់បញ្ជាក់ការទូទាត់ និងដឹកជញ្ជូនវា!',
    详细地址: 'អាសយដ្ឋាន',
    消息通知: 'ការជូនដំណឹង',
    消息详情: 'ព័ត៌មានលម្អិតនៃសារ',
    销量高低: 'ចំនួនលក់',
    销量最低: 'ចំនួនលក់តិចបំផុត',
    销量最高: 'ចំនួនលក់ច្រើនបំផុត',
    新密码: 'លេខសម្ងាត់  ថ្មី',
    新密码不能和旧密码相同: 'លេខសម្ងាត់ថ្មីមិនអាចដូចគ្នានឹងលេខសម្ងាត់ដើមទេ',
    新增地址: 'បន្ថែមអាសយដ្ឋាន',
    性别: 'ភេទ',
    姓: 'នាមត្រកូល',
    修改: 'កែសម្រួល',
    修改成功: 'កែប្រែដោយជោគជ័យ',
    修改密码: 'ផ្លាស់ប្តូរលេខសម្ងាត់',
    修改邮箱: 'កែប្រែអ៊ីមែល',
    需勾选协议: 'ត្រូវចុចធីកលើកិច្ចព្រមព្រៀង',
    选择: 'ជ្រើសរើស',
    选择成功: 'ជ្រើសរើសជោគជ័យ',
    选择新地址: 'ជ្រើសរើសអាសយដ្ឋានថ្មី',
    选中地址: 'អាសយដ្ឋានដែលបានជ្រើសរើស',
    验证: 'ផ្ទៀង  ផ្ទាត់',
    验证码: 'កូដ  ផ្ទៀង  ផ្ទាត់',
    验证码登录: 'ការ  ចូល  កូដ  បញ្ជាក់',
    已读消息: 'សារបានអាន',
    已发货: 'បានដឹកទំនិញចេញ',
    已关闭: 'បានបិទ',
    已取消: 'បានបោះបង់',
    已取消删除: 'បានបោះបង់ការ  លុបចោល',
    已完成: 'បានបញ្ចប់',
    已选择: 'បានជ្រើសរើស',
    已阅读并同意以下协议: 'បានអាន និងយល់ព្រមលើកិច្ចព្រមព្រៀងខាងក្រោម',
    隐私政策: 'គោលការណ៍  ភាព  ឯកជន',
    应付总额: 'ទឹកប្រាក់ត្រូវបង់សរុប',
    用户服务协议: 'កិច្ចព្រមព្រៀងសេវាកម្មអ្នកប្រើប្រាស់',
    用户服务协议及法律声明:
        'កិច្ចព្រមព្រៀងសេវាកម្មអ្នកប្រើប្រាស់ និងសេចក្តីថ្លែងការណ៍ផ្លូវច្បាប់',
    优惠金额: 'បញ្ចុះតម្លៃ',
    优惠卷抵扣: 'ប្រើប័ណ្ណបញ្ចុះ',
    邮箱: 'អ៊ីមែល',
    邮箱手机号: 'អ៊ីមែល / លេខទូរស័ព្ទចល័ត',
    邮箱修改: 'ការកែប្រែអ៊ីមែល',
    邮箱验证: 'ការផ្ទៀងផ្ទាត់អ៊ីមែល',
    邮政编码: 'លេខ  កូដ  តំបន់',
    语言: 'ភាសា',
    原密码: 'លេខសម្ងាត់    ដើម',
    运费: 'ថ្លៃដឹកជញ្ជូន',
    暂无订单信息: 'មិនមានព័ត៌មានបញ្ជាទិញ',
    暂无商品: 'គ្មានទំនិញទេ',
    暂无售后信息: 'មិនមានព័ត៌មានក្រោយការលក់',
    长度在1到8个字符: 'ពី 1 ទៅ 8 តួអក្សរ',
    支付宝: 'អាលីផេ',
    支付成功: 'ទូទាត់ប្រាក់បានជោគជ័យ',
    支付订单: 'ទូទាត់ប្រាក់សម្រាប់ការបញ្ជាទិញ',
    支付方式: 'វិធី  សា  ស្រ្ត  ទូទាត់ប្រាក់',
    支付金额: 'ចំនួនទឹកប្រាក់ទូទាត់',
    支付凭证: 'បង្ហាន់ដៃទូទាត់',
    支付失败: 'ការទូទាត់ប្រាក់បានបរាជ័យ',
    重新发送验证码: 'ផ្ញើលេខកូដឡើងវិញ',
    重新申请: 'ស្នើសុំឡើងវិញ',
    注册: 'ចុះឈ្មោះ',
    注册成功: 'ចុះឈ្មោះជោគជ័យ',
    综合排序: 'លំដាប់លំដោយទូទៅ',
    总价: 'តម្លៃ  សរុប',
    最多可上传5张图片每张图片大小不超过5M支持xxx格式文件:
        'អាចបង្ហោះរូបភាពបានរហូតដល់ 5 សន្លឹក ហើយទំហំរូបភាពនីមួយៗមិនលើសពី 5M គាំទ្រឯកសារទម្រង់ bmp, gif, jpg, png, jpeg',
    最多上传五张: 'អាចបង្ហោះរហូតដល់ 5 សន្លឹក',
    推荐商品: 'ផលិតផលដែលបានណែនាំ',
    共x件商品已选择x件: 'សរុបចំនួន {num} មុខទំនិញ បានជ្រើសរើស {num2} មុខ',
    共x件商品: 'សរុប {num}  ធាតុ',
    绑定: 'ចង',
    绑定邮箱: 'ចងភ្ជាប់អ៊ីមែល',
    分享店铺: 'ហាងចែករំលែក',
    分享至: 'ចែករំលែក',
    海报: 'ផ្ទាំងរូបភាព',
    分享: 'ចែករំលែក',
    推荐店铺给你: 'ផ្សារទំនើបដែលបានណែនាំសម្រាប់អ្នក',
    链接: 'តំណភ្ជាប់',

    至: 'ដល់',
    币种: 'រូបិយប័ណ្ណ',
    变动理由: 'ហេតុផលសម្រាប់ការផ្លាស់ប្តូរ',
    不记得: 'មិនចាំទេ។',
    充值: 'បញ្ចូល  លុយ',
    打款凭证: 'បង្កាន់ដៃទូទាត់ប្រាក់',
    打款说明: 'ពិពណ៌នាអំពីការទូទាត់ប្រាក់',
    打款信息: 'ព  ត៌  មាន  ទូទាត់ប្រាក់',
    打款状态: 'ស្ថានភាពការទូទាត់៖',
    待打款: 'រងចាំការទូទាត់ប្រាក់',
    待审核: 'រង់ចាំការពិនិត្យផ្ទៀងផ្ទាត់',
    到账方式: 'វិធី  សា  ស្រ្ត  ទូទាត់',
    到账银行卡号: 'ទៅកាត  ធនាគារ',
    冻结中: 'ត្រជាក់',
    国家: 'ប្រទេស',
    获取验证码: 'ទទួលបានលេខកូដផ្ទៀងផ្ទាត់',
    记得: 'ចងចាំ',
    记录时间: 'ពេលវេលាកត់ត្រា',
    拒绝打款: 'បដិសេធមិនបង់ប្រាក់',
    开户人: 'ម្ចាស់  គណនី',
    开户支行: 'ឈ្មោះសាខាធនាគារ',
    可提现: 'អាចដកបាន។',
    可提现金额: 'ចំនួនទឹកប្រាក់ដែលអាចដកបាន',
    立即提现: 'អាចដកបាន។',
    流水号: 'លេខប្រតិបត្តិការ',
    没有适合此币种的银行卡请先添加:
        'មិនមានកាតធនាគារដែលសមរម្យសម្រាប់រូបិយប័ណ្ណនេះទេ សូមបញ្ចូលវាជាមុនសិន',
    没有收款码请先添加: 'មិនមានលេខកូដបង់ប្រាក់ទេ សូមបញ្ចូលវាជាមុនសិន',
    每天最多提现x次单次最高提现金额x:
        'ចំនួនអតិបរមានៃការដកប្រាក់ក្នុងមួយថ្ងៃគឺ * ហើយចំនួនដកអតិបរមាក្នុងមួយលើក*',
    您是否记得账号当前使用的支付密码:
        'តើ  អ្នក  ចាំ  ពាក្យ  សម្ងាត់  នៃ  ការ  ទូទាត់  ប្រាក់  ដែល  គណនី  របស់  អ្នក  ប្រើ  បច្ចុប្បន្ន  ដែរ  ឬ  ទេ?？',
    您未设置支付密码请先设置:
        'អ្នកមិនទាន់បានកំណត់ពាក្យសម្ងាត់បង់ប្រាក់ទេ សូមកំណត់វាជាមុនសិន',
    钱包余额: 'សមតុល្យកាបូប',
    请输入开户人: 'សូមបញ្ចូលម្ចាស់គណនី',
    请输入开户支行: 'សូមបញ្ជូលសាខាធនាគារ',
    请输入收款名称: 'សូមបញ្ចូលឈ្មោះការទូទាត់',
    请输入提现金额: 'សូមបំពេញចំនួនទឹកប្រាក់ដែលត្រូវដកចេញ',
    请输入新支付密码6位数字: 'សូម  បញ្ចូល  ពាក្យ  សម្ងាត់  ការ  ទូទាត់  ចំនួន 6 ខ្ទង់',
    请输入新支付密码: 'សូមបញ្ចូលពាក្យសម្ងាត់ការទូទាត់',
    请输入银行卡号: 'សូមបញ្ចូលលេខកាតធនាគារ',
    请输入原支付密码: 'សូមបញ្ចូលពាក្យសម្ងាត់ដើមនៃការបង់ប្រាក់',
    请输入支付密码6位数字: 'សូម  បញ្ចូល  ពាក្យ  សម្ងាត់  ការ  ទូទាត់  ចំនួន 6 ខ្ទង់',
    请输入支付密码: 'សូម  បញ្ចូល  ពាក្យ  សម្ងាត់  ការ  ទូទាត់  ចំនួន 6 ខ្ទង់',
    请选择币种: 'សូមជ្រើសរើសរូបិយប័ណ្ណ',
    请选择大洲: 'សូមជ្រើសរើសទ្វីប',
    请选择结束时间: 'សូមជ្រើសរើសកាលបរិច្ឆេទបញ្ចប់',
    请选择开始时间: 'សូមជ្រើសរើសកាលបរិច្ឆេទចាប់ផ្តើម',
    请选择收款码类型: 'សូមជ្រើសរើសប្រភេទលេខកូដបង់ប្រាក់',
    请选择银行: 'សូមជ្រើសរើសធនាគារ',
    请选择银行名称: 'សូមជ្រើសរើសឈ្មោះធនាគារ',
    请再次输入支付密码: 'សូមបញ្ចូលពាក្យសម្ងាត់ទូទាត់ម្តងទៀត',
    去设置: 'ចូលទៅកាន់ការកំណត់',
    全部提现: 'ដកប្រាក់ទាំងអស់',
    确定删除收款码吗: 'តើអ្នកប្រាកដថាចង់លុបលេខកូដបង់ប្រាក់មែនទេ?',
    确定删除银行卡吗: 'តើអ្នកប្រាកដថាចង់លុបកាតធនាគារមែនទេ?',
    确定提现: 'បញ្ជាក់ការដកប្រាក់៖',
    确认打款: 'បញ្ជាក់ការទូទាត់ប្រាក់',
    确认新密码: 'បញ្ជាក់ពាក្យសម្ងាត់ថ្មី៖',
    请再次输入新支付密码: 'សូមបញ្ចូលពាក្យសម្ងាត់ទូទាត់ថ្មីម្តងទៀត',
    确认支付密码: 'បញ្ជាក់ពាក្យសម្ងាត់ទូទាត់',
    上传的收款二维码需要清晰无遮挡:
        'កូដ QR នៃការទូទាត់ដែលបានបង្ហោះត្រូវតែច្បាស់ និងមិនមានការរារាំង',
    审核备注: 'កំណត់ចំណាំក្នុងការពិនិត្យផ្ទៀងផ្ទាត់',
    审核不通过: 'សវនកម្មមិនឆ្លងកាត់ទេ។',
    审核结果: 'លទ្ធផលនៃការពិនិត្យផ្ទៀងផ្ទាត់',
    审核通过: 'បានឆ្លងកាត់ការពិនិត្យផ្ទៀងផ្ទាត់',
    审核信息: 'ពិនិត្យព័ត៌មាន',
    时间: 'ពេលវេលា',
    实际到账金额: 'ចំនួនពិតប្រាកដដែលបានទទួល',
    收款方式: 'វិធី  សា  ស្រ្ត  ទូទាត់',
    收款管理: 'ការគ្រប់គ្រងការប្រមូល',
    收款码: 'លេខកូដបង់ប្រាក់',
    收款码管理: 'ការគ្រប់គ្រងលេខកូដទូទាត់',
    收款码类型: 'ប្រភេទលេខកូដទូទាត់',
    收款名称: 'ឈ្មោះអ្នកទទួលប្រាក់',
    收款账号: 'លេខគណនីទូទាត់',
    手续费: 'ថ្លៃសេវាដកប្រាក់',
    提现: 'ដកប្រាក់',
    提现币种: 'រូបិយប័ណ្ណដកប្រាក់',
    提现成功: 'ការដកប្រាក់បានជោគជ័យ',
    提现记录: 'កត់ត្រាការដកប្រាក់',
    提现金额: 'ចំនួនទឹកប្រាក់ដកចេញ',
    提现失败: 'ការដកប្រាក់បានបរាជ័យ',
    提现时间: 'ពេលវេលាដកប្រាក់',
    提现手续费: 'ថ្លៃដកប្រាក់',
    提现详情: 'ព័ត៌មានលម្អិតអំពីការដកប្រាក់',
    提现中: 'ការដកប្រាក់',
    提现状态: 'ស្ថានភាពដកប្រាក់',
    添加收款码: 'បញ្ចូលលេខកូដបង់ប្រាក់',
    添加银行卡: 'បន្ថែមកាតធនាគារ',
    退款: 'សងប្រាក់វិញ។',
    忘记支付密码: 'ភ្លេចលេខសម្ងាត់បង់ប្រាក់របស់អ្នក?',
    尾号: 'លេខកន្ទុយ',
    我的钱包: 'កាបូបរបស់ខ្ញុំ',
    我的余额: 'សមតុល្យរបស់ខ្ញុំ',
    详情: 'ព័ត៌មានលម្អិត',
    消费: 'ការប្រើប្រាស់',
    新支付密码: 'ពាក្យសម្ងាត់ទូទាត់ថ្',
    修改支付密码: 'ប្តូរ  ពាក្យ  សម្ងាត់  ការ  ទូទាត់',
    选择收款方式: 'ជ្រើសរើសវិធីបង់ប្រាក់',
    银行卡: 'កាត  ធនាគារ',
    银行卡管理: 'ការគ្រប់គ្រងកាតធនាគារ',
    银行卡号: 'លេខកាតធនាគារ',
    银行名称: 'ឈ្មោះធនាគារ',
    余额记录: 'កំណត់ត្រាសមតុល្',
    余额数据: 'ទិន្នន័យសមតុល្',
    原支付密码: 'ពាក្យសម្ងាត់ការទូទាត់ដើម',
    暂无数据: 'គ្មាន  ទិន្នន័យ',
    支持币种: 'គាំទ្ររូបិយប័ណ្ណ',
    支付密码: 'ពាក្យសម្ងាត់ទូទាត់',
    只可上传一张图片图片大小不超过5M支持jpgpngjpeg格式文件:
        'មានតែរូបភាពមួយប៉ុណ្ណោះដែលអាចបង្ហោះបាន ទំហំរូបភាពមិនលើសពី 5M គាំទ្រឯកសារទម្រង់ jpg, png, jpeg៖',
    账户余额: 'សមតុល្យ  គណនី',
    钱包服务: 'សេវាកាបូប',
    每天最多提现x次单次最高提现金额xx最低提现金额xx:
        'ចំនួនដកអតិបរមាក្នុងមួយថ្ងៃគឺ {num} ដង ចំនួនដកអតិបរមាក្នុងមួយលើកគឺ {icon}{money} ហើយចំនួនដកអប្បបរមាគឺ {icon}{money2}',
    确认: 'បញ្ជាក់',
    输入金额超过可提现余额: 'ចំនួនទឹកប្រាក់បញ្ចូលលើសពីសមតុល្យដែលអាចដកបាន។',
    日期选择: 'ការជ្រើសរើសកាលបរិច្ឆេទ',
    备注: 'ចំណាំ',
    当前没有余额记录: 'មិន  ទាន់  មាន  កំណត់ត្រា  សមតុល្យ  ទេ!',
    当前没有提现记录: 'បច្ចុប្បន្នមិនមានកំណត់ត្រាដកប្រាក់ទេ!',
    还没有绑定银行卡: 'កាតធនាគារមិនទាន់ត្រូវបានចង!',
    还没有添加收款码: 'មិនទាន់មានលេខកូដបង់ប្រាក់ត្រូវបានបញ្ចូលនៅឡើយទេ!',
    提示只可上传一张图片图片大小不超过5M支持xxx格式文件上传的收款二维码需要清晰无遮挡:
        'គន្លឹះ៖ មានតែរូបភាពមួយប៉ុណ្ណោះដែលអាចបង្ហោះបាន ទំហំរូបភាពមិនគួរលើសពី 5M ហើយឯកសារទម្រង់ jpg, png និង jpeg ត្រូវបានគាំទ្រ។ កូដ QR នៃការទូទាត់ដែលបានបង្ហោះត្រូវតែច្បាស់ និងមិនមានការរារាំង។',
    请上传收款码: 'សូមបង្ហោះលេខកូដបង់ប្រាក់',
    请输入收款码名称: 'សូមបញ្ចូលឈ្មោះកូដបង់ប្រាក់',
    暂无银行卡: 'មិនទាន់មានកាតធនាគារនៅឡើយទេ',
    余额: 'តុល្យភាព',
    重置: 'កំណត់ឡើងវិញ',
    暂无收款码: 'មិនទាន់មានលេខកូដបង់ប្រាក់នៅឡើយទេ',
    保障支付安全: 'ធានាសុវត្ថិភាពការទូទាត់',
    打款成功: 'ការទូទាត់បានជោគជ័យ',
    请选择收款方式: 'សូមជ្រើសរើសវិធីបង់ប្រាក់',
    收款类型: 'ប្រភេទការទូទាត់',
    编辑银行卡: 'កែសម្រួលកាតធនាគារ',
    编辑收款码: 'កែសម្រួលលេខកូដបង់ប្រាក់',
    收款码名称: 'ឈ្មោះកូដបង់ប្រាក់',
    订单ID: 'លេខ  សម្គាល់  លំដាប់',
    如果您已支付成功请点击确认支付按钮: 'ប្រសិនបើការទូទាត់របស់អ្នកបានជោគជ័យ សូមចុចប៊ូតុង "បញ្ជាក់ការទូទាត់"',
    确认支付: 'បញ្ជាក់ការទូទាត់',
    支付遇到问题: 'ជួបប្រទះបញ្ហា',
    对不起您访问的店铺可能已被删除名称被更改或不存在: 'សូមអភ័យទោស ហាងដែលអ្នកបានទៅប្រហែលជាត្រូវបានលុប ឈ្មោះរបស់វាត្រូវបានផ្លាស់ប្តូរ ឬប្រហែលជាមិនមាន...',
    卢比支付: 'ការទូទាត់ IDR',
    比索支付: 'ការទូទាត់ PHP',
    泰铢支付: 'ការទូទាត់ THB',
    越南盾支付: 'ការទូទាត់ VND',
    银行卡编码: 'លេខកូដធនាគារ',
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-0": "{currencyUnit}{money}",
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-1": "ទម្ងន់ដំបូង{num}kg{currencyUnit}{money}，ទម្ងន់បន្ទាប់{num2}kg{currencyUnit}{money2}",
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-2": "ទំនិញដំបូង{num3}មុខ{currencyUnit}{money3}，ទំនិញបន្ទាប់{num4}មុខ{currencyUnit}{money4}",
    暂无配送地区: 'មិនទាន់មានតំបន់ដឹកជញ្ជូននៅឡើយទេ',
    该商品在当前地区暂不支持配送: 'ផលិតផលនេះមិនគាំទ្រការដឹកជញ្ជូននៅក្នុងតំបន់បច្ចុប្បន្នទេ។',
    当前商品仅支持以下地区发货如有疑问请联系店铺:
        'ផលិតផលបច្ចុប្បន្នគាំទ្រតែការដឹកជញ្ជូនទៅកាន់តំបន់ខាងក្រោមប៉ុណ្ណោះ។ ប្រសិនបើអ្នកមានសំណួរ សូមទាក់ទងហាង',
    运费计价说明: 'ការណែនាំអំពីតម្លៃដឹកជញ្ជូន',
    发货地: 'កន្លែងដឹកទំនិញចេញ',
    配送至: 'ផ្តល់  ទៅ  ឱ្យ',
    打款中: 'ការទូទាត់កំពុងដំណើរការ',
    未添加地址请先添加: 'Chưa thêm địa chỉ, vui lòng thêm địa chỉ trước',
    请输入大于1的正整数: 'សូមបញ្ចូលចំនួនគត់វិជ្ជមានធំជាង 1',
    设置密码: 'កំណត់ពាក្យសម្ងាត់',
    登录密码: 'ពាក្យសម្ងាត់ចូល',
    长度在8到16个字符: 'ពី 8 ទៅ 16 តួអក្សរ',
    请输入旧密码: 'សូមបញ្ចូលលេខសម្ងាត់ដើម',
    必须包含两种字符: 'ត្រូវតែមានតួអក្សរពីរ',
    当前仅支持xxx的金额: 'បច្ចុប្បន្នគាំទ្រតែចំនួនទឹកប្រាក់ចាប់ពី {currency} {min}-{currency} {max} ប៉ុណ្ណោះ',
    查看运费: 'មើលថ្លៃដឹកជញ្ជូន',
    运费方式: 'វិធីសាស្រ្តដឹកជញ្ជូន',
    价格: 'តម្លៃ',
    请选择配送方式: 'សូមជ្រើសរើសវិធីដឹកជញ្ជូន',
    配送方式: 'វិធី  សា  ស្រ្ត  ដឹកជញ្ជូន',
    配送费说明: 'ការពិពណ៌នាអំពីថ្លៃដឹកជញ្ជូន',
    我明白了: 'ខ្ញុំ  ឃើញ',
    若多个商品选用同一个运费模板则运费是按同一个运费方式的规则进行计算: 'ប្រសិនបើផលិតផលច្រើនប្រើគំរូដឹកជញ្ជូនដូចគ្នា ការដឹកជញ្ជូននឹងត្រូវគណនាដោយយោងទៅតាមច្បាប់នៃវិធីសាស្ត្រដឹកជញ្ជូនដូចគ្នា។',
    若多个商品不是同一个运费模板则运费是多个模板的价格之和: 'ប្រសិនបើផលិតផលជាច្រើនមិនមានគំរូថ្លៃដឹកជញ្ជូនដូចគ្នា ថ្លៃដឹកជញ្ជូនគឺជាផលបូកនៃតម្លៃនៃគំរូច្រើននោះ។',
    快捷登录登录即表示同意协议: 'តាមរយៈការចូល អ្នកយល់ព្រមតាមកិច្ចព្រមព្រៀង',
    促销广告: 'ការផ្សព្វផ្សាយពាណិជ្ជកម្ម',
    最近新品: 'ផលិតផល  ថ្មី',
    更多: 'ច្រើនទៀត',
    设置成功: 'ការដំឡើងបានជោគជ័យ',
    更多商品:'ផលិតផលច្រើនទៀត',


    //     1.6
    我的优惠券:'ប័ណ្ណបញ្ចុះតម្លៃរបស់ខ្ញុំ',
    历史记录:'កំណត់ត្រាប្រវត្តិសាស្ត្រ',
    已使用:'បានប្រើរួចហើយ',
    已过期:'ផុតកំណត់',
    优惠券:'គូប៉ុង',
    领取:'ទទួល',
    已领取:'បានទទួល',
    有效期:'រយៈពេលសុពលភាព：',
    活动优惠详情:'ផ្តល់ជូនព័ត៌មានលម្អិត',
    可用商品:'ធាតុដែលមាន',
    单独购买:'តម្លៃ ដើម',
    发起拼团:'តម្លៃក្រុម',
    拼团价:'តម្លៃក្រុម',
    可参与的拼团:'ការទិញជាក្រុមដែលមាន',
    '再邀X人即可拼单成功':'ចែករំលែកជាមួយមនុស្ស {num} នាក់ទៀតដើម្បីទទួលបានការបញ្ជាទិញជោគជ័យ >',
    拼单详情:'ព័ត៌មានលម្អិតអំពីការទិញជាក្រុម',
    待分享:'រង់ចាំចែករំលែក',
    '若拼团失败，货款将原路退回':'ប្រសិនបើការទិញជាក្រុមបរាជ័យ ការទូទាត់នឹងត្រូវបានបង្វិលសងវិញ។',
    促销价:'តំលៃ លក់',
    距离结束时间:'End in ',
    满XX减XX:"ទិញ {currencyUnit}{priceStart} ទទួល {currencyUnit}{reducePrice}{couponType==2?'%':''} ការបញ្ចុះតម្លៃ។",
    满XX减X:'ទិញ {currencyUnit}{priceStart} ទទួល {reducePrice}% ការបញ្ចុះតម្លៃ។',
    分享好友X人成团立省X:'ទិញជាក្រុមសម្រាប់<span style="color: #8D4BBB;">{num}</span>នាក់ សន្សំ<span style="color: #8D4BBB;">{currencyUnit}{groupPrice}</span>',
    分享好友:'ចែករំលែកជាមួយមិត្តភក្តិ',
    '这些人正在拼，立即参与 ':'ការទិញក្រុមកំពុងដំណើរការ សូមចូលរួមឥឡូវនេះ ',
    满x可用:'មានសម្រាប់ការបញ្ជាទិញលើសពី {currencyUnit}{priceStart}',
    这些人正在拼立即参与:'ការទិញក្រុមកំពុងដំណើរការ សូមចូលរួមឥឡូវនេះ ',
    限领x张:'ប័ណ្ណចំនួន {receiveCount} ក្នុងមួយដែនកំណត់អ្នកប្រើប្រាស់',
    满XX送赠品:'ទិញតាមតម្លៃ {currencyUnit}{priceStart} ទទួលបានទំនិញដែលសំបកស្រាល',
    去使用:'ប្រើ',
    销量:'ការលក់',
    赠品:'អំណោយ',
    限时优惠:'ការផ្តល់ជូនពេលវេលាមានកំណត់',
    距结束:'End in  ',
    拼团好物:'ទិញក្រុម',
    当前优惠券可以购买以下商品:'ប័ណ្ណបច្ចុប្បន្នអាចទិញផលិតផលដូចខាងក្រោម:',
    以下商品:'លទ្ធផល​ខាងក្រោម',
    满XX减XX可用:"ទិញ {currencyUnit}{priceStart} ទទួល {currencyUnit}{reducePrice} ការបញ្ចុះតម្លៃ។",
    满XX减X可用:'ទិញ {currencyUnit}{priceStart} ទទួល {reducePrice}% ការបញ្ចុះតម្លៃ។',
    再买x可用:'នៅតែត្រូវការទិញ {currencyUnit}{difPrice}',
    合计:'សរុប',
    优惠:'ការបញ្ចុះតម្លៃ',
    去购物车:'រទេះដើរទិញឥវ៉ាន់',
    拼团:'ទិញក្រុម',
    满XX可用:'មានសម្រាប់ការបញ្ជាទិញលើសពី {currencyUnit}{priceStart}',
    满减满赠:'ការបញ្ចុះតម្លៃពេញលេញ / អំណោយពេញលេញ',
    成团发货:'រង់ចាំការដឹកជញ្ជូន',
    还差xx人成团:'នៅតែត្រូវការមនុស្ស <span style="color: #FF4545;">{num}</span>នាក់ដើម្បីដាក់ការបញ្ជាទិញដោយជោគជ័យ',
    拼团结束:'ការទិញក្រុមបញ្ចប់',
    拼团成功:'ការទិញជាក្រុមទទួលបានជោគជ័យ',
    有成员未付款请等待付款成团:'មាននរណាម្នាក់មិនទាន់បានបង់ប្រាក់ទេ សូមរង់ចាំការបង់ប្រាក់',
    我正在参与的拼团:'ចូលរួមក្នុងការទិញជាក្រុម',
    满减:'ការបញ្ចុះតម្លៃពេញលេញ',
    满赠:'អំណោយពេញលេញ',
    凑单:'ធ្វើការបញ្ជាទិញរួមគ្នា',
    切换其他优惠:'ជ្រើសរើសការផ្តល់ជូនផ្សេងទៀត។',
    超过最大购买数:'លើសពីចំនួនអតិបរមានៃការទិញ',
    商品限购x件:'កំណត់ {num} បំណែក',
    参与拼团:'ចូលរួម',
    结束:'结束',
    您还没有优惠券:'អ្នកមិនមានគូប៉ុងទេ។',
    优惠券历史记录:'កំណត់ត្រាប្រវត្តិសាស្ត្រ',
    拼单失败:'ការទិញជាក្រុមបានបរាជ័យ',
    免费:'ឥតគិតថ្លៃ',
    已赠完:'អស់ពី ស្តុក',
    此拼团已满请选择其他拼团:'ការទិញក្រុមនេះពេញហើយ សូមជ្រើសរើសការទិញក្រុមផ្សេងទៀត។',
    以下商品正在限时优惠当中:'ការផ្តល់ជូនពេលវេលាមានកំណត់លើផលិតផលខាងក្រោម',
    以下商品正在限时拼团当中:'ការទិញជាក្រុមក្នុងរយៈពេលកំណត់នៃផលិតផលខាងក្រោម',
    再买X可减X:'ទិញ {currencyUnit}{difPrice} ថែម {currencyUnit}{reducePrice}',
    已领完:"ប្រើអស់ហើយ។",
    活动未开始:'ព្រឹត្តិការណ៍មិនទាន់បានចាប់ផ្តើមទេ។',

    已满X可减X:'បញ្ចុះតម្លៃ {currencyUnit}{reducePrice} សម្រាប់ការទិញលើសពី {currencyUnit}{difPrice}',
    已满X可减XX:'បញ្ចុះតម្លៃ {reducePrice}% សម្រាប់ការទិញលើសពី {currencyUnit}{difPrice}',

    再买X可减XX:'ទិញ {currencyUnit}{difPrice} ថែម {reducePrice}%',
    再买X送赠品:'ទិញ {currencyUnit}{difPrice} ទៀត និងទទួលបានកាដូឥតគិតថ្លៃ',
    已满X送赠品:'អំណោយត្រូវបានផ្តល់ឱ្យ',
    缺少库存: 'អស់ពី​ស្តុក',
    限X件:'ដែនកំណត់ទិញ  {num} បំណែក',
    房间号已失效请联系工作人员:'លេខបន្ទប់បានផុតកំណត់ សូមទាក់ទងបុគ្គលិក!',


    退货积分:'ពិន្ទុត្រឡប់មកវិញ',
    积分:'អាំងតេក្រាល',
    用户注册:'ការចុះឈ្មោះអ្នកប្រើប្រាស់',
    手动增加:'បង្កើនដោយដៃ',
    积分兑换退款:'ការបង្វិលសងពិន្ទុ',
    下单购物:'ដាក់បញ្ជាទិញទំនិញ',
    过期积分:'ពិន្ទុផុតកំណត់',
    兑换商品:'ប្រោសលោះទំនិញ',
    手动减少:'ការកាត់បន្ថយដោយដៃ',
    订单退款:'បញ្ជាទិញការសងប្រាក់វិញ។',
    积分明细:'ចំណុចលម្អិត',
    已兑:'បានលោះរួចហើយ',
    积分商城:'Points Mall',
    规则:'ក្បួន',
    可用积分:'ពិន្ទុដែលមាន',
    冻结积分:'ចំណុចបង្កក',
    我的兑换:'ការផ្លាស់ប្តូររបស់ខ្ញុំ',
    售后:'បន្ទាប់ពីការលក់',
    分销中心:'មជ្ឈមណ្ឌលចែកចាយ',
    立即兑换:'ប្រោសលោះឥឡូវនេះ',
    已兑完:'បានលោះរួចហើយ',
    "积分不足，无法兑换":'ពិន្ទុមិនគ្រប់គ្រាន់មិនអាចយកមកវិញបានទេ។',
    兑换数量:'ផ្លាស់ប្តូរបរិមាណ',
'限兑*件':'ការរំលោះមានកំណត់{num} ទំនិញ',
    积分抵扣:'ការដកពិន្ទុ',
'共*分':'សរុប {num} ពិន្ទុ',
    全部订单:'ការបញ្ជាទិញទាំងអស់។',

兑换须知:'ការណែនាំអំពីការប្រោសលោះ',
    已达兑换上限:'ដែនកំណត់នៃការប្រោសលោះត្រូវបានឈានដល់',
    我的积分:'ពិន្ទុរបស់ខ្ញុំ',
    积分规则:'ច្បាប់ពិន្ទុ',
    兑换时间:'ពេលវេលានៃការប្រោសលោះ',
    我的佣金:'គណៈកម្មាការរបស់ខ្ញុំ',
    佣金数据:'ទិន្នន័យគណៈកម្មការ',
    分销申请协议:'កិច្ចព្រមព្រៀងពាក្យសុំចែកចាយ',
    申请成为分销员:'ចូលជាតំណាងចែកចាយ',
    "已申请，请等待审核":'បានអនុវត្តរួចហើយ សូមរង់ចាំការពិនិត្យ',
    "审核拒绝，继续申请":'បានបដិសេធដោយការពិនិត្យឡើងវិញ សូមបន្តអនុវត្ត',
    可提佣金:'គណៈកម្មការដកប្រាក់',
    冻结佣金:'គណៈកម្មការបង្កក',
    分销订单:'លំដាប់ចែកចាយ',
    佣金明细:'ព័ត៌មានលម្អិតអំពីគណៈកម្មការ',
    我的下级:'អ្នកក្រោមបង្គាប់របស់ខ្ញុំ',
    推广商品:'ផ្សព្វផ្សាយផលិតផល',
    推广店铺:'ផ្សព្វផ្សាយហាង',
    暂无记录:'គ្មានកំណត់ត្រាទេ។',
    一级佣金收入:'ប្រាក់ចំណូលកម្រិតដំបូង',
    一级佣金退款:'ការសងប្រាក់កម្រៃជើងសារកម្រិតទីមួយ',
    二级佣金收入:'ប្រាក់កំរៃជើងសារបន្ទាប់បន្សំ',
    二级佣金退款:'ការសងប្រាក់កម្រៃជើងសារកម្រិតទីពីរ',
    下单用户:'ការបញ្ជាទិញដាក់អ្នកប្រើប្រាស់',
    订单总价:'បញ្ជាទិញតម្លៃសរុប',
    分销利润层级:'កម្រិតចែកចាយ',
    结算状态:'ស្ថានភាពនៃការតាំងទីលំនៅ',
    佣金:'គណៈកម្មាការ',
    一级:'កម្រិត 1',
    二级:'កម្រិត 2',
    已结算:'បានតាំងទីលំនៅ',
    请输入用户昵称或手机号:'សូមបញ្ចូលឈ្មោះហៅក្រៅរបស់អ្នកប្រើប្រាស់ ឬលេខទូរស័ព្ទចល័ត',
    二级团队:'ក្រុមកម្រិតទីពីរ',
    分享链接:'ចែករំលែកតំណ',
    可推广至:'អាចត្រូវបានពង្រីកទៅ',
    分享最高可赚x:'អ្នកអាចរកបានរហូតដល់ {icon}{num} ដោយការចែករំលែក',
    分享推广:'ចែករំលែក និងផ្សព្វផ្សាយ',
    用户昵称或手机号:'ឈ្មោះហៅក្រៅរបស់អ្នកប្រើ ឬលេខទូរស័ព្ទ',
    商品名称:'ឈ្មោះ​ផលិតផល',
    最高可得:'អាចរកបានខ្ពស់បំផុត',
    可获得积分:'ពិន្ទុដែលទទួលបាន',
    总获佣金:'កម្រៃជើងសារសរុបដែលទទួលបាន',

    佣金详情:'ព័ត៌មានលម្អិតអំពីគណៈកម្មការ',
    已退款:'សងប្រាក់វិញ។',
    请输入商品名称:'សូមបញ្ចូលឈ្មោះផលិតផល',
    请选择银行卡: 'សូមជ្រើសរើសកាតធនាគារ',
    请选择收款码: 'សូមជ្រើសរើសលេខកូដបង់ប្រាក់',
    不能超过最小提现额度:'ចំនួនដកប្រាក់អប្បបរមាមិនអាចលើសពី!',


    货柜商品:'ទំនិញកុងតឺន័រ',
    该浏览器不支持蓝牙:'កម្មវិធីរុករកតាមអ៊ីនធឺណិតនេះមិនគាំទ្រប៊្លូធូសទេ សូមចម្លងតំណទៅ Chrome កម្មវិធីរុករកអ៊ីនធឺណិត Samsung ដើម្បីបើក',
    iOS系统暂不支持售货机取货请使用Android系统扫码购物:'ប្រព័ន្ធ iOS បច្ចុប្បន្នមិនគាំទ្រការយកម៉ាស៊ីនលក់ទេ សូមប្រើប្រព័ន្ធ Android ដើម្បីស្កេនកូដ QR សម្រាប់ការទិញទំនិញ។',
    去商城首页:'ចូលទៅកាន់គេហទំព័រផ្សារទំនើប',
    已售完:'លក់អស់ហើយ។',
    蓝牙未连接:'ប៊្លូធូសមិនបានភ្ជាប់ទេ។',
    蓝牙已连接:'ប៊្លូធូសត្រូវបានភ្ជាប់',
    重试:'ព្យាយាមម្តងទៀត',
    蓝牙未授权请打开蓝牙后重试:'ប៊្លូធូសមិនត្រូវបានអនុញ្ញាតទេ សូមបើកប៊្លូធូស ហើយព្យាយាមម្តងទៀត!',
    蓝牙未连接请选择蓝牙并配对:'ប៊្លូធូសមិនត្រូវបានភ្ជាប់ទេ សូមជ្រើសរើសប៊្លូធូស ហើយផ្គូផ្គង!',
    连接:'ភ្ជាប់',
    蓝牙已断开请重新连接:'ប៊្លូធូសត្រូវបានផ្តាច់ សូមភ្ជាប់ឡើងវិញ',
    蓝牙连接中:'ការតភ្ជាប់ប៊្លូធូស...',
    购买成功请取出商品若柜门未打开请尝试再次打开:'ការទិញបានជោគជ័យ សូមដកផលិតផលចេញ! ប្រសិនបើទ្វារគណៈរដ្ឋមន្ត្រីមិនបើកទេ សូមព្យាយាមបើកវាម្តងទៀត!',
    再次打开:'បើកម្តងទៀត',
    普通订单:'លំដាប់ធម្មតា។',
    售货机:'ម៉ាស៊ីនលក់',
    售货机已无货无法支付:'ម៉ាស៊ីនលក់អស់ស្តុក ហើយមិនអាចទូទាត់បានទេ។',
    订单来源:'ប្រភពបញ្ជាទិញ',
    连接失败请重新连接:'ការតភ្ជាប់បានបរាជ័យ សូមភ្ជាប់ឡើងវិញ',
    售罄:'លក់អស់',
    '房间号/桌号':'លេខបន្ទប់/លេខតុ'
}
// 柬埔寨
