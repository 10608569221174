<template>
  <div id="app" class="app">
    <router-view></router-view>
    <div v-if="wxshow">
      <div class="mask" @touchmove.stop.prevent="moveHandle"></div>
      <div class="boxiuq">
        <img src="@/assets/zhiying.png" />
      </div>
    </div>
<!--    main.js 全局路由守卫  $root.$isShow-->
    <homeTabbar v-show="$root.$isShow" :act="$root.$tab"></homeTabbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import homeTabbar from '@/components/Tabbar'
import { getStoreUrl, Language, currency } from '@/API/Language'
import { environments } from '@/utils/env'
export default {
  name: 'App',
  computed: {
    ...mapGetters(['logins', 'PreviousPath'])
  },
  components: {
    /* 注册子组件 */
    homeTabbar
  },

  data () {
    return {
      isVisable: false,
      wxshow: false,
      isBack: false
    }
  },

  watch: {},
  created () {
    // 读取 localStorage 中的数据
    const storedData = JSON.parse(localStorage.getItem('storeSell'));

// 判断是否存在数据以及是否超过半个小时
    if (storedData && (new Date().getTime() - storedData?.time) > 30 * 60 * 1000) {
      // 如果超过半个小时，则清空该条缓存数据
      localStorage.removeItem('storeSell');
    }
    if(storedData?.storeId!=localStorage.getItem('storeID')){
      // 店铺id对不上也删除
      localStorage.removeItem('storeSell');
    }
    let ua = navigator.userAgent.toLowerCase()
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      // 在微信中打开
      // this.wxshow = true
    }
    this.detectBrowser() //调用函数，判别浏览器，如果是IE低版本提示用户升级
    if (
      this.$route.query.storeId !== undefined ||
      this.parseUrlParams(location.href).storeId !== undefined
    ) {
      localStorage.setItem(
        'storeID',
        this.$route.query.storeId || this.parseUrlParams(location.href).storeId
      )
      console.log('走这里没错')
    }
    if (
      this.$route.query.lang !== undefined ||
      this.parseUrlParams(location.href).lang !== undefined
    ) {
      localStorage.setItem(
        'lang',
        this.$route.query.lang || this.parseUrlParams(location.href).lang
      )
    }
    if (
      this.$route.query.currency !== undefined ||
      this.parseUrlParams(location.href).currency !== undefined
    ) {
      localStorage.setItem(
        'currency',
        this.$route.query.currency ||
          this.parseUrlParams(location.href).currency
      )
    }
    this.redirect()

    let url = location.href
    const match = url.match(/\/(\w+)\?/)
    if (url && url.indexOf('undefined') !== -1) {
      var prefix = url.substr(0, url.lastIndexOf('/') + 1)
      window.location.href =
        prefix + '?storeId=' + localStorage.getItem('storeID')
    }

    if (!match) {
      this.isShow = true
      this.tab = 0
    } else {
      if (
        match[1] == 'goods' ||
        match[1] == 'shoppingCart' ||
        match[1] == 'usermy'
      ) {
        this.tab =
          match[1] == 'goods'
            ? 1
            : match[1] == 'shoppingCart'
            ? 2
            : match[1] == 'usermy'
            ? 3
            : 0
        this.isShow = true
        if (
          match[1] == 'shoppingCart' &&
         ( this.PreviousPath.path.indexOf('productDetails') != -1||this.PreviousPath.path.indexOf('activityProducts') != -1)
        ) {

          this.isShow = true
        } else {
          this.isShow = false
        }
      } else {
        this.isShow = false
      }
    }
    localStorage.setItem("customizeId", "");
    localStorage.setItem('classId1', '')
    localStorage.setItem('classId2', '')
    localStorage.setItem('classId3', '')
    this.creadlocalSshopcart()
    this.gainsoildid()
    window.addEventListener('scroll', this.windowScrollListener)
    if (localStorage.getItem('lang')) {
      this.$changeLanguage(localStorage.getItem('lang'))
    } else if (!localStorage.getItem('lang')) {
      this.getLanguage()
    }
    if (!localStorage.getItem('currency')) {
      this.getCurrency()
    }else {

      if(localStorage.getItem('currencyUnit')){

        this.$store.dispatch('order/upadtaCurrency',localStorage.getItem('currencyUnit'))
      }
    }
  },
  mounted() {
    this.preventDoubleTapZoom();
  },
  methods: {
    preventDoubleTapZoom() {
      let lastTouchEnd = 0;
      document.addEventListener('touchstart', (event) => {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      }, { passive: false });

      document.addEventListener('touchend', (event) => {
        const now = (new Date()).getTime();
        if (now - lastTouchEnd < 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      }, { passive: false });
    },
    async getCurrency () {
      if (location.pathnameh === '/404') {
        return
      }
      let a = { storeId: localStorage.getItem('storeID') }
      let { data } = await currency(a)
      this.currencyList = data
      if (!localStorage.getItem('currency')) {
        localStorage.setItem('currency', data[0].currencyId)
        this.currency = data[0].currencyId
        this.$store.dispatch('order/upadtaCurrency', data[0].icon)
        // this.form.currency = data[0].currencyId
        location.reload()
      } else {
        var isCNExist = data.filter(el => {
          return el.currencyId == localStorage.getItem('currency')
        })
        console.log(isCNExist,'isCNExist')
        if (isCNExist.length == 0) {
          let url = window.location.href
          let index = url.indexOf('&lang=')
          if (index > -1) {
            url = url.substring(0, index)
            let idx = url.indexOf('&currency=')
            if (idx > -1) {
              url = url.substring(0, idx)
            }
          }

          localStorage.setItem('currency', data[0].currencyId)
          this.currency = data[0].currencyId
          this.$store.dispatch('order/upadtaCurrency', data[0].icon)
          // this.form.currency = data[0].currencyId
          // location.reload()
          location.href = url
        } else {
          this.currency = isCNExist[0].currencyId
          this.$store.dispatch('order/upadtaCurrency', isCNExist[0].icon)
          // this.form.currency = isCNExist[0].currencyId
        }
      }
    },
    async getLanguage () {
      if (location.pathname === '/404') {
        return
      }
      let a = { storeId: localStorage.getItem('storeID') }
      let b = []
      let { data } = await Language(a)
      data.forEach(el => {
        b.push(el.code)
      })
      if (!localStorage.getItem('lang')) {
        let a = data.filter(el => {
          return el.isDefault == true
        })
        console.log('执行啊')
        localStorage.setItem('lang', a[0].code)
      } else {
        var isCNExist = b.includes(localStorage.getItem('lang'))
        if (!isCNExist) {
          let url = window.location.href
          let index = url.indexOf('&lang=')
          if (index > -1) {
            url = url.substring(0, index)
            let idx = url.indexOf('&currency=')
            if (idx > -1) {
              url = url.substring(0, idx)
            }
          }
          let a = data.filter(el => {
            return el.isDefault == true
          })
          localStorage.setItem('lang', a[0].code)
          // location.reload()
        }
      }
      // this.actions=data
    },
    detectBrowser () {
      var browser = navigator.appName //获取浏览器名字
      var b_version = navigator.appVersion //获取浏览器版本信息
      var version = parseFloat(b_version) //提取浏览器版本号
      /* eslint-disable */
      if (
        (browser == 'Netscape' || browser == 'Microsoft Internet Explorer') &&
        version >= 4
      ) {
      } else {
        alert('优秀的小伙伴都选择切换其他浏览器或升级IE浏览器了哦')
      }
      /* eslint-enable */
    },
    // detectBrowser();//调用函数，判别浏览器，如果是IE低版本提示用户升级
    async redirect () {
      console.log('s刷新一次')
      if (location.pathname === '/404') {
        return
      }
      const newPath = this.getPagePathWithQuery()

      if (!this.shouldRedirect()) {
        return
      }
      let url, isMobile, route
      try {
        const response = await getStoreUrl({
          merchantId: localStorage.getItem('storeID')
        })
        if (!response.data) {
          throw new Error('No data returned')
        }

        url = response.data.web_url.slice(0, -1)
        isMobile = window.innerWidth < 768
        if (isMobile) {
          url = response.data.mobile_url.slice(0, -1)
        }
      } catch (err) {
        console.error(`Failed to retrieve store URL with error: ${err.message}`)
        return
      }
      route = window.location.pathname
      sessionStorage.setItem('redirected', 'true')

      if (url == window.location.origin) {
        return
      }

      if (environments.apiNow != 'sb') {
        window.location.replace(`${url}${route}${newPath}`)
      }
    },

    shouldRedirect () {
      const redirected = sessionStorage.getItem('redirected')
      return redirected === null || redirected === 'false'
    },

    getPagePathWithQuery () {
      return window.location.search
    },
    windowScrollListener () {
      //获取操作元素最顶端到页面顶端的垂直距离
      var scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop
      if (scrollTop >= 100) {
        this.isVisable = true //大于480时显示元素
      }
      if (scrollTop < 100) {
        this.isVisable = false //小于480时隐藏元素
      }
    },
    // 第一种方法
    parseUrlParams (url) {
      const params = {}
      if (!url || url === '' || typeof url !== 'string') {
        return params
      }
      const paramsStr = url.split('?')[1]
      if (!paramsStr) {
        return params
      }
      const paramsArr = paramsStr.replace(/&|=/g, ' ').split(' ')
      for (let i = 0; i < paramsArr.length / 2; i++) {
        const value = paramsArr[i * 2 + 1]
        params[paramsArr[i * 2]] =
          value === 'true' ? true : value === 'false' ? false : value
      }
      return params
    },
    // 获取店铺id
    gainsoildid () {
      let url = location.href
      this.$store.dispatch('loginfalse/updataorder', '0')

      if (
        this.$route.query.classId1 !== undefined ||
        this.parseUrlParams(url).classId1
      ) {
        localStorage.setItem(
          'customizeId',
          this.$route.query.classId1 || this.parseUrlParams(url).classId1
        )
        this.$store.dispatch(
          'user/TablistID',
          this.$route.query.classId1 || this.parseUrlParams(url).classId1
        )
        localStorage.setItem(
          'classId1',
          this.$route.query.classId1 || this.parseUrlParams(url).classId1
        )
      } else {
        // localStorage.setItem("menutable", '');
      }
      if (
        this.$route.query.classId2 !== undefined ||
        this.parseUrlParams(url).classId2
      ) {
        localStorage.setItem(
          'customizeId',
          this.$route.query.classId2 || this.parseUrlParams(url).classId2
        )
        this.$store.dispatch(
          'user/TablistID',
          this.$route.query.classId2 || this.parseUrlParams(url).classId2
        )
        localStorage.setItem(
          'classId2',
          this.$route.query.classId2 || this.parseUrlParams(url).classId2
        )
      }
      if (
        this.$route.query.classId3 !== undefined ||
        this.parseUrlParams(url).classId3
      ) {
        localStorage.setItem(
          'customizeId',
          this.$route.query.classId3 || this.parseUrlParams(url).classId3
        )
        this.$store.dispatch(
          'user/TablistID',
          this.$route.query.classId3 || this.parseUrlParams(url).classId3
        )
        localStorage.setItem(
          'classId3',
          this.$route.query.classId3 || this.parseUrlParams(url).classId3
        )
      }
      if (
        this.$route.query.customizeId !== undefined ||
        this.parseUrlParams(url).customizeId !== undefined
      ) {
        localStorage.setItem(
          'customizeId',
          this.$route.query.customizeId || this.parseUrlParams(url).customizeId
        )
        this.$store.dispatch(
          'user/TablistID',
          this.$route.query.customizeId || this.parseUrlParams(url).customizeId
        )
      } else {
        // localStorage.setItem("menutable", '');
        return
      }
    },


    // 购物车信息
    creadlocalSshopcart () {
      let c = JSON.parse(localStorage.getItem('locshoppingcart'))
      if (c === null) {
        let a = []
        localStorage.setItem('locshoppingcart', JSON.stringify(a))
      } else {
        return
      }
    }
  }
}
</script>

<style lang="scss">
.boxiuq {
  position: fixed;
  top: 1%;
  right: 6%;
  // transform: translateX(-50%);
  z-index: 999;

  img {
    width: 2.7rem;
    height: 1.63rem;
  }
}
* {
  touch-action: manipulation;
}

html {
  font-size: 100px;
  height: 100%;
}

body {
  height: 100%;
  background: #f5f5f5 !important;
}

.app {
  height: 100%;
}

/* 弹框样式 */
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent !important;
}

.mask {
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
}

.noshadow {
  hover-class: none;
}

/* button {
  background: none;
  border: none;
  padding: 0 !important;
  margin: 0 !important;
  line-height: initial;
  width: auto;
  font-weight: normal;
  height: auto;
  border-radius: 0;
  overflow: initial;
  -webkit-tap-highlight-color: transparent !important;
}

button::after {
  border: none;
} */

.row-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row-s {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.row-e {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.row-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row-d {
  flex-direction: column;
}

.row-a {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

body {
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  height: 100%;
  font-size: 0.12rem;
  background-color: #fff;
  font-family: 'PingFang SC';
  font-weight: 400;
  /* filter: grayscale(100%); */
  /* filter: grayscale(100%)!important; */
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
  color: transparent;
}

image {
  display: block !important;
  width: 100%;
  height: 100%;
}

view,
text,
input,
textarea {
  box-sizing: border-box;
}

.ellipsis1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsis2 {
  //white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
</style>
