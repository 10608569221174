<template>
  <div class="navBarCom">
    <van-nav-bar left-arrow v-if="storeIndexInfo?.storeIndexInfo?.themeId == 1">
      <template #left>
        <img
            src="../assets/更多@2x.png"
            class="more"
            v-show="type == 1"
            @click="show = true"
        />
        <img :src="storeIndexInfo?.storeIndexInfo.storeLogo" class="logo"/>
      </template>
      <template #right>
        <div
            class="row-c"
            @click.stop="goUrl('/searchFor')"
            style="width:.4rem;height:.46rem"
            v-if="searchShow"
        >
          <img src="../assets/搜索小@2x.png" class="search"/>
        </div>

        <div
            @click.stop="languageSwitch = !languageSwitch"
            class="row-c"
            style="width:.4rem;height:.46rem"
        >
          <img src="../assets/语言@2x.png" class="language"/>
        </div>
      </template>
    </van-nav-bar>

    <div class="navBar2" v-if="storeIndexInfo?.storeIndexInfo?.themeId == 2">
      <div class="row-b">
        <img
            src="../assets/Menu@2x.png"
            style="width:.35rem;height:.35rem;"
            @click="show = true"
        />
        <img
            :src="storeIndexInfo?.storeIndexInfo.storeLogo"
            style="width:1.24rem;height:.38rem;"
        />
        <!--            .912rem-->
        <img
            src="../assets/Cart@2x.png"
            style="width:.3rem;height:.3rem;"
            @click.stop="languageSwitch = !languageSwitch"
        />
      </div>
      <div class="row-b searchBox" @click.stop="goUrl('/searchFor')" v-if="searchShow">
        <div style="font-size:.16rem;color:rgba(0, 18, 38, 0.50);">
          {{ $t('搜索') }}
        </div>
        <img
            src="../assets/searchBox.png"
            style="width:.34rem;height:.35rem;"
        />
      </div>
    </div>

    <van-popup
        v-model="show"
        position="left"
        :style="{ width: '60%', height: '100%' }"
    >
      <div class="box1">
        <div class="row-s login">
          <div class="row-s" style="padding: 0 .21rem;">
            <img
                :src="
                ruleForm.avatar
                  ? $getImageUrl(
                      ruleForm.avatar ? ruleForm.avatar : userImg,
                      49,
                      49,
                      'webp'
                    )
                  : userImg
              "
                class="userImg"
                @click=" ruleForm.avatar?goUser():trmyuser()"
            />
            <div
                v-if="token"
                style="width: 1.4rem;"
                class="ellipsis1"
                @click="goUser"
            >
              {{ ruleForm.nickname }}
            </div>
            <div v-else @click="trmyuser">
              {{ $t('登录') }}/{{ $t('注册') }}
            </div>
          </div>
        </div>
        <div
            v-for="(item, index) in datalist"
            :key="item.id"
            @click="clicktable(item.customizeId, item.pageType, item.url)"
            :class="[
            'li',
            index === datalist.length - 1 ? 'last-li' : '',
            'row-b'
          ]"
        >
          {{ item.title }}
          <van-icon name="arrow" size="14"/>
        </div>
        <div class="box2">
          <div class="li3 row-b" @click="showServices = true">
            <div class="row-s">
              <img src="@/assets/客服 (1)@2x.png" class="kf"/>
              {{ $t('客户服务') }}
            </div>
            <van-icon name="arrow" size="14"/>
          </div>
          <div class="li3 row-b" @click="leaveMessage">
            <div class="row-s">
              <img src="@/assets/留言管理@2x.png" class="kf"/>
              {{ $t('留言管理') }}
            </div>
            <van-icon name="arrow" size="14"/>
          </div>
          <div class="li3 row-b" @click="twitterShareUrl">
            <div class="row-s">
              <img src="@/assets/fenxiang.png" class="kf"/>
              {{ $t('分享店铺') }}
            </div>
            <van-icon name="arrow" size="14"/>
          </div>
          <div class="li2" @click="routeClick">{{ storeIndexInfo?.INFORMATION?.language?.value }}</div>
        </div>
      </div>
    </van-popup>
    <van-popup
        v-model="showServices"
        closeable
        :style="{ padding: '.2rem 0', width: '70%' }"
    >
      <div class="ltitlet">
        <div class="services">
          <div style="margin-bottom:.1rem;">{{ $t('客服电话') }}</div>
          <template v-if="kefuCont?.phone.length > 0">
            <div
                class="services2 row-s"
                v-for="(item, index) in kefuCont?.phone"
                :key="index"
                @touchstart="handleTouchStart(item)"
                @touchend="handleTouchEnd"
            >
              <img
                  src="@/assets/kfphone.png"
                  style="width:.16rem;height:.17rem;margin-right:.13rem"
              />
              <div
                  style="word-wrap:break-word;overflow-wrap:break-word;word-break:break-all;"
              >
                {{ item ? item : '-' }}
              </div>
            </div>
          </template>
          <div class="services2 row-s" v-else>
            <img
                src="@/assets/kfphone.png"
                style="width:16px;height:17px;margin-right:13px;"
            />
            -
          </div>
        </div>
        <div class="serviceemil">
          <div style="margin-bottom:.1rem;">{{ $t('客服邮箱') }}</div>
          <template v-if="kefuCont?.email.length > 0">
            <div
                class="serviceemil2 row-s"
                v-for="(item, index) in kefuCont?.email"
                :key="index"
                @touchstart="handleTouchStart(item)"
                @touchend="handleTouchEnd"
            >
              <img
                  src="@/assets/kfyouxiang.png"
                  style="width:.17rem;height:.13rem;margin-right:.13rem"
              />
              <div
                  style="word-wrap:break-word;overflow-wrap:break-word;word-break:break-all;"
              >
                {{ item ? item : '-' }}
              </div>
            </div>
          </template>
          <div class="serviceemil2 row-s" v-else>
            <img
                src="@/assets/kfyouxiang.png"
                style="width:17px;height:13px;margin-right:13px"
            />
            -
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="languageSwitch" round :style="{ width: '80%' }">
      <div class="languageSwitch">
        <div class="title">{{ $t('切换语言和货币') }}</div>
        <div class="list">
          <div class="labe">{{ $t('货币') }}</div>
          <ScrollViewY
              :style="currencyList.length > 3 ? 'height: 1.7rem;' : ''"
          >
            <div
                class="li row-b"
                v-for="(item, index) in currencyList"
                :key="index"
                @click="onSelectCurrency(item)"
            >
              <div class="txt">{{ item.showNameCn }}</div>
              <img :src="currency == item.currencyId ? huobiact : huobi"/>
            </div>
          </ScrollViewY>
        </div>
        <div class="list">
          <div class="labe">{{ $t('语言') }}</div>
          <ScrollViewY :style="actions.length > 3 ? 'height: 1.7rem;' : ''">
            <div
                class="li row-b"
                v-for="(item, index) in actions"
                :key="index"
                @click="onSelectLang(item)"
            >
              <div class="txt">{{ item.text }}</div>
              <img :src="lang == item.code ? huobiact : huobi"/>
            </div>
          </ScrollViewY>
        </div>
        <div class="btn-box row-b">
          <div class="btn1" @click="cancel">{{ $t('取消') }}</div>
          <div class="btn2" @click="monetaryLanguage">{{ $t('确定') }}</div>
        </div>
      </div>
    </van-popup>
    <!-- <van-share-sheet
      v-model="showShare"
      title="立即分享给好友"
      :options="options"
      @select="onSelect"
    /> -->
    <van-action-sheet v-model="showShare" :title="$t('分享至')">
      <div class="content">
        <div class="sharList row-s">
          <div
              data-share="facebook"
              data-width="800"
              data-height="600"
              :data-title="storeIndexInfo?.storeIndexInfo?.storeName"
              data-quote="Share quote"
              data-description="Share lalala"
              data-hashtag="#哇呗"
              :data-url="baseUrl"
              class="sharli button row-c row-d"
          >
            <img src="@/assets/Facebook.png"/>
            <div class="title">FaceBook</div>
          </div>

          <div
              data-share="whatsapp"
              data-width="800"
              data-height="600"
              :data-title="storeIndexInfo?.storeIndexInfo?.storeName"
              data-quote="Share quote"
              data-description="Share lalala"
              data-hashtag="#哇呗"
              :data-url="baseUrl"
              class="button sharli row-c row-d"
          >
            <img src="@/assets/whatsapp.png"/>
            <div class="title">WhatsApp</div>
          </div>
          <div
              data-share="twitter"
              data-width="800"
              data-height="600"
              :data-title="storeIndexInfo?.storeIndexInfo?.storeName"
              data-quote="Share quote"
              data-description="Share lalala"
              data-hashtag="#哇呗"
              :data-url="baseUrl"
              class="button sharli row-c row-d"
          >
            <img src="@/assets/tuite.png"/>
            <div class="title">X</div>
          </div>
          <div class="row-c row-d sharli" @click="haibaoFx">
            <img src="@/assets/sharePoster.png"/>
            <div class="title">{{ $t('海报') }}</div>
          </div>
          <div class="row-c row-d sharli" @click="copyText">
            <img src="@/assets/lianjie.png"/>
            <div class="title">{{ $t('链接') }}</div>
          </div>
          <div class="row-c row-d sharli" v-if="is_wx" @click="wxShar">
            <img src="@/assets/wx.png"/>
            <div class="title">{{ $t('微信好友') }}</div>
          </div>
          <div class="row-c row-d sharli" v-if="is_wx" @click="wxShar">
            <img src="@/assets/wechatMoments.png"/>
            <div class="title">{{ $t('微信朋友圈') }}</div>
          </div>
        </div>
      </div>
    </van-action-sheet>
    <div class="weixinBox" v-if="weixinShow">
      <div class="mask" @click="weixinShow = false"></div>
      <div class="weixinJt row-c row-d">
        <img src="@/assets/weixinJT.png"/>
        <div class="labe">{{ $t('请点击右上角') }}</div>
        <div class="labe">{{ $t('分享给好友或朋友圈') }}</div>
      </div>
    </div>
    <div class="ewm" v-if="show2">
      <div class="mask" @click="show2 = false"></div>
      <div class="pop">
        <div class="content">
          <div class="spTitle">
            {{ storeIndexInfo?.storeIndexInfo?.storeName }}
          </div>
          <div class="tip">{{ $t('推荐店铺给你') }}</div>
          <div class="ewmBox">
            <canvas
                id="QRCode_header"
                style="width: 150px; height: 150px;"
            ></canvas>
          </div>
          <div class="btnBox">
            <div class="btn" @click="show2 = false">{{ $t('取消') }}</div>
            <div class="btn2" @click="savePoster">{{ $t('保存') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div style="position:fixed;left:9999px;top:9999px;">
      <div id="poster-container">
        <div class="ewm">
          <div class="pop2">
            <div class="content">
              <div class="spTitle">
                {{ storeIndexInfo?.storeIndexInfo?.storeName }}
              </div>
              <div class="tip">{{ $t('推荐店铺给你') }}</div>
              <div class="ewmBox">
                <canvas
                    id="QRCode_header2"
                    style="width: 150px; height: 150px;"
                ></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import userImg from '@/assets/user.png'
import {gettoken} from '@/utils/auth'
import {listStoreNavigation} from '@/API/listFront'
import {
  Language,
  currency,
  StoreFindContact,
  AreaCodeDefault
} from '@/API/Language'
import {userList} from '@/API/user'
import huobiact from '@/assets/huobiact.png'
import huobi from '@/assets/huobi.png'
import Share from '@/utils/share'
import QRCode from 'qrcode'
import ScrollViewY from '@/components/scrollViewY'

export default {
  components: {
    /* 注册子组件 */
    ScrollViewY
  },
  name: 'navBar',
  props: {
    type: Number,
    storeIndexInfo: Object,
    searchShow:{
      type:Boolean,
      default:true
    }
  },
  data() {
    return {
      // H5 网页的 URL 地址（假设为 https://example.com）
      baseUrl: window.location.href,
      // 分享标语
      shareText: '我发现了一个很棒的网页，大家快来看看吧！',
      showServices: false,
      userImg: userImg,
      token: gettoken(),
      show: false,
      showShare: false,
      options: [
        {name: '微信', icon: 'wechat'},
        {name: '微博', icon: 'weibo'},
        {name: '复制链接', icon: 'link'},
        {name: '分享海报', icon: 'poster'},
        {name: '二维码', icon: 'qrcode'}
      ],
      info: {},
      ruleForm: {
        avatar: '',
        email: '',
        cellphone: '',
        gender: '1',
        birthday: '',
        sign: ''
      },
      datalist: [],
      actions: [],
      showPopover: false,
      languageSwitch: false,
      lang: '',
      huobiact: huobiact,
      huobi: huobi,
      currencyList: [],
      currency: '',
      show2: false,
      kefuCont: {
        phone: [],
        email: [],
        address:''
      },
      longPressTimer: '',
      is_wx: /MicroMessenger/i.test(window.navigator.userAgent),
      weixinShow: false
    }
  },
  created() {
    let url = window.location.href
    let index = url.indexOf('&lang=')
    if (index > -1) {
      url = url.substring(0, index)
      let idx = url.indexOf('&currency=')
      if (idx > -1) {
        url = url.substring(0, idx)
      }
      let idxs = url.indexOf('&parentUserId=')
      if (idxs > -1) {
        url = url.substring(0, idxs)
      }
    }
    this.baseUrl =
        url +
        '&lang=' +
        localStorage.getItem('lang') +
        '&currency=' +
        localStorage.getItem('currency')
    if(localStorage.getItem('userData')){
      this.baseUrl=this.baseUrl+'&parentUserId='+ JSON.parse(localStorage.getItem('userData')).id
    }
    if(this.$route.query.parentUserId){
      localStorage.setItem('parentUserId',this.$route.query.parentUserId)
    }
    if (this.type == 1) {
      this.dynamicrouter()
    }
    if (gettoken()) {
      this.gainuserList()
    }
    if (localStorage.getItem('lang')) {
      this.lang = localStorage.getItem('lang')
      this.defaultSelection = {
        lang: this.lang
      }
    }
    if (localStorage.getItem('currency')) {
      this.currency = localStorage.getItem('currency')
      this.defaultSelection = {
        currency: this.currency
      }
    }
    // this.storeIndexInfo = JSON.parse(localStorage.getItem('storeIndexInfo'))
    this.getLanguage()
    this.getCurrency()
    this.kefu()
    this.getAreaCodeDefault()
  },

  async mounted() {
    // 发起异步请求
    await this.$store.dispatch('user/logoTable')
    // 等待请求完成后再访问 logotable 状态变量
    this.info = this.$store.state.user.logotable
  },
  methods: {
    wxShar() {
      this.showShare = false
      this.weixinShow = true
    },
    routeClick() {
      if (this.isValidUrl(this.storeIndexInfo?.INFORMATION?.technical?.agreementText)) {
        window.open(this.storeIndexInfo?.INFORMATION?.technical?.agreementText)
      } else {
        console.error('无效的路径:', this.storeIndexInfo?.INFORMATION?.technical?.agreementText)
        // 可以选择进行其他处理或者提示给用户
      }
      // 通过此方法可以使用
      // if (this.info.endPageUrl) {
      //   // window.location.href=this.info.endPageUrl
      //   window.open(this.info.endPageUrl)
      // }
    },
    isValidUrl(string) {
      try {
        new URL(string)
        return true
      } catch (_) {
        return false
      }
    },
    handleTouchStart(text) {
      this.longPressTimer = setTimeout(() => {
        this.copyText2(text)
      }, 1000) // 长按时间，可根据需求调整
    },
    handleTouchEnd() {
      clearTimeout(this.longPressTimer)
    },
    copyText2(text) {
      const hiddenTextarea = document.createElement('textarea')
      hiddenTextarea.value = text
      document.body.appendChild(hiddenTextarea)
      hiddenTextarea.select()
      document.execCommand('copy')
      document.body.removeChild(hiddenTextarea)
      this.$toast({
        showClose: true,
        message: window.$i18n.t('复制成功'),
        type: 'success'
      })
    },
    // 获取默认区号
    async getAreaCodeDefault() {
      let res = await AreaCodeDefault({
        code: localStorage.getItem('lang')
      })
      if (res.data) {
        localStorage.setItem('AreaCode', res.data.areaCode)
      }
    },
    async kefu() {
      let res = await StoreFindContact({
        storeId: localStorage.getItem('storeID')
      })
      let kefu = {phone:[], email:[],address:''}
      if(res.data?.phone?.length>0){
        kefu.phone=JSON.parse(res.data.phone)
      }
      if(res.data?.email?.length>0){
        kefu.email=JSON.parse(res.data.email)
      }
      if(res.data?.address){
        let lang=localStorage.getItem('lang')
       let address=JSON.parse(res.data.address)[lang]
        kefu.address=address
      }
      this.kefuCont = kefu
      localStorage.setItem('kefu', JSON.stringify(kefu))
    },
    copyText() {
      let inputDom = document.createElement('textarea') // js创建一个文本框
      document.body.appendChild(inputDom) //将文本框暂时创建到实例里面
      inputDom.value = this.baseUrl //将需要复制的内容赋值到创建的文本框中
      inputDom.select() //选中文本框中的内容
      inputDom.focus()
      document.execCommand('copy') //执行复制操作
      document.body.removeChild(inputDom) //最后移出
      this.$toast({
        showClose: true,
        message: window.$i18n.t('复制成功'),
        type: 'success'
      })
    },
    savePoster() {
      // 获取海报容器的 DOM 元素
      const posterContainer = document.getElementById('poster-container')
      // 使用 html2canvas 将海报容器绘制为 Canvas
      html2canvas(posterContainer).then(canvas => {
        canvas.toBlob(
            function (blob) {
              const ele = document.createElement('a')
              ele.download = 'poster.png'
              ele.style.display = 'none'
              ele.href = URL.createObjectURL(blob) //字符内容转为blob地址
              ele.click()
            },
            'image/png',
            1
        )
        // // 转换为图像
        // const dataUrl = canvas.toDataURL('image/png')

        // // 创建一个隐藏的链接
        // const link = document.createElement('a')
        // link.setAttribute('download', 'poster.png')
        // link.setAttribute('href', dataUrl)

        // // 触发点击事件下载图像
        // link.click()
      })
    },
    haibaoFx() {
      this.show2 = true
      this.show = false
      this.showShare = false
      // this.storeIndexInfo = JSON.parse(localStorage.getItem('storeIndexInfo'))
      setTimeout(() => {
        this.getQRCode()
      }, 100)
    },
    getQRCode() {
      //生成的二维码为URL地址js
      // this.qrUrl = window.location.href
      let opts = {
        errorCorrectionLevel: 'H', //容错级别
        type: 'image/png', //生成的二维码类型
        quality: 0.3, //二维码质量
        margin: 0, //二维码留白边距
        width: 150, //宽
        height: 150, //高
        text: this.baseUrl, //二维码内容
        color: {
          dark: '#333333', //前景色
          light: '#fff' //背景色
        }
      }

      let msg = document.getElementById('QRCode_header')
      let msg2 = document.getElementById('QRCode_header2')
      // 将获取到的数据（val）画到msg（canvas）上
      QRCode.toCanvas(msg, this.baseUrl, opts, function (error) {
        if (error) {
          // this.$message.error('二维码加载失败')
        }
      })
      QRCode.toCanvas(msg2, this.baseUrl, opts, function (error) {
        if (error) {
          // this.$message.error('二维码加载失败')
        }
      })
    },
    // 获取 分享链接
    twitterShareUrl() {
      this.show = false
      this.showShare = true
      setTimeout(() => {
        Share.init()
      }, 100)
    },
    goUser() {
      this.$router.push(
          {
            path: '/usermy',
            query: {storeId: localStorage.getItem('storeID')}
          },
          () => {
          },
          () => {
          }
      )
    },
    trmyuser() {
      this.$router.push(
          {path: '/login', query: {storeId: localStorage.getItem('storeID')}},
          () => {
          },
          () => {
          }
      )
    },
    leaveMessage() {
      if (gettoken()) {
        this.$router.push(
            {
              path: '/usermy/leaveMessage',
              query: {storeId: localStorage.getItem('storeID')}
            },
            () => {
            },
            () => {
            }
        )
      } else {
        // localStorage.setItem("menutable", 10);
        this.$router.push(
            {
              path: '/login',
              query: {storeId: localStorage.getItem('storeID')}
            },
            () => {
            },
            () => {
            }
        )
      }
    },
    // 获取个人信息
    async gainuserList() {
      let a = {
        storeId: localStorage.getItem('storeID')
      }
      let {data} = await userList(a)
      localStorage.setItem('userData', JSON.stringify(data))
      this.ruleForm = data
    },
    onSelectLang(action) {
      this.lang = action.code
      // location.reload();
      // window.$i18n.locale=
    },
    onSelectCurrency(action) {
      this.currency = action.currencyId
    },
    cancel() {
      this.languageSwitch = false
      this.lang = this.defaultSelection.lang
      this.currency = this.defaultSelection.currency
    },

    monetaryLanguage() {
      localStorage.setItem('lang', this.lang)
      localStorage.setItem('currency', this.currency)
      this.$store.dispatch('order/upadtaCurrency', this.currency.icon)
      this.$changeLanguage(this.lang)
      let url = window.location.href
      let index = url.indexOf('&lang=')
      if (index > -1) {
        url = url.substring(0, index)
        let idx = url.indexOf('&currency=')
        if (idx > -1) {
          url = url.substring(0, idx)
        }
      }
      location.href = url
      // this.$router.go(0) // 刷新页面
    },
    async getCurrency() {
      let a = {storeId: localStorage.getItem('storeID')}
      let {data} = await currency(a)
      this.currencyList = data
      if (!localStorage.getItem('currency')) {
        localStorage.setItem('currency', data[0].currencyId)
        this.currency = data[0].currencyId
        this.$store.dispatch('order/upadtaCurrency', data[0].icon)
        // this.form.currency = data[0].currencyId
        location.reload()
      } else {
        var isCNExist = data.filter(el => {
          return el.currencyId == localStorage.getItem('currency')
        })
        if (isCNExist.length == 0) {
          let url = window.location.href
          let index = url.indexOf('&lang=')
          if (index > -1) {
            url = url.substring(0, index)
            let idx = url.indexOf('&currency=')
            if (idx > -1) {
              url = url.substring(0, idx)
            }
          }
          localStorage.setItem('currency', data[0].currencyId)
          this.currency = data[0].currencyId
          this.$store.dispatch('order/upadtaCurrency', data[0].icon)
          // this.form.currency = data[0].currencyId
          // location.reload()
          location.href = url
        } else {
          this.currency = isCNExist[0].currencyId
          this.$store.dispatch('order/upadtaCurrency', isCNExist[0].icon)
          // this.form.currency = isCNExist[0].currencyId
        }
      }
    },
    async getLanguage() {
      let a = {storeId: localStorage.getItem('storeID')}
      let b = []
      let {data} = await Language(a)
      data.forEach(el => {
        this.actions.push({
          text: el.showName,
          code: el.code,
          isDefault: el.isDefault
        })
        b.push(el.code)
      })
      if (!localStorage.getItem('lang')) {
        let a = data.filter(el => {
          return el.isDefault == true
        })

        this.lang = a[0]?.code
        localStorage.setItem('lang', a[0].code)
        location.reload()
      } else {
        var isCNExist = b.includes(localStorage.getItem('lang'))
        if (!isCNExist) {
          let url = window.location.href
          let index = url.indexOf('&lang=')
          if (index > -1) {
            url = url.substring(0, index)
            let idx = url.indexOf('&currency=')
            if (idx > -1) {
              url = url.substring(0, idx)
            }
          }
          let a = data.filter(el => {
            return el.isDefault == true
          })
          this.lang = a[0]?.code
          localStorage.setItem('lang', a[0].code)
          // location.reload()
          location.href = url
        }
      }
      // this.actions=data
    },

    goUrl(url) {
      this.$router.push({
        path: url,
        query: {storeId: localStorage.getItem('storeID')}
      })
    },
    // 跳转详情
    clicktable(id, type, url) {
      this.$store.dispatch('user/TablistID', id)
      if (type == 0) {
        this.$router.push({
          path: '/',
          query: {storeId: localStorage.getItem('storeID')}
        })
        this.show = false
      } else if (type == 1) {
        sessionStorage.setItem('home', 0)
        this.$router.push({
          path: '/productDetails',
          query: {
            value: this.parseUrlParams(url).value,
            storeId: localStorage.getItem('storeID')
          }
        })
      } else if (type == 2) {
        if (url.indexOf('classId3=') != -1) {
          let a = url.split('classId3=')
          let c = a[0].split('classId2=')
          let b = c[0].split('classId1=')
          localStorage.setItem('customizeId', a[1])
          this.$store.dispatch('user/TablistID', a[1])
          this.$router.push({
            path: '/goods',
            query: {
              classId1: b[1].slice(0, -1),
              classId2: c[1].slice(0, -1),
              classId3: a[1],
              storeId: localStorage.getItem('storeID')
            }
          })
          // window.location.href = url
        } else if (url.indexOf('classId2=') != -1) {
          let a = url.split('classId2=')
          let b = a[0].split('classId1=')
          localStorage.setItem('customizeId', a[1])
          this.$store.dispatch('user/TablistID', a[1])

          this.$router.push({
            path: '/goods',
            query: {
              classId1: b[1].slice(0, -1),
              classId2: a[1],
              storeId: localStorage.getItem('storeID')
            }
          })
          // window.location.href = url
        } else if (url.indexOf('classId1=') != -1) {
          let a = url.split('classId1=')
          localStorage.setItem('customizeId', a[1])
          this.$store.dispatch('user/TablistID', a[1])

          this.$router.push({
            path: '/goods',
            query: {classId1: a[1], storeId: localStorage.getItem('storeID')}
          })
        } else {
          this.$router.push({
            path: '/goods',
            query: {storeId: localStorage.getItem('storeID')}
          })
        }
        // window.location.href = url
      } else if (type == 3) {
        this.$router.push({
          path: '/ExternalWord',
          query: {storeId: localStorage.getItem('storeID')}
        })
      } else if (type == 4) {
        this.$router.push({
          path: '/goods',
          query: {storeId: localStorage.getItem('storeID')}
        })
      }
    },
    // 第一种方法
    parseUrlParams(url) {
      const params = {}
      if (!url || url === '' || typeof url !== 'string') {
        return params
      }
      const paramsStr = url.split('?')[1]
      if (!paramsStr) {
        return params
      }
      const paramsArr = paramsStr.replace(/&|=/g, ' ').split(' ')
      for (let i = 0; i < paramsArr.length / 2; i++) {
        const value = paramsArr[i * 2 + 1]
        params[paramsArr[i * 2]] =
            value === 'true' ? true : value === 'false' ? false : value
      }
      return params
    },
    // 初始化动态添加路由
    async dynamicrouter() {
      let a = {
        storeId: localStorage.getItem('storeID')
      }
      let {data} = await listStoreNavigation(a)
      this.datalist = data
      this.datalist.unshift({pageType: 4, title: window.$i18n.t('全部分类')})
      this.datalist.unshift({pageType: 0, title: window.$i18n.t('首页')})
      let b = localStorage.getItem('customizeId')
      if (b !== '' && b !== undefined && b !== null) {
        await data.forEach(item => {
          if (item.customizeId === b) {
            this.activeIndex = item.id
            localStorage.setItem('menutable', item.id)
          }
        })
        // if (localStorage.getItem("classId1") !== "" || localStorage.getItem("classId2") !== "" || localStorage.getItem("classId3") !== "") {
        //   await this.$router.push({ path: "/allProducts", query: { classId1: localStorage.getItem("classId1"), classId2: localStorage.getItem("classId2"), classId3: localStorage.getItem("classId3"), storeId: localStorage.getItem("storeID") } });
        // } else {
        //   await this.$router.push({ path: "/ExternalWord", query: { id: b, storeId: localStorage.getItem("storeID") } });
        // }
      }
      // if()
    }
  }
}
</script>
<style scoped lang="scss">
.weixinBox {
  position: relative;

  .mask {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 17;
  }

  .weixinJt {
    position: fixed;
    z-index: 18;
    right: 0.1rem;
    top: 0.2rem;
    // position: absolute;
    img {
      width: 1rem;
      height: 1rem;
    }

    .labe {
      margin: 0.1rem 0 0;
      font-size: 0.16rem;
      color: #fff;
    }
  }
}

.navBar2 {
  padding: 0.1rem 0.19rem;
  background: #fff;

  .searchBox {
    margin-top: 0.22rem;
    padding: 0 0.08rem 0 0.15rem;
    height: 0.49rem;
    border: 0.01rem solid #e0e0e0;
    border-radius: 0.06rem;
  }
}

.ewm {
  .mask {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 17;
  }

  background: #fff;

  .pop2 {
    background: #fff;
    border-radius: 20px;
    min-width: 280px;
    box-shadow: 0 2px 10px 0 rgba(10, 5, 74, 0.3);

    .content {
      padding: 22px;

      .spTitle {
        // width: 380px;
        font-size: 15px;
        color: #384358;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box; //作为弹性伸缩盒子模型显示。
        -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
        -webkit-line-clamp: 2; //显示的行
      }

      .tip {
        font-size: 13px;
        color: #999999;
      }

      .ewmBox {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0;
      }

      .btnBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;

        .btn {
          width: 90px;
          height: 31px;
          text-align: center;
          border: 1px solid #8d4bbb;
          border-radius: 8px;
          line-height: 31px;
          color: #6e4faa;
        }

        .btn2 {
          width: 90px;
          height: 31px;
          text-align: center;
          border-radius: 8px;
          line-height: 31px;
          background: #6e4faa;
          color: #fff;
        }
      }
    }
  }

  .pop {
    background: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    border-radius: 20px;
    min-width: 280px;
    box-shadow: 0 2px 10px 0 rgba(10, 5, 74, 0.3);

    .content {
      padding: 22px;

      .spTitle {
        // width: 380px;
        font-size: 15px;
        color: #384358;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box; //作为弹性伸缩盒子模型显示。
        -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
        -webkit-line-clamp: 2; //显示的行
      }

      .tip {
        font-size: 13px;
        color: #999999;
      }

      .ewmBox {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 0;
      }

      .btnBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;

        .btn {
          width: 90px;
          height: 31px;
          text-align: center;
          border: 1px solid #8d4bbb;
          border-radius: 8px;
          line-height: 31px;
          color: #6e4faa;
        }

        .btn2 {
          width: 90px;
          height: 31px;
          text-align: center;
          border-radius: 8px;
          line-height: 31px;
          background: #6e4faa;
          color: #fff;
        }
      }
    }
  }
}

.sharList {
  padding: 16px 0.1rem;
  flex-wrap: wrap;

  .sharli {
    width: 20%;
    // margin-right: .2rem;
    margin-bottom: 0.1rem;

    img {
      width: 48px;
      height: 48px;
    }

    .title {
      color: #646566;
      font-size: 12px;
      margin-top: 8px;
    }
  }

  .sharli:nth-child(5n) {
    margin-right: 0;
  }
}

.navBarCom {
  .languageSwitch {
    .title {
      height: 0.53rem;
      border-radius: 5px 5px 0 0;
      background: #fff;
      text-align: center;
      line-height: 0.53rem;
      font-size: 0.16rem;
      color: #333;
      font-weight: 500;
    }

    .list {
      .labe {
        height: 0.32rem;
        background: #f5f5f5;
        padding: 0 0.17rem;
        font-size: 0.13rem;
        color: #666666;
        font-weight: 500;
        line-height: 0.32rem;
      }

      .li {
        height: 0.5rem;
        padding: 0 0.17rem 0 0.22rem;
        border-bottom: 1px solid #dbdbdb;

        .txt {
          font-size: 0.13rem;
          color: #333;
          font-weight: 500;
        }

        img {
          width: 0.16rem;
          height: 0.16rem;
        }
      }

      .li:last-child {
        border: 0;
      }
    }

    .btn-box {
      border-top: 2px solid #dbdbdb;
      height: 0.45rem;

      .btn1 {
        width: 50%;
        background: #fff;
        text-align: center;
        line-height: 0.45rem;
        font-size: 0.17rem;
        color: #6e4faa;
        font-weight: 500;
      }

      .btn2 {
        width: 50%;
        background: #6e4faa;
        text-align: center;
        line-height: 0.45rem;
        font-size: 0.17rem;
        color: #fff;
        font-weight: 500;
      }
    }
  }

  .van-popover__wrapper {
    display: flex;
  }

  .ltitlet {
    height: 100%;
    padding: 0 0.2rem;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .services {
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // height: 40px;

      .services2 {
        // padding-left: 10px;
        margin-bottom: 0.1rem;
      }
    }

    .serviceemil {
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // height: 40px;

      .serviceemil2 {
        // padding-left: 10px;
        margin-bottom: 0.1rem;
      }
    }
  }

  .more {
    width: 0.17rem;
    height: 0.13rem;
    margin-right: 0.13rem;
  }

  .logo {
    width: 0.529rem;
    height: 0.23rem;
  }

  .search {
    width: 0.17rem;
    height: 0.17rem;
    // margin-right: 0.16rem;
  }

  .language {
    width: 0.17rem;
    height: 0.17rem;
  }

  .box1 {
    .login {
      // padding: 0 .22rem;
      height: 0.64rem;
      background-image: url('@/assets/loginbg2.png');
      background-repeat: repeat;
      background-size: cover;

      .userImg {
        width: 0.34rem;
        height: 0.34rem;
        margin-right: 0.12rem;
      }

      font-size: 0.17rem;
      color: #fff;
    }

    .li {
      height: 0.5rem;
      padding: 0 0.22rem;
      line-height: 0.5rem;
      background: #f7f7f7;
      font-size: 0.15rem;
      color: #384358;
      font-weight: 500;
      border-bottom: 1px solid #dbdbdb;
    }

    .li:last-child,
    .last-li {
      border-bottom: none !important;
    }

    .box2 {
      padding: 0 0.19rem;
      margin-top: 0.7rem;
      border-top: 1px solid #dbdbdb;

      .share {
        font-size: 0.15rem;
        font-weight: 500;
        color: #384358;
        height: 0.4rem;
        line-height: 0.4rem;
      }

      .li2 {
        font-size: 0.12rem;
        color: #666666;
      }

      .li3 {
        // padding: 0 .22rem;
        height: 0.5rem;

        .kf {
          width: 0.18rem;
          height: 0.17rem;
          flex-shrink: 0;
          margin-right: 0.1rem;
        }

        font-size: 0.15rem;
        font-weight: 500;
        color: #384358;
      }
    }
  }
}
</style>
