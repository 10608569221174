import request from '@/utils/request'

// 验证码
export function verification(data) {
    return request({
        url: '/userConsumer/auth/sendCode',
        method: 'get',
        data
    })
}

//图片验证码
export function getImageCode(data) {
    return request({
        url: '/userConsumer/users/getImageCode',
        method: 'get',
        data,
        responseType: 'blob'
    })
}

//图片验证码 验证
export function verifyImageCode(data) {
    return request({
        url: 'userConsumer/users/verifyImageCode',
        // sellerConsumer/common/getImageCode
        // userConsumer/users/verifyImageCode
        method: 'get',
        data,

    })
}

// 注册
export function register(params) {
    return request({
        url: '/userConsumer/users/register',
        method: 'post',
        params
    })
}

// 登录
export function login(params) {
    return request({
        url: '/userConsumer/auth/login',
        method: 'post',
        params
    })
}

// 获取当前用用户信息
export function userList(params) {
    return request({
        url: '/userConsumer/auth/getMe',
        method: 'get',
        params
    })
}

// 验证验证码
export function verificationCode(params) {
    return request({
        url: '/userConsumer/auth/checkCode',
        method: 'post',
        params
    })
}

// facebook 登录获取信息
export function facebookcallBack(params) {
    return request({
        url: '/userConsumer/front/auth/facebookcallBack',
        method: 'get',
        params
    })
}

// 登出
export function exitUser(params) {
    return request({
        url: '/userConsumer/auth/logout',
        method: 'get',
        params
    })
}

// 忘记密码
export function forgetPassword(params) {
    return request({
        url: '/userConsumer/users/forgetPassword',
        method: 'post',
        params
    })
}

// 修改密码
export function amendPassword(params) {
    return request({
        url: '/userConsumer/users/amendPassword',
        method: 'post',
        params
    })
}

// 修改用户信息
export function amendUser(params) {
    return request({
        url: '/userConsumer/users/amendUser',
        method: 'post',
        params
    })
}

// 发送邮箱验证码
export function sendEmailCode(params) {
    return request({
        url: '/threeTerminalCon/utils/sendEmailCode',

        // url: '/goodsConsumer/utils/sendEmailCode',
        method: 'post',
        params
    })
}


// 验证\修改邮箱
export function checkEmailCode(params) {
    return request({
        url: '/threeTerminalCon/utils/checkEmailCode',
        // url: '/goodsConsumer/utils/checkEmailCode',

        method: 'post',
        params
    })
}

// 查询整个超时规则\用户协议 查2
export function getSysDictionaryAgreementCon(params) {
    return request({
        url: '/threeTerminalCon/sysDictionaryCon/getSysDictionaryAgreementCon',
        // url: '/platformConsumer/sysDictionaryCon/getSysDictionaryAgreementCon',
        method: 'post',
        params
    })
}


// 新查询整个超时规则\用户协议  查 0 1
export function getSysDictionaryAgreementCon2(params) {
    return request({
        url: '/userConsumer/sysDictionaryCon/getAgreement4StoreCon',
        method: 'post',
        params
    })
}


// 微信内置浏览器登录
export function wxloginUrl(params) {
    return request({
        url: '/userConsumer/front/auth/wxlogin',
        method: 'get',
        params
    })
}

// 微信内置浏览器登录
export function wxlogin(params) {
    return request({
        url: '/userConsumer/front/auth/wxcallBack',
        method: 'get',
        params
    })
}

// 外部浏览器微信登录
export function wxsmloginUrl(params) {
    return request({
        url: '/userConsumer/front/auth/wxsmlogin',
        method: 'get',
        params
    })
}

// 微信pc浏览器登录
export function wxsmlogin(params) {
    return request({
        url: '/userConsumer/front/auth/sm/wxcallBack',
        method: 'get',
        params
    })
}


// 绑定手机号并且登陆
export function phoneNumberBind(params) {
    return request({
        url: '/userConsumer/front/auth/userSetPhoneAndToLogin',
        method: 'get',
        params
    })
}

// 短信验证
export function checkCode(params) {
    return request({
        url: '/userConsumer/auth/checkCode',
        method: 'POST',
        params
    })
}


// 谷歌登录
export function googleloginUrl(params) {
    return request({
        url: '/userConsumer/front/auth/googlelogin',
        method: 'get',
        params
    })
}

// 谷歌返回登录
export function googlelogin(params) {
    return request({
        url: '/userConsumer/front/auth/googlecallBack',
        method: 'get',
        params
    })
}

// wechatShare
export function wechatShare(params) {
    return request({
        url: '/userConsumer/wx/share',
        method: 'post',
        params
    })
}

//用户个人中心数据
export function getUserInfo(params) {
    return request({
        url: '/userConsumer/users/getUserInfo?'+params,
        method: 'post',
        // params
    })
}
