// 缅甸
export default {
    BCEL支付: 'BCEL',
    KBZ支付: 'KBZpay',
    OnePay支付: 'OnePay',
    OnePay支付二维码: 'OnePay QR ကုဒ်',
    保存: 'သိမ်းဆည်းပါ။',
    x件: '{num} ခု',
    请再次输入新密码: 'စကားဝှက်အသစ်ကို နောက်တစ်ကြိမ် ထည့်သွင်းပါ။',
    保密: 'လျှို့ဝှက်ထားပါ။',
    备注说明: 'ညွှန်ကြားချက်လက်စွဲ',
    编辑地址: 'လိပ်စာကို တည်းဖြတ်ပါ။',
    标记已读: 'ဖတ်ပြီးကြောင်း အမှတ်အသားပြုပါ။',
    不可修改最多: 'ပြုပြင်၍မရသော၊ အများဆုံး',
    部分订单取消: 'တစ်စိတ်တစ်ပိုင်း အော်ဒါများကို ပယ်ဖျက်လိုက်ပါပြီ။',
    部分发货: 'တစ်စိတ်တစ်ပိုင်း ပေးပို့ခြင်း။',
    部分商品正在退款中: 'အချို့သောပစ္စည်းများကို ငွေပြန်အမ်းပေးနေပါသည်။',
    部分收货: 'တစိတ်တပိုင်းပြေစာ',
    部分退款: 'တစ်စိတ်တစ်ပိုင်း ပြန်အမ်းငွေ',
    部分退款完成: 'တစ်စိတ်တစ်ပိုင်း ပြန်အမ်းငွေ ပြီးပါပြီ။',
    查看订单: 'အော်ဒါ စစ်ဆေးရန်',
    查看全部: 'အားလုံးကိုကြည့်ရှုပါ။',
    查看全部xx条回复: 'ပြန်ကြားချက် {num} ခုလုံးကို ကြည့်ပါ။',
    查看详情: 'စစ်ဆေးပါ။',
    查看支付是否成功: 'ငွေပေးချေမှု အောင်မြင်မှုရှိမရှိ စစ်ဆေးပါ။>>>',
    查询中: 'စုံစမ်းရေး',
    城市: 'မြို့',
    创建时间: 'ဖန်တီးချိန်',
    此宝贝已下架: 'ဤပစ္စည်းသည် အရောင်းမတင်တော့ပါ။',
    待发货: 'ပေးပို့ရန်ဆိုင်းငံ့ခြင်း',
    待付款: 'ဆိုင်းငံ့ပေးချေမှု',
    待收货: 'လက်ခံရရှိရန်',
    当前安全手机号: 'လက်ရှိ လုံခြုံသောဖုန်းနံပါတ်',
    地址管理: 'လိပ်စာစီမံခန့်ခွဲမှု',
    登录: 'လော့ဂ်အင်',
    等待店铺审核: 'ဆိုင်၏စိစစ်သုံးသပ်ချက်ကို စောင့်နေသည်။',
    点击选择城市: 'မြို့ကိုရွေးချယ်ရန် နှိပ်ပါ။',
    点击选择国家: 'နိုင်ငံကို ရွေးချယ်ရန် နှိပ်ပါ။',
    点击选择退货类型: 'ပစ္စည်းပြန်အပ်မည့်အမျိုးအစားကို ရွေးချယ်ရန် နှိပ်ပါ။',
    点击选择退货原因: 'ပစ္စည်းပြန်အပ်ရသည့်အကြောင်းရင်းကို ရွေးရန် နှိပ်ပါ။',
    店: 'S',
    店铺: 'ဆိုင်',
    店铺处理: 'အရောင်းဆိုင်ကိုင်တွယ်ဖြေရှင်းခြင်း',
    店铺拒绝您的申请: 'သင့်လျှောက်လွှာကို စတိုးဆိုင်မှ ပယ်ချခဲ့သည်။',
    店铺退款: 'ဆိုင်မှ ပြန်အမ်းငွေ',
    订单编号: 'အော်ဒါနံပါတ်',
    订单号: 'အော်ဒါနံပါတ်',
    订单详情: 'အော်ဒါအသေးစိတ်',
    订单已超时: 'မှာယူမှု သက်တမ်းကုန်သွားပါပြီ။',
    短信验证码: 'SMS အတည်ပြုကုဒ်',
    发表留言: 'အမှာစကားထားခဲ့ပါ',
    发货: 'ကုန်ပစ္စည်းပေးပို့ခြင်း',
    发货件数: 'ပို့ဆောင်သည့်အရေအတွက်',
    发货时间: 'ပို့ဆောင်ချိန်',
    发货状态: 'ပို့ဆောင်မှုအခြေအနေ',
    发送成功: 'ပေးပို့မှု အောင်မြင်သည်။',
    发送验证码: 'အတည်ပြုကုဒ်ကို ပေးပို့ပါ။',
    法律声明: 'ဥပဒေသတိပေးချက်များ',
    返回: 'နောက်သို့ပြန်သွားသည်',
    返回首页: 'ပင်မစာမျက်နှာသို့ပြန်သွားမည်။',
    分: 'မိနစ်',
    分类: 'အမျိုးအစားခွဲခြားခြင်း။',
    付款时间: 'ငွေပေးချေချိန်',
    复制: 'ကော်ပီ',
    复制成功: 'ကူးယူမှု အောင်မြင်သည်။',
    该订单已取消: 'ဤမှာယူမှုကို ပယ်ဖျက်လိုက်ပါပြီ။',
    个人信息: 'ကိုယ်ပိုင်သတင်းအချက်အလက်များ',
    个字以内: 'x လုံး အက္ခရာများအတွင်း',
    恭喜您邮箱地址验证成功:
        'ဂုဏ်ယူပါတယ်အီးမေးလ်လိပ်စာ အတည်ပြုခြင်း အောင်မြင်ပါသည်။',
    购买商品: 'ကုန်ပစ္စည်းဝယ်ယူခြင်း',
    购买数量: 'ဝယ်ယူမှုပမာဏ',
    购买信息: 'ဝယ်ယူမှုအချက်အလက်',
    购物车: 'စျေးဝယ်ခြင်းတောင်း',
    国家区域: 'နိုင်ငံ/ဒေသ',
    含发货邮费: 'ပို့ဆောင်ခပါဝင်ပါသည်။',
    欢迎登录: 'လော့ဂ်အင်ဝင်ရန် ကြိုဆိုပါသည်။',
    活动优惠: 'ပရိုမိုးရှင်း လျှော့ဈေး',
    货币: 'ငွေကြေး',
    继续逛逛: 'ဆက်လက် စျေးဝယ်မယ်',
    加入购物车: 'စျေးဝယ်ခြင်းထဲသို့ထည့်သည်',
    加载中: 'loading',
    价格高低: 'စျေးနှုန်းအဆင့် အနိမ့်အမြင့်',
    价格最低: 'အနိမ့်ဆုံးစျေးနှုန်း',
    价格最高: 'အမြင့်ဆုံးစျေးနှုန်း',
    件商品: 'x ခု ကုန်ပစ္စည်းများ',
    交易异常: 'ငွေပေးငွေယူ မူမမှန်ပါ။',
    看不清换一张: 'ရှင်းရှင်းလင်းလင်းမမြင်ရပါ။ နောက်တစ်ပုံပြောင်းပါ။',
    客: 'C',
    客服: 'ဧည့်ဝန်ဆောင်မှု',
    客服电话: 'တယ်လီဖုန်း',
    客服邮箱: 'မေးလ်',
    客户: 'ဖောက်သည်',
    客户服务: 'ဧည့်ဝန်ဆောင်မှု',
    客户取消售后: 'ဖောက်သည်သည် အရောင်းပြီးနောက်ကို ပယ်ဖျက်လိုက်သည်။',
    客户已付款等待店铺发货:
        'ဖောက်သည်သည် ငွေပေးချေပြီး ဆိုင်မှ ပစ္စည်းပို့ဆောင်ရန် စောင့်နေသည်။',
    历史搜索: 'ရှာဖွေမှု ရာဇဝင်',
    立即登录: 'ချက်ချင်းလော့ဂ်အင်ဝင်ပါ။',
    立即购买: 'ချက်ချင်းဝယ်မည်',
    立即留言: 'ချက်ချင်း မက်ဆေ့ချ် ထားခဲ့ပါ။',
    立即提交: 'ယခုတင်သွင်းပါ။',
    立即注册: 'ယခုဝင်မည်',
    联系电话: 'ဆက်သွယ်ရန်နံပါတ်',
    联系方式: 'ဆက်သွယ်ရန်အချက်အလက်များ',
    联系客服: 'ဧည့်ဝန်ဆောင်မှု',
    联系人: 'ဆက်သွယ်ရမည့်သူ',
    两次输入密码不一致: 'နှစ်ကြိမ်ထည့်သော စကားဝှက်များသည် မကိုက်ညီပါ။',
    留言成功: 'မက်ဆေ့ချ်ပေးမှု အောင်မြင်သည်။',
    留言管理: 'မက်ဆေ့ချ်စီမံခန့်ခွဲမှု',
    留言回复: 'မက်ဆေ့ချ်ပြန်ကြားချက် ချန်ထားမည်',
    留言回复长度: 'မက်ဆေ့ချ် ပြန်ကြားချက်၊ အရှည် 2-100 လုံး',
    留言回复长度xxx个字符: 'မက်ဆေ့ချ် ပြန်ကြားချက်၊ အရှည် 2-100 လုံး',
    留言内容: 'မက်ဆေ့ခ်ျအကြောင်းအရာ',
    留言详情: 'မက်ဆေ့ခ်ျအသေးစိတ်',
    没有更多了: 'မရှိတော့ပါ',
    每张图片大小不超过3M: 'ပုံတစ်ပုံချင်းစီ၏အရွယ်အစားသည် 3M ထက်မပိုပါ။',
    密码: 'စကားဝှက်',
    密码登录: 'စကားဝှက် လော့ဂ်အင်',
    密码最少8位最多16位:
        'စကားဝှက်သည် အနည်းဆုံး အက္ခရာ 8 လုံးနှင့် အများဆုံး 16 လုံး ရှိရပါမည်။',
    秒: 'စက္ကန့်',
    秒后重新发送: '{seconds} စက္ကန့်ပြီးနောက် အသစ်ပြန်လည်ပေးပို့ပါ။',
    名: 'နာမည်',
    默认地址: 'မူရင်းလိပ်စာ',
    男: 'ကျား',
    昵称: 'အမည်ပြောင်',
    昵称不能为空: 'အမည်ပြောင်သည် ကွက်လပ်မဖြစ်ရပါ။',
    您当前是线下支付请耐心等待店铺确认收款若x后店铺未确认收款订单将自动取消:
        'သင်သည် လောလောဆယ် အော့ဖ်လိုင်းပေးချေနေပါသည်၊ ပြေစာအတည်ပြုရန် စတိုးဆိုင်အတွက် စိတ်ရှည်စွာစောင့်ဆိုင်းပါ။ <span style="color: red;">{num}</span> ပြီးနောက် ပြေစာကို စတိုးဆိုင်မှ အတည်မပြုပါက မှာယူမှုကို အလိုအလျောက် ပယ်ဖျက်ပါမည်။',
    您的购物车还没有商品哟: 'သင့်စျေးဝယ်လှည်းတွင် ကုန်ပစ္စည်းများမရှိပါ။~',
    您的收货地址为空点此添加收货地址:
        'သင်၏ပစ္စည်းလက်ခံမည့်လိပ်စာသည် ကွက်လပ်ဖြစ်နေသည်၊ ပစ္စည်းလက်ခံမည့်လိပ်စာတစ်ခုထည့်ရန် ဤနေရာကိုနှိပ်ပါ။',
    您可以参考如下线下付款方式:
        'အောက်ပါ အော့ဖ်လိုင်းငွေပေးချေမှုနည်းလမ်းများကို ကိုးကားနိုင်ပါသည်။',
    您可以在这里给店铺留言为了保证你的权益请尽可能详细的上传留言资料:
        'သင့်အခွင့်အရေးနှင့် အကျိုးစီးပွားများကို ကာကွယ်ရန်အတွက် ဤနေရာတွင် စတိုးဆိုင်သို့ မက်ဆေ့ချ်ချန်ထားနိုင်သည်၊ ကျေးဇူးပြု၍ အသေးစိတ်အချက်အလက်များကို တတ်နိုင်သမျှ မက်ဆေ့ချ်ကို အပ်လုဒ်လုပ်ပါ။',
    您未支付请重新支付: 'သင်မပေးဆပ်ရသေးပါ၊ ကျေးဇူးပြု၍ ထပ်မံပေးချေပါ။',
    女: 'မ',
    评论成功: 'မှတ်ချက် အောင်မြင်ခဲ့သည်။',
    切换语言和货币: 'ဘာသာစကားနှင့် ငွေကြေးကို ပြောင်းပါ။',
    请勾选删除商品: 'ကျေးဇူးပြု၍ ထုတ်ကုန်ကိုဖျက်ရန် စစ်ဆေးပါ။',
    请勾选商品: 'ပစ္စည်းကို အမှန်ခြစ်ရွေးချယ်ပါ။',
    请勾选信息: 'အချက်အလက်ကို ရွေးချယ်ပါ။',
    请上传图片: 'ပုံတစ်ပုံတင်ပါ။',
    请输入短信验证码: 'SMS အတည်ပြုကုဒ်ကို ထည့်ပါ။',
    请输入反馈内容: 'ကျေးဇူးပြု၍ အကြံပြုချက် အကြောင်းအရာကို ထည့်သွင်းပါ။',
    请输入留言内容: 'ကျေးဇူးပြု၍ မက်ဆေ့ချ်အကြောင်းအရာကို ထည့်သွင်းပါ။',
    请输入留言内容若您使用BCEL等支付请在此输入用来接收退款的银行卡号及户名:
        '请输入留言内容，若您使用BCEL或KBZPay支付，请在此输入用来接收退款的银行卡号及户名',
    请输入密码: 'စကားဝှက်ထည့်ပါ။',
    请输入内容: 'အကြောင်းအရာကို ထည့်သွင်းပါ။',
    请输入手机号: 'ကျေးဇူးပြု၍ ဖုန်းနံပါတ်ထည့်ပါ။',
    请输入搜索关键词: 'ရှာဖွေမှုသော့ချက်စာလုံးများကို ထည့်သွင်းပါ။',
    请输入下方图形验证码: 'ကျေးဇူးပြု၍ အောက်ပါ ဂရပ်ဖစ် အတည်ပြုကုဒ်ကို ထည့်ပါ။',
    请输入详细地址信息如道路门牌号小区楼层号等:
        'ကျေးဇူးပြု၍ လမ်း၊ အိမ်နံပါတ်၊ ရပ်ကွက်၊ အဆောက်အဦးနံပါတ်စသည်ဖြင့် အသေးစိတ်လိပ်စာအချက်အလက်များကို ထည့်သွင်းပါ။',
    请输入新密码: 'စကားဝှက်အသစ်ကို ထည့်ပါ။',
    请输入验证码: 'ကျေးဇူးပြု၍ အတည်ပြုကုဒ်ကို ထည့်ပါ။',
    请输入用户名: 'ကျေးဇူးပြု၍ အသုံးပြုသူအမည်ကို ထည့်သွင်းပါ။',
    请输入邮箱: 'ကျေးဇူးပြု၍ သင့်အီးမေးလ်ကို ထည့်သွင်းပါ။',
    请输入邮箱手机号: 'ကျေးဇူးပြု၍ အီးမေးလ်/မိုဘိုင်းနံပါတ်ကို ထည့်ပါ။',
    请输入原密码: 'မူလ စကားဝှက်ကို ထည့်ပါ။',
    请输入正确的密码格式必须包含两种字符:
        'မှန်ကန်သော စကားဝှက်ဖော်မတ်ကို ရိုက်ထည့်ပါ စာလုံးနှစ်လုံး ပါဝင်ရပါမည်။',
    请输入正确的邮箱: 'ကျေးဇူးပြု၍ သင်၏ မှန်ကန်သော အီးမေးလ်ကို ထည့်ပါ။',
    请填写联系电话: 'သင့်ဖုန်းနံပါတ်ကို ရိုက်ထည့်ပါ။',
    请填写联系人: 'ကျေးဇူးပြု၍ ဆက်သွယ်ရန်အမည်ကို ထည့်ပါ။',
    请填写留言内容: 'ကျေးဇူးပြု၍ မက်ဆေ့ခ်ျပါအကြောင်းအရာကိုဖြည့်ပါ။',
    请填写名: 'နာမည်ကို ဖြည့်ပေးပါ။',
    请填写收货地址: 'ပစ္စည်းပေးပို့ရမည့်လိပ်စာကိုဖြည့်ပါ။',
    请填写完整必填项: 'ဖြည့်ရန်လိုအပ်သောအကွက်များကိုဖြည့်ပါ။',
    请填写详细地址: 'လိပ်စာအသေးစိတ်ဖြည့်ပေးပါ။',
    请填写姓: 'ကျေးဇူးပြု၍ မျိုးရိုးအမည်ကိုဖြည့်ပါ။',
    请填写邮政编码: 'စာတိုက်ကုဒ်ကိုဖြည့်ပါ။',
    请选择: 'ကျေးဇူးပြု။ ရွေးချယ်ပါ',
    请选择城市: 'ကျေးဇူးပြု၍ မြို့ကိုရွေးချယ်ပါ။',
    请选择付款方式: 'ငွေပေးချေမှုနည်းလမ်းကို ရွေးချယ်ပါ။',
    请选择规格: 'သတ်မှတ်ချက်တစ်ခုကို ရွေးပါ။',
    请选择国家: 'ကျေးဇူးပြု၍ နိုင်ငံကို ရွေးပါ။',
    请选择删除项需已读才能删除:
        'ကျေးဇူးပြု၍ ဖျက်ရန် အကြောင်းအရာကို ရွေးပါ၊ ဖတ်ပြီးမှသာ ဖျက်နိုင်ပါသည်။',
    请再次输入密码: 'ကျေးဇူးပြု၍ စကားဝှက်ကို ထပ်မံထည့်သွင်းပါ။',
    请在x内完成支付否则订单会被自动取消:
        'ကျေးဇူးပြု၍ <span style="color: red;">{num}</span> အတွင်း ငွေပေးချေမှုကို အပြီးသတ်ပါ၊ သို့မဟုတ်ပါက မှာယူမှုကို အလိုအလျောက် ပယ်ဖျက်ပါမည်။',
    请在下次使用邮箱地址进行登录:
        'နောက်တစ်ကြိမ်ဝင်ရောက်ရန် သင့်အီးမေးလ်လိပ်စာကို အသုံးပြုပါ။',
    取消: 'ပယ်ဖျက်မည်။',
    取消成功: 'အောင်မြင်မှုကို ပယ်ဖျက်ပါ။',
    取消订单: 'မလုပ်တော့',
    取消订单成功: 'မှာယူမှုကို အောင်မြင်စွာ ပယ်ဖျက်လိုက်ပါပြီ။',
    取消时间: 'ပယ်ဖျက်ချိန်',
    取消售后: 'အရောင်းအပြီးဖျက်သိမ်းခြင်း။',
    去付款: 'ငွေပေးချေမှု',
    去支付: 'ငွေပေးချေမှု',
    全部: 'အားလုံး',
    全部分类: 'အမျိုးအစားအားလုံး',
    全部消息: 'မက်ဆေ့ချ်အားလုံး',
    全选: 'အားလုံးကို ရွေးပါ။',
    确定: 'သေချာပါသည်။',
    确定放弃支付吗: 'ငွေပေးချေမှုကို စွန့်လွှတ်လိုသည်မှာ သေချာပါသလား။?',
    确定删除选中的商品吗:
        'သင်ရွေးချယ်ထားသည့်ကုန်ပစ္စည်းအား ဖျက်လိုသည်မှာ သေချာပါသလား။',
    确定提交: 'တင်ပြမှုကို အတည်ပြုပါ။',
    确定要清空历史记录吗: 'ရာဇဝင်မှတ်တမ်းကို ရှင်းပစ်ချင်သည်မှာ သေချာပါသလား',
    确定要取消支付这笔订单吗:
        'ဤအော်ဒါအတွက် ငွေပေးချေမှုကို ပယ်ဖျက်လိုသည်မှာ သေချာပါသလား။',
    确定已输入接收退款的银行卡号和户名: 'ပြန်အမ်းငွေလက်ခံရရှိရန် ဘဏ်ကတ်နံပါတ်နှင့် အကောင့်အမည်ကို ထည့်သွင်းထားကြောင်း သေချာပါစေ။',
    确定支付: 'ငွေပေးချေမှုကိုအတည်ပြုပါ။',
    确认订单: 'အော်ဒါကို အတည်ပြုပါ။',
    确认密码: 'စကားဝှက်အတည်ပြုခြင်း',
    确认收货: 'ကုန်ပစ္စည်းလက်ခံရရှိမှုကို အတည်ပြုပါ။',
    确认收货成功: 'ကုန်ပစ္စည်းလက်ခံရရှိမှု အောင်မြင်ကြောင်း အတည်ပြုပါ။',
    确认已付款: 'ငွေပေးချေပြီးကြောင် အတည်ပြုသည်။',
    如果您当前的手机号已无法使用请联系客服:
        'သင့်လက်ရှိမိုဘိုင်းဖုန်းနံပါတ်ကို အသုံးမပြုနိုင်တော့ပါက ဖောက်သည်ဝန်ဆောင်မှုကို ဆက်သွယ်ပါ။',
    若您已按如上付款方式付款请上传相关凭证如转账截图等支持jpg和png格式文件图片不大于3M:
        'အထက်ဖော်ပြပါ ငွေပေးချေမှုနည်းလမ်းအတိုင်း ပေးချေပြီးပါက၊ ငွေလွှဲစခရင်ရှော့ကဲ့သို့သော သက်ဆိုင်ရာ ငွေလွှဲအထောက်အထားများကို အပ်လုဒ်လုပ်ပါ၊ jpg၊ png ဖော်မတ်ကို ပံ့ပိုးပါ၊ ပုံသည် 3M ထက်မကြီးသင့်ပါ။',
    扫码或者点击去支付: 'QR ကုဒ်ကို စကင်န်ဖတ်ပါ သို့မဟုတ် "ငွေပေးချေရန် သွားရန်" ကိုနှိပ်ပါ။',
    删除: 'ဖျက်ပါ။',
    删除成功: 'အောင်မြင်စွာ ဖျက်လိုက်ပါပြီ။',
    删除订单: 'အော်ဒါဖျက်မည်',
    删除订单成功: 'မှာယူမှုကို အောင်မြင်စွာ ဖျက်လိုက်ပါပြီ။',
    删除失效商品: 'အကျုံးမဝင်သော ကုန်ပစ္စည်းများကို ဖျက်ပါ။',
    商品: 'ကုန်စည်',
    商品单价: 'ကုန်ပစ္စည်းဈေးနှုန်း',
    商品分类: 'အမျိုးအစားများ',
    商品合计: 'စုစုပေါင်းကုန်သွယ်',
    商品寄回地址将在审核通过后以短信形式告知或在申请记录中查询:
        'ထုတ်ကုန်၏ပြန်ပေးပို့မည့်လိပ်စာကို အတည်ပြုပြီးနောက် စာသားမက်ဆေ့ခ်ျဖြင့် အကြောင်းကြားမည် သို့မဟုတ် လျှောက်လွှာမှတ်တမ်းတွင် မေးမြန်းပါ။',
    商品详情: 'ကုန်ပစ္စည်းအသေးစိတ်',
    商品总额: 'ကုန်ပစ္စည်း စုစုပေါင်းပမာဏ',
    商品总价: 'ကုန်ပစ္စည်း စုစုပေါင်းစျေးနှုန်း',
    上传成功: 'အောင်မြင်စွာ အပ်လုဒ်လုပ်ထားပါသည်။',
    上传凭证: 'အပ်လုဒ် အထောက်အထားလက်မှတ်',
    上传头像图片大小不能超过2MB:
        'အပ်လုဒ်လုပ်ထားသော ပရိုဖိုင်၏ အရွယ်အစားသည် 2MB ထက် မပိုနိုင်ပါ။',
    上传头像图片只能是JPG格式:
        'အပ်လုဒ်လုပ်ထားသော ကိုယ်ပွားပုံသည် JPG ဖော်မက်သာ ဖြစ်နိုင်သည်။',
    上传中: 'အပ်လုဒ်တင်နေသည်။...',
    设为默认: 'Default အဖြစ် သတ်မှတ်သည်။',
    设为默认收货地址: 'မူရင်း ပို့ဆောင်ရေးလိပ်စာအဖြစ် သတ်မှတ်ပါ။',
    申请时间: 'လျှောက်လွှာအချိန်',
    申请信息: 'လျှောက်လွှာအချက်အလက်',
    剩余X件: 'ကျန် {num} အပိုင်းများ',
    失效宝贝: 'အကျုံးမဝင်သော ဘေဘီလေး',
    时: 'နာရီ',
    实付金额: 'အမှန်တကယ် ပေးရသည့်ပမာဏ',
    实付款: 'အမှန်အကန် ငွေပေးချေမှု',
    是否删除地址: 'လိပ်စာကို ဖျက်မှာလား။',
    是否选择该地址: 'ဤလိပ်စာကို သင်ရွေးချယ်ပါသလား။',
    收货地址: 'ကုန်ပစ္စည်းပို့ဆောင်ရမည့်လိပ်စာ',
    收货人: 'လက်ခံသူ',
    手机号: 'ဖုန်းနံပါတ်',
    手机号码: 'ဖုန်းနံပါတ်',
    手机验证: 'ဖုန်းအတည်ပြုချက်',
    手机验证码: 'မိုဘိုင်းဖုန်း အတည်ပြုကုဒ်',
    首页: 'ရှေ့စာမျက်နှာ',
    售后单号: 'အရောင်းအပြီး အော်ဒါနံပါတ်',
    售后方式: 'ရောင်းချအပြီး နည်းလမ်းများ',
    售后服务: 'အရောင်းအပြီးဝန်ဆောင်မှု',
    售后商品: 'အရောင်းပြီးနောက် ကုန်ပစ္စည်းများ',
    售后申请: 'ရောင်းချပြီးနောက်လျှောက်လွှာ',
    售后申请成功: 'အရောင်းအပြီး လျှောက်လွှာ အောင်မြင်သည်။',
    售后申请提交成功:
        'ရောင်းချပြီးနောက် လျှောက်လွှာကို အောင်မြင်စွာ တင်သွင်းခဲ့သည်။',
    售后完成: 'အရောင်းအပြီး ပြီးဆုံးပါပြီ။',
    售后详情: 'အရောင်းအပြီးအသေးစိတ်',
    售后信息: 'အရောင်းအပြီး အချက်အလက်များ',
    售后专员可能与您电话沟通请保持手机畅通:
        'အရောင်းအပြီးတွင် ကျွမ်းကျင်သူသည် သင့်ထံ ဖုန်းဖြင့် ဆက်သွယ်နိုင်သည်၊ ကျေးဇူးပြု၍ သင့်ဖုန်းကို ဖွင့်ထားပါ။',
    搜索: 'ရှာဖွေ',
    提交: 'တင်ပြပါ။',
    提交订单: 'အော်ဒါ တင်ပါ။',
    提交服务单后售后专员可能与您电话沟通请保持手机畅通:
        'ဝန်ဆောင်မှုအမှာစာကို တင်သွင်းပြီးနောက်၊ အရောင်းအပြီးတွင် ကျွမ်းကျင်သူသည် သင့်ထံ ဖုန်းဖြင့် ဆက်သွယ်နိုင်သည်၊ ကျေးဇူးပြု၍ သင့်ဖုန်းကို မပိတ်မထားပါနှင့်။',
    提交申请: 'လျှောက်လွှာတင်ပါ။',
    提示: 'သတိပေးချက်များ',
    天: 'ရက်',
    添加超时: 'timeout ကိုထည့်ပါ။',
    添加成功: 'အောင်မြင်စွာ ထည့်သွင်းခဲ့သည်။',
    添加失败库存不足: 'ထည့်သွင်းမအောင်မြင်ပါ။ စတော့မလုံလောက်ပါ။',
    添加新地址: 'လိပ်စာအသစ်ထည့်ပါ။',
    条回复: '* ပြန်ကြားချက်များ',
    通过BCEL银行完成支付后务必返回当前页面确认支付是否成功:
        'BCEL ဘဏ်မှတဆင့် ငွေပေးချေမှုပြီးပါက၊ ငွေပေးချေမှုအောင်မြင်ကြောင်း အတည်ပြုရန် လက်ရှိစာမျက်နှာသို့ ပြန်သွားရန် သေချာပါစေ။',
    头像: 'ပရိုဖိုင်ပုံ',
    图片上传成功: 'ပုံကို အောင်မြင်စွာ အပ်လုဒ်တင်ပြီးပါပြီ။',
    图片上传失败请重试:
        'ပုံအပ်လုဒ်လုပ်ခြင်း မအောင်မြင်ပါ၊ ကျေးဇူးပြု၍ ထပ်စမ်းကြည့်ပါ။',
    退出登录: 'ဆိုင်းအောက်',
    退货处理成功后退款金额将原路返回到您的支持账户中:
        'ပစ္စည်းပြန်ပေးအပ်မှုကို အောင်မြင်စွာလုပ်ဆောင်ပြီးပါက၊ ပြန်အမ်းငွေပမာဏကို သင်၏ပံ့ပိုးမှုအကောင့်သို့ မူလလမ်းကြောင်းအတိုင်း ပြန်ပေးပါမည်။',
    退货金额: 'ပစ္စည်းပြန်ပေးငွေပမာဏ',
    退货类型: 'return type ပါ။',
    退货数量: 'ပြန်အပ်ပစ္စည်း အရေအတွက်',
    退货原因: 'ပစ္စည်းပြန်အပ်ရသည့်အကြောင်းအရင်း',
    退款成功: 'ငွေပြန်အမ်းခြင်း အောင်မြင်ပါသည်။',
    退款金额: 'ပြန်အမ်းငွေပမာဏ',
    退款失败: 'မအောင်မြင်ပါ။',
    退款说明: 'ပြန်အမ်းငွေ ညွှန်ကြားချက်များ',
    退款完成: 'ပြီးသွားပါပြီ။',
    退款原因: 'ငွေပြန်အမ်းရသည့်အကြောင်းအရင်း',
    退款中: 'ငွေပြန်အမ်းနေဆဲ',
    完成: 'ပြီးမြောက်သည်။',
    忘记密码: 'စကားဝှက်ကိုမေ့သွားပါသည်။',
    微信: 'WeChat',
    为了帮助您更好的解决问题请上传图片:
        'ပြဿနာကို ပိုမိုကောင်းမွန်စွာဖြေရှင်းနိုင်ရန် ကျေးဇူးပြု၍ ပုံတစ်ပုံတင်ပါ။',
    为确认身份我们需验证您的安全手机:
        ' မည်သူမည်ဝါဖြစ်ကြောင်း အတည်ပြုရန်၊ ကျွန်ုပ်တို့သည် သင်၏ လုံခြုံသောဖုန်းကို အတည်ပြုရန် လိုအပ်ပါသည်။',
    未登录: 'လော့ဂ်အင် မဝင်ရသေးပါ။',
    未读消息: 'မဖတ်ရသေးသော သတင်းအချက်အလက်များ',
    未发货: 'မပို့ရသေးပါ။',
    未添加收货地址: 'ပစ္စည်းလက်ခံမည့်လိပ်စာ မထည့်ရသေးပါ။',
    未选择地址或没有地址: 'လိပ်စာမရွေးချယ်ထားပါ သို့မဟုတ် လိပ်စာမရှိပါ',
    温馨提示: 'နွေးထွေးသော အကြံပြုချက်',
    文件过大: 'ဖိုင်အရမ်းကြီးသည်။',
    我的: 'ငါ့',
    我的订单: 'ကျွန်ုပ်၏ အော်ဒါ',
    我是新用户: 'ကျွန်ုပ်သည် အသုံးပြုသူအသစ်ဖြစ်သည်။',
    我要留言: 'မက်ဆေ့လေး ချန်ထားချင်ပါတယ်။',
    我要退货退款: 'ကျွန်တော် ပစ္စည်းပြန်ပေးခြင်းနဲ့ ပြန်အမ်းငွေ ရယူချင်ပါတယ်',
    我要退款: 'ကျွန်ုပ် ပြန်အမ်းငွေရယူပါမည်။',
    物流单号: 'ပို့ဆောင်မှုနံပါတ်：',
    下单结算: 'အော်ဒါပြေစာ',
    下一步: 'နောက်တစ်ဆင့်',
    线下支付: 'အော့ဖ်လိုင်းငွေပေးချေမှုများ',
    线下支付不支持线上售后服务如需售后请联系店铺若已付款请耐心等待店铺确认收款并发货:
        'အော့ဖ်လိုင်းငွေပေးချေမှုသည် အွန်လိုင်းရောင်းချပြီးနောက်ဝန်ဆောင်မှုကို မပံ့ပိုးပါ၊ ကျေးဇူးပြုပြီး အရောင်းအပြီးဝန်ဆောင်မှုလိုအပ်ပါက ကုန်သည်ထံ ဆက်သွယ်ပါ။ငွေပေးချေပြီးပါက၊ ကုန်သည်မှ ငွေပေးချေမှုကို အတည်ပြုပြီး ပစ္စည်းပေးပို့ခြင်းကို ကျေးဇူးပြု၍ စိတ်ရှည်စွာစောင့်ဆိုင်းပါ။',
    详细地址: 'လိပ်စာ အသေးစိတ်',
    消息通知: 'သတင်းအသိပေးချက်',
    消息详情: 'မက်ဆေ့ခ်ျအသေးစိတ်',
    销量高低: 'အရောင်းအဆင့် အနိမ့်အမြင့်',
    销量最低: 'အနိမ့်ဆုံးရောင်းအား',
    销量最高: 'အမြင့်ဆုံးရောင်းအား',
    新密码: 'စကားဝှက်အသစ်',
    新密码不能和旧密码相同: 'စကားဝှက်အသစ်သည် စကားဝှက်ဟောင်းနှင့် တူညီလို့မရပါ။',
    新增地址: 'လိပ်စာ အသစ် ထည့်ပါ။',
    性别: 'ကျား/မ',
    姓: 'မျိုးရိုးအမည်',
    修改: 'ပြန်လည်ပြင်ဆင်ပါ။',
    修改成功: 'ပြင်ဆင်မှု အောင်မြင်ပါသည်။',
    修改密码: 'စကားဝှက်ကိုပြောင်းရန်',
    修改邮箱: 'အီးမေးလ်ကို ပြင်ဆင်ပါ။',
    需勾选协议: 'သဘောတူညီချက်ကို အမှန်ခြစ်ရွေးချယ်ရန်လိုအပ်သည်။',
    选择: 'ရွေးချယ်ပါ။',
    选择成功: 'ရွေးချယ်မှု အောင်မြင်သည်။',
    选择新地址: 'လိပ်စာအသစ်ကို ရွေးပါ။',
    选中地址: 'ရွေးချယ်ထားသောလိပ်စာ',
    验证: 'စိစစ်ပါ။',
    验证码: 'အတည်ပြုကုတ်',
    验证码登录: 'အတည်ပြုကုဒ် လော့ဂ်အင်ဝင်ပါ။',
    已读消息: 'သတင်းအချက်အလက်အားလုံးကို ဖတ်ပြီးပါပြီ။',
    已发货: 'ပို့ဆောင်ခဲ့သည်။',
    已关闭: 'ပိတ်သိမ်းသွားပါပြီ။',
    已取消: 'ဖျက်သိမ်းလိုက်ပါပြီ။',
    已取消删除: 'ဖျက်သိမ်းမှုကို ပယ်ဖျက်လိုက်ပါပြီ။',
    已完成: 'ပြီးပါပြီ။',
    已选择: 'ရွေးချယ်ခဲ့သည်။ ',
    已阅读并同意以下协议: 'အောက်ပါသဘောတူညီချက်ကို ဖတ်ပြီး သဘောတူသည်။',
    隐私政策: 'ကိုယ်ရေးအချက်အလက်မူဝါဒ',
    应付总额: 'စုစုပေါင်းပေးဆောင်ရမည့်ပမာဏ',
    用户服务协议: 'သုံးစွဲသူဝန်ဆောင်မှု သဘောတူညီချက်',
    用户服务协议及法律声明:
        'အသုံးပြုသူဝန်ဆောင်မှုသဘောတူညီချက်နှင့် တရားဝင်ထုတ်ပြန်ချက်',
    优惠金额: 'လျှော့စျေးပမာဏ',
    优惠卷抵扣: 'လျှော့ဈေးကွန်ပွန်မှဖြတ်ခြင်း',
    邮箱: 'မေးလ်',
    邮箱手机号: 'အီးမေးလ်/ မိုဘိုင်းနံပါတ်',
    邮箱修改: 'အီးမေးလ် ပြင်ဆင်ခြင်း။',
    邮箱验证: 'အီးမေးလ်အတည်ပြုခြင်း။',
    邮政编码: 'စာတိုက်သင်္ကေတ',
    语言: 'ဘာသာစကား',
    原密码: 'စကားဝှက်အဟောင်း',
    运费: 'ကုန်စည်ပို့ဆောင်ခ',
    暂无订单信息: 'လတ်တလောတွင် မှာယူမှု အချက်အလက် မရှိပါ။',
    暂无商品: 'လတ်တလောတွင် ထုတ်ကုန်မရှိပါ။',
    暂无售后信息: 'လတ်တလောတွင် အရောင်းအပြီး အချက်အလက် မရှိပါ။',
    长度在1到8个字符: 'အရှည် 1 မှ 8 စာလုံး',
    支付宝: 'Alipay',
    支付成功: 'ငွေပေးချေမှု အောင်မြင်သည်။',
    支付订单: 'အော်ဒါအတွက် ပေးချေပါ။',
    支付方式: 'ငွေပေးချေရန် နည်းလမ်း',
    支付金额: 'ပေးချေမှုပမာဏ',
    支付凭证: 'ငွေပေးချေမှုဘောက်ချာ',
    支付失败: 'ငွေပေးချေမှု မအောင်မြင်ပါ။',
    重新发送验证码: 'အတည်ပြုကုဒ် အသစ်ပြန်လည်ပေးပို့သည်။',
    重新申请: 'ပြန်လည်လျှောက်ထားပါ။',
    注册: 'မှတ်ပုံတင်ခြင်း',
    注册成功: 'မှတ်ပုံတင်ခြင်းအောင်မြင်ပါပြီ',
    综合排序: 'အထွေထွေ အမျိုးအစား',
    总价: 'စုစုပေါင်းစျေးနှုန်း',
    最多可上传5张图片每张图片大小不超过5M支持xxx格式文件:
        'ပုံ 5 ပုံအထိ အပ်လုဒ်လုပ်နိုင်ပြီး ပုံတစ်ပုံချင်းစီ၏ အရွယ်အစားသည် 5M ထက် မကျော်လွန်ဘဲ bmp၊ gif၊ jpg၊ png၊ jpeg ဖော်မတ်ဖိုင်များကို ပံ့ပိုးပေးသည်',
    最多上传五张: 'အများဆုံးအပ်လုဒ် ၅ ခုအထိတင်နိုင်သည်။',
    推荐商品: 'အကြံပြုထားသော ထုတ်ကုန်များ',
    共x件商品已选择x件: '{num} ထုတ်ကုန်စုစုပေါင်း၊ {num2} ကို ရွေးချယ်ထားသည်။',
    共x件商品: 'စုစုပေါင်း {num} ပစ္စည်းများ',
    绑定: 'လင့်',
    绑定邮箱: 'အီးမေးလ်ကို ချည်နှောင်ပါ။',
    分享店铺: 'မျှဝေစတိုးဆိုင်',
    分享至: 'မျှဝေပါ။',
    海报: 'နံရံကပ်ကြော်ငြာ',
    分享: 'မျှဝေပါ။',
    推荐店铺给你: 'သင့်အတွက် အကြံပြုထားသော ကုန်တိုက်',
    链接: 'လင့်',

    至: 'သို့',
    币种: 'ငွေကြေးအမျိုးအစား',
    变动理由: 'ပြောင်းလဲရခြင်း အကြောင်းအရင်း',
    不记得: 'သတိမရဘူး',
    充值: 'ငွေဖြည့်',
    打款凭证: 'ငွေပေးချေမှု အထောက်အထားလက်မှတ်',
    打款说明: 'ငွေပေးချေမှု ညွှန်ကြားချက်များ',
    打款信息: 'ငွေပေးချေမှုအချက်အလက်',
    打款状态: 'ငွေပေးချေမှုအခြေအနေ',
    待打款: 'ငွေပေးချေရန် စောင့်ဆိုင်းနေသည်။',
    待审核: 'ပြန်လည်သုံးသပ်ရန် ဆိုင်းငံ့ထားသည်',
    到账方式: 'ငွေပေးချေစနစ်',
    到账银行卡号: 'ငွေဝင်သည့် ဘဏ်ကဒ်',
    冻结中: 'အေးခဲသည်။',
    国家: 'နိုင်ငံ',
    获取验证码: 'အတည်ပြုကုဒ်ကို ရယူပါ။',
    记得: 'သတိရပါ။',
    记录时间: 'အချိန်ကို မှတ်တမ်းတင်ပါ။',
    拒绝打款: 'ပေးဆောင်ရန် ငြင်းဆိုသည်။',
    开户人: 'အကောင့်ပိုင်ရှင်',
    开户支行: 'အကောင့်ဖွင့်ထားသည့်ဘဏ်ခွဲ',
    可提现: 'ထုတ်ယူနိုင်ပါသည်။',
    可提现金额: 'ငွေသားထုတ်ယူနိုင်သောပမာဏ',
    立即提现: 'ချက်ချင်းထုတ်ပါ။',
    流水号: 'ငွေသွင်းငွေထုတ်အမှတ်စဉ်',
    没有适合此币种的银行卡请先添加: 'ဤငွေကြေးအတွက် သင့်လျော်သောဘဏ်ကတ်မရှိပါ၊ ကျေးဇူးပြု၍ ၎င်းကို ဦးစွာထည့်ပါ။',
    没有收款码请先添加: 'ငွေပေးချေမှုကုဒ် မရှိပါ၊ ကျေးဇူးပြု၍ ၎င်းကို ဦးစွာထည့်ပါ။',
    每天最多提现x次单次最高提现金额x: 'တစ်ရက်လျှင် အများဆုံး ထုတ်ယူမှုများ*၊ အများဆုံး ထုတ်ယူသည့် ပမာဏ**',
    您是否记得账号当前使用的支付密码: 'သင့်အကောင့်မှ လက်ရှိအသုံးပြုနေသော ငွေပေးချေမှုစကားဝှက်ကို သင်မှတ်မိပါသလား။？',
    您未设置支付密码请先设置: 'ငွေပေးချေမှုစကားဝှက်ကို သင်မသတ်မှတ်ရသေးပါ၊ ၎င်းကို ဦးစွာသတ်မှတ်ပါ။',
    钱包余额: 'ပိုက်ဆံအိတ်လက်ကျန်',
    请输入开户人: 'ကျေးဇူးပြု၍ အကောင့်ပိုင်ရှင်ကို ထည့်သွင်းပါ။',
    请输入开户支行: 'အကောင့်ဖွင့်ထားသည့်ဘဏ်ခွဲကို ထည့်သွင်းပါ။',
    请输入收款名称: 'ငွေပေးချေမှုအမည်ကို ထည့်သွင်းပါ။',
    请输入提现金额: 'ကျေးဇူးပြု၍ ငွေသားထုတ်သည့်ပမာဏကို ထည့်ပါ။',
    请输入新支付密码6位数字: 'ငွေပေးချေမှု စကားဝှက်အသစ်၊ ဂဏန်း 6 လုံးကို ထည့်ပါ။',
    请输入新支付密码: 'ငွေပေးချေမှု စကားဝှက်အသစ်ကို ထည့်သွင်းပါ။',
    请输入银行卡号: 'ဘဏ်ကတ်နံပါတ်ကို ထည့်ပါ။',
    请输入原支付密码: 'မူရင်းငွေပေးချေမှု စကားဝှက်ကို ထည့်ပါ။',
    请输入支付密码6位数字: 'ငွေပေးချေမှု စကားဝှက်၊ ဂဏန်း 6 လုံးကို ရိုက်ထည့်ပါ။',
    请输入支付密码: 'ငွေပေးချေမှု စကားဝှက်ကို ထည့်သွင်းပါ။',
    请选择币种: 'ငွေကြေးကို ရွေးချယ်ပါ။',
    请选择大洲: 'ကျေးဇူးပြု၍ တိုက်ကြီးတစ်ခုကို ရွေးပါ။',
    请选择结束时间: 'ကျေးဇူးပြု၍ ကုန်ဆုံးရက်ကို ရွေးပါ။',
    请选择开始时间: 'ကျေးဇူးပြု၍ စတင်မည့်ရက်စွဲကို ရွေးပါ။',
    请选择收款码类型: 'ငွေပေးချေမှုကုဒ်အမျိုးအစားကို ရွေးပါ။',
    请选择银行: 'ဘဏ်တစ်ခုကို ရွေးပါ။',
    请选择银行名称: 'ကျေးဇူးပြု၍ ဘဏ်အမည်ကို ရွေးပါ။',
    请再次输入新支付密码: 'ငွေပေးချေမှု စကားဝှက်အသစ်ကို ထပ်မံထည့်သွင်းပါ။',
    请再次输入支付密码: 'ငွေပေးချေမှု စကားဝှက်ကို ထပ်မံထည့်သွင်းပါ။',
    去设置: 'ဆက်တင်များသို့သွားပါ။',
    全部提现: 'အားလုံးကို ငွေသားထုတ်ယူမည်။',
    确定删除收款码吗: 'ငွေပေးချေမှုကုဒ်ကို ဖျက်လိုသည်မှာ သေချာပါသလား။',
    确定删除银行卡吗: 'ဘဏ်ကတ်ကို ဖျက်လိုသည်မှာ သေချာပါသလား။',
    确定提现: 'နုတ်ထွက်ကြောင်း အတည်ပြုပါ-',
    确认打款: 'ငွေပေးချေမှုကို အတည်ပြုပါ။',
    确认新密码: 'စကားဝှက်အသစ်ကို အတည်ပြုပါ',
    确认支付密码: 'ငွေပေးချေမှု စကားဝှက်ကို အတည်ပြုပါ။',
    上传的收款二维码需要清晰无遮挡: 'အပ်လုဒ်လုပ်ထားသော ငွေပေးချေမှု QR ကုဒ်သည် ရှင်းလင်းပြီး အတားအဆီးမရှိ ဖြစ်ရပါမည်။',
    审核备注: 'မှတ်ချက်များကို စိစစ်သုံးသပ်ခြင်း',
    审核不通过: 'စာရင်းစစ်မအောင်မြင်ပါ။',
    审核结果: 'စိစစ် သုံးသပ်ချက်ရလဒ်များ',
    审核通过: 'စိစစ်သုံးသပ်ချက် အောင်မြင်သည်။',
    审核信息: 'အချက်အလက်ကို ပြန်လည်သုံးသပ်ပါ။',
    时间: 'အချိန်',
    实际到账金额: 'လက်ခံရရှိငွေပမာဏ',
    收款方式: 'ငွေပေးချေစနစ်',
    收款管理: 'စုဆောင်းစီမံခန့်ခွဲမှု',
    收款码: 'ငွေပေးချေမှုကုဒ်',
    收款码管理: 'ငွေပေးချေမှုကုဒ်စီမံခန့်ခွဲမှု',
    收款码类型: 'ငွေပေးချေမှုကုဒ် အမျိုးအစား',
    收款名称: 'ငွေလက်ခံသူအမည်',
    收款账号: 'ငွေလက်ခံသူအမည်',
    手续费: 'ငွေထုတ်မှု လုပ်ဆောင်ခ',
    提现: 'ငွေသားထုတ်ယူခြင်း',
    提现币种: 'ငွေထုတ်သည့်ငွေကြေး',
    提现成功: 'ငွေထုတ်မှု အောင်မြင်သည်။',
    提现记录: 'ငွေထုတ်မှတ်တမ်း',
    提现金额: 'ငွေထုတ်ပမာဏ',
    提现失败: 'ငွေထုတ်မှု မအောင်မြင်ပါ။',
    提现时间: 'ငွေထုတ်ချိန်',
    提现手续费: 'ငွေထုတ်ကြေး',
    提现详情: 'ငွေထုတ်အသေးစိတ်',
    提现中: 'ထုတ်ယူခြင်း။',
    提现状态: 'ငွေထုတ်မှုအခြေအနေ',
    添加收款码: 'ငွေပေးချေမှုကုဒ်ကို ထည့်ပါ။',
    添加银行卡: 'ဘဏ်ကတ် အသစ်ထည့်ပါ။',
    退款: 'ပြန်အမ်းငွေ',
    忘记支付密码: 'သင့်ငွေပေးချေမှု စကားဝှက်ကို မေ့နေပါသလား။？',
    尾号: 'အမြီးနံပါတ်',
    我的钱包: 'ငါ့ပိုက်ဆံအိတ်',
    我的余额: 'ငါ့လက်ကျန်',
    详情: 'အသေးစိတ် အခြေအနေ',
    消费: 'စားသုံးမှု',
    新支付密码: 'ငွေပေးချေမှု စကားဝှက်အသစ်',
    修改支付密码: 'ငွေပေးချေမှု စကားဝှက်ကို ပြောင်းပါ။',
    选择收款方式: 'ငွေပေးချေမှုနည်းလမ်းကို ရွေးပါ။',
    银行卡: 'ဘဏ်ကဒ်',
    银行卡管理: 'ဘဏ်ကတ်စီမံခန့်ခွဲမှု',
    银行卡号: 'ဘဏ်ကတ်နံပါတ်',
    银行名称: 'ဘဏ်အမည်',
    余额记录: 'လက်ကျန်မှတ်တမ်း',
    余额数据: 'လက်ကျန်ဒေတာ',
    原支付密码: 'မူရင်း ငွေပေးချေမှု စကားဝှက်',
    暂无数据: 'ဒေတာမရှိပါ။',
    支持币种: 'ပံ့ပိုးထားသည့် ငွေကြေးအမျိုးအစား',
    支付密码: 'ငွေပေးချေမှု စကားဝှက်',
    只可上传一张图片图片大小不超过5M支持jpgpngjpeg格式文件: 'ပုံတစ်ပုံသာ အပ်လုဒ်လုပ်နိုင်သည်၊ ပုံအရွယ်အစားသည် 5M ထက်မပိုပါ၊ jpg၊ png၊ jpeg ဖော်မတ်ဖိုင်များကို ပံ့ပိုးသည်',
    账户余额: 'အကောင့်လက်ကျန်',
    钱包服务: 'ပိုက်ဆံအိတ်ဝန်ဆောင်မှု',
    每天最多提现x次单次最高提现金额xx最低提现金额xx: 'တစ်ရက်လျှင် အများဆုံး ထုတ်ယူသည့် ပမာဏမှာ {num} ကြိမ် ဖြစ်ပြီး၊ တစ်ကြိမ်လျှင် အများဆုံး ထုတ်ယူသည့် ပမာဏမှာ {icon}{money} ဖြစ်ပြီး အနိမ့်ဆုံး ထုတ်ယူသည့် ပမာဏမှာ {icon}{money2} ဖြစ်သည်။',
    确认: 'အတည်ပြုပါ။',
    输入金额超过可提现余额: 'ထည့်သွင်းမှုပမာဏသည် ထုတ်ယူနိုင်သော လက်ကျန်ပမာဏထက် ကျော်လွန်နေပါသည်။',
    日期选择: 'ရက်စွဲရွေးချယ်မှု',
    备注: 'မှတ်ချက်',
    当前没有余额记录: 'လောလောဆယ် လက်ကျန်မှတ်တမ်းမရှိပါ။',
    当前没有提现记录: 'လောလောဆယ် ငွေထုတ်မှတ်တမ်းမရှိပါ။',
    还没有绑定银行卡: 'ဘဏ်ကတ်ကို ချည်နှောင်ထားခြင်းမရှိသေးပါ။',
    还没有添加收款码: 'ငွေပေးချေမှုကုဒ်ကို မထည့်ရသေးပါ။',
    提示只可上传一张图片图片大小不超过5M支持xxx格式文件上传的收款二维码需要清晰无遮挡: 'အကြံပြုချက်- ပုံတစ်ပုံကိုသာ အပ်လုဒ်လုပ်နိုင်ပြီး၊ ပုံ၏အရွယ်အစားသည် 5M ထက်မပိုသင့်ပါ၊ jpg၊ png နှင့် jpeg ဖော်မတ်ဖိုင်များကို ပံ့ပိုးထားသည်။ အပ်လုဒ်လုပ်ထားသော ငွေပေးချေမှု QR ကုဒ်သည် ရှင်းလင်းပြီး အတားအဆီးမရှိ ဖြစ်ရပါမည်။',
    请上传收款码: 'ကျေးဇူးပြု၍ ငွေပေးချေမှုကုဒ်ကို အပ်လုဒ်လုပ်ပါ။',
    请输入收款码名称: 'ကျေးဇူးပြု၍ ငွေပေးချေမှုကုဒ်အမည်ကို ထည့်ပါ။',
    暂无银行卡: 'ဘဏ်ကတ်မရှိသေးပါ။',
    余额: 'လက်ကျန်',
    重置: 'ပြန်လည်သတ်မှတ်ပါ။',
    暂无收款码: 'ငွေပေးချေမှုကုဒ် မရှိသေးပါ။',
    保障支付安全: 'ငွေပေးချေမှုလုံခြုံရေးကိုသေချာပါစေ။',
    打款成功: 'ငွေပေးချေမှု အောင်မြင်ပါသည်။',
    请选择收款方式: 'ကျေးဇူးပြု၍ ငွေပေးချေမှုနည်းလမ်းကို ရွေးချယ်ပါ။',
    收款类型: 'ငွေပေးချေမှုအမျိုးအစား',
    编辑银行卡: 'ဘဏ်ကတ်ကို တည်းဖြတ်ပါ။',
    编辑收款码: 'ငွေပေးချေမှုကုဒ်ကို တည်းဖြတ်ပါ။',
    收款码名称: 'ငွေပေးချေမှုကုဒ်အမည်',
    订单ID: 'မှာယူမှု ID',
    如果您已支付成功请点击确认支付按钮: 'သင့်ငွေပေးချေမှု အောင်မြင်ပါက၊ ကျေးဇူးပြု၍ "ငွေပေးချေမှုကို အတည်ပြုပါ" ခလုတ်ကို နှိပ်ပါ။',
    确认支付: 'ငွေပေးချေမှုကို အတည်ပြုပါ',
    支付遇到问题: 'ပြဿနာများ ကြုံမည်။',
    对不起您访问的店铺可能已被删除名称被更改或不存在: 'ဝမ်းနည်းပါသည်၊ သင်သွားရောက်လည်ပတ်ခဲ့သော စတိုးဆိုင်ကို ဖျက်လိုက်နိုင်သည်၊ ၎င်း၏အမည်ကို ပြောင်းလဲထားသည်၊ သို့မဟုတ် မရှိနိုင်တော့သည်...',
    卢比支付: 'IDR ငွေပေးချေမှု',
    比索支付: 'PHP ငွေပေးချေမှု',
    泰铢支付: 'THB ငွေပေးချေမှု',
    越南盾支付: 'VND ငွေပေးချေမှု',
    银行卡编码: 'ဘဏ်ကုဒ်',
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-0": "{currencyUnit}{money}",
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-1": "ပထမဆုံး အလေးချိန်{num}kg{currencyUnit}{money}，ထပ်တိုးသည့် အလေးချိန်{num2}kg{currencyUnit}{money2}",
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-2": "ပထမဆုံး ပစ္စည်း{num3}ခု{currencyUnit}{money3}，ထပ်တိုးသည့်ပစ္စည်း{num4}ခု{currencyUnit}{money4}",
    暂无配送地区: 'ပို့ဆောင်ပေးမယ့် နေရာတော့ မရှိသေးပါဘူး။',
    该商品在当前地区暂不支持配送: 'ဤထုတ်ကုန်သည် လက်ရှိဒေသအတွင်း ပို့ဆောင်မှုကို လောလောဆယ် မပံ့ပိုးပါ။',
    当前商品仅支持以下地区发货如有疑问请联系店铺:
        'လက်ရှိထုတ်ကုန်များသည် အောက်ဖော်ပြပါနေရာများသို့ ပို့ဆောင်ခြင်းကိုသာ ပံ့ပိုးပေးပါသည်။ သင့်တွင် မေးခွန်းများရှိပါက ကျေးဇူးပြု၍ စတိုးဆိုင်သို့ ဆက်သွယ်ပါ။',
    运费计价说明: 'ကုန်စည်ပို့ဆောင်ခစျေးနှုန်းညွှန်ကြားချက်',
    发货地: 'ပို့ဆောင်မည့်နေရာ',
    配送至: 'သို့ပေးပို့ပါ။',
    打款中: 'ငွေပေးချေမှု လုပ်ဆောင်နေပါသည်။',
    未添加地址请先添加: 'လိပ်စာထည့်မထားပါ၊ ကျေးဇူးပြုပြီး အရင်ထည့်ပါ။',
    请输入大于1的正整数: 'ကျေးဇူးပြု၍ 1 ထက်ကြီးသော အပေါင်းကိန်းပြည့်ကို ထည့်ပါ။',
    设置密码: 'စကားဝှက်သတ်မှတ်ပါ။',
    登录密码: 'ဝင်ရောက်ရန်စကားဝှက်',
    长度在8到16个字符: 'အရှည် 8 မှ 16 စာလုံး',
    请输入旧密码: 'စကားဝှက်ဟောင်းကို ထည့်ပါ။',
    必须包含两种字符: 'စာလုံးနှစ်လုံးပါဝင်ရမည်။',
    当前仅支持xxx的金额: 'လောလောဆယ်  {currency} {min}-{currency} {max}  မှ ပမာဏများကိုသာ ပံ့ပိုးသည်',
    我明白了: 'ဟုတ်လား',
    若多个商品选用同一个运费模板则运费是按同一个运费方式的规则进行计算: 'ကုန်ပစ္စည်းအများအပြားသည် တူညီသောကုန်စည်ပို့ဆောင်ရေးပုံစံကိုအသုံးပြုပါက၊ တူညီသောကုန်စည်ပို့ဆောင်ရေးနည်းလမ်း၏စည်းမျဉ်းများနှင့်အညီ ကုန်စည်ပို့ဆောင်ခကို တွက်ချက်မည်ဖြစ်သည်။',
    若多个商品不是同一个运费模板则运费是多个模板的价格之和: 'ကုန်ပစ္စည်းအများအပြားတွင် တူညီသော ပို့ဆောင်ခနမူနာမပါပါက၊ ပို့ဆောင်ခသည် ပုံစံပလိတ်များစွာ၏စျေးနှုန်းများဖြစ်သည်။',
    快捷登录登录即表示同意协议: 'အကောင့်ဝင်ခြင်းဖြင့် သင်သည် သဘောတူညီချက်ကို သဘောတူပါသည်။',
    促销广告: 'ပရိုမိုးရှင်းကြော်ငြာ',
    最近新品: 'ထုတ်ကုန်အသစ်များ',
    更多: 'နောက်ထပ်',
    设置成功: 'စနစ်ထည့်သွင်းမှု အောင်မြင်ပါပြီ။',
    更多商品:'နောက်ထပ်ထုတ်ကုန်များ',


    //     1.6
    我的优惠券:'ငါ့လျှော့စျေးကူပွန်',
    历史记录:'သမိုင်းမှတ်တမ်း',
    已使用:'အသုံးပြုခံ့',
    已过期:'သက်တမ်းကုန်ပြီ။',
    优惠券:'ကူပွန်',
    领取:'လက်ခံသည်။',
    已领取:'ရရှိခဲ့သည်။',
    有效期:'ရက်စွဲရတဲ့ ကာလ：',
    活动优惠详情:'ပွဲလျှော့စျေးအသေးစိတ်',
    可用商品:'ရနိုင်သောပစ္စည်းများ',
    单独购买:'မူရင်းစျေးနှုန်း',
    发起拼团:'အုပ်စုစျေးနှုန်း',
    拼团价:'အုပ်စုစျေးနှုန်း',
    可参与的拼团:'ရနိုင်သော အဖွဲ့လိုက် ဝယ်ယူမှုများ',
    '再邀X人即可拼单成功':'မှာယူမှုအောင်မြင်ရန်အတွက် နောက်ထပ်လူ {num} ဦးနှင့် မျှဝေပါ။>',
    拼单详情:'အုပ်စုဝယ်ယူမှုအသေးစိတ်',
    待分享:'မျှဝေရန်စောင့်နေသည်။',
    '若拼团失败，货款将原路退回':'အဖွဲ့လိုက်ဝယ်ယူမှု မအောင်မြင်ပါက ငွေပေးချေမှုကို ပြန်အမ်းပါမည်။',
    促销价:'ရောင်းဈေး',
    距离结束时间:'End in',
    满XX减XX:"ကျသင့်အား {currencyUnit}{priceStart} စျေးနှင့် {currencyUnit}{reducePrice}{couponType==2?'%':''} ရရှိပါသည်။",
    满XX减X:'ကျသင့်အား {currencyUnit}{priceStart} စျေးနှင့် {reducePrice}% ရရှိပါသည်။',
    分享好友X人成团立省X:'လူ<span style="color: #8D4BBB;">{num}</span>ယောက်အတွက် အဖွဲ့လိုက်ဝယ်ယူပါ၊<span style="color: #8D4BBB;">{currencyUnit}{groupPrice} </span> သက်သာပါသည်။',
    分享好友:'သူငယ်ချင်းများနှင့်မျှဝေပါ။',
    '这些人正在拼，立即参与 ':'အဖွဲ့လိုက်ဝယ်ယူမှုလုပ်ဆောင်နေ၊ ယခုပါဝင်လိုက်ပါ။ ',
    满x可用:'{currencyUnit}{priceStart} အထက် အော်ဒါများအတွက် ရနိုင်ပါသည်။',
    这些人正在拼立即参与:'အဖွဲ့လိုက်ဝယ်ယူမှုလုပ်ဆောင်နေ၊ ယခုပါဝင်လိုက်ပါ။',
    限领x张:'သုံးစွဲသူတစ်ဦးလျှင် ကူပွန် {receiveCount}ခု',
    满XX送赠品:'{currencyUnit}{priceStart} အတွင်းအမှားပစ္စည်းရယူပါ ',
    去使用:'အသုံးပြုရန်',
    销量:'အရောင်း',
    赠品:'လူပြင် လက်ဆောင်သော ပစ္စည်',
    限时优惠:'ကန့်သတ်အချိန်ကမ်းလှမ်းချက်',
    距结束:'End in',
    拼团好物:'အုပ်စုတာဝန်ဆောင်မှု',
    当前优惠券可以购买以下商品:'လက်ရှိကူပွန်သည် အောက်ပါထုတ်ကုန်များကို ဝယ်ယူနိုင်ပါသည်။:',
    以下商品:'အောက်မှာရှိတဲ့ ထုတ်လုပ်ငန်းတွေဟာ',
    满XX减XX可用:"ကျသင့်အား {currencyUnit}{priceStart} စျေးနှင့် {currencyUnit}{reducePrice} ရရှိပါသည်။",
    满XX减X可用:'ကျသင့်အား {currencyUnit}{priceStart} စျေးနှင့် {reducePrice}% ရရှိပါသည်။',
    再买x可用:'{currencyUnit}{difPrice} ဝယ်ဖို့ လိုပါသေးတယ်။',
    合计:'စုစုပေါင်း',
    优惠:'လျှော့စျေး',
    去购物车:'စျေးဝယ်ခြင်းတောင်း',
    拼团:'အုပ်စုတာဝန်ဆောင်မှု',
    满XX可用:'{currencyUnit}{priceStart}  အထက် အော်ဒါများအတွက် ရနိုင်ပါသည်။',
    满减满赠:'လျှော့စျေး/လက်ဆောင်အပြည့်အစုံ',
    成团发货:'ပေးပို့ရန် စောင့်ပါ။',
    还差xx人成团:'အော်ဒါတင်ရန် လူ <span style="color: #FF4545;">{num}</span> ဦး အလိုရှိပါသည်။',
    拼团结束:'အုပ်စုလိုက် စျေးဝယ်ခြင်း ပြီးဆုံးသည်။',
    拼团成功:'အဖွဲ့လိုက် ဝယ်ယူမှု အောင်မြင်ပါသည်။',
    有成员未付款请等待付款成团:'တစ်စုံတစ်ဦးမှ ငွေမပေးရသေးပါ၊ ကျေးဇူးပြု၍ ငွေပေးချေမှုကို စောင့်ပါ။',
    我正在参与的拼团:'အုပ်စုလိုက်ဝယ်ယူမှုတွင်ပါဝင်ခြင်း။',
    满减:'လျှော့စျေး',
    满赠:'လက်ဆောင်အပြည့်အစုံ',
    凑单:'အတူတူအော်ဒါလုပ်ပါ။',
    切换其他优惠:'အခြားကမ်းလှမ်းချက်ကို ရွေးပါ။',
    超过最大购买数:'အများဆုံးဝယ်ယူမှုအရေအတွက်ကို ကျော်လွန်သွားပါပြီ။',
    商品限购x件:'{num} အပိုင်းများကို ကန့်သတ်ပါ။',
    参与拼团:'ပူးပေါင်းပါ',
    结束:'结束',
    您还没有优惠券:'သင့်တွင် ကူပွန်မရှိပါ။',
    优惠券历史记录:'သမိုင်းမှတ်တမ်း',
    拼单失败:'အဖွဲ့လိုက် ဝယ်ယူမှု မအောင်မြင်ပါ။',
    免费:'အခမဲ့',
    已赠完:'ပစ္စည်းပြတ်တောက်နေသည်',
    已领完:"သုံးတယ်။",
    此拼团已满请选择其他拼团:'ဤအဖွဲ့ဝယ်ယူမှု ပြည့်သွားပါပြီ၊ ကျေးဇူးပြု၍ အခြားအဖွဲ့ဝယ်ယူမှုကို ရွေးချယ်ပါ။',
    以下商品正在限时优惠当中:'အောက်ပါထုတ်ကုန်များအတွက် အချိန်အကန့်အသတ်ဖြင့် ကမ်းလှမ်းမှု',
    以下商品正在限时拼团当中:'အောက်ဖော်ပြပါ ထုတ်ကုန်များကို အုပ်စုလိုက် ဝယ်ယူသည့် အချိန်ကန့်သတ်ချက်',
    再买X可减X:'နောက်ထပ် {currencyUnit}{difPrice} ဝယ်ပြီး {currencyUnit}{reducePrice} လျှော့လိုက်ပါ။',
    活动未开始:'ပွဲမစသေးပါ။',

    已满X可减X:'{currencyUnit}{difPrice} သို့မဟုတ် ထို့ထက်ပိုသုံးသောအခါတွင် {currencyUnit}{reducePrice} လျှော့စျေးရယူပါ။',
    已满X可减XX:'{currencyUnit}{difPrice} သို့မဟုတ် ထို့ထက်ပိုသုံးသောအခါတွင် {reducePrice}% လျှော့စျေးရယူပါ။',

    再买X可减XX:'နောက်ထပ် {currencyUnit}{difPrice} ဝယ်ပြီး {reducePrice}% လျှော့လိုက်ပါ။',
    再买X送赠品:'နောက်ထပ် {currencyUnit}{difPrice} ကိုဝယ်ပြီး အခမဲ့လက်ဆောင်ရယူလိုက်ပါ။',
    已满X送赠品:'လက်ဆောင်တွေ ပေးခဲ့ပြီးပြီ။',
    缺少库存: 'ပစ္စည်းပြတ်တယ်။',
    限X件:'ကန့်သတ်ချက် {num} အပိုင်းများကို ဝယ်ယူပါ။',
    房间号已失效请联系工作人员:'အခန်းနံပါတ် သက်တမ်းကုန်သွားပြီ၊ ကျေးဇူးပြု၍ ဝန်ထမ်းထံ ဆက်သွယ်ပါ။',


    退货积分:'အမှတ်ပြန်ပေးသည်။',
    积分:'တစ်သားတည်း',
    用户注册:'အသုံးပြုသူမှတ်ပုံတင်',
    手动增加:'ကိုယ်တိုင် တိုးပေးတယ်။',
    积分兑换退款:'အမှတ်များ ပြန်အမ်းခြင်း။',
    下单购物:'ဈေးဝယ်ရန်အတွက် အော်ဒါတင်ပါ။',
    过期积分:'သက်တမ်းလွန်အမှတ်များ',
    兑换商品:'ကုန်ပစ္စည်းကို ရွေးပါ။',
    手动减少:'လက်ဖြင့် လျှော့ပါ။',
    订单退款:'အမိန့်ပြန်အမ်းခြင်း။',
    积分明细:'အမှတ်များအသေးစိတ်',
    已兑:'ရွေးပြီးသား',
    积分商城:'ပွိုင့်ဈေးဆိုင်',
    规则:'စည်းကမ်း',
    可用积分:'ရနိုင်သော အမှတ်များ',
    冻结积分:'အေးခဲသောအချက်များ',
    我的兑换:'ငါ့အလဲအလှယ်',
    售后:'ရောင်းချပြီးနောက်',
    分销中心:'ဖြန့်ဖြူးရေးစင်တာ',
    立即兑换:'ယခု ရွေးပါ။',
    已兑完:'ရွေးပြီးသား',
    "积分不足，无法兑换":'မလုံလောက်သော အမှတ်များကို ရွေးယူ၍မရပါ။',
    兑换数量:'ပမာဏဖလှယ်ခြင်း။',
'限兑*件':'အကန့်အသတ်ဖြင့် ရွေးနှုတ်ခြင်း{num} ပစ္စည်းများ',
    积分抵扣:'အမှတ်များ နုတ်ခြင်း။',
'共*分':'စုစုပေါင်း{num}  အမှတ်',
    全部订单:'အမိန့်အားလုံး',

兑换须知:'ရွေးနှုတ်ညွှန်ကြားချက်များ',
    已达兑换上限:'ရွေးနှုတ်မှုကန့်သတ်ချက် ပြည့်သွားပါပြီ။',
    我的积分:'ငါ့ရမှတ်များ',
    积分规则:'အမှတ်စည်းမျဉ်းများ',
    兑换时间:'ရွေးနှုတ်ချိန်',
    我的佣金:'ငါ့ကော်မရှင်',
    佣金数据:'ကော်မရှင်အချက်အလက်',
    分销申请协议:'ဖြန့်ဝေခြင်းလျှောက်လွှာသဘောတူညီချက်',
    申请成为分销员:'ဖြန့်ဖြူးသူဖြစ်လာရန်လျှောက်ထားပါ။',
    "已申请，请等待审核":'လျှောက်ထားပြီးပြီ၊ ပြန်လည်သုံးသပ်ရန် စောင့်ပါ။',
    "审核拒绝，继续申请":'သုံးသပ်ချက်ဖြင့် ပယ်ချပြီး ဆက်လက်လျှောက်ထားပါ။',
    可提佣金:'ငွေထုတ်ကော်မရှင်',
    冻结佣金:'ကော်မရှင်ကို ဖျက်သိမ်းပါ။',
    分销订单:'ဖြန့်ချီရေး',
    佣金明细:'ကော်မရှင်အသေးစိတ်',
    我的下级:'ငါ့လက်အောက်ငယ်သားများ',
    推广商品:'ထုတ်ကုန်များကို မြှင့်တင်ပါ။',
    推广店铺:'အရောင်းမြှင့်တင်ပါ။',
    暂无记录:'မှတ်တမ်းမရှိပါ။',
    一级佣金收入:'ပထမအဆင့် ကော်မရှင်ဝင်ငွေ',
    一级佣金退款:'ပထမအဆင့် ကော်မရှင်ပြန်အမ်းငွေ',
    二级佣金收入:'ဆင့်ပွားကော်မရှင်ဝင်ငွေ',
    二级佣金退款:'ဒုတိယအဆင့် ကော်မရှင်ပြန်အမ်းငွေ',
    下单用户:'အမှာစာအသုံးပြုသူအားမရ',
    订单总价:'စုစုပေါင်းစျေးနှုန်းကိုမှာယူပါ။',
    分销利润层级:'ဖြန့်ဖြူးမှုအဆင့်',
    结算状态:'အခြေချနေထိုင်မှုအခြေအနေ',
    佣金:'ကော်မရှင်',
    一级:'အဆင့် ၁',
    二级:'အဆင့် ၂',
    已结算:'အခြေချသည်။',
    请输入用户昵称或手机号:'ကျေးဇူးပြု၍ သုံးစွဲသူအမည် သို့မဟုတ် မိုဘိုင်းဖုန်းနံပါတ်ကို ထည့်သွင်းပါ။',
    二级团队:'ဒုတိယအဆင့်အသင်း',
    分享链接:'လင့်ကိုမျှဝေပါ',
    可推广至:'သို့ တိုးချဲ့နိုင်သည်။',
    分享最高可赚x:'မျှဝေခြင်းဖြင့် {icon}{num} အထိ ရယူပါ။',
    分享推广:'မျှဝေပြီး မြှင့်တင်ပါ။',
    用户昵称或手机号:'အသုံးပြုသူအမည် သို့မဟုတ် မိုဘိုင်းဖုန်းနံပါတ်',
    商品名称:'ထုတ်ကုန်အမည်',
    最高可得:'အများဆုံးရရှိနိုင်သည်။',
    可获得积分:'အမှတ်များရရှိခဲ့သည်။',
    总获佣金:'စုစုပေါင်းကော်မရှင်ရရှိခဲ့သည်။',
    佣金详情:'ကော်မရှင်အသေးစိတ်',
    已退款:'ပြန်အမ်းသည်။',
    请输入商品名称:'ကျေးဇူးပြု၍ ထုတ်ကုန်အမည်ကို ထည့်သွင်းပါ။',
    请选择银行卡: 'ဘဏ်ကတ်ကို ရွေးပါ။',
    请选择收款码: 'ကျေးဇူးပြု၍ ငွေပေးချေမှုကုဒ်ကို ရွေးပါ။',
    不能超过最小提现额度:'အနိမ့်ဆုံး ငွေထုတ်ပမာဏကို ကျော်လွန်၍မရပါ။',


    货柜商品:'ကုန်သေတ္တာ',
    该浏览器不支持蓝牙:'ဤဘရောက်ဆာသည် ဘလူးတုသ်ကို မပံ့ပိုးပါ၊ ဖွင့်ရန် Chrome၊ Samsung အင်တာနက်ဘရောက်ဆာသို့ လင့်ခ်ကို ကူးယူပါ။',
    iOS系统暂不支持售货机取货请使用Android系统扫码购物:'iOS စနစ်သည် စျေးဝယ်ခြင်းအတွက် QR ကုဒ်ကို စကင်န်ဖတ်ရန် Android စနစ်အား အသုံးပြုပါ။',
    去商城首页:'ကုန်တိုက်ပင်မစာမျက်နှာသို့သွားပါ။',
    已售完:'ရောင်းကုန်ပြီ',
    蓝牙未连接:'Bluetooth မချိတ်ဆက်ပါ။',
    蓝牙已连接:'ဘလူးတုသ် ချိတ်ဆက်ထားသည်။',
    重试:'ထပ်ကြိုးစားပါ။',
    蓝牙未授权请打开蓝牙后重试:'ဘလူးတုသ်ကို ခွင့်မပြုပါ၊ ကျေးဇူးပြု၍ ဘလူးတုသ်ကိုဖွင့်ပြီး ထပ်စမ်းကြည့်ပါ။',
    蓝牙未连接请选择蓝牙并配对:'ဘလူးတုသ်ကို မချိတ်ဆက်ထားပါ၊ ကျေးဇူးပြု၍ Bluetooth ကိုရွေးချယ်ပြီး တွဲချိတ်ပါ။',
    连接:'ချိတ်ဆက်ပါ။',
    蓝牙已断开请重新连接:'ဘလူးတုသ် ချိတ်ဆက်မှု ဖြတ်လိုက်ပါပြီ၊ ကျေးဇူးပြု၍ ပြန်လည်ချိတ်ဆက်ပါ။',
    蓝牙连接中:'ဘလူးတုသ်ချိတ်ဆက်မှု...',
    购买成功请取出商品若柜门未打开请尝试再次打开:'ဝယ်ယူမှုအောင်မြင်ပါသည်၊ ကျေးဇူးပြု၍ ထုတ်ကုန်ကိုထုတ်ပါ။ ကက်ဘိနက်တံခါးမပွင့်ပါက၊ ကျေးဇူးပြု၍ ထပ်မံဖွင့်ကြည့်ပါ။',
    再次打开:'ပြန်ဖွင့်ပါ။',
    普通订单:'သာမာန်အမိန့်',
    售货机:'အရောင်းစက်',
    售货机已无货无法支付:'အရောင်းစက်တွင် ပစ္စည်းပြတ်သွားသဖြင့် ငွေပေးချေ၍မရပါ။',
    订单来源:'အော်ဒါအရင်း',
    连接失败请重新连接:'ချိတ်ဆက်မှု မအောင်မြင်ပါ၊ ကျေးဇူးပြု၍ ပြန်လည်ချိတ်ဆက်ပါ။',
    售罄:'ရောင်းကုန်ပြီ',
    '房间号/桌号':'အခန်းနံပါတ်/ စားပွဲနံပါတ်'
}
// 缅甸
