// 越南
export default {
    请再次输入新密码: 'Vui lòng nhập lại mật khẩu mới',
    BCEL支付: 'BCEL',
    KBZ支付: 'KBZpay',
    OnePay支付: 'OnePay',
    OnePay支付二维码: 'Mã QR OnePay',
    保存: 'Lưu lại',
    保密: 'Bảo mật',
    备注说明: 'Hướng dẫn ghi chú',
    编辑地址: 'Sửa địa chỉ',
    标记已读: 'Đánh dấu đã đọc',
    不可修改最多: 'Không thể sửa đổi, nhiều nhất',
    部分订单取消: 'Đơn đặt hàng một phần bị hủy',
    部分发货: 'Phát hàng 1 phần',
    部分商品正在退款中: 'Một số mặt hàng đang được hoàn trả',
    部分收货: 'Biên nhận một phần',
    部分退款: 'Hoàn lại một phần',
    部分退款完成: 'Hoàn trả một phần đã hoàn tất',
    查看订单: 'Kiểm tra đơn hàng',
    查看全部: 'xem tất cả',
    查看全部xx条回复: 'Xem tất cả {num} tin nhắn trả lời',
    查看详情: 'Xem chi tiết',
    查看支付是否成功: 'Kiểm tra xem thanh toán có thành công không>>>',
    查询中: 'Cuộc điều tra',
    城市: 'Thành phố',
    创建时间: 'Thời gian tạo ra',
    此宝贝已下架: 'Sản phẩm này đã bị gỡ bỏ',
    待发货: 'Đợi phát hàng',
    待付款: 'Đợi thanh toán',
    待收货: 'Chờ nhận hàng',
    当前安全手机号: 'Số điện thoại bảo mật hiện tại',
    地址管理: 'Quản lý địa chỉ',
    登录: 'Đăng nhập',
    等待店铺审核: 'Chờ cửa hàng xét duyệt',
    点击选择城市: 'Bấm để chọn thành phố',
    点击选择国家: 'Bấm để chọn quốc gia',
    点击选择退货类型: 'Nhấp để chọn hình thức trả hàng',
    点击选择退货原因: 'Bấm để chọn lý do trả hàng',
    店: 'S',
    店铺: 'Cửa hàng',
    店铺处理: 'Cửa hàng xử lý',
    店铺拒绝您的申请: 'Cửa hàng từ chối yêu cầu đổi trả của khách hàng',
    店铺退款: 'Cửa hàng hoàn tiền',
    订单编号: 'Mã đơn hàng',
    订单号: 'Mã đơn hàng',
    订单详情: 'Chi tiết đơn hàng',
    订单已超时: 'Đơn đặt hàng đã quá thời gian',
    短信验证码: 'Mã xác minh SMS',
    发表留言: 'Đăng lên lời nhắn',
    发货: 'Giao hàng',
    发货件数: 'Số kiện phát hàng ',
    发货时间: 'Thời gian phát hàng',
    发货状态: 'Trạng thái phát hàng',
    发送成功: 'Gửi thành công',
    发送验证码: 'Gửi mã xác minh',
    法律声明: 'Thông báo pháp lý',
    返回: 'Trở về',
    返回首页: 'Trở về trang chủ',
    分: 'Phút',
    分类: 'Phân loại',
    付款时间: 'Thời gian thanh toán',
    复制: 'Sao chép',
    复制成功: 'Sao chép thành công',
    该订单已取消: 'Đơn hàng này đã bị hủy',
    个人信息: 'Thông tin cá nhân',
    个字以内: 'Trong x ký tự',
    恭喜您邮箱地址验证成功: 'Chúc mừng! Xác minh địa chỉ email thành công',
    购买商品: 'Sản phẩm mua',
    购买数量: 'Số lượng mua',
    购买信息: 'Thông tin mua',
    购物车: 'giỏ hàng',
    国家区域: 'Quốc gia/Khu vực',
    含发货邮费: 'Đã bao gồm cước vận chuyển',
    欢迎登录: 'Chào mừng đăng nhập',
    活动优惠: 'Khuyến mãi giảm giá',
    货币: 'Tiền tệ',
    继续逛逛: 'Tiếp tục mua sắm',
    加入购物车: 'Thêm vào giỏ hàng',
    加载中: 'Đang tải',
    价格高低: 'Mức giá cao thấp',
    价格最低: 'Giá thấp nhất',
    价格最高: 'Giá cao nhất',
    件商品: 'x mục',
    交易异常: 'Giao dịch bất thường',
    看不清换一张: 'Không nhìn rõ đổi ảnh khác',
    客: 'C',
    客服: 'dịch vụ khách hàng',
    客服电话: 'Hotline cskh',
    客服邮箱: 'Email',
    客户: 'Khách hàng',
    客户服务: 'dịch vụ khách hàng',
    客户取消售后: 'Khách hàng hủy hậu mãi',
    客户已付款等待店铺发货:
        'Khách hàng đã thanh toán và đang đợi cửa hàng phát hàng',
    历史搜索: 'Lịch sử tìm kiếm',
    立即登录: 'Đăng nhập ngay',
    立即购买: 'Mua ngay',
    立即留言: 'Để lại lời nhắn ngay',
    立即提交: 'Gửi ngay lập tức',
    立即注册: 'Đăng ký ngay',
    联系电话: 'Số điện thoại liên lạc',
    联系方式: 'Thông tin liên lạc',
    联系客服: 'Liên hệ với cskh',
    联系人: 'Người liên lạc',
    两次输入密码不一致: 'Hai lần nhập mật khẩu không giống nhau',
    留言成功: 'Nhắn tin thành công',
    留言管理: 'quản lý tin nhắn',
    留言回复: 'Lời nhắn phản hồi',
    留言回复长度: 'Tin nhắn trả lời, độ dài 2-100 ký tự',
    留言回复长度xxx个字符: 'Tin nhắn trả lời, độ dài 2-100 ký tự',
    留言内容: 'Nội dung tin nhắn',
    留言详情: 'Chi tiết lời nhắn',
    没有更多了: 'Không còn nữa',
    每张图片大小不超过3M: 'Kích thước mỗi hình không vượt quá 3M',
    密码: 'Mật khẩu',
    密码登录: 'Mật khẩu đăng nhập',
    密码最少8位最多16位:
        'Mật khẩu phải có ít nhất 8 ký tự và nhiều nhất là 16 ký tự',
    秒: 'Giây',
    秒后重新发送: 'Gửi lại sau {seconds} giây',
    名: 'Tên',
    默认地址: 'Địa chỉ mặc định',
    男: 'Nam',
    昵称: 'Nick name',
    昵称不能为空: 'Nick name không được để trống',
    您当前是线下支付请耐心等待店铺确认收款若x后店铺未确认收款订单将自动取消:
        'Hiện tại bạn đang thanh toán offline, vui lòng kiên nhẫn đợi cửa hàng xác nhận đã nhận nhé! Nếu cửa hàng không xác nhận đã nhận sau <span style="color: red;">{num}</span>, đơn hàng sẽ tự động bị hủy!',
    您的购物车还没有商品哟: 'Không có sản phẩm nào trong giỏ hàng của bạn~',
    您的收货地址为空点此添加收货地址:
        'Địa chỉ nhận hàng của bạn trống, bấm vào đây để thêm địa chỉ giao hàng',
    您可以参考如下线下付款方式:
        'Bạn có thể tham khảo các phương thức thanh toán ngoại tuyến sau',
    您可以在这里给店铺留言为了保证你的权益请尽可能详细的上传留言资料:
        'Bạn có thể để lại tin nhắn cho cửa hàng tại đây, để bảo vệ quyền và lợi ích của bạn, vui lòng tải lên thông tin tin nhắn càng chi tiết càng tốt',
    您未支付请重新支付: 'Bạn chưa thanh toán, vui lòng thanh toán lại',
    女: 'Nữ',
    评论成功: 'Bình luận thành công',
    切换语言和货币: 'Chuyển đổi ngôn ngữ và tiền tệ',
    请勾选删除商品: 'Vui lòng kích chọn xóa sản phẩm',
    请勾选商品: 'Vui lòng kích chọn sản phẩm',
    请勾选信息: 'Vui lòng kích chọn tin nhắn',
    请上传图片: 'Vui lòng tải ảnh lên',
    请输入短信验证码: 'Vui lòng nhập mã xác minh SMS',
    请输入反馈内容: 'Vui lòng nhập nội dung phản hồi',
    请输入留言内容: 'Vui lòng nhập nội dung lời nhắn',
    请输入留言内容若您使用BCEL等支付请在此输入用来接收退款的银行卡号及户名:
        '请输入留言内容，若您使用BCEL或KBZPay支付，请在此输入用来接收退款的银行卡号及户名',
    请输入密码: 'Xin vui lòng nhập mật khẩu',
    请输入内容: 'Vui lòng nhập nội dung',
    请输入手机号: 'Vui lòng nhập số điện thoại',
    请输入搜索关键词: 'Nhập từ khóa tìm kiếm',
    请输入下方图形验证码: 'Vui lòng nhập mã xác minh đồ họa bên dưới',
    请输入详细地址信息如道路门牌号小区楼层号等:
        'Vui lòng nhập thông tin địa chỉ chi tiết, chẳng hạn như đường, số nhà, khu phố, số tầng, v.v.',
    请输入新密码: 'Vui lòng nhập mật khẩu mới',
    请输入旧密码: 'Vui lòng nhập mật khẩu cũ',

    请输入验证码: 'Vui lòng nhập mã xác thực',
    请输入用户名: 'Vui lòng nhập tên người dùng',
    请输入邮箱: 'Vui lòng nhập email của bạn',
    请输入邮箱手机号: 'Vui lòng nhập email/số di động',
    请输入原密码: 'Vui lòng nhập mật khẩu ban đầu',
    请输入正确的密码格式必须包含两种字符:
        'Vui lòng nhập đúng định dạng mật khẩu phải chứa hai ký tự',
    请输入正确的邮箱: 'Vui lòng nhập email chính xác',
    请填写联系电话: 'Vui lòng nhập số điện thoại của bạn',
    请填写联系人: 'Tên người liên lạc',
    请填写留言内容: 'Vui lòng điền nội dung tin nhắn',
    请填写名: 'Vui lòng điền tên',
    请填写收货地址: 'Vui lòng điền địa chỉ nhận hàng',
    请填写完整必填项: 'Vui lòng điền vào phần được yêu cầu',
    请填写详细地址: 'Vui lòng điền địa chỉ chi tiết',
    请填写姓: 'Vui lòng điền họ',
    请填写邮政编码: 'Vui lòng điền mã bưu điện',
    请选择: 'Vui lòng chọn',
    请选择城市: 'Vui lòng chọn thành phố',
    请选择付款方式: 'Lựa chọn phương thức thanh toán',
    请选择规格: 'Vui lòng chọn một thông số kỹ thuật',
    请选择国家: 'Vui lòng chọn một quốc gia',
    请选择删除项需已读才能删除:
        'Vui lòng chọn mục cần xóa, phải đã đọc mới được xóa',
    请再次输入密码: 'Vui lòng nhập lại mật khẩu',
    请在x内完成支付否则订单会被自动取消:
        'Vui lòng hoàn tất thanh toán trong vòng <span style="color: red;">{num}</span>, nếu không đơn hàng sẽ tự động bị hủy',
    请在下次使用邮箱地址进行登录:
        'Vui lòng sử dụng địa chỉ email của bạn để đăng nhập lần sau',
    取消: 'Hủy bỏ',
    取消成功: 'Hủy thành công',
    取消订单: 'Hủy đơn hàng',
    取消订单成功: 'Đã hủy đơn hàng thành công',
    取消时间: 'Thời gian hủy',
    取消售后: 'Hủy bỏ hậu mãi',
    去付款: 'trả',
    去支付: 'trả',
    全部: 'Toàn bộ',
    全部分类: 'Tất cả danh mục',
    全部消息: 'Toàn bộ tin nhắn',
    全选: 'Chọn tất cả',
    确定: 'Xác nhận',
    确定放弃支付吗: 'Bạn có chắc chắn muốn từ bỏ thanh toán không?',
    确定删除选中的商品吗: 'Bạn có chắc muốn xóa sản phẩm đã chọn không?',
    确定提交: 'Xác nhận gửi đi',
    确定要清空历史记录吗: 'Bạn có chắc chắn muốn xóa lịch sử không?',
    确定要取消支付这笔订单吗:
        'Bạn có chắc muốn hủy thanh toán đơn hàng này không?',
    确定已输入接收退款的银行卡号和户名:
        'Đảm bảo bạn đã nhập số thẻ ngân hàng và tên tài khoản để nhận được tiền hoàn lại',
    确定支付: 'Xác nhận thanh toán',
    确认订单: 'Xác nhận đơn hàng',
    确认密码: 'Xác nhận mật khẩu',
    确认收货: 'Xác nhận đã nhận hàng',
    确认收货成功: 'Xác nhận nhận hàng thành công',
    确认已付款: 'Xác nhận đã thanh toán',
    如果您当前的手机号已无法使用请联系客服:
        'Nếu số điện thoại di động hiện tại của bạn không còn sử dụng được, vui lòng liên hệ với cskh',
    若您已按如上付款方式付款请上传相关凭证如转账截图等支持jpg和png格式文件图片不大于3M:
        'Chứng từ thanh toán: Nếu bạn đã thanh toán theo phương thức thanh toán trên, vui lòng tải lên các chứng từ có liên quan, chẳng hạn như ảnh chụp màn hình chuyển khoản, v.v., hỗ trợ định dạng jpg, png và hình ảnh không được lớn hơn 3M',
    扫码或者点击去支付: 'Quét mã hoặc bấm "thanh toán"',
    删除: 'Xóa',
    删除成功: 'Đã xóa thành công',
    删除订单: 'Xóa đơn đặt hàng',
    删除订单成功: 'Đã xóa đơn hàng thành công',
    删除失效商品: 'Xóa sản phẩm thất bại',
    商品: 'Hàng hóa',
    商品单价: 'Đơn giá sản phẩm',
    商品分类: 'Phân loại sản phẩm',
    商品合计: 'Tổngtính sản phẩm',
    商品寄回地址将在审核通过后以短信形式告知或在申请记录中查询:
        'Địa chỉ trả lại của sản phẩm sẽ được thông báo bằng tin nhắn văn bản sau khi được phê duyệt hoặc xem trong lịch sử yêu cầu',
    商品详情: 'Thông tin chi tiết sản phẩm',
    商品总额: 'Tổng số tiền sản phẩm',
    商品总价: 'Tổng giá sản phẩm',
    上传成功: 'Tải lên thành công',
    上传凭证: 'Tải lên chứng từ',
    上传头像图片大小不能超过2MB:
        'Kích thước của ảnh đại diện được lên không được vượt quá 2MB!',
    上传头像图片只能是JPG格式:
        'Ảnh đại diện được tải lên chỉ có thể ở định dạng JPG',
    上传中: 'Đang tải lên...',
    设为默认: 'Đặt làm mặc định',
    设为默认收货地址: 'Đặt làm địa chỉ giao hàng mặc định',
    申请时间: 'Thời gian yêu cầu',
    申请信息: 'Thông tin yêu cầu',
    剩余X件: 'Còn {num} cái',
    失效宝贝: 'Sản phẩm thất bại',
    时: 'Giờ',
    实付金额: 'Số tiền thực trả',
    实付款: 'Thực trả',
    是否删除地址: 'Có chắc muốn xóa địa chỉ',
    是否选择该地址: 'Bạn có chắc chọn địa chỉ này',
    收货地址: 'Địa chỉ nhận hàng',
    收货人: 'Người nhận hàng',
    手机号: 'Số điện thoại',
    手机号码: 'Số điện thoại',
    手机验证: 'Xác minh điện thoại',
    手机验证码: 'Mã xác minh điện thoại di động',
    首页: 'Trang chủ',
    售后单号: 'Mã đơn hậu mãi',
    售后方式: 'Phươn thức hậu mãi',
    售后服务: 'Dịch vụ hậu mãi',
    售后商品: 'Sản phẩm hậu mãi',
    售后申请: 'Yêu cầu hậu mãi',
    售后申请成功: 'Yêu cầu hậu mãi thành công',
    售后申请提交成功: 'Yêu cầu hậu mãi đã được gửi thành công',
    售后完成: 'Hoàn tất hậu mãi',
    售后详情: 'Chi tiết hậu mãi',
    售后信息: 'Thông tin hậu mãi',
    售后专员可能与您电话沟通请保持手机畅通:
        'chuyên viên hậu mãi có thể liên lạc với bạn qua điện thoại, vui lòng chú ý điện thoại',
    搜索: 'Tìm kiếm',
    提交: 'Gửi đi',
    提交订单: 'Gửi đơn hàng',
    提交服务单后售后专员可能与您电话沟通请保持手机畅通:
        'Sau khi gửi yêu cầu dịch vụ, chuyên viên hậu mãi có thể liên lạc với bạn qua điện thoại, vui lòng chú ý điện thoại',
    提交申请: 'Gửi yêu cầu',
    提示: 'Gợi ý',
    天: 'Ngày',
    添加超时: 'Đã hết thời gian thêm vào',
    添加成功: 'Thêm thành công',
    添加失败库存不足: 'Thêm không thành công hàng trong kho không đủ',
    添加新地址: 'Thêm địa chỉ mới',
    条回复: '* tin nhắn trả lời',
    通过BCEL银行完成支付后务必返回当前页面确认支付是否成功:
        'Sau khi hoàn tất thanh toán qua Ngân hàng BCEL, hãy nhớ quay lại trang hiện tại để xác nhận xem thanh toán có thành công hay không',
    头像: 'Avatar',
    图片上传成功: 'Hình ảnh được tải lên thành công',
    图片上传失败请重试: 'Tải lên hình ảnh không thành công, vui lòng thử lại',
    退出登录: 'Đăng xuất',
    退货处理成功后退款金额将原路返回到您的支持账户中:
        'Sau khi trả hàng được xử lý thành công, số tiền hoàn trả sẽ được trả lại vào tài khoản của bạn theo cách thanh toán ban đầu',
    退货金额: 'Số tiền hoàn trả',
    退货类型: 'Loại hình hoàn trả',
    退货数量: 'Số lượng trả hàng',
    退货原因: 'Lý do trả hàng',
    退款成功: 'Hoàn tiền thành công',
    退款金额: 'Số tiền hoàn trả',
    退款失败: 'Hoàn tiền thất bại',
    退款说明: 'Hướng dẫn hoàn tiền',
    退款完成: 'Đi thanh toán',
    退款原因: 'Lý do hoàn tiền',
    退款中: 'Đang hoàn tiền',
    完成: 'Hoàn tất',
    忘记密码: 'Quên mật khẩu',
    微信: 'WeChat',
    为了帮助您更好的解决问题请上传图片:
        'Để giúp bạn giải quyết vấn đề tốt hơn, vui lòng tải lên một hình ảnh',
    为确认身份我们需验证您的安全手机:
        'Để xác nhận danh tính, chúng tôi cần xác minh số điện thoại bảo mật của bạn',
    未登录: 'Chưa đăng nhập',
    未读消息: 'Tin nhắn chưađọc',
    未发货: 'Chưa phát hàng',
    未添加收货地址: 'Chưa thêm địa chỉ nhận hàng',
    未选择地址或没有地址: 'Không có địa chỉ được chọn hoặc không có địa chỉ',
    温馨提示: 'Lời nhắc nhờ',
    文件过大: 'Tệp quá lớn',
    我的: 'Của tôi',
    我的订单: 'Đơn hàng của tôi',
    我是新用户: 'Tôi là người dùng mới',
    我要留言: 'Tôi muốn để lại lời nhắn',
    我要退货退款: 'Tôi muốn trả hàng hoàn tiền',
    我要退款: 'Tôi muốn hoàn tiền',
    物流单号: 'Mã đơn vận chuyển：',
    下单结算: 'Kết toán đặt hàng',
    下一步: 'Bước tiếp theo',
    线下支付: 'Thanh toán ngoại tuyến',
    线下支付不支持线上售后服务如需售后请联系店铺若已付款请耐心等待店铺确认收款并发货:
        'Thanh toán ngoại tuyến không hỗ trợ dịch vụ hậu mãi trực tuyến, vui lòng liên hệ với người bán nếu bạn cần dịch vụ hậu mãi. Nếu bạn đã thanh toán, vui lòng kiên nhẫn chờ người bán xác nhận thanh toán và giao hàng!',
    详细地址: 'Địa chỉ chi tiết',
    消息通知: 'Thông báo tin nhắn',
    消息详情: 'Chi tiết tin nhắn',
    销量高低: 'Cấp độ bán hàng',
    销量最低: 'Bán chậm nhất',
    销量最高: 'Bán chạy nhất',
    新密码: 'Mật khẩu mới',
    新密码不能和旧密码相同: 'Mật khẩu mới không được trùng với mật khẩu cũ',
    新增地址: 'Thêm địa chỉ mới',
    性别: 'Giới tính',
    姓: 'Họ',
    修改: 'Sửa đổi',
    修改成功: 'Sửa đổi thành công',
    修改密码: 'Đổi mật khẩu',
    修改邮箱: 'Sửa email',
    需勾选协议: 'Cần kích chọn thỏa thuận',
    选择: 'Chọn',
    选择成功: 'Chọn thành công',
    选择新地址: 'Chọn địa chỉ mới',
    选中地址: 'Địa chỉ đã chọn',
    验证: 'xác minh',
    验证码: 'Mã xác minh',
    验证码登录: 'Mã xác minh đăng nhập',
    已读消息: 'Tin nhắn đã đọc',
    已发货: 'Đã phát hàng',
    已关闭: 'Đã đóng',
    已取消: 'Đã hủy',
    已取消删除: 'Đã hủy xóa',
    已完成: 'Đã hoàn thành',
    已选择: 'Đã chọn',
    已阅读并同意以下协议: 'Đã đọc và đồng ý với các thỏa thuận sau',
    隐私政策: 'Chính sách quyền riêng tư',
    应付总额: 'Tổng số tiền phải trả',
    用户服务协议: 'Thỏa thuận dịch vụ người dùng',
    用户服务协议及法律声明: 'Thỏa thuận dịch vụ người dùng và tuyên bố pháp lý',
    优惠金额: 'Số tiền khuyến mã',
    优惠卷抵扣: 'Mã khuyến mãi giảm giá',
    邮箱: 'Email',
    邮箱手机号: 'Email/Số điện thoại di động',
    邮箱修改: 'Sửa đổi email',
    邮箱验证: 'Email xác thực',
    邮政编码: 'Mã bưu Chính',
    语言: 'Ngôn ngữ',
    原密码: 'Mật khẩu ban đầu',
    运费: 'Phí vận chuyển',
    暂无订单信息: 'Không có thông tin đặt hàng',
    暂无商品: 'Không có sản phẩm',
    暂无售后信息: 'Không có thông tin hậu mãi',
    长度在1到8个字符: 'Độ dài từ 1 đến 8 ký tự',
    支付宝: 'Alipay',
    支付成功: 'Thanh toán thành công',
    支付订单: 'Thanh toánh đơn hàng ',
    支付方式: 'Phương thức thanh toán',
    支付金额: 'Số tiền thanh toán',
    支付凭证: 'Chứng từ thanh toán',
    支付失败: 'Thanh toán thất bại',
    重新发送验证码: 'Gửi lại mã xác minh',
    重新申请: 'Yêu cầu lại',
    注册: 'Đăng ký ',
    注册成功: 'Đăng ký thành công',
    综合排序: 'Sắp xếp tổng hợp',
    总价: 'Tổng giá',
    最多可上传5张图片每张图片大小不超过5M支持xxx格式文件:
        'Có thể tải lên tối đa 5 ảnh và kích thước mỗi ảnh không vượt quá 5M, hỗ trợ các tệp định dạng bmp, gif, jpg, png, jpeg',
    最多上传五张: 'Tối đa tải lên 5 ảnh',
    推荐商品: 'Sản phẩm khuyên dùng',
    共x件商品已选择x件: 'Tổng cộng {num} sản phẩm, {num2} sản phẩm đã được chọn',
    共x件商品: 'Tổng số {num} mặt hàng',
    x件: '{num} Sản phẩm',
    绑定: 'Trói buộc',
    绑定邮箱: 'Liên kết Email',
    分享店铺: 'Chia sẻ cửa hàng',
    分享至: 'chia sẽ với',
    海报: 'áp phích',
    分享: 'chia sẻ',
    推荐店铺给你: 'Giới thiệu trung tâm mua sắm cho bạn',
    链接: 'liên kết',

    至: 'Đến',
    币种: 'Loại tiền tệ',
    变动理由: 'Lý do thay đổi',
    不记得: 'không nhớ',
    充值: 'Nạp tiền',
    打款凭证: 'Chứng từ chuyển khoản ',
    打款说明: 'Hướng dẫn chuyển tiền',
    打款信息: 'Thông tin thanh toán',
    打款状态: 'Trạng thái thanh toán',
    待打款: 'Chờ thanh toán',
    待审核: 'Chờ phê duyệt',
    到账方式: 'Phương thức gửi tiền',
    到账银行卡号: 'Thẻ ngân hàng nhận tiền',
    冻结中: 'Đóng băng',
    国家: 'Quốc gia',
    获取验证码: 'Nhận mã xác minh',
    记得: 'Nhớ',
    记录时间: 'thời gian ghi',
    拒绝打款: 'Từ chối trả',
    开户人: 'Chủ tài khoản',
    开户支行: 'Chi nhánh mở tài khoản',
    可提现: 'Có thể rút tiền',
    可提现金额: 'Số tiền có thể rút',
    立即提现: 'Rút tiền ngay lập tức',
    流水号: 'Số seri',
    没有适合此币种的银行卡请先添加:
        'Không có thẻ ngân hàng nào phù hợp với loại tiền này, vui lòng thêm thẻ trước',
    没有收款码请先添加: 'Không có mã thanh toán, vui lòng thêm trước',
    每天最多提现x次单次最高提现金额x:
        'Số lần rút tối đa mỗi ngày, số tiền rút tối đa mỗi ngày*',
    您是否记得账号当前使用的支付密码:
        'Bạn có nhớ mật khẩu thanh toán hiện đang được tài khoản của bạn sử dụng không?',
    您未设置支付密码请先设置:
        'Bạn chưa đặt mật khẩu thanh toán, vui lòng đặt trước',
    钱包余额: 'Số dư trên Ví',
    请输入开户人: 'Vui lòng nhập chủ tài khoản',
    请输入开户支行: 'Vui lòng chọn chi nhánh mở tài khoản',
    请输入收款名称: 'Vui lòng nhập tên thanh toán',
    请输入提现金额: 'Vui lòng nhập số tiền rút',
    请输入新支付密码6位数字: 'Vui lòng nhập mật khẩu thanh toán mới, 6 chữ số',
    请输入新支付密码: 'Vui lòng nhập mật khẩu thanh toán mới',

    请输入银行卡号: 'Vui lòng nhập số thẻ ngân hàng',
    请输入原支付密码: 'Vui lòng nhập mật khẩu thanh toán ban đầu',
    请输入支付密码6位数字: 'Vui lòng nhập mật khẩu thanh toán, 6 chữ số',
    请输入支付密码: 'Vui lòng nhập mật khẩu thanh toán',

    请选择币种: 'Vui lòng chọn đơn vị tiền tệ',
    请选择大洲: 'Vui lòng chọn một lục địa',

    请选择结束时间: 'Vui lòng chọn ngày kết thúc',
    请选择开始时间: 'Vui lòng chọn ngày bắt đầu',
    请选择收款码类型: 'Vui lòng chọn loại mã thanh toán',
    请选择银行: 'Vui lòng chọn ngân hàng',
    请选择银行名称: 'Vui lòng chọn tên ngân hàng',
    请再次输入新支付密码: 'Vui lòng nhập lại mật khẩu thanh toán mới',
    请再次输入支付密码: 'Vui lòng nhập lại mật khẩu thanh toán',
    去设置: 'Đi tới cài đặt',
    全部提现: 'Rút tất cả',
    确定删除收款码吗: 'Bạn có chắc chắn muốn xóa mã thanh toán không?',
    确定删除银行卡吗: 'Bạn có chắc chắn muốn xóa thẻ ngân hàng không?',
    确定提现: 'Xác nhận rút tiền:',
    确认打款: 'Xác nhận chuyển khoản',
    确认新密码: 'Xác nhận mật khẩu mới',
    确认支付密码: 'Xác nhận mật khẩu thanh toán',
    上传的收款二维码需要清晰无遮挡:
        'Mã QR thanh toán được tải lên phải rõ ràng và không bị cản trở',
    审核备注: 'Ghi chú phê duyệt',
    审核不通过: 'Kiểm toán không được thông qua',
    审核结果: 'kết quả phê duyệt',
    审核通过: 'Thông qua phê duyệt',
    审核信息: 'Xem lại thông tin',
    时间: 'Thời gian',
    实际到账金额: 'Số tiền thực tế nhận được',
    收款方式: 'phương thức thanh toán',
    收款管理: 'Quản lý bộ sưu tập',
    收款码: 'Mã thanh toán',
    收款码管理: 'Quản lý mã thanh toán',
    收款码类型: 'Loại mã thanh toán',
    收款名称: 'Tên người nhận',
    收款账号: 'Số tài khoản thanh toán',
    手续费: 'Phí rút tiền',
    提现: 'Rút tiền',
    提现币种: 'Loại tiền rút',
    提现成功: 'Rút tiền thành công',
    提现记录: 'Lịch sử rút tiền',
    提现金额: 'Số tiền rút',
    提现失败: 'Rút tiền không thành công',
    提现时间: 'Thời gian rút tiền',
    提现手续费: 'Phí rút tiền',
    提现详情: 'Chi tiết rút tiền',
    提现中: 'Rút tiền',
    提现状态: 'Trạng thái rút tiền',
    添加收款码: 'Thêm mã thanh toán',
    添加银行卡: 'Thêm thẻ ngân hàng',
    退款: 'Đền bù',
    忘记支付密码: 'Quên mật khẩu thanh toán của bạn?',
    尾号: 'Số đuôi',
    我的钱包: 'ví của tôi',
    我的余额: 'Số dư của tôi',
    详情: 'Chi tiết',
    消费: 'tiêu thụ',
    新支付密码: 'Mật khẩu thanh toán mới',
    修改支付密码: 'Thay đổi mật khẩu thanh toán',
    选择收款方式: 'Chọn phương thức thanh toán',
    银行卡: 'Thẻ ngân hàng',
    银行卡管理: 'Quản lý thẻ ngân hàng',
    银行卡号: 'số thẻ ngân hàng',
    银行名称: 'Tên ngân hàng',
    余额记录: 'Bản ghi số dư',
    余额数据: 'Dữ liệu số dư',
    原支付密码: 'Mật khẩu thanh toán gốc',
    暂无数据: 'Không có dữ liệu',
    支持币种: 'Loại tiền tệ hỗ trợ',
    支付密码: 'Mật khẩu thanh toán',
    只可上传一张图片图片大小不超过5M支持jpgpngjpeg格式文件:
        'Chỉ có thể tải lên một ảnh và kích thước ảnh không vượt quá 5M và jpg, png và jpeg tập tin định dạng được hỗ trợ',
    账户余额: 'Số dư tài khoản',
    钱包服务: 'Dịch vụ ví',
    每天最多提现x次单次最高提现金额xx最低提现金额xx:
        'Số tiền rút tối đa mỗi ngày là {num} lần, số tiền rút tối đa mỗi lần là {icon}{money} và số tiền rút tối thiểu là {icon}{money2}',
    确认: 'xác nhận',
    输入金额超过可提现余额: 'Số tiền đầu vào vượt quá số dư có thể rút',
    日期选择: 'lựa chọn ngày',
    备注: 'Nhận xét',
    当前没有余额记录: 'Hiện tại không có hồ sơ số dư!',
    当前没有提现记录: 'Hiện tại không có hồ sơ rút tiền!',
    还没有绑定银行卡: 'Chưa có thẻ ngân hàng nào bị ràng buộc!',
    还没有添加收款码: 'Chưa có mã thanh toán nào được thêm vào!',
    提示只可上传一张图片图片大小不超过5M支持xxx格式文件上传的收款二维码需要清晰无遮挡:
        'Mẹo: Chỉ có thể tải lên một ảnh, kích thước của ảnh không được vượt quá 5M và hỗ trợ các tệp định dạng jpg, png và jpeg. Mã QR thanh toán được tải lên phải rõ ràng và không bị cản trở.',
    请上传收款码: 'Vui lòng tải lên mã thanh toán',
    请输入收款码名称: 'Vui lòng nhập tên mã thanh toán',
    暂无银行卡: 'Chưa có thẻ ngân hàng',
    余额: 'THĂNG BẰNG',
    重置: 'cài lại',
    暂无收款码: 'Chưa có mã thanh toán',
    保障支付安全: 'Đảm bảo an ninh thanh toán',
    打款成功: 'Thanh toán thành công',
    请选择收款方式: 'Vui lòng chọn phương thức thanh toán',
    收款类型: 'Hình thức thanh toán',
    编辑银行卡: 'Chỉnh sửa thẻ ngân hàng',
    编辑收款码: 'Chỉnh sửa mã thanh toán',
    收款码名称: 'Tên mã thanh toán',
    订单ID: 'ID đơn hàng',
    如果您已支付成功请点击确认支付按钮:
        'Nếu thanh toán của bạn thành công, vui lòng nhấp vào nút "Xác nhận thanh toán"',
    确认支付: 'Xác nhận thanh toán',
    支付遇到问题: 'Gặp phải vấn đề',
    对不起您访问的店铺可能已被删除名称被更改或不存在:
        'Xin lỗi, cửa hàng bạn ghé thăm có thể đã bị xóa, tên đã thay đổi hoặc có thể không tồn tại...',
    打款中: 'Đang thanh toán',
    银行编码: 'Mã ngân hàng',
    卢比支付: 'thanh toán IDR',
    比索支付: 'thanh toán PHP',
    泰铢支付: 'thanh toán THB',
    越南盾支付: 'thanh toán VND',
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-0": "{currencyUnit}{money}",
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-1": "Trọng lượng đầu{num}kg{currencyUnit}{money}，Trọng lượng kế tiếp{num2}kg{currencyUnit}{money2}",
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-2": "Kiện đầu{num3}Sản phẩm{currencyUnit}{money3}，Kiện kế tiếp{num4}Sản phẩm{currencyUnit}{money4}",
    暂无配送地区: 'Chưa có khu vực giao hàng',
    该商品在当前地区暂不支持配送: 'Sản phẩm này hiện không hỗ trợ giao hàng tại khu vực hiện tại',
    当前商品仅支持以下地区发货如有疑问请联系店铺:
        'Sản phẩm hiện tại chỉ hỗ trợ giao hàng các khu vực sau, nếu có thắc mắc vui lòng liên hệ cửa hàng',
    运费计价说明: 'Hướng dẫn tính giá cước vận chuyển',
    发货地: 'Nơi phát hàng',
    配送至: 'Giao hàng tới',
    未添加地址请先添加: 'Chưa thêm địa chỉ, vui lòng thêm địa chỉ trước',
    请输入大于1的正整数: 'Vui lòng nhập số nguyên dương lớn hơn 1',
    设置密码: 'đặt mật khẩu',
    登录密码: 'mật khẩu đăng nhập',
    长度在8到16个字符: 'Độ dài từ 8 đến 16 ký tự',
    必须包含两种字符: 'Phải chứa hai ký tự',
    当前仅支持xxx的金额: 'Hiện tại chỉ hỗ trợ số tiền từ {currency} {min}-{currency} {max}',
    查看运费: 'Xem chi phí vận chuyển',
    运费方式: 'Phương pháp vận chuyển',
    价格: 'giá',
    请选择配送方式: 'Vui lòng chọn hình thức vận chuyển',
    配送方式: 'phương thức vận chuyển',
    配送费说明: 'Mô tả phí giao hàng',
    我明白了: 'tôi hiểu rồi',
    若多个商品选用同一个运费模板则运费是按同一个运费方式的规则进行计算: 'Nếu nhiều sản phẩm sử dụng cùng một mẫu cước vận chuyển thì cước phí sẽ được tính theo quy định của cùng một phương thức vận chuyển hàng hóa.',
    若多个商品不是同一个运费模板则运费是多个模板的价格之和: 'Nếu nhiều sản phẩm không có mẫu phí vận chuyển giống nhau thì phí vận chuyển là tổng giá của nhiều mẫu.',
    快捷登录登录即表示同意协议: 'Bằng cách đăng nhập, bạn đồng ý với thỏa thuận',
    促销广告: 'quảng cáo khuyến mại',
    最近新品: 'Sản phẩm mới',
    更多: 'Hơn',
    设置成功: 'Thiết lập thành công',
    更多商品:'Nhiều sản phẩm hơn',

    //     1.6
    我的优惠券:'phiếu giảm giá của tôi',
    历史记录:'kỷ lục lịch sử',
    已使用:'Đã sử dụng',
    已过期:'hết hạn',
    优惠券:'phiếu mua hàng',
    领取:'nhận được',
    已领取:'Đã nhận',
    有效期:'Thời gian hiệu lực：',
    活动优惠详情:'Chi tiết giảm giá sự kiện',
    可用商品:'Các mặt hàng có sẵn',
    单独购买:'Giá gốc',
    发起拼团:'Giá nhóm',
    拼团价:'Giá nhóm',
    可参与的拼团:'Mua nhóm có sẵn',
    '再邀X人即可拼单成功':'Chia sẻ với {num} người nữa để đặt hàng thành công>',
    拼单详情:'Chi tiết mua nhóm',
    待分享:'đang chờ chia sẻ',
    '若拼团失败，货款将原路退回':'Nếu mua nhóm không thành công, khoản thanh toán sẽ được hoàn lại.',
    促销价:'Giá bán',
    距离结束时间:'End in',
    满XX减XX:"Chi tiêu {currencyUnit}{priceStart} nhận{currencyUnit}{reducePrice}{couponType==2?'%':''} giảm giá",
    满XX减X:'Chi tiêu {currencyUnit}{priceStart} nhận {reducePrice}% giảm giá',
    分享好友X人成团立省X:'Mua nhóm cho<span style="color: #8D4BBB;">{num}</span>người，tiết kiệm<span style="color: #8D4BBB;">{currencyUnit}{groupPrice}</span>',
    分享好友:'Chia sẻ với bạn bè',
    '这些人正在拼，立即参与 ':'Đang mua nhóm, hãy tham gia ngay',
    满x可用:'Có sẵn cho các đơn hàng trên {currencyUnit}{priceStart}',
    这些人正在拼立即参与:'Đang mua nhóm, hãy tham gia ngay',
    限领x张:'Giới hạn {receiveCount} phiếu giảm giá cho mỗi người dùng',
    满XX送赠品:'Mua trị giá {currencyUnit}{priceStart} nhận quà tặng',
    去使用:'sử dụng',
    销量:'Việc bán hàng',
    赠品:'Quà tặng',
    限时优惠:'Ưu đãi trong thời gian có hạn',
    距结束:'End in',
    拼团好物:'Mua Nhóm',
    当前优惠券可以购买以下商品:'Phiếu giảm giá hiện tại có thể mua các sản phẩm sau:',
    满XX减XX可用:"Chi tiêu {currencyUnit}{priceStart} nhận {currencyUnit}{reducePrice}giảm giá",
    满XX减X可用:'Chi tiêu {currencyUnit}{priceStart} nhận {reducePrice}% giảm giá',
    再买x可用:'Vẫn cần mua {currencyUnit}{difPrice}',
    合计:'tổng cộng',
    优惠:'giảm giá',
    去购物车:'giỏ hàng',
    拼团:'Mua Nhóm',
    满XX可用:'Có sẵn cho các đơn hàng trên {currencyUnit}{priceStart}',
    满减满赠:'Giảm giá đầy đủ/quà tặng đầy đủ',
    成团发货:'Chờ giao hàng',
    还差xx人成团:'Vẫn cần <span style="color: #FF4545;">{num}</span>người để đặt hàng thành công',
    拼团结束:'Mua theo nhóm kết thúc',
    拼团成功:'Mua nhóm thành công',
    有成员未付款请等待付款成团:'Có người chưa thanh toán, vui lòng chờ thanh toán',
    我正在参与的拼团:'Tham gia mua nhóm',
    满减:'Giảm giá đầy đủ',
    满赠:'Quà tặng đầy đủ',
    凑单:'Cùng nhau đặt hàng',
    切换其他优惠:'Chọn ưu đãi khác',
    超过最大购买数:'Đã vượt quá số lượng mua hàng tối đa',
    商品限购x件:'Giới hạn {num} miếng',
    参与拼团:'tham gia',
    结束:'结束',
    您还没有优惠券:'Bạn không có phiếu giảm giá',
    优惠券历史记录:'kỷ lục lịch sử',
    拼单失败:'Mua nhóm không thành công',
    免费:'miễn phí',
    已赠完:'hết hàng',
    以下商品:'Sản phẩm sau',
    此拼团已满请选择其他拼团:'Mua nhóm này đã đầy, vui lòng chọn mua nhóm khác',
    以下商品正在限时优惠当中:'Ưu đãi trong thời gian có hạn cho các mặt hàng sau',
    以下商品正在限时拼团当中:'Giới hạn thời gian mua nhóm các sản phẩm sau',
    再买X可减X:'Mua thêm {currencyUnit}{difPrice} và được giảm và được giảm{currencyUnit}{reducePrice}',
    已领完:"sử dụng hết",
    活动未开始:'Sự kiện chưa bắt đầu',

    已满X可减X:'Giảm {currencyUnit}{reducePrice} khi mua hàng trên {currencyUnit}{difPrice}',
    已满X可减XX:'Giảm {reducePrice}% khi mua hàng trên {currencyUnit}{difPrice}',

    再买X可减XX:'Mua thêm {currencyUnit}{difPrice} và được giảm và được giảm{reducePrice}%',
    再买X送赠品:'Mua thêm {currencyUnit}{difPrice} và nhận quà tặng miễn phí',
    已满X送赠品:'Quà tặng đã được trao',
    缺少库存: 'hết hàng',
    房间号已失效请联系工作人员:'Số phòng đã hết, vui lòng liên hệ nhân viên!',

    限X件:'Giới hạn mua {num} miếng',


    退货积分:'Trả lại điểm',
    积分:'tích phân',
    用户注册:'Đăng ký người dùng',
    手动增加:'Tăng thủ công',
    积分兑换退款:'Hoàn lại điểm',
    下单购物:'Đặt hàng để mua sắm',
    过期积分:'Điểm đã hết hạn',
    兑换商品:'Đổi hàng',
    手动减少:'Giảm thủ công',
    订单退款:'Hoàn tiền đơn hàng',
    积分明细:'Chi tiết điểm',
    已兑:'Đã đổi',
    积分商城:'Trung tâm mua sắm điểm',
    规则:'luật lệ',
    可用积分:'Điểm có sẵn',
    冻结积分:'điểm đóng băng',
    我的兑换:'trao đổi của tôi',
    售后:'Sau khi bán hàng',
    分销中心:'trung tâm phân phối',
    立即兑换:'Đổi ngay bây giờ',
    已兑完:'Đã đổi',
    "积分不足，无法兑换":'Không đủ điểm không thể đổi được',
    兑换数量:'Số lượng trao đổi',
'限兑*件':'Vật phẩm đổi quà có giới hạn{num}',
    积分抵扣:'Trừ điểm',
'共*分':'Tổng {num} điểm',
    全部订单:'Tất cả các đơn đặt hàng',


兑换须知:'Hướng dẫn đổi thưởng',
    已达兑换上限:'Đã đạt đến giới hạn đổi quà',
    我的积分:'Điểm của tôi',
    积分规则:'Quy tắc tính điểm',
    兑换时间:'Thời gian quy đổi',
    我的佣金:'hoa hồng của tôi',
    佣金数据:'Dữ liệu hoa hồng',
    分销申请协议:'Thỏa thuận ứng dụng phân phối',
    申请成为分销员:'Đăng ký trở thành nhà phân phối',
    "已申请，请等待审核":'Đã áp dụng, vui lòng chờ xem xét',
    "审核拒绝，继续申请":'Bị từ chối qua quá trình xem xét, tiếp tục áp dụng',
    可提佣金:'Hoa hồng rút tiền',
    冻结佣金:'hoa hồng đóng băng',
    分销订单:'Thứ tự phân phối',
    佣金明细:'Chi tiết hoa hồng',
    我的下级:'cấp dưới của tôi',
    推广商品:'Quảng bá sản phẩm',
    推广店铺:'Quảng cáo cửa hàng',
    暂无记录:'Không có hồ sơ',
    一级佣金收入:'Thu nhập hoa hồng cấp độ đầu tiên',
    一级佣金退款:'Hoàn trả hoa hồng cấp độ đầu tiên',
    二级佣金收入:'Thu nhập hoa hồng phụ',
    二级佣金退款:'Hoàn trả hoa hồng cấp hai',
    下单用户:'Người dùng đặt hàng',
    订单总价:'Tổng giá đặt hàng',
    分销利润层级:'Cấp độ phân phối',
    结算状态:'tình trạng định cư',
    佣金:'nhiệm vụ',
    一级:'Cấp độ 1',
    二级:'Cấp độ 2',
    已结算:'Định cư',
    请输入用户昵称或手机号:'Vui lòng nhập biệt danh người dùng hoặc số điện thoại di động',
    二级团队:'Đội cấp hai',
    分享链接:'chia sẻ đường link',
    可推广至:'Có thể mở rộng đến',
    分享最高可赚x:'Kiếm tới {icon}{num} bằng cách chia sẻ',
    分享推广:'Chia sẻ và quảng bá',
    用户昵称或手机号:'Biệt danh người dùng hoặc số điện thoại di động',
    商品名称:'tên sản phẩm',
    最高可得:'Cao nhất hiện có',
    可获得积分:'điểm kiếm được',
    总获佣金:'Tổng hoa hồng kiếm được',

    佣金详情:'Chi tiết hoa hồng',
    已退款:'được hoàn lại tiền',
    请输入商品名称:'Vui lòng nhập tên sản phẩm',
    请选择银行卡: 'Vui lòng chọn thẻ ngân hàng',
    请选择收款码: 'Vui lòng chọn mã thanh toán',
    不能超过最小提现额度:'Số tiền rút tối thiểu không thể vượt quá!',


    货柜商品:'Hàng container',
    该浏览器不支持蓝牙:'Trình duyệt này không hỗ trợ Bluetooth, vui lòng copy link vào trình duyệt Chrome, Samsung Internet để mở',
    iOS系统暂不支持售货机取货请使用Android系统扫码购物:'Hệ thống iOS hiện không hỗ trợ nhận hàng tại máy bán hàng tự động. Vui lòng sử dụng hệ thống Android để quét mã QR để mua sắm.',
    去商城首页:'Tới trang chủ của trung tâm mua sắm',
    已售完:'Bán hết',
    蓝牙未连接:'Bluetooth không được kết nối',
    蓝牙已连接:'Bluetooth đã được kết nối',
    重试:'Thử lại',
    蓝牙未授权请打开蓝牙后重试:'Bluetooth không được phép, vui lòng bật Bluetooth và thử lại!',
    蓝牙未连接请选择蓝牙并配对:'Bluetooth chưa được kết nối, vui lòng chọn Bluetooth và ghép nối!',
    连接:'kết nối',
    蓝牙已断开请重新连接:'Bluetooth đã bị ngắt kết nối, vui lòng kết nối lại',
    蓝牙连接中:'Kết nối blutooth...',
    购买成功请取出商品若柜门未打开请尝试再次打开:'Mua hàng thành công, vui lòng lấy sản phẩm ra! Nếu cửa tủ không mở, vui lòng thử mở lại!',
    再次打开:'Mở lại',
    普通订单:'Thứ tự thông thường',
    售货机:'máy bán hàng tự động',
    售货机已无货无法支付:'Máy bán hàng tự động đã hết hàng và không thể thanh toán được.',
    订单来源:'Nguồn đặt hàng',
    连接失败请重新连接:'Kết nối không thành công, vui lòng kết nối lại',
    售罄:'bán hết',
    '房间号/桌号':'Số phòng/số bàn'
}
// 越南
