// 马来西亚
export default {
    BCEL支付: 'BCEL',
    KBZ支付: 'KBZpay',
    OnePay支付: 'OnePay',
    OnePay支付二维码: 'Kod QR OnePay',
    保存: 'jimat',
    保密: 'Simpan rahsia',
    备注说明: 'Arahan manual',
    x件: '{num} keping',
    编辑地址: 'Edit alamat',
    标记已读: 'Tanda sebagai telah dibaca',
    不可修改最多: 'Tidak boleh diubah suai, sehingga',
    部分订单取消: 'Pembatalan separa pesanan',
    部分发货: 'Penghantaran separa',
    部分商品正在退款中: 'Beberapa item sedang dipulangkan',
    部分收货: 'Separa resit',
    部分退款: 'Bayaran balik separa',
    部分退款完成: 'Bayaran balik separa selesai',
    查看订单: 'Semak pesanan',
    查看全部: 'Lihat semua',
    查看全部xx条回复: 'Lihat semua {num} balasan',
    查看详情: 'Semak butiran',
    查看支付是否成功: 'Semak sama ada pembayaran berjaya>>>',
    查询中: 'Siasatan',
    城市: 'Bandar',
    创建时间: 'Masa penciptaan',
    此宝贝已下架: 'Kehabisan stok',
    待发货: 'Untuk dihantar',
    待付款: 'Pembayaran belum selesai',
    待收货: 'Akan Diterima',
    当前安全手机号: 'Nombor telefon keselamatan semasa',
    地址管理: 'Pengurusan alamat',
    登录: 'Log masuk',
    等待店铺审核: 'Menunggu semakan kedai',
    点击选择城市: 'Klik untuk memilih bandar',
    点击选择国家: 'Klik untuk memilih negara',
    点击选择退货类型: 'Klik untuk memilih jenis pulangan',
    点击选择退货原因: 'Klik untuk memilih sebab pengembalian',
    店: 'S',
    店铺: 'Kedai',
    店铺处理: 'Pengendalian kedai',
    店铺拒绝您的申请: 'Kedai menolak permohonan anda',
    店铺退款: 'Bayaran balik kedai',
    订单编号: 'Nombor pesanan',
    订单号: 'Nombor pesanan',
    订单详情: 'Maklumat-maklumat Pesanan',
    订单已超时: 'Pesanan telah tamat masa',
    短信验证码: 'Kod pengesahan SMS',
    发表留言: 'Tinggalkan pesanan',
    发货: 'Kapal',
    发货件数: 'Bilangan penghantaran',
    发货时间: 'Masa penghantaran',
    发货状态: 'Status penghantaran',
    发送成功: 'Berjaya Dihantar',
    发送验证码: 'Hantar kod pengesahan ',
    法律声明: 'Notis Undang-undang',
    返回: 'Kembali',
    返回首页: 'Pulang ke rumah',
    分: 'Titik',
    分类: 'Pengelasan',
    付款时间: 'Masa pembayaran',
    复制: 'Salinan',
    复制成功: 'Salin dengan jayanya',
    该订单已取消: 'Pesanan ini telah dibatalkan',
    个人信息: 'Maklumat peribadi',
    个字以内: 'Dalam x aksara',
    恭喜您邮箱地址验证成功: 'Tahniah! Pengesahan alamat e-mel berjaya',
    购买商品: 'Beli barang',
    购买数量: 'Kuantiti belian',
    购买信息: 'Maklumat pembelian',
    购物车: 'Troli beli-belah',
    国家区域: 'Negara/Wilayah',
    含发货邮费: 'Termasuk kos penghantaran',
    欢迎登录: 'Selamat datang ke Log Masuk',
    活动优惠: 'Promosi',
    货币: 'Mata wang',
    继续逛逛: 'Terus membeli-belah',
    加入购物车: 'Tambah ke troli',
    加载中: 'Memuatkan',
    价格高低: 'Paras harga',
    价格最低: 'Harga terendah',
    价格最高: 'Harga tertinggi',
    件商品: 'x barang',
    交易异常: 'Pengecualian transaksi',
    看不清换一张: 'Tidak dapat melihat dengan jelas',
    客: 'C',
    客服: 'Khidmat Pelanggan',
    客服电话: 'Talian hotline pengguna',
    客服邮箱: 'E-mel Perkhidmatan Pelanggan',
    客户: 'Pelanggan',
    客户服务: 'Khidmat Pelanggan',
    客户取消售后: 'Selepas pelanggan membatalkan jualan',
    客户已付款等待店铺发货: 'Pelanggan telah membayar dan sedang menunggu kedai untuk menghantar',
    历史搜索: 'Carian sejarah',
    立即登录: 'Log masuk segera',
    立即购买: 'Beli sekarang',
    立即留言: 'Tinggalkan mesej sekarang',
    立即提交: 'Hantar sekarang',
    立即注册: 'Daftarlah sekarang',
    联系电话: 'Nombor telefon',
    联系方式: 'Maklumat perhubungan',
    联系客服: 'Hubungi Khidmat Pelanggan',
    联系人: 'Orang yang boleh dihubungi',
    两次输入密码不一致: 'Kata laluan yang dimasukkan dua kali tidak konsisten',
    留言成功: 'Mesej berjaya',
    留言管理: 'Pengurusan mesej',
    留言回复: 'Tinggalkan maklum balas',
    留言回复长度: 'Balasan mesej, panjang 2-100 aksara',
    留言回复长度xxx个字符: 'Balasan mesej, panjang 2-100 aksara',
    留言内容: 'Kandungan mesej',
    留言详情: 'Butiran mesej',
    没有更多了: 'Tiada lagi',
    每张图片大小不超过3M: 'Saiz setiap gambar hendaklah tidak melebihi 3M',
    密码: 'Kata laluan',
    密码登录: 'Log masuk kata laluan',
    密码最少8位最多16位: 'Kata laluan minimum 8 bit dan maksimum 16 bit',
    秒: 'Kedua',
    秒后重新发送: 'Hantar semula selepas {seconds} saat ',
    名: 'Nama',
    默认地址: 'Alamat lalai',
    男: 'Lelaki',
    昵称: 'Nama panggilan',
    昵称不能为空: 'Nama panggilan harus diisi',
    您当前是线下支付请耐心等待店铺确认收款若x后店铺未确认收款订单将自动取消: 'Anda sedang membayar di luar talian, sila tunggu dengan sabar untuk kedai mengesahkan penerimaan! Jika kedai tidak mengesahkan penerimaan selepas <span style="color: red;">{num}</span>, pesanan akan dibatalkan secara automatik!',
    您的购物车还没有商品哟: 'Tiada produk dalam troli beli-belah anda~',
    您的收货地址为空点此添加收货地址: 'Alamat penghantaran anda kosong, klik di sini untuk menambah alamat penghantaran',
    您可以参考如下线下付款方式: 'Anda boleh merujuk kepada kaedah pembayaran luar talian berikut',
    您可以在这里给店铺留言为了保证你的权益请尽可能详细的上传留言资料: 'Anda boleh meninggalkan mesej kepada kedai di sini, untuk melindungi hak dan kepentingan anda, sila muat naik maklumat mesej sedetail mungkin',
    您未支付请重新支付: 'Anda belum bayar, sila bayar lagi',
    女: 'Perempuan',
    评论成功: 'Komen itu berjaya',
    切换语言和货币: 'Tukar bahasa dan mata wang',
    请勾选删除商品: 'Sila semak untuk memadam produk',
    请勾选商品: 'Sila tandakan item tersebut',
    请勾选信息: 'Jualan dari tinggi ke rendah',
    请上传图片: 'Sila muat naik gambar',
    请输入短信验证码: 'Sila masukkan kod pengesahan SMS',
    请输入反馈内容: 'Sila masukkan kandungan maklum balas',
    请输入留言内容: 'Sila masukkan kandungan mesej',
    请输入留言内容若您使用BCEL等支付请在此输入用来接收退款的银行卡号及户名: 'Sila masukkan kandungan mesej. Jika anda membayar menggunakan BCEL atau KBZPay, sila masukkan nombor kad bank dan nama akaun yang digunakan untuk menerima bayaran balik di sini.',
    请输入密码: 'Sila masukkan kata laluan',
    请输入内容: 'Sila masukkan kandungan',
    请输入手机号: 'Sila masukkan nombor telefon',
    请输入搜索关键词: 'Masukkan kata kunci carian',
    请输入下方图形验证码: 'Sila masukkan kod pengesahan grafik di bawah',
    请输入详细地址信息如道路门牌号小区楼层号等: 'Sila masukkan maklumat alamat terperinci, seperti jalan, nombor rumah, komuniti, nombor lantai, dsb.',
    请输入新密码: 'Sila masukkan kata laluan baharu',
    请输入验证码: 'sila masukkan kod pengesahan',
    请输入用户名: 'sila masukkan nama pengguna',
    请输入邮箱: 'Sila masukkan peti e-mel',
    请输入邮箱手机号: 'Sila masukkan e-mel/nombor mudah alih',
    请输入原密码: 'Sila masukkan kata laluan asal',
    请输入正确的密码格式必须包含两种字符: 'Sila masukkan format kata laluan yang betul mesti mengandungi dua aksara',
    请输入正确的邮箱: 'Sila masukkan e-mel kosong anda',
    请填写联系电话: 'Sila taip nombor telefon anda',
    请填写联系人: 'Sila masukkan nama kenalan',
    请填写留言内容: 'Sila isi kandungan mesej',
    请填写名: 'Sila isikan nama',
    请填写收货地址: 'Sila isikan alamat penghantaran',
    请填写完整必填项: 'Sila isikan medan yang diperlukan',
    请填写详细地址: 'Sila isikan alamat terperinci',
    请填写姓: 'Sila isikan nama keluarga',
    请填写邮政编码: 'Sila isikan poskod',
    请选择: 'Sila pilih',
    请选择城市: 'Sila pilih bandar',
    请选择付款方式: 'Sila pilih kaedah pembayaran',
    请选择规格: 'Sila pilih spesifikasi',
    请选择国家: 'Sila pilih negara',
    请选择删除项需已读才能删除: 'Sila pilih item untuk dipadam, ia perlu dibaca untuk dipadam',
    请再次输入密码: 'Sila masukkan kata laluan sekali lagi',
    请在x内完成支付否则订单会被自动取消: 'Sila lengkapkan pembayaran dalam tempoh <span style="color: red;">{num}</span>, jika tidak pesanan akan dibatalkan secara automatik',
    请在下次使用邮箱地址进行登录: 'Sila gunakan alamat e-mel anda untuk log masuk lain kali',
    取消: 'Batal',
    取消成功: 'Batalkan kejayaan',
    取消订单: 'Membatalkan pesanan',
    取消订单成功: "Pesanan berjaya dibatalkan",
    取消时间: 'Masa pembatalan',
    取消售后: 'Pembatalan selepas jualan',
    去付款: 'Bayar',
    去支付: 'Bayar',
    全部: 'Semua',
    全部分类: 'Semua Kategori',
    全部消息: 'Semua berita',
    全选: 'Pilih semua',
    确定: 'Pasti',
    确定放弃支付吗: 'Adakah anda pasti mahu melepaskan pembayaran?',
    确定删除选中的商品吗: 'Adakah anda pasti mahu memadamkan item yang dipilih?',
    确定提交: 'Sahkan Penyerahan',
    确定要清空历史记录吗: 'Adakah anda pasti mahu mengosongkan sejarah？',
    确定要取消支付这笔订单吗: 'Adakah anda pasti mahu membatalkan pembayaran untuk pesanan ini?',
    确定已输入接收退款的银行卡号和户名: 'Pastikan anda telah memasukkan nombor kad bank dan nama akaun untuk menerima bayaran balik',
    确定支付: 'Mengesahkan pembayaran',
    确认订单: 'Mengesahkan pesanan',
    确认密码: 'Sahkan Kata Laluan',
    确认收货: 'Mengesahkan penerimaan barang',
    确认收货成功: 'Sahkan penerimaan berjaya',
    确认已付款: 'Mengesahkan pembayaran',
    如果您当前的手机号已无法使用请联系客服: 'Jika nombor telefon bimbit semasa anda tidak lagi tersedia, sila hubungi perkhidmatan pelanggan',
    若您已按如上付款方式付款请上传相关凭证如转账截图等支持jpg和png格式文件图片不大于3M: 'Jika anda telah membayar mengikut kaedah pembayaran di atas, sila muat naik baucar yang berkaitan, seperti tangkapan skrin pemindahan, dsb., sokongan jpg, format png dan gambar tidak boleh lebih besar daripada 3M',
    扫码或者点击去支付: 'Imbas kod QR atau klik "Pergi ke Bayar"',
    删除: 'Padam',
    删除成功: 'Berjaya dipadamkan',
    删除订单: 'Memadamkan pesanan',
    删除订单成功: 'Pesanan berjaya dipadamkan',
    删除失效商品: 'Padamkan item yang tidak sah',
    商品: 'Komoditi',
    商品单价: 'Harga komoditi',
    商品分类: 'Kategori',
    商品合计: 'Jumlah produk',
    商品寄回地址将在审核通过后以短信形式告知或在申请记录中查询: 'Alamat pemulangan produk akan dimaklumkan melalui mesej teks selepas kelulusan, atau bertanya dalam rekod permohonan',
    商品详情: 'Maklumat produk',
    商品总额: 'Jumlah barangan',
    商品总价: 'Amaun Keseluruhan',
    上传成功: 'Berjaya dimuat naik',
    上传凭证: 'Muat naik sijil',
    上传头像图片大小不能超过2MB: 'Saiz gambar avatar yang dimuat naik tidak boleh melebihi 2MB!',
    上传头像图片只能是JPG格式: 'Gambar avatar yang dimuat naik hanya boleh dalam format JPG',
    上传中: 'Memuat naik...',
    设为默认: 'Ditetapkan sebagai Lalai',
    设为默认收货地址: 'Tetapkan sebagai alamat penghantaran lalai',
    申请时间: 'Masa permohonan',
    申请信息: 'Maklumat Permohonan',
    剩余X件: 'Baki {num} keping',
    请再次输入新密码: 'Sila masukkan kata laluan baharu sekali lagi',
    失效宝贝: 'Gagal sayang',
    时: 'Jam',
    实付金额: 'Jumlah yang sebenarnya dibayar',
    实付款: 'Bayaran sebenar',
    是否删除地址: 'Sama ada hendak memadam alamat',
    是否选择该地址: 'Adakah anda memilih alamat ini',
    收货地址: 'Alamat penghantaran',
    收货人: 'Penerima',
    手机号: 'Nombor telefon bimbit',
    手机号码: 'Nombor telefon',
    手机验证: 'Pengesahan telefon',
    手机验证码: 'Kod pengesahan telefon bimbit',
    首页: 'Muka depan',
    售后单号: 'Nombor pesanan selepas jualan',
    售后方式: 'Kaedah selepas jualan',
    售后服务: 'Perkhidmatan selepas jualan',
    售后商品: 'Barangan selepas pasaran',
    售后申请: 'Permohonan selepas jualan',
    售后申请成功: 'Permohonan selepas jualan berjaya',
    售后申请提交成功: 'Permohonan selepas jualan berjaya dihantar',
    售后完成: 'Siap selepas jualan',
    售后详情: 'Butiran selepas jualan',
    售后信息: 'Maklumat selepas jualan',
    售后专员可能与您电话沟通请保持手机畅通: 'Pakar selepas jualan boleh berkomunikasi dengan anda melalui telefon, sila pastikan telefon anda tidak disekat',
    搜索: 'Cari',
    提交: 'Menyerahkan',
    提交订单: 'Hantar pesanan',
    提交服务单后售后专员可能与您电话沟通请保持手机畅通: 'Selepas menyerahkan pesanan perkhidmatan, pakar selepas jualan boleh berkomunikasi dengan anda melalui telefon, sila pastikan telefon anda tidak disekat',
    提交申请: 'mengemukakan permohonan',
    提示: 'Petunjuk',
    天: 'Langit',
    添加超时: 'Tambah tamat masa',
    添加成功: 'Berjaya ditambahkan',
    添加失败库存不足: 'Tambah gagal Stok tidak mencukupi',
    添加新地址: 'Tambah alamat baru',
    条回复: '* balasan',
    通过BCEL银行完成支付后务必返回当前页面确认支付是否成功: 'Selepas menyelesaikan pembayaran melalui Bank BCEL, pastikan anda kembali ke halaman semasa untuk mengesahkan sama ada pembayaran berjaya.',
    头像: 'avatar',
    图片上传成功: 'Imej berjaya dimuat naik',
    图片上传失败请重试: 'Muat naik imej gagal, sila cuba lagi',
    退出登录: 'Log keluar',
    退货处理成功后退款金额将原路返回到您的支持账户中: 'Selepas pemulangan berjaya diproses, jumlah bayaran balik akan dikembalikan ke akaun sokongan anda dengan cara yang sama',
    退货金额: 'Jumlah pulangan',
    退货类型: 'Jenis pulangan',
    退货数量: 'Kuantiti Pulangan',
    退货原因: 'Alasan untuk kembali',
    退款成功: 'Bayaran balik berjaya',
    退款金额: 'Jumlah bayaran balik',
    退款失败: 'Bayaran balik gagal',
    退款说明: 'Arahan bayaran balik',
    退款完成: 'Bayaran balik selesai',
    退款原因: 'Sebab balik',
    退款中: 'Membayar balik',
    完成: 'Selesai',
    忘记密码: 'Lupa kata laluan',
    微信: 'WeChat',
    为了帮助您更好的解决问题请上传图片: 'Untuk membantu anda menyelesaikan masalah dengan lebih baik, sila muat naik gambar',
    为确认身份我们需验证您的安全手机: 'Untuk mengesahkan identiti, kami perlu mengesahkan telefon selamat anda',
    未登录: 'Tidak log masuk',
    未读消息: 'Mesej yang belum dibaca',
    未发货: 'Tidak dihantar',
    未添加收货地址: 'Alamat penghantaran tidak ditambahkan',
    未选择地址或没有地址: 'Tiada alamat dipilih atau tiada alamat',
    温馨提示: 'Petua yang baik',
    文件过大: 'Fail terlalu besar',
    我的: 'Saya',
    我的订单: 'Arahan saya',
    我是新用户: 'Saya pengguna baru',
    我要留言: 'Saya ingin meninggalkan mesej',
    我要退货退款: 'Saya mahu kembali untuk bayaran balik',
    我要退款: 'Saya mahukan bayaran balik',
    物流单号: 'Nombor penghantaran：',
    下单结算: 'Penyelesaian pesanan',
    下一步: 'Langkah seterusnya',
    线下支付: 'Pembayaran luar talian',
    线下支付不支持线上售后服务如需售后请联系店铺若已付款请耐心等待店铺确认收款并发货: 'Pembayaran luar talian tidak menyokong perkhidmatan selepas jualan dalam talian, sila hubungi peniaga jika anda memerlukan perkhidmatan selepas jualan. Jika anda telah membayar, sila tunggu dengan sabar untuk pedagang mengesahkan pembayaran dan menghantarnya!',
    详细地址: 'Alamat',
    消息通知: 'Pemberitahuan',
    消息详情: 'Butiran mesej',
    销量高低: 'Tahap jualan',
    销量最低: 'Jualan terendah',
    销量最高: 'Jualan tertinggi',
    新密码: 'Kata laluan baharu',
    新密码不能和旧密码相同: 'Kata laluan baharu tidak boleh sama dengan kata laluan lama',
    新增地址: 'Tambah alamat',
    性别: 'Jantina',
    姓: 'Nama keluarga',
    修改: 'Semak semula',
    修改成功: 'Berjaya diubah suai',
    修改密码: 'Tukar kata laluan',
    修改邮箱: 'Ubah suai e-mel',
    需勾选协议: 'Perlu menyemak perjanjian',
    选择: 'Pilih',
    选择成功: 'Pilih kejayaan',
    选择新地址: 'Pilih alamat baru',
    选中地址: 'Alamat yang dipilih',
    验证: 'Mengesahkan',
    验证码: 'Kod pengesahan',
    验证码登录: 'Log masuk kod pengesahan',
    已读消息: 'Membaca mesej',
    已发货: 'Dihantar',
    已关闭: 'Tertutup',
    已取消: 'Dibatalkan',
    已取消删除: 'Tidak dipadamkan',
    已完成: 'Selesai',
    已选择: 'Dipilih ',
    已阅读并同意以下协议: 'Telah membaca dan bersetuju dengan perjanjian berikut',
    隐私政策: 'Dasar Privasi',
    应付总额: 'Jumlah yang perlu dibayar',
    用户服务协议: 'Perjanjian Perkhidmatan Pengguna',
    用户服务协议及法律声明: 'Perjanjian Perkhidmatan Pengguna dan Pernyataan Undang-undang',
    优惠金额: 'Harga diskaun',
    优惠卷抵扣: 'Potongan kupon',
    邮箱: 'Peti E-Mel',
    邮箱手机号: 'E-mel/Nombor telefon bimbit',
    邮箱修改: 'Pengubahsuaian e-mel',
    邮箱验证: 'Pengesahan email',
    邮政编码: 'Poskod',
    语言: 'Bahasa',
    原密码: 'Kata laluan lama',
    运费: 'Penghantaraan',
    暂无订单信息: 'Tiada maklumat pesanan',
    暂无商品: 'Tiada produk',
    暂无售后信息: 'Tiada maklumat selepas jualan',
    长度在1到8个字符: 'Panjang 1 hingga 8 aksara',
    支付宝: 'Alipay',
    支付成功: 'Pembayaran berjaya',
    支付订单: 'Bayar untuk pesanan',
    支付方式: 'Kaedah pembayaran',
    支付金额: 'Jumlah pembayaran',
    支付凭证: 'Baucar bayaran',
    支付失败: 'Pembayaran gagal',
    重新发送验证码: 'Hantar semula Kod',
    重新申请: 'Mohon semula',
    注册: 'Daftar',
    注册成功: 'Pendaftaran berjaya',
    综合排序: 'Jenis komprehensif',
    总价: 'Harga keseluruhan',
    最多可上传5张图片每张图片大小不超过5M支持xxx格式文件: 'Sehingga 5 gambar boleh dimuat naik, dan saiz setiap gambar tidak melebihi 5M, menyokong fail format bmp, gif, jpg, png, jpeg',
    最多上传五张: 'Sehingga 5 muat naik',
    推荐商品: 'Produk yang Disyorkan',
    共x件商品已选择x件: 'Sebanyak {num} item, {num2} item telah dipilih',
    共x件商品: 'Jumlah {num} item',
    绑定: 'Mengikat',
    绑定邮箱: 'Ikat peti mel',
    分享店铺: 'Kongsi kedai',
    分享至: 'Kongsi ke',
    海报: 'Poster',
    分享: 'Kongsi',
    推荐店铺给你: 'Pusat beli-belah yang disyorkan kepada anda',
    链接: 'Pautan',

    至: 'kepada',
    币种: 'mata wang',
    变动理由: 'Sebab perubahan',
    不记得: 'tidak ingat',
    充值: 'Caj semula',
    打款凭证: 'Sijil pembayaran',
    打款说明: 'Arahan pembayaran',
    打款信息: 'Maklumat pembayaran',
    打款状态: 'Status pembayaran',
    待打款: 'pembayaran belum selesai',
    待审核: 'untuk disemak',
    到账方式: 'Kaedah deposit',
    到账银行卡号: 'kad bank',
    冻结中: 'Pembekuan',
    国家: 'bangsa',
    获取验证码: 'Dapatkan kod pengesahan',
    记得: 'ingat',
    记录时间: 'masa rakaman',
    拒绝打款: 'Enggan bayar',
    开户人: 'Pemegang akaun',
    开户支行: 'Cawangan pembukaan akaun',
    可提现: 'Pengeluaran tersedia',
    可提现金额: 'Jumlah yang boleh dikeluarkan',
    立即提现: 'Tarik diri serta-merta',
    流水号: 'nombor siri',
    没有适合此币种的银行卡请先添加: 'Tiada kad bank yang sesuai untuk mata wang ini, sila tambah dahulu',
    没有收款码请先添加: 'Tiada kod pembayaran, sila tambah dahulu',
    每天最多提现x次单次最高提现金额x: 'Bilangan pengeluaran maksimum setiap hari, jumlah pengeluaran maksimum setiap hari*',
    您是否记得账号当前使用的支付密码: 'Adakah anda masih ingat kata laluan pembayaran yang digunakan oleh akaun anda pada masa ini?',
    您未设置支付密码请先设置: 'Anda belum menetapkan kata laluan pembayaran, sila tetapkannya dahulu',
    钱包余额: 'Baki Wallet',
    请输入开户人: 'Sila masukkan pemegang akaun',
    请输入开户支行: 'Sila masukkan cawangan pembukaan akaun',
    请输入收款名称: 'Sila masukkan nama pembayaran',
    请输入提现金额: 'Sila masukkan jumlah pengeluaran',
    请输入新支付密码6位数字: 'Sila masukkan kata laluan pembayaran baharu, 6 digit',
    请输入新支付密码: 'Sila masukkan kata laluan pembayaran baharu',
    请输入银行卡号: 'Sila masukkan nombor kad bank',
    请输入原支付密码: 'Sila masukkan kata laluan pembayaran asal',
    请输入支付密码6位数字: 'Sila masukkan kata laluan pembayaran, 6 digit',
    请输入支付密码: 'Sila masukkan kata laluan pembayaran',
    请选择币种: 'Sila pilih mata wang',
    请选择大洲: 'Sila pilih benua',
    请选择结束时间: 'Sila pilih tarikh tamat',
    请选择开始时间: 'Sila pilih tarikh mula',
    请选择收款码类型: 'Sila pilih jenis kod pembayaran',
    请选择银行: 'Sila pilih nama bank',
    请选择银行名称: 'Sila masukkan kata laluan pembayaran baharu sekali lagi',
    请再次输入新支付密码: 'Sila masukkan kata laluan pembayaran baharu sekali lagi',
    请再次输入支付密码: 'Sila masukkan kata laluan pembayaran sekali lagi',
    去设置: 'Pergi ke tetapan',
    全部提现: 'Tarik balik semua',
    确定删除收款码吗: 'Adakah anda pasti anda sedang memadamkan kod pembayaran',
    确定删除银行卡吗: 'Adakah anda pasti memadamkan kad bank',
    确定提现: 'Sahkan pengeluaran',
    确认打款: 'Sahkan pembayaran',
    确认新密码: 'Sila masukkan kata laluan pembayaran baharu sekali lagi',
    确认支付密码: 'Sahkan kata laluan pembayaran',
    上传的收款二维码需要清晰无遮挡: 'Kod QR pembayaran yang dimuat naik perlu jelas dan tidak terhalang',
    审核备注: 'Nota Semakan',
    审核不通过: 'Gagal lulus semakan',
    审核结果: 'Keputusan audit',
    审核通过: 'peperiksaan lulus',
    审核信息: 'Maklumat audit',
    时间: 'masa',
    实际到账金额: 'Jumlah sebenar diterima',
    收款方式: 'Kaedah pembayaran',
    收款管理: 'Pengurusan Resit',
    收款码: 'kod resit',
    收款码管理: 'Pengurusan kod resit',
    收款码类型: 'jenis kod resit',
    收款名称: 'Nama resit',
    收款账号: 'Akaun resit',
    手续费: 'Yuran pengeluaran',
    提现: 'menarik diri',
    提现币种: 'Mata wang pengeluaran',
    提现成功: 'Pengeluaran yang berjaya',
    提现记录: 'Rekod pengeluaran',
    提现金额: 'Jumlah Pengeluaran',
    提现失败: 'Pengeluaran gagal',
    提现时间: 'Masa pengeluaran',
    提现手续费: 'Yuran pengeluaran',
    提现详情: 'Butiran pengeluaran',
    提现中: 'Mengeluarkan',
    提现状态: 'Status pengeluaran',
    添加收款码: 'Tambah kod pembayaran',
    添加银行卡: 'Tambah kad bank',
    退款: 'Bayar Balik',
    忘记支付密码: 'Terlupa kata laluan pembayaran anda?',
    尾号: 'Nombor ekor',
    我的钱包: 'dompet saya',
    我的余额: 'Baki saya',
    详情: 'butiran',
    消费: 'penggunaan',
    新支付密码: 'Kata laluan pembayaran baharu',
    修改支付密码: 'Tukar kata laluan pembayaran',
    选择收款方式: 'Pilih kaedah pembayaran',
    银行卡: 'kad bank',
    银行卡管理: 'Pengurusan Kad Bank',
    银行卡号: 'Nombor kad bank',
    银行名称: 'Nama bank',
    余额记录: 'Rekod baki',
    余额数据: 'Data baki',
    原支付密码: 'Kata laluan pembayaran asal',
    暂无数据: 'Tiada data',
    支持币种: 'Mata wang sokongan',
    支付密码: 'Kata laluan pembayaran',
    只可上传一张图片图片大小不超过5M支持jpgpngjpeg格式文件: 'Hanya satu gambar boleh dimuat naik dan saiz gambar tidak melebihi 5M dan jpg, png dan jpeg fail format disokong',
    账户余额: 'Baki akaun',
    钱包服务: 'Perkhidmatan dompet',
    每天最多提现x次单次最高提现金额xx最低提现金额xx: 'Jumlah pengeluaran maksimum setiap hari ialah {num} kali, jumlah pengeluaran maksimum setiap masa ialah {icon}{money}, dan jumlah pengeluaran minimum ialah {icon}{money2}',
    确认: 'mengesahkan',
    输入金额超过可提现余额: 'Jumlah input melebihi baki yang boleh dikeluarkan',
    日期选择: 'pemilihan tarikh',
    备注: 'Teguran',
    当前没有余额记录: 'Pada masa ini tiada rekod baki!',
    当前没有提现记录: 'Pada masa ini tiada rekod pengeluaran!',
    还没有绑定银行卡: 'Belum ada kad bank yang diikat!',
    还没有添加收款码: 'Tiada kod pembayaran telah ditambahkan lagi!',
    提示只可上传一张图片图片大小不超过5M支持xxx格式文件上传的收款二维码需要清晰无遮挡: 'Petua: Hanya satu gambar boleh dimuat naik, saiz gambar tidak boleh melebihi 5M dan fail format jpg, png dan jpeg disokong. Kod QR pembayaran yang dimuat naik mestilah jelas dan tidak terhalang.',
    请上传收款码: 'Sila muat naik kod pembayaran',
    请输入收款码名称: 'Sila masukkan nama kod pembayaran',
    暂无银行卡: 'Belum ada kad bank',
    余额: 'Seimbang',
    重置: 'set semula',
    暂无收款码: 'Tiada kod pembayaran lagi',
    保障支付安全: 'Memastikan keselamatan pembayaran',
    打款成功: 'Pembayaran berjaya',
    请选择收款方式: 'Sila pilih kaedah pembayaran',
    收款类型: 'Jenis pembayaran',
    编辑银行卡: 'Edit kad bank',
    编辑收款码: 'Edit kod pembayaran',
    收款码名称: 'Nama kod pembayaran',
    订单ID: 'nombor pesanan',
    如果您已支付成功请点击确认支付按钮: 'Jika pembayaran anda telah berjaya, sila klik butang "Sahkan Pembayaran"',
    确认支付: 'Sahkan Pembayaran',
    支付遇到问题: 'Menghadapi masalah',
    对不起您访问的店铺可能已被删除名称被更改或不存在: 'Maaf, kedai yang anda lawati mungkin telah dipadamkan, namanya telah ditukar atau mungkin tidak wujud...',
    卢比支付: 'pembayaran IDR',
    比索支付: 'pembayaran PHP',
    泰铢支付: 'pembayaran THB',
    越南盾支付: 'pembayaran VND',
    银行卡编码: 'Kod bank',
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-0": "{currencyUnit}{money}",
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-1": "berat pertama{num}kg{currencyUnit}{money}，sambungan{num2}kg{currencyUnit}{money2}",
    "运费x首重xkgx续重xkgx首件x件x续件x件x-fareType-2": "item pertama{num3}keping{currencyUnit}{money3}，sambungan{num4}keping{currencyUnit}{money4}",
    暂无配送地区: 'Tiada kawasan penghantaran lagi',
    该商品在当前地区暂不支持配送: 'Produk ini tidak menyokong penghantaran di rantau semasa pada masa ini',
    当前商品仅支持以下地区发货如有疑问请联系店铺:
        'Produk semasa hanya menyokong penghantaran ke kawasan berikut. Jika anda mempunyai sebarang pertanyaan, sila hubungi kedai',
    运费计价说明: 'Arahan harga pengangkutan',
    发货地: 'tempat penghantaran',
    配送至: 'Hantar kepada',
    打款中: 'Pembayaran sedang dijalankan',
    未添加地址请先添加: 'Tiada alamat ditambahkan, sila tambahkannya dahulu',
    请输入大于1的正整数: 'Sila masukkan integer positif lebih daripada 1',
    设置密码: 'tetapkan kata laluan',
    登录密码: 'kata laluan log masuk',
    长度在8到16个字符: 'Panjang 8 hingga 16 aksara',
    请输入旧密码: 'Sila masukkan kata laluan lama',
    必须包含两种字符: 'Mesti mengandungi dua aksara',
    当前仅支持xxx的金额: 'Pada masa ini hanya menyokong jumlah daripada {currency} {min}-{currency} {max}',
    查看运费: 'Lihat kos penghantaran',
    运费方式: 'Cara penghantaran',
    价格: 'harga',
    请选择配送方式: 'Sila pilih kaedah penghantaran',
    配送方式: 'Kaedah Penyampaian',
    配送费说明: 'Penerangan yuran penghantaran',
    我明白了: 'saya faham',
    若多个商品选用同一个运费模板则运费是按同一个运费方式的规则进行计算: 'Jika berbilang produk menggunakan templat tambang yang sama, tambang akan dikira mengikut peraturan kaedah tambang yang sama.',
    若多个商品不是同一个运费模板则运费是多个模板的价格之和: 'Jika berbilang produk tidak mempunyai templat yuran penghantaran yang sama, yuran penghantaran ialah jumlah harga bagi berbilang templat.',
    快捷登录登录即表示同意协议: 'Dengan log masuk anda bersetuju menerima perjanjian itu',
    促销广告: 'Pengiklanan promosi',
    最近新品: 'Produk baru',
    更多: 'Lagi',
    设置成功: 'Persediaan berjaya',
    更多商品:'Lebih banyak produk',


    //     1.6
    我的优惠券:'Kupon diskaun saya',
    历史记录:'Rekod sejarah',
    已使用:'terpakai',
    已过期:'tamat tempoh',
    优惠券:'kupon',
    领取:'Menerima',
    已领取:'Menerima',
    有效期:'Tempoh sah：',
    活动优惠详情:'Butiran diskaun acara',
    可用商品:'Item yang ada',
    单独购买:'Harga asal',
    发起拼团:'Harga kumpulan',
    拼团价:'Harga kumpulan',
    可参与的拼团:'Pembelian berkumpulan tersedia',
    '再邀X人即可拼单成功':'Kongsikan dengan {num} orang lagi untuk tempahan yang berjaya>',
    拼单详情:'Butiran pembelian kumpulan',
    待分享:'menunggu untuk berkongsi',
    '若拼团失败，货款将原路退回':'Jika pembelian kumpulan gagal, bayaran akan dikembalikan ke laluan asal.',
    促销价:'Harga jualan',
    距离结束时间:'End in',
    满XX减XX:"Belanja {currencyUnit}{priceStart} dapat {currencyUnit}{reducePrice}{couponType==2?'%':''} potongan harga",
    满XX减X:'Belanja {currencyUnit}{priceStart} dapat {reducePrice}% potongan harga',
    分享好友X人成团立省X:'Belian berkumpulan untuk <span style="color: #8D4BBB;">{num}</span>orang, jimat<span style="color: #8D4BBB;">{currencyUnit}{groupPrice}</span>',
    分享好友:'Kongsi dengan kawan-kawan',
    '这些人正在拼，立即参与 ':'Pembelian kumpulan sedang dijalankan, sertai sekarang',
    满x可用:'Tersedia untuk pesanan melebihi {currencyUnit}{priceStart}',
    这些人正在拼立即参与:'Pembelian kumpulan sedang dijalankan, sertai sekarang ',
    限领x张:'{receiveCount} kupon setiap had pengguna',
    满XX送赠品:'Hadiah percuma untuk tempahan melebihi {currencyUnit}{priceStart}',
    去使用:'untuk menggunakan',
    销量:'Jualan',
    赠品:'Memberi',
    限时优惠:'Tawaran Masa Terhad',
    距结束:'End in',
    拼团好物:'Beli Berkumpulan',
    当前优惠券可以购买以下商品:'Kupon semasa boleh membeli produk berikut:',
    以下商品:'Produk berikut',
    满XX减XX可用:"Belanja {currencyUnit}{priceStart} dapat {currencyUnit}{reducePrice} potongan harga",
    满XX减X可用:'Belanja {currencyUnit}{priceStart} dapat {reducePrice}% potongan harga',
    再买x可用:'Masih perlu membeli {currencyUnit}{difPrice}',
    合计:'Jumlah',
    优惠:'Diskaun',
    去购物车:'Troli beli-belah',
    拼团:'Beli Berkumpulan',
    满XX可用:'Tersedia untuk pesanan melebihi {currencyUnit}{priceStart}',
    满减满赠:'Diskaun penuh/hadiah penuh',
    成团发货:'Tunggu penghantaran',
    还差xx人成团:'Masih memerlukan <span style="color: #FF4545;">{num}</span> orang untuk membuat tempahan dengan jayanya',
    拼团结束:'Pembelian kumpulan tamat',
    拼团成功:'Pembelian kumpulan berjaya',
    有成员未付款请等待付款成团:'Ada yang belum bayar, sila tunggu pembayaran',
    我正在参与的拼团:'Mengambil bahagian dalam pembelian berkumpulan',
    满减:'Diskaun penuh',
    满赠:'Hadiah penuh',
    凑单:'Buat pesanan bersama',
    切换其他优惠:'Pilih tawaran lain',
    超过最大购买数:'Melebihi bilangan pembelian maksimum',
    商品限购x件:'Hadkan {num} keping',
    参与拼团:'Sertai',
    拼单失败:'Pembelian kumpulan gagal',

    结束:'结束',
    您还没有优惠券:'Anda tidak mempunyai kupon',
    优惠券历史记录:'Rekod sejarah',
    免费:'Percuma',
    已赠完:'kehabisan stok',
    此拼团已满请选择其他拼团:'Pembelian kumpulan ini penuh, sila pilih pembelian kumpulan lain',
    以下商品正在限时优惠当中:'Tawaran masa terhad untuk produk berikut',
    以下商品正在限时拼团当中:'Pembelian kumpulan masa terhad bagi produk berikut',
    再买X可减X:'Beli {currencyUnit}{difPrice}  lagi dan dapatkan potongan {currencyUnit}{reducePrice}',
    已领完:"Digunakan",
    活动未开始:'Acara belum bermula',

    已满X可减X:'Dapatkan potongan {currencyUnit}{reducePrice} apabila anda membelanjakan {currencyUnit}{difPrice} atau lebih',
    已满X可减XX:'Dapatkan potongan {reducePrice}% apabila anda membelanjakan {currencyUnit}{difPrice} atau lebih',

    再买X可减XX:'Beli {currencyUnit}{difPrice} lagi dan dapatkan potongan {reducePrice}%',
    再买X送赠品:'Beli lagi {currencyUnit}{difPrice} dan dapat hadiah percuma',
    已满X送赠品:'Hadiah telah diberikan',
    缺少库存: 'kehabisan stok',
    限X件:'Had pembelian {num} keping',
    房间号已失效请联系工作人员:'Nombor bilik telah tamat tempoh, sila hubungi kakitangan!',



    退货积分:'Pulangan mata',
    积分:'integral',
    用户注册:'Pendaftaran pengguna',
    手动增加:'Meningkatkan secara manual',
    积分兑换退款:'Pemulangan mata',
    下单购物:'Buat pesanan untuk membeli-belah',
    过期积分:'Mata tamat tempoh',
    兑换商品:'Tebus barang',
    手动减少:'Pengurangan manual',
    订单退款:'Bayaran balik pesanan',
    积分明细:'Butiran mata',
    已兑:'Sudah ditebus',
    积分商城:'Points Mall',
    规则:'peraturan',
    可用积分:'Mata Tersedia',
    冻结积分:'titik beku',
    我的兑换:'pertukaran saya',
    售后:'Selepas jualan',
    分销中心:'Pusat pengagihan',
    立即兑换:'Tebus sekarang',
    已兑完:'Sudah ditebus',
    "积分不足，无法兑换":'Mata yang tidak mencukupi tidak boleh ditebus',
    兑换数量:'Kuantiti tukaran',
'限兑*件':'Item penebusan terhad{num}',
    积分抵扣:'Potongan mata',
'共*分':'Jumlah {num} mata',
    全部订单:'Semua pesanan',

兑换须知:'Arahan penebusan',
    已达兑换上限:'Had penebusan telah dicapai',
    我的积分:'Markah saya',
    积分规则:'Peraturan mata',
    兑换时间:'Masa penebusan',
    我的佣金:'komisen saya',
    佣金数据:'Data komisen',
    分销申请协议:'Perjanjian Permohonan Pengedaran',
    申请成为分销员:'Mohon untuk menjadi pengedar',
    "已申请，请等待审核":'Sudah memohon, sila tunggu untuk semakan',
    "审核拒绝，继续申请":'Ditolak melalui semakan, teruskan memohon',
    可提佣金:'Komisen pengeluaran',
    冻结佣金:'Membekukan komisen',
    分销订单:'Perintah pengedaran',
    佣金明细:'Butiran komisen',
    我的下级:'orang bawahan saya',
    推广商品:'Mempromosikan produk',
    推广店铺:'Promosikan kedai',
    暂无记录:'Tiada rekod',
    一级佣金收入:'Pendapatan komisen peringkat pertama',
    一级佣金退款:'Bayaran balik komisen peringkat pertama',
    二级佣金收入:'Pendapatan komisen sekunder',
    二级佣金退款:'Bayaran balik komisen peringkat kedua',
    下单用户:'Pengguna meletakkan pesanan',
    订单总价:'Jumlah harga pesanan',
    分销利润层级:'Tahap pengedaran',
    结算状态:'status penyelesaian',
    佣金:'komisen',
    一级:'Tahap 1',
    二级:'Tahap 2',
    已结算:'Selesai',
    请输入用户昵称或手机号:'Sila masukkan nama panggilan pengguna atau nombor telefon mudah alih',
    二级团队:'Pasukan peringkat kedua',
    分享链接:'kongsi pautan',
    可推广至:'Boleh dipanjangkan ke',
    分享最高可赚x:'Dapatkan sehingga {icon}{num} dengan berkongsi',
    分享推广:'Kongsi dan promosikan',
    用户昵称或手机号:'Nama panggilan pengguna atau nombor telefon bimbit',
    商品名称:'Nama Produk',
    最高可得:'Tertinggi tersedia',
    可获得积分:'mata yang diperolehi',
    总获佣金:'Jumlah komisen yang diperolehi',

    佣金详情:'Butiran komisen',
    已退款:'dikembalikan',
    请输入商品名称:'Sila masukkan nama produk',
    请选择银行卡: 'Sila pilih kad bank',
    请选择收款码: 'Sila pilih kod pembayaran',
    不能超过最小提现额度:'Jumlah pengeluaran minimum tidak boleh melebihi!',


    货柜商品:'Barang bekas',
    该浏览器不支持蓝牙:'Penyemak imbas ini tidak menyokong Bluetooth, sila salin pautan ke Chrome, pelayar Internet Samsung untuk membuka',
    iOS系统暂不支持售货机取货请使用Android系统扫码购物:'Sistem iOS pada masa ini tidak menyokong pengambilan mesin layan diri Sila gunakan sistem Android untuk mengimbas kod QR untuk membeli-belah.',
    去商城首页:'Pergi ke laman utama pusat membeli-belah',
    已售完:'habis terjual',
    蓝牙未连接:'Bluetooth tidak disambungkan',
    蓝牙已连接:'Bluetooth disambungkan',
    重试:'Cuba lagi',
    蓝牙未授权请打开蓝牙后重试:'Bluetooth tidak dibenarkan, sila hidupkan Bluetooth dan cuba lagi!',
    蓝牙未连接请选择蓝牙并配对:'Bluetooth tidak disambungkan, sila pilih Bluetooth dan pasangkan!',
    连接:'menyambung',
    蓝牙已断开请重新连接:'Bluetooth telah diputuskan, sila sambung semula',
    蓝牙连接中:'Sambungan Bluetooth...',
    购买成功请取出商品若柜门未打开请尝试再次打开:'Pembelian berjaya, sila keluarkan produk! Jika pintu kabinet tidak dibuka, sila cuba buka semula!',
    再次打开:'Buka semula',
    普通订单:'Pesanan biasa',
    售货机:'mesin layan diri',
    售货机已无货无法支付:'Mesin layan diri kehabisan stok dan pembayaran tidak boleh dibuat.',
    订单来源:'Sumber pesanan',
    连接失败请重新连接:'Sambungan gagal, sila sambung semula',
    售罄:'habis dijual',
    '房间号/桌号':'Nombor bilik/nombor meja'
}
// 马来西亚
