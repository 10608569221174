
import Vue from 'vue'
import Vuex from 'vuex'
import user from './module/User'
import shoppingcar from './module/shoppingcar'
import order from './module/order'
import locshoppingcar from './module/locshoppingcar'
import getters from './module/getters'
import loginfalse from './module/loginfalse'
import bluetooth from "./module/bluetooth";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    namespaced: true, // 为了解决不同模块命名冲突的问题
    user,
    order,
    shoppingcar,
    locshoppingcar,
    loginfalse,
    bluetooth
  },
  getters
})
