<template>
  <div>
    <div v-if="storeIndexInfo?.storeIndexInfo?.themeId==1">
      <van-empty image="search" :description="title" v-if="list.length == 0"/>
      <van-list
          v-model="isLoading"
          :finished="isFinished"
          :finished-text="source=='home'?'':$t('没有更多了')"
          @load="onLoad"
          v-else
      >
        <template v-if="(activityName=='fullReductionFullGiftCar'||activityName=='limitedTimeDiscount'||activityName=='teamwork'||activityName=='fullReductionFullGift')&&source=='home'">
          <div class="list4 row-s">
            <div
                class="li"
                v-for="(item, index) in list"
                :key="index"
                @click="goDetails(item)"
            >
              <div style="height: 1.65rem;overflow: hidden">
                <van-image  class="shopImg" :src="item.goodImg"    fit="cover" />
              </div>
<!--              <van-image-->
<!--                  class="shopImg"-->
<!--                  lazy-load-->
<!--                  :src="$getImageUrl(item.goodImg,'165','165','webp')"-->

<!--              />-->
              <div class="li-info">
                <div class="discount" v-if="source=='activity'&&activityName=='limitedTimeDiscount'">
                  <div class="discountNum">{{ parseInt(item.discount*1)}}%</div>
                  <div class="discountNum">OFF</div>
                </div>
                <div class="title ellipsis2" v-if="source=='activity'">{{ item.goodsName }}</div>
                <div class="row-b" style=" margin-top: 0.05rem;">
                  <div class="money">
                <span>{{ currencyUnit }}</span
                >{{ $formatNumber(activityName=='teamwork'?item.groupPrice:activityName=="limitedTimeDiscount"?item.discountPrice:item.goodsPrice) }}
                  </div>
                  <div v-if="source=='activity'&&activityName=='teamwork'" style="padding: 0 .09rem;height: .21rem;background: #f3edf8;border-radius: .04rem;text-align: center;line-height: .21rem;color: #8D4BBB;font-size: .12rem">
                    {{ $t('拼团价') }}</div>
                </div>

                <div class="row-b" style="margin-top: .05rem">
                  <div class="money1" v-if="item.sellingPrice">
                <span>{{ currencyUnit }}</span
                >{{ $formatNumber(item.sellingPrice) }}
                  </div>
                  <!--                  <img src="@/assets/addToShoppingCart.png" style="width: .2rem;height: .15rem;flex-shrink: 0" v-if="active" />-->
                </div>
                <div class="row-c">
                  <div style="height: .15rem;width: 80%;background: #8D4BBB;border-radius: .1rem;margin-top: .05rem" class="row-c">
                    <div class="num" style="font-size: .11rem;color: #fff">{{ $t('销量') }} {{item.num?item.num:item.sales?item.sales:0}}</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </template>
        <template v-else-if="(activityName=='limitedTimeDiscount'||activityName=='teamwork')&&source!='home'">
        <div class="list3 row-b">
          <div
              class="li"
              v-for="(item, index) in list"
              :key="index"
              @click="goDetails(item)"
          >
            <div style="height: 1.65rem;overflow: hidden">
              <van-image  class="shopImg" :src="item.goodImg"    fit="cover" />
            </div>
<!--            <van-image-->
<!--                class="shopImg"-->
<!--                lazy-load-->
<!--                :src="$getImageUrl(item.goodImg,'165','165','webp')"-->

<!--            />-->
            <div class="li-info">
              <div class="discount" v-if="source=='activity'&&activityName=='limitedTimeDiscount'">
                <div class="discountNum">{{ parseInt(item.discount*1)}}%</div>
                <div class="discountNum">OFF</div>
              </div>
              <div class="title ellipsis2" v-if="source=='activity'">{{ item.goodsName }}</div>
              <div class="row-b" style=" margin-top: 0.05rem;">
                <div class="money">
                <span>{{ currencyUnit }}</span
                >{{ $formatNumber(activityName=='teamwork'?item.groupPrice:item.discountPrice) }}
                </div>
                <div v-if="source=='activity'&&activityName=='teamwork'" style="padding: 0 .09rem;height: .21rem;background: #f3edf8;border-radius: .04rem;text-align: center;line-height: .21rem;color: #8D4BBB;font-size: .12rem">{{ $t('拼团价') }}</div>
              </div>

              <div class="row-b" style="margin-top: .05rem">
                <div class="money1" v-if="item.sellingPrice">
                <span>{{ currencyUnit }}</span
                >{{ $formatNumber(item.sellingPrice) }}
                </div>
<!--                <div class="num">销量{{item.num?item.num:0}}</div>-->
                <!--                  <img src="@/assets/addToShoppingCart.png" style="width: .2rem;height: .15rem;flex-shrink: 0" v-if="active" />-->
              </div>
              <div class="row-c">
                <div style="height: .15rem;width: 100%;background: #8D4BBB;border-radius: .1rem;margin-top: .05rem" class="row-c">
                  <div class="num" style="font-size: .11rem;color: #fff">{{ $t('销量') }} {{item.num?item.num:0}}</div>
                </div>
              </div>


            </div>
          </div>
        </div>
</template>
        <template v-else>
          <div class="list row-b" v-if="activityName=='fullReductionFullGiftCar'||activityName=='fullReductionFullGift'||activityName=='yhqcar'||activityName=='yhq'||!activityName"  :style="activityName=='fullReductionFullGiftCar'||activityName=='fullReductionFullGift'||activityName=='yhqcar'||activityName=='yhq'?'padding: 0.11rem 0 0;':''">
            <div
                class="li"
                v-for="(item, index) in list"
                :key="index"
                @click="goDetails(item)"
            >
              <div style="height: 1.65rem;overflow: hidden">
                <van-image  class="shopImg" :src="item.goodImg"    fit="cover" />
              </div>
              <div class="li-info">
                <div class="title ellipsis2">{{activityName=='fullReductionFullGiftCar'|| activityName=='fullReductionFullGift'?item.goodsName:item.title }}</div>
                <div class="row-b" style="margin-top:.1rem ">
                  <div class="money">
                <span>{{ currencyUnit }}</span
                >{{ $formatNumber(activityName=='fullReductionFullGiftCar'||activityName=='fullReductionFullGift'?item.goodsPrice:item.price) }}
                  </div>
                  <img src="@/assets/addToShoppingCart.png" @click.stop="jionShop(item)" style="width: .2rem;height: .15rem;flex-shrink: 0" v-if="activityName=='fullReductionFullGiftCar'||activityName=='yhqcar'||activityName=='yhq'||activityName=='fullReductionFullGift'" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </van-list>
    </div>
    <div v-if="storeIndexInfo?.storeIndexInfo?.themeId==2">
      <van-empty image="search" :description="title" v-if="list.length == 0"/>
      <van-list
          v-model="isLoading"
          :finished="isFinished"
          @load="onLoad"
          v-else
      >

        <template v-if="(activityName=='fullReductionFullGiftCar'||activityName=='limitedTimeDiscount'||activityName=='teamwork'||activityName=='fullReductionFullGift')&&source=='home'">
          <div class="list4 row-s">
            <div
                class="li"
                v-for="(item, index) in list"
                :key="index"
                @click="goDetails(item)"
            >
<!--              <van-image-->
<!--                  class="shopImg"-->
<!--                  lazy-load-->
<!--                  :src="$getImageUrl(item.goodImg,'165','165','webp')"-->

<!--              />-->
              <div style="height: 1.65rem;overflow: hidden">
                <van-image  class="shopImg" :src="item.goodImg"    fit="cover" />
              </div>
              <div class="li-info">
                <div class="discount" v-if="source=='activity'&&activityName=='limitedTimeDiscount'">
                  <div class="discountNum">{{ parseInt(item.discount*1)}}%</div>
                  <div class="discountNum">OFF</div>
                </div>
                <div class="title ellipsis2" v-if="source=='activity'">{{ item.goodsName }}</div>
                <div class="row-b" style=" margin-top: 0.05rem;">
                  <div class="money">
                <span>{{ currencyUnit }}</span
                >{{ $formatNumber(activityName=='teamwork'?item.groupPrice:activityName=="limitedTimeDiscount"?item.discountPrice:item.goodsPrice) }}
                  </div>
                  <div v-if="source=='activity'&&activityName=='teamwork'" style="padding: 0 .09rem;height: .21rem;background: #f3edf8;border-radius: .04rem;text-align: center;line-height: .21rem;color: #8D4BBB;font-size: .12rem">{{ $t('拼团价') }}</div>
                </div>

                <div class="row-b" style="margin-top: .05rem">
                  <div class="money1" v-if="item.sellingPrice">
                <span>{{ currencyUnit }}</span
                >{{ $formatNumber(item.sellingPrice) }}
                  </div>
                  <!--                  <img src="@/assets/addToShoppingCart.png" style="width: .2rem;height: .15rem;flex-shrink: 0" v-if="active" />-->
                </div>
                <div class="row-c">
                  <div style="height: .15rem;width: 100%;background: #8D4BBB;border-radius: .1rem;margin-top: .05rem" class="row-c">
                    <div class="num" style="font-size: .11rem;color: #fff">{{ $t('销量') }} {{item.num?item.num:0}}</div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </template>
        <template v-else-if="(activityName=='limitedTimeDiscount'||activityName=='teamwork')&&source!='home'">
          <div class="list3 row-b">
            <div
                class="li"
                v-for="(item, index) in list"
                :key="index"
                @click="goDetails(item)"
            >
<!--              <van-image-->
<!--                  class="shopImg"-->
<!--                  lazy-load-->
<!--                  :src="$getImageUrl(item.goodImg,'165','165','webp')"-->

<!--              />-->
              <div style="height: 1.65rem;overflow: hidden">
                <van-image  class="shopImg" :src="item.goodImg"    fit="cover" />
              </div>
              <div class="li-info">
                <div class="discount" v-if="source=='activity'&&activityName=='limitedTimeDiscount'">
                  <div class="discountNum">{{ parseInt(item.discount*1)}}%</div>
                  <div class="discountNum">OFF</div>
                </div>
                <div class="title ellipsis2" v-if="source=='activity'">{{ item.goodsName }}</div>
                <div class="row-b" style=" margin-top: 0.05rem;">
                  <div class="money">
                <span>{{ currencyUnit }}</span
                >{{ $formatNumber(activityName=='teamwork'?item.groupPrice:item.discountPrice) }}
                  </div>
                  <div v-if="source=='activity'&&activityName=='teamwork'" style="padding: 0 .09rem;height: .21rem;background: #f3edf8;border-radius: .04rem;text-align: center;line-height: .21rem;color: #8D4BBB;font-size: .12rem">{{ $t('拼团价') }}</div>
                </div>

                <div class="row-b" style="margin-top: .05rem">
                  <div class="money1" v-if="item.sellingPrice">
                <span>{{ currencyUnit }}</span
                >{{ $formatNumber(item.sellingPrice) }}
                  </div>
                  <!--                <div class="num">销量{{item.num?item.num:0}}</div>-->
                  <!--                  <img src="@/assets/addToShoppingCart.png" style="width: .2rem;height: .15rem;flex-shrink: 0" v-if="active" />-->
                </div>
                <div class="row-c">
                  <div style="height: .15rem;width: 100%;background: #8D4BBB;border-radius: .1rem;margin-top: .05rem" class="row-c">
                    <div class="num" style="font-size: .11rem;color: #fff">{{ $t('销量') }} {{item.num?item.num:item.sales?item.sales:0}}</div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="list row-b" v-if="activityName=='fullReductionFullGiftCar'||activityName=='fullReductionFullGift'||activityName=='yhqcar'||activityName=='yhq'||!activityName"  :style="activityName=='yhqcar'||activityName=='yhq'||activityName=='fullReductionFullGift'?'padding: 0.11rem 0 0;':''">
            <div
                class="li"
                v-for="(item, index) in list"
                :key="index"
                @click="goDetails(item)"
            >
<!--              <van-image-->
<!--                  class="shopImg"-->
<!--                  lazy-load-->
<!--                  :src="$getImageUrl(item.goodImg,'165','165','webp')"-->

<!--              />-->
              <div style="height: 1.65rem;overflow: hidden">
                <van-image  class="shopImg" :src="item.goodImg"    fit="cover" />
              </div>
              <div class="li-info">
                <div class="title ellipsis2">{{ activityName=='fullReductionFullGiftCar'||activityName=='fullReductionFullGift'?item.goodsName:item.title }}</div>
                <div class="row-b" style="margin-top:.1rem ">
                  <div class="money">
                <span>{{ currencyUnit }}</span
                >{{ $formatNumber(activityName=='fullReductionFullGiftCar'||activityName=='fullReductionFullGift'?item.goodsPrice:item.price) }}
                  </div>
                  <img src="@/assets/addToShoppingCart.png" @click.stop="jionShop(item)" style="width: .2rem;height: .15rem;flex-shrink: 0" v-if="activityName=='fullReductionFullGiftCar'||activityName=='yhqcar'||activityName=='yhq'||activityName=='fullReductionFullGift'" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </van-list>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters(['currencyUnit'])
  },
  name: 'goodList',
  props: {
    list: Array,
    loading: Boolean,
    finished: Boolean,
    title: String,
    type: Number,
    active:Boolean,
    activityName:String,
    source:String,
    teamworkID:String
  },
  watch: {
    finished(newVal) {
      // 监听 finished 数据的变化
      this.isFinished = newVal
    },
    loading(newVal) {
      // 监听 finished 数据的变化
      this.isLoading = newVal
    }
  },
  data() {
    return {
      isLoading: this.loading,
      isFinished: this.finished,
      // storeIndexInfo: JSON.parse(localStorage.getItem('storeIndexInfo')),
      storeIndexInfo:{
        storeIndexInfo:{themeId:1}
      }
    }
  },

  methods: {
    onLoad() {
      // 加载状态结束
      this.isLoading = false
      this.$emit('getList')
    },
    jionShop(item){
      this.$emit('jionShop',item)
    },
    goDetails(item) {
      sessionStorage.setItem('home', this.type)
      if(this.$route.query.activeName||this.activityName){
        this.$router.push({
          path: '/productDetails',
          query: {value: item.id, storeId: localStorage.getItem('storeID'),teamworkID:this.teamworkID,activeName:this.$route.query.activeName,id:this.$route.query.id,source:this.source}
        })
      } else {
        this.$router.push({
          path: '/productDetails',
          query: {value: item.id, storeId: localStorage.getItem('storeID')}
        })
      }

    }
  }
}
</script>

<style scoped lang="scss">
/deep/ .van-empty {
  height: 44vh;
}
.list4 {
  padding: 0.1rem 0 0;
  flex-wrap: wrap;
  box-sizing: border-box;
  .li {
    flex-shrink: 0;
    width: 30%;
    // height: 2.82rem;
    border-radius: 0.05rem;
    background: #fff;
    margin-bottom: 0.075rem;
    margin-right: .1rem;

    .shopImg {
      width: 100%;
      // height: 1.65rem;
      object-fit: cover;
      border-radius: 0.05rem 0.05rem 0 0;
      border-top: 0.01rem solid #F3F3F3;
      border-left: 0.01rem solid #F3F3F3;
      border-right: 0.01rem solid #F3F3F3;
    }

    .li-info {
      border-radius: 0 0 0.05rem 0.05rem;
      padding: 0.05rem 0.05rem .12rem;
      border: .01rem solid #F3F3F3;
      border-top: none;
      position: relative;
      // height: 1rem;
      .discount{
        position: absolute;
        right: .14rem;
        bottom: .36rem;
        width: .5rem;
        height: .34rem;
        text-align: center;
        background: rgba(254, 39, 37, .1);
        font-family: PingFang SC;
        font-weight: 500;
        font-size: .12rem;
        border-radius: .08rem;
        color: #FE2725;
      }
      .discount::after {
        content: "";
        position: absolute;
        top: .15rem; /* 将三角形定位到父元素底部 我就是吊毛，我为刘俊鹏代言*/
        left: -0.05rem; /* 将三角形定位到父元素中间 */

        border-top: 0.05rem solid transparent; /* 左侧透明 */
        border-bottom: 0.05rem solid transparent; /* 右侧透明  我就是吊毛，我为刘俊鹏代言*/
        border-right: 0.05rem solid #ffe9e9; /* 底部为实线  我就是吊毛，我为刘俊鹏代言*/
      }
      .title {
        color: #384358;
        font-size: 0.16rem;
        font-weight: 500;
        height: .3rem;
        line-height: .16rem;
      }

      .money1 {
        text-decoration: line-through; /* 添加划线  我就是吊毛，我为刘俊鹏代言*/
        //margin-top: 0.1rem;
        font-size: .10rem;
        color: #666;

        span {
          font-size: 0.10rem;
        }
      }

      .money {

        font-size: 0.15rem;
        color: #ff4545;
        font-width: 700;
        span {
          font-size: 0.10rem;
        }
      }
    }
  }
}
.list3 {
  padding: 0.1rem 0 0;
  flex-wrap: wrap;
  box-sizing: border-box;
  .li {
    flex-shrink: 0;
    width: 49%;
    // height: 2.82rem;
    border-radius: 0.05rem;
    background: #fff;
    margin-bottom: 0.075rem;


    .shopImg {
      width: 100%;
       //height: 1.65rem;
      object-fit: cover;
      border-radius: 0.05rem 0.05rem 0 0;
    }

    .li-info {
      border-radius: 0 0 0.05rem 0.05rem;
      padding: 0.1rem 0.14rem .12rem;
      border: .01rem solid #F3F3F3;
      border-top: none;
      position: relative;
      // height: 1rem;
      .discount{
        position: absolute;
        right: .14rem;
        bottom: .35rem;
          width: .5rem;
        height: .34rem;
        text-align: center;
        background: rgba(254, 39, 37, .1);
        font-family: PingFang SC;
        font-weight: 500;
        font-size: .13rem;
        border-radius: .08rem;
        color: #FE2725;
      }
      .discount::after {
        content: "";
        position: absolute;
        top: .15rem; /* 将三角形定位到父元素底部 */
        left: -0.05rem; /* 将三角形定位到父元素中间 */

        border-top: 0.05rem solid transparent; /* 左侧透明 */
        border-bottom: 0.05rem solid transparent; /* 右侧透明 */
        border-right: 0.05rem solid #ffe9e9; /* 底部为实线 */
      }
      .title {
        color: #384358;
        font-size: 0.16rem;
        font-weight: 500;
        height: .3rem;
        line-height: .16rem;
      }

      .money1 {
        text-decoration: line-through; /* 添加划线 */
        //margin-top: 0.1rem;
        font-size: .12rem;
        color: #666;

        span {
          font-size: 0.12rem;
        }
      }

      .money {

        font-size: 0.16rem;
        color: #ff4545;

        span {
          font-size: 0.12rem;
        }
      }
    }
  }
}

.list2 {
  padding: 0.1rem 0.19rem 0;
  flex-wrap: wrap;
  box-sizing: border-box;
  .li {
    flex-shrink: 0;
     width: 49%;
    // height: 2.82rem;
    border-radius: 0.05rem;
    background: #fff;
    margin-bottom: 0.075rem;


    .shopImg {
      width: 100%;
      // height: 1.65rem;
      object-fit: cover;
      border-radius: 0.05rem 0.05rem 0 0;
    }

    .li-info {
      border-radius: 0 0 0.05rem 0.05rem;
      padding: 0.1rem 0.14rem .12rem;
      border: .01rem solid #F3F3F3;
      border-top: none;
      // height: 1rem;

      .title {
        color: #384358;
        font-size: 0.14rem;
        font-weight: 500;
        height: .3rem;
        line-height: .15rem;
      }

      .money1 {
        text-decoration: line-through; /* 添加划线 */
        margin-top: 0.05rem;
        font-size: 0.12rem;
        color: #666;

        span {
          font-size: 0.12rem;
        }
      }

      .money {
        //margin-top: 0.1rem;
        font-size: 0.16rem;
        color: #ff4545;

        span {
          font-size: 0.12rem;
        }
      }
    }
  }
}

.list {
  padding: 0.11rem 0.19rem 0;
  flex-wrap: wrap;
box-sizing: border-box;
  .li {
    width: 49%;
    // height: 2.6rem;
    border-radius: 0.05rem;
    background: #fff;
    margin-bottom: 0.075rem;

    .shopImg {
      width: 100%;
      // height: 1.65rem;
      object-fit: cover;
      border-radius: 0.05rem 0.05rem 0 0;
    }

    .li-info {
      border-radius: 0 0 0.05rem 0.05rem;
      padding: 0.1rem 0.14rem ;

      .title {
        color: #384358;
        font-size: 0.14rem;
        font-weight: 500;
        height: .3rem;
        line-height: .15rem;
        // height: .38rem;
      }

      .money {
        //margin-top: 0.1rem;
        font-size: 0.16rem;
        color: #ff4545;

        span {
          font-size: 0.12rem;
        }
      }
    }
  }
}
</style>
