<template>
  <!-- Vue 模板部分 -->
  <div class="home">
    <homeNavBar :type="1" :storeIndexInfo="storeIndexInfo"></homeNavBar>
    <div v-if="storeIndexInfo?.storeIndexInfo?.themeId == 1">
      <van-swipe :autoplay="3000" class="swipe">
        <van-swipe-item
            v-for="(image, index) in Bannerlist"
            :key="index"
            @click.stop="H5banner(image)"
        >
          <!-- <img :src="$getImageUrl(image.imgUrl, 375, 200, 'webp')" class="swipe-img" /> -->
          <van-image
              :src="$getImageUrl(image.imgUrl, 375, 200, 'webp')"
              class="swipe-img"
          />
        </van-swipe-item>
      </van-swipe>
      <div class="limitedTimeDiscount" v-for="(item,index) in limitedTimeActivities" :key="index" v-if="item?.userDiscountGoodsDTOList?.records?.length>0">
        <div class="top row-b">
          <div class="row-s" >
            <div class="row-s" style="position: relative;margin-right: .05rem">
              <div class="label">
                {{$t('限时优惠')}}
              </div>
              <div class="line"></div>
            </div>
          </div>
          <div class="row-s" @click="goActive(item.id,'limitedTimeDiscount')">
<!--            <div class="txt"> {{$t('更多')}}</div>-->
            <van-count-down :time="item.timeDiff" format="DD:HH:mm:ss">
              <template #default="timeData">
                <span style="margin-right: .02rem" v-if="timeData.days>0">{{timeData.days}} {{$t('天')}}</span>
                <span class="block">{{ timeData.hours>=10?timeData.hours:'0'+timeData.hours }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.minutes>=10?timeData.minutes:'0'+timeData.minutes }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.seconds>=10?timeData.seconds:'0'+timeData.seconds }}</span>
              </template>
            </van-count-down>
            <van-icon name="arrow" color="#909090" size="16" />
          </div>
        </div>
        <goodList
            :list="item.userDiscountGoodsDTOList.records"
            :finished="true"
            :loading="true"
            :type="0"
            :activityName="'limitedTimeDiscount'"
            :source="'home'"

        >
        </goodList>

      </div>
      <div class="limitedTimeDiscount" v-for="(item,index) in fullReductionFullGift" :key="index" v-if="item?.applicationFullReductionGoodsDTOList?.records?.length>0">
        <div class="top row-b">
          <div class="row-s" >
            <div class="row-s" style="position: relative;margin-right: .05rem">
              <div class="label ellipsis1" style="max-width: 2rem" v-if="item.type==1">{{$t('满XX减XX',{currencyUnit:item.applicationFullReductionPriceDTOList[0].icon,priceStart:item.applicationFullReductionPriceDTOList[0].fullPrice,reducePrice:item.applicationFullReductionPriceDTOList[0].reducePrice})}}</div>
              <div class="label ellipsis1" style="max-width: 2rem" v-if="item.type==2">{{$t('满XX送赠品',{currencyUnit:item.applicationFullReductionPriceDTOList[0].icon,priceStart:item.applicationFullReductionPriceDTOList[0].fullPrice})}}</div>
              <div class="line"></div>
            </div>
          </div>
          <div class="row-s" @click="goActive(item.id,'fullReductionFullGift')">
<!--            <div class="txt">{{$t('更多')}}</div>-->
            <van-count-down :time="item.timeDiff" format="DD:HH:mm:ss">
              <template #default="timeData">
                <span style="margin-right: .02rem" v-if="timeData.days>0">{{timeData.days}} {{$t('天')}}</span>
                <span class="block">{{ timeData.hours>=10?timeData.hours:'0'+timeData.hours }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.minutes>=10?timeData.minutes:'0'+timeData.minutes }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.seconds>=10?timeData.seconds:'0'+timeData.seconds }}</span>
              </template>
            </van-count-down>
            <van-icon name="arrow" color="#909090" size="16" />
          </div>
        </div>
        <goodList
            :list="item.applicationFullReductionGoodsDTOList.records"
            :finished="true"
            :loading="true"
            :type="0"
            :activityName="'fullReductionFullGift'"
            :source="'home'"

        >
        </goodList>

      </div>
      <div class="limitedTimeDiscount" v-for="(item,index) in teamwork" :key="index" v-if="item?.userGroupGoodsDTOIPage?.records?.length>0">
        <div class="top row-b">
          <div class="row-s" >
            <div class="row-s" style="position: relative;margin-right: .05rem">
              <div class="label">
                {{$t('拼团')}}
              </div>
              <div class="line"></div>
            </div>
          </div>
          <div class="row-s" @click="goActive(item.id,'teamwork')">
            <van-count-down :time="item.timeDiff" format="DD:HH:mm:ss">
              <template #default="timeData">
                <span style="margin-right: .02rem" v-if="timeData.days>0">{{timeData.days}} {{$t('天')}}</span>
                <span class="block">{{ timeData.hours>=10?timeData.hours:'0'+timeData.hours }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.minutes>=10?timeData.minutes:'0'+timeData.minutes }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.seconds>=10?timeData.seconds:'0'+timeData.seconds }}</span>
              </template>
            </van-count-down>
            <van-icon name="arrow" color="#909090" size="16" />
          </div>
        </div>
        <goodList
            :list="item.userGroupGoodsDTOIPage.records"
            :finished="true"
            :loading="true"
            :type="0"
            :activityName="'teamwork'"
            :source="'home'"
            :teamworkID="item.id"
        >
        </goodList>
      </div>
      <div class="liTitle row-s">
        <div class="line"></div>
        <div class="labe">{{ $t('推荐商品') }}</div>
      </div>
      <goodList
          :list="list"
          :loading="true"
          :finished="true"
          :type="0"
      ></goodList>
      <footBox></footBox>
    </div>
    <div v-if="storeIndexInfo?.storeIndexInfo?.themeId == 2">
      <div v-if="classificationArray.length != 0" class="swipeBox">
        <div class="labe">{{ $t('全部分类') }}</div>
        <van-swipe class="my-swipe" :loop="false" indicator-color="#8D4BBB">
          <van-swipe-item
              v-for="(item, index) in classificationArray"
              :key="index"
          >
            <div class="list row-s">
              <div
                  class="row-d li"

                  v-for="(it, idx) in item"
                  :key="idx"
                  @click="goClassification(it.id)"
              >
                <img :src="it.classUrl"/>
                <div class="txt">{{ it.nameZh }}</div>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div v-if="Bannerlist.length != 0" class="swipePromo">
        <div class="labe">{{ $t('促销广告') }}</div>
        <van-swipe
            class="my-swipe"
            indicator-color="#8D4BBB"
            @change="onChange"
        >
          <van-swipe-item
              v-for="(image, index) in Bannerlist"
              :key="index"
              @click.stop="H5banner(image)"

          >
            <img
                :src="$getImageUrl(image.imgUrl, 343, 200, 'webp')"

            />
          </van-swipe-item>
        </van-swipe>
      </div>
      <div v-if="latestProductsList.length != 0" class="NewProducts">
        <div class="row-b">
          <div class="labe">{{ $t('最近新品') }}</div>
          <!-- <div class="text">{{ $t('更多') }}</div> -->
        </div>
        <ScrollView style="height:3.5rem">
          <div class="list row-s">
            <div
                class="li"
                v-for="(item, index) in latestProductsList"
                :key="index"
                @click="goDetails(item)"
            >
              <img :src="item.goodImg"/>
              <div class="productsBox">
                <div class="productsName ellipsis1">
                  {{ item.title }}
                </div>
                <div class="productsDele ellipsis1">
                  <!-- Cama e banho | 2 lençois + 2 fronhas -->
                </div>
                <div class="money">
                  {{ item.price?.icon }}{{ item.price?.goodsPrice }}
                  <span v-if="item.price?.originalPrice&&item.isDistribute!=1"
                  >{{ item.price?.icon }}{{ item.price?.originalPrice }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </ScrollView>
      </div>
      <div class="limitedTimeDiscount" v-for="(item,index) in limitedTimeActivities" :key="index" v-if="item?.userDiscountGoodsDTOList?.records.length>0">
        <div class="top row-b">
          <div class="row-s" >
            <div class="row-s" style="position: relative;margin-right: .05rem">
              <div class="label">
                {{ $t('限时优惠') }}
              </div>
              <div class="line"></div>
            </div>


          </div>
          <div class="row-s" @click="goActive(item.id,'limitedTimeDiscount')">
            <van-count-down :time="item.timeDiff" format="DD:HH:mm:ss">
              <template #default="timeData">
                <span style="margin-right: .02rem" v-if="timeData.days>0">{{timeData.days}}{{$t('天')}}</span>
                <span class="block">{{ timeData.hours>=10?timeData.hours:'0'+timeData.hours }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.minutes>=10?timeData.minutes:'0'+timeData.minutes }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.seconds>=10?timeData.seconds:'0'+timeData.seconds }}</span>
              </template>
            </van-count-down>
            <van-icon name="arrow" color="#909090" size="16" />
          </div>
        </div>
        <goodList
            :list="item.userDiscountGoodsDTOList.records"
            :finished="true"
            :loading="true"
            :type="0"
            :activityName="'limitedTimeDiscount'"
            :source="'home'"

        >
        </goodList>

      </div>
      <div class="limitedTimeDiscount" v-for="(item,index) in fullReductionFullGift" :key="index" v-if="item?.applicationFullReductionGoodsDTOList?.records?.length>0">
        <div class="top row-b">
          <div class="row-s" >
            <div class="row-s" style="position: relative;margin-right: .05rem">
              <div class="label ellipsis1" style="max-width: 2rem" v-if="item.type==1">{{$t('满XX减XX',{currencyUnit:item.applicationFullReductionPriceDTOList[0].icon,priceStart:item.applicationFullReductionPriceDTOList[0].fullPrice,reducePrice:item.applicationFullReductionPriceDTOList[0].reducePrice})}}</div>
              <div class="label ellipsis1" style="max-width: 2rem" v-if="item.type==2">{{$t('满XX送赠品',{currencyUnit:item.applicationFullReductionPriceDTOList[0].icon,priceStart:item.applicationFullReductionPriceDTOList[0].fullPrice})}}</div>
              <div class="line"></div>
            </div>
          </div>
          <div class="row-s" @click="goActive(item.id,'fullReductionFullGift')">
            <!--            <div class="txt">{{$t('更多')}}</div>-->
            <van-count-down :time="item.timeDiff" format="DD:HH:mm:ss">
              <template #default="timeData">
                <span style="margin-right: .02rem" v-if="timeData.days>0">{{timeData.days}} {{$t('天')}}</span>
                <span class="block">{{ timeData.hours>=10?timeData.hours:'0'+timeData.hours }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.minutes>=10?timeData.minutes:'0'+timeData.minutes }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.seconds>=10?timeData.seconds:'0'+timeData.seconds }}</span>
              </template>
            </van-count-down>
            <van-icon name="arrow" color="#909090" size="16" />
          </div>
        </div>
        <goodList
            :list="item.applicationFullReductionGoodsDTOList.records"
            :finished="true"
            :loading="true"
            :type="0"
            :activityName="'fullReductionFullGift'"
            :source="'home'"

        >
        </goodList>

      </div>
      <div class="limitedTimeDiscount" v-for="(item,index) in teamwork" :key="index" v-if="item?.userGroupGoodsDTOIPage?.records.length>0">
        <div class="top row-b">
          <div class="row-s" >
            <div class="row-s" style="position: relative;margin-right: .05rem">
              <div class="label">

                {{ $t('拼团') }}
              </div>
              <div class="line"></div>
            </div>
          </div>
          <div class="row-s" @click="goActive(item.id,'teamwork')">
            <van-count-down :time="item.timeDiff" format="DD:HH:mm:ss">
              <template #default="timeData">
                <span style="margin-right: .02rem" v-if="timeData.days>0">{{timeData.days}}{{$t('天')}}</span>
                <span class="block">{{ timeData.hours>=10?timeData.hours:'0'+timeData.hours }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.minutes>=10?timeData.minutes:'0'+timeData.minutes }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.seconds>=10?timeData.seconds:'0'+timeData.seconds }}</span>
              </template>
            </van-count-down>
            <van-icon name="arrow" color="#909090" size="16" />
          </div>
        </div>
        <goodList
            :list="item.userGroupGoodsDTOIPage.records"
            :finished="true"
            :loading="true"
            :type="0"
            :activityName="'teamwork'"
            :source="'home'"
            :teamworkID="item.id"
        >
        </goodList>
      </div>

      <div class="Hotcommodity">
        <div class="row-b">
          <div class="labe">{{ $t('推荐商品') }}</div>
          <div class="text" @click="goAllgoods">{{ $t('更多') }}</div>
        </div>
      </div>
      <goodList
          :list="list"
          :loading="true"
          :finished="true"
          :type="0"
          :source="'home'"

      ></goodList>
      <div class="moreBox row-c">
        <div class="btn-more" @click="goAllgoods">
          {{$t('更多商品')}}
        </div>
      </div>
      <div class="platform">
        <div class="logoBox">
          <img :src="storeIndexInfo?.storeIndexInfo.storeLogo"/>
        </div>
        <div
            class="contactenos"
            v-if="
            kefuCont?.email ||
             kefuCont?.phone||kefuCont?.address
          "
        >
          <div class="labe" v-if="kefuCont?.email.length>0||kefuCont?.phone.length>0||kefuCont?.address">{{ $t('联系方式') }}</div>
          <template v-if="kefuCont?.email.length>0">
            <div  v-for="(item, index) in kefuCont?.email"
                  :key="index"
                  @touchstart="handleTouchStart(item)"
                  @touchend="handleTouchEnd">
              <div
                  class="row-s label"
              >
                <img
                    src="@/assets/outline-email@2x.png"
                    style="margin-right:.11rem;width:.24rem;height:.24rem"
                />
                {{ item?item:'-' }}
              </div>
            </div>
          </template>

          <template v-if="kefuCont?.phone.length>0">
            <div  v-for="(item, index) in kefuCont?.phone"
                  :key="index"
                  @touchstart="handleTouchStart(item)"
                  @touchend="handleTouchEnd">
              <div
                  class="row-s label"
              >
                <img
                    src="@/assets/phone-outlined@2x.png"
                    style="margin-right:.11rem;width:.24rem;height:.24rem"
                />
                {{ item?item:'-' }}
              </div>
            </div>
          </template>

          <div
              class="row-s label"
              v-if="kefuCont?.address"
          >
            <img
                src="@/assets/map@2x.png"
                style="margin-right:.11rem;width:.21rem;height:.21rem"
            />
            {{ kefuCont?.address }}
          </div>

        </div>
        <div class="RedesSociales">
          <div class="labe">{{ $t('分享') }}</div>
          <div class="sharList row-s">
            <div
                data-share="facebook"
                data-width="800"
                data-height="600"
                :data-title="storeIndexInfo?.storeIndexInfo?.storeName"
                data-quote="Share quote"
                data-description="Share lalala"
                data-hashtag="#哇呗"
                :data-url="baseUrl"
                class="sharli button row-c row-d"
            >
              <img src="@/assets/Facebook2.png"/>
              <!-- <div class="title">FaceBook</div> -->
            </div>

            <div
                data-share="whatsapp"
                data-width="800"
                data-height="600"
                :data-title="storeIndexInfo?.storeIndexInfo?.storeName"
                data-quote="Share quote"
                data-description="Share lalala"
                data-hashtag="#哇呗"
                :data-url="baseUrl"
                class="button sharli row-c row-d"
            >
              <img src="@/assets/whatsapp2.png"/>
              <!-- <div class="title">WhatsApp</div> -->
            </div>
            <div
                data-share="twitter"
                data-width="800"
                data-height="600"
                :data-title="storeIndexInfo?.storeIndexInfo?.storeName"
                data-quote="Share quote"
                data-description="Share lalala"
                data-hashtag="#哇呗"
                :data-url="baseUrl"
                class="button sharli row-c row-d"
            >
              <img src="@/assets/tuite2.png"/>
              <!-- <div class="title">X</div> -->
            </div>
            <div class="row-c row-d sharli" v-if="is_wx" @click="wxShar">
              <img src="@/assets/weixinb.png"/>
              <!-- <div class="title">{{ $t('链接') }}</div> -->
            </div>
            <div class="row-c row-d sharli" v-if="is_wx" @click="wxShar">
              <img src="@/assets/wechatMoments2.png"/>
              <!-- <div class="title">{{ $t('链接') }}</div> -->
            </div>
            <div class="row-c row-d sharli" @click="haibaoFx">
              <img src="@/assets/sharePoster2.png"/>
              <!-- <div class="title">{{ $t('海报') }}</div> -->
            </div>
            <div class="row-c row-d sharli" @click="copyText">
              <img src="@/assets/lianjie2.png"/>
              <!-- <div class="title">{{ $t('链接') }}</div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="version-warp">
<!--        <div class="cell row-c row-d">-->
<!--          <div v-if="storeIndexInfo?.storeIndexInfo?.copyrightInfo">{{ storeIndexInfo?.storeIndexInfo?.copyrightInfo }}</div>-->
<!--          <div  @click.stop="routeClick()" v-if="storeIndexInfo?.INFORMATION?.language?.value" style="margin:.06rem 0 0">-->
<!--          {{ storeIndexInfo?.INFORMATION?.language?.value }}</div>-->
<!--        </div>-->
        <div class="cell row-c row-d" style="background:#f5f5f5;padding: .05rem 0 .20rem ">
          <img src="@/assets/pingtaiLog.png" style="width: 1.5rem;height: .31rem;margin: .05rem 0;">
          <div  @click.stop="routeClick()" v-if="storeIndexInfo?.INFORMATION?.language?.value" style="color: #999999">
            {{ storeIndexInfo?.INFORMATION?.language?.value }}</div>
        </div>
        <!-- <div class="cell" @click.stop="routeClick()" v-if="storeIndexInfo?.INFORMATION?.language?.value">
          {{ storeIndexInfo?.INFORMATION?.language?.value }}
        </div> -->
      </div>
      <div class="weixinBox" v-if="weixinShow">
        <div class="mask" @click="weixinShow = false"></div>
        <div class="weixinJt row-c row-d">
          <img src="@/assets/weixinJT.png"/>
          <div class="labe">{{ $t('请点击右上角') }}</div>
          <div class="labe">{{ $t('分享给好友或朋友圈') }}</div>
        </div>
      </div>
      <div class="ewm" v-if="show2">
        <div class="mask" @click="show2 = false"></div>
        <div class="pop">
          <div class="content">
            <div class="spTitle">
              {{ storeIndexInfo?.storeIndexInfo?.storeName }}
            </div>
            <div class="tip">{{ $t('推荐店铺给你') }}</div>
            <div class="ewmBox">
              <canvas
                  id="QRCode_header"
                  style="width: 150px; height: 150px;"
              ></canvas>
            </div>
            <div class="btnBox">
              <div class="btn" @click="show2 = false">{{ $t('取消') }}</div>
              <div class="btn2" @click="savePoster">{{ $t('保存') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div style="position:fixed;left:9999px;top:9999px;">
        <div id="poster-container">
          <div class="ewm">
            <div class="pop2">
              <div class="content">
                <div class="spTitle">
                  {{ storeIndexInfo?.storeIndexInfo?.storeName }}
                </div>
                <div class="tip">{{ $t('推荐店铺给你') }}</div>
                <div class="ewmBox">
                  <canvas
                      id="QRCode_header2"
                      style="width: 150px; height: 150px;"
                  ></canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-overlay :show="overlayShow" z-index="999">
      <div class="wrapper" @click.stop>
        <div class="block">
          {{ $t('房间号已失效请联系工作人员') }}
        </div>
      </div>
    </van-overlay>

  </div>
</template>

<script>
import homeNavBar from '@/components/NavBar'
// import homeTabbar from '@/components/Tabbar'
import goodList from '@/components/goodList'
import footBox from '@/components/foot'
import weixinModule from '@/utils/publick'
import Vue from 'vue'
import {Lazyload} from 'vant'

import {
  listFrontGoodsUser,
  listStoreBanner,
  updateStoreBannerIncrement4Id,
  getStoreIndexInfo,
  getStoreClassification,
  getLatestProducts
} from '@/API/listFront'
import ScrollView from '@/components/scrollView'
import Share from '@/utils/share'
import QRCode from 'qrcode'
import html2canvas from 'html2canvas'
import {StoreFindContact} from "@/API/Language";
Vue.use(Lazyload)

export default {
  name: 'HomeIndex', // 组件名称
  components: {
    /* 注册子组件 */
    homeNavBar,
    // homeTabbar,
    goodList,
    footBox,
    ScrollView
  },
  props: {
    /* 组件属性 */
  },
  data() {
    return {
      /* 组件数据 */
      images: [
        'https://img01.yzcdn.cn/vant/apple-1.jpg',
        'https://img01.yzcdn.cn/vant/apple-2.jpg'
      ],
      list: [],
      Bannerlist: [],
      indicator: 0,
      storeIndexInfo: {},
      // H5 网页的 URL 地址（假设为 https://example.com）
      baseUrl: window.location.href,
      // 分享标语
      shareText: '我发现了一个很棒的网页，大家快来看看吧！',
      show2: false,
      classificationArray: [],
      latestProductsList: [],
      is_wx: /MicroMessenger/i.test(window.navigator.userAgent),
      weixinShow: false,
      limitedTimeActivities:[],
      teamwork:[],
      fullReductionFullGift:[],
      time: 90 * 60 * 60 * 1000,
      kefuCont: {
        phone: [],
        email: []
      },
      overlayShow:false

    }
  },
  computed: {
    /* 计算属性 */
  },
  created() {
    this.logotable()
    this.gainlist()
    this.kefu()
    localStorage.setItem('menutable', 1)
  },
  mounted() {
    this.twitterShareUrl()
  },

  methods: {
    handleTouchStart (text) {
      this.longPressTimer = setTimeout(() => {
        this.copyText2(text)
      }, 1000) // 长按时间，可根据需求调整
    },
    handleTouchEnd () {
      clearTimeout(this.longPressTimer)
    },
    copyText2 (text) {
      const hiddenTextarea = document.createElement('textarea')
      hiddenTextarea.value = text
      document.body.appendChild(hiddenTextarea)
      hiddenTextarea.select()
      document.execCommand('copy')
      document.body.removeChild(hiddenTextarea)
      this.$toast({
        showClose: true,
        message: window.$i18n.t('复制成功'),
        type: 'success'
      })
    },
    async kefu() {
      let res = await StoreFindContact({
        storeId: localStorage.getItem('storeID')
      })
      let kefu = {phone:[], email:[],address:''}
      if(res.data?.phone?.length>0){
        kefu.phone=JSON.parse(res.data.phone)
      }
      if(res.data?.email?.length>0){
        kefu.email=JSON.parse(res.data.email)
      }
      if(res.data?.address){
        let lang=localStorage.getItem('lang')
        let address=JSON.parse(res.data.address)[lang]
        kefu.address=address
      }
      this.kefuCont = kefu
      localStorage.setItem('kefu', JSON.stringify(kefu))
    },

    goActive(id,name){
      this.$router.push({
        path: '/activityProducts',
        query: {id:id,  storeId: localStorage.getItem('storeID'),activeName:name,source:'home' }
      })
    },
    wxShar() {
      this.weixinShow = true
      // weixinModule.weiXinShareFn(
      //   this.logo.title,
      //   window.$i18n.t('推荐店铺给你'),
      //   this.logo?.storeLogo
      // )
    },
    goAllgoods() {
      this.$router.push({
        path: '/goods',
        query: {storeId: localStorage.getItem('storeID')}
      })
    },
    routeClick() {
      // if (this.abox) {
      //   return this.$message({
      //     showClose: true,
      //     message: "电脑端浏览效果更佳",
      //     type: "success",
      //   });
      // }
      // 通过此方法可以使用
      if (this.storeIndexInfo?.INFORMATION?.technical.agreementText) {
        if (
            this.isValidUrl(
                this.storeIndexInfo?.INFORMATION?.technical.agreementText
            )
        ) {
          window.open(this.storeIndexInfo?.INFORMATION?.technical.agreementText)
        } else {
          console.error(
              '无效的路径1:',
              this.storeIndexInfo?.INFORMATION?.technical.agreementText
          )
          // 可以选择进行其他处理或者提示给用户
        }
      }

      // window.location.href = this.logo.endPageUrl
    },
    isValidUrl(string) {
      try {
        new URL(string)
        return true
      } catch (_) {
        return false
      }
    },
    // 去商品详情
    goDetails(item) {
      sessionStorage.setItem('home', 0)
      this.$router.push({
        path: '/productDetails',
        query: {value: item.id, storeId: localStorage.getItem('storeID')}
      })
    },
    // 获取最新商品
    async getLatest() {
      let res = await getLatestProducts({
        storeId: localStorage.getItem('storeID')
      })
      if (res.code == 200) {
        this.latestProductsList = res.data
      }
    },
    // 去分类需求
    goClassification(it) {
      // 替换当前返回的路由
      this.$router.replace({
        path: '/goods',
        query: {
          classId1: it,
          storeId: localStorage.getItem('storeID')
        }
      })
    },
    // 获取分类
    async getclassification() {
      let res = await getStoreClassification({
        storeId: localStorage.getItem('storeID')
      })

      this.classificationArray = this.chunkArray(res.data, 8)
      console.log(res, this.classificationArray)
    },
    chunkArray(arr, size) {
      let result = []
      for (let i = 0; i < arr.length; i += size) {
        result.push(arr.slice(i, i + size))
      }
      return result
    },
    // 获取店铺logo
    async logotable() {
      let data1 = {
        storeId: localStorage.getItem('storeID')
      }
      // 读取 localStorage 中的数据
      let storedData = JSON.parse(localStorage.getItem('storeSell'))
      if(storedData?.storeSellId){
        data1.storeSellId=storedData.storeSellId
      }else {
        data1.storeSellId=null
      }

      let res = await getStoreIndexInfo(data1)
      if(res.code==200444){
        this.overlayShow=true
        console.log(55545)
      }
      console.log(res)
      if (res.data) {
        this.storeIndexInfo = res.data
        weixinModule.weiXinShareFn(
            res.data?.storeIndexInfo?.storeName,
            window.$i18n.t('推荐店铺给你'),
            res.data?.storeIndexInfo?.storeLogo
        )
        if (res.data.storeIndexInfo.themeId == 2) {
          this.getclassification()
          this.getLatest()
        }
      }
    },

    copyText() {
      let inputDom = document.createElement('textarea') // js创建一个文本框
      document.body.appendChild(inputDom) //将文本框暂时创建到实例里面
      inputDom.value = this.baseUrl //将需要复制的内容赋值到创建的文本框中
      inputDom.select() //选中文本框中的内容
      inputDom.focus()
      document.execCommand('copy') //执行复制操作
      document.body.removeChild(inputDom) //最后移出
      this.$toast({
        showClose: true,
        message: window.$i18n.t('复制成功'),
        type: 'success'
      })
    },
    savePoster() {
      // 获取海报容器的 DOM 元素
      const posterContainer = document.getElementById('poster-container')
      // 使用 html2canvas 将海报容器绘制为 Canvas
      html2canvas(posterContainer).then(canvas => {
        canvas.toBlob(
            function (blob) {
              const ele = document.createElement('a')
              ele.download = 'poster.png'
              ele.style.display = 'none'
              ele.href = URL.createObjectURL(blob) //字符内容转为blob地址
              ele.click()
            },
            'image/png',
            1
        )
        // // 转换为图像
        // const dataUrl = canvas.toDataURL('image/png')

        // // 创建一个隐藏的链接
        // const link = document.createElement('a')
        // link.setAttribute('download', 'poster.png')
        // link.setAttribute('href', dataUrl)

        // // 触发点击事件下载图像
        // link.click()
      })
    },
    haibaoFx() {
      this.show2 = true
      this.show = false
      this.showShare = false
      this.storeIndexInfo = JSON.parse(localStorage.getItem('storeIndexInfo'))
      setTimeout(() => {
        this.getQRCode()
      }, 100)
    },
    getQRCode() {
      //生成的二维码为URL地址js
      // this.qrUrl = window.location.href
      let opts = {
        errorCorrectionLevel: 'H', //容错级别
        type: 'image/png', //生成的二维码类型
        quality: 0.3, //二维码质量
        margin: 0, //二维码留白边距
        width: 150, //宽
        height: 150, //高
        text: this.baseUrl, //二维码内容
        color: {
          dark: '#333333', //前景色
          light: '#fff' //背景色
        }
      }

      let msg = document.getElementById('QRCode_header')
      let msg2 = document.getElementById('QRCode_header2')
      // 将获取到的数据（val）画到msg（canvas）上
      QRCode.toCanvas(msg, this.baseUrl, opts, function (error) {
        if (error) {
          // this.$message.error('二维码加载失败')
        }
      })
      QRCode.toCanvas(msg2, this.baseUrl, opts, function (error) {
        if (error) {
          // this.$message.error('二维码加载失败')
        }
      })
    },
    // 获取 分享链接
    twitterShareUrl() {
      setTimeout(() => {
        Share.init()
      }, 100)
    },
    /* 组件方法 */
    onChange(index) {
      console.log(index)
      this.indicator = index
    },

    // H5移动端
    async H5banner(list) {
      let data1 = {
        id: list.id,
        type: '0'
      }
      let data = await updateStoreBannerIncrement4Id(data1)

      if (list.hrefUrl.indexOf('value=') != -1) {
        let a = list.hrefUrl.split('value=')
        await this.$store.dispatch('user/Bannergoods', a[1])
        await this.$store.dispatch('user/Bannerid', list.id)
        if (data.code === 200) {
          sessionStorage.setItem('home', 0)
          this.$router.push({
            path: '/productDetails',
            query: {value: a[1], storeId: localStorage.getItem('storeID')}
          })
        }
      } else if (list.hrefUrl.indexOf('customizeId=') != -1) {
        let a = list.hrefUrl.split('customizeId=')
        await this.$store.dispatch('user/TablistID', a[1])
        this.$router.push({
          path: '/ExternalWord',
          query: {storeId: localStorage.getItem('storeID')}
        })
      } else if (list.hrefUrl.indexOf('classId3=') != -1) {
        let a = list.hrefUrl.split('classId3=')
        let c = a[0].split('classId2=')
        let b = c[0].split('classId1=')
        localStorage.setItem('customizeId', a[1])
        this.$store.dispatch('user/TablistID', a[1])
        // this.$router.push({
        //   path: '/goods',
        //   query: {
        //     classId1: b[1].slice(0, -1),
        //     classId2: c[1].slice(0, -1),
        //     classId3: a[1],
        //     storeId: localStorage.getItem('storeID')
        //   }
        // })
        window.location.href = list.mobileHrefUrl
      } else if (list.hrefUrl.indexOf('classId2=') != -1) {
        let a = list.hrefUrl.split('classId2=')
        let b = a[0].split('classId1=')
        localStorage.setItem('customizeId', a[1])
        this.$store.dispatch('user/TablistID', a[1])
        // this.$router.push({
        //   path: '/goods',
        //   query: {classId1: b[1].slice(0, -1), classId2: a[1],storeId: localStorage.getItem('storeID')}
        // })
        window.location.href = list.mobileHrefUrl
      } else if (list.hrefUrl.indexOf('classId1=') != -1) {
        let a = list.hrefUrl.split('classId1=')
        localStorage.setItem('customizeId', a[1])
        this.$store.dispatch('user/TablistID', a[1])
        // this.$router.push({
        //   path: '/goods',
        //   query: {classId1: a[1],storeId: localStorage.getItem('storeID')}
        // })
        window.location.href = list.mobileHrefUrl
      }
    },
    // 获取商品列表
    async gainlist() {
      // if(!localStorage.getItem('userData')){
      //   return false
      // }
      let id = {
        storeId: localStorage.getItem('storeID')
      }
      let lorder = {
        position: 1,
        storeId: localStorage.getItem('storeID')
      }
      let llist = await listStoreBanner(lorder)
      let {data} = await listFrontGoodsUser(id)
      this.list = []
      this.Bannerlist = llist.data
      for (let i = 0; i < data.listFrontGoods.length; i++) {
        if (i === 12) {
          return
        } else {
          this.list.push(data.listFrontGoods[i])
        }
      }
     if(data?.discount?.length>0){
       data.discount.forEach(el=>{
         // 将时间字符串转换为 Date 对象
         let endTime = new Date(this.$getTimeZoneOffset(el.endTime));
         let nowTime = new Date(this.$getTimeZoneOffset(el.nowTime));

// 计算时间差（单位：毫秒）
         el.timeDiff = endTime - nowTime;
         if(el.userDiscountGoodsDTOList.records.length>0)
           el.userDiscountGoodsDTOList.records=el.userDiscountGoodsDTOList.records.splice(0,3)
       })

       this.limitedTimeActivities=data.discount

     }
      if(data?.group?.length>0){
        data.group.forEach(el=>{
          // 将时间字符串转换为 Date 对象
          let endTime = new Date(this.$getTimeZoneOffset(el.endTime));
          let nowTime = new Date(this.$getTimeZoneOffset(el.nowTime));

// 计算时间差（单位：毫秒）
          el.timeDiff = endTime - nowTime;
          if(el.userGroupGoodsDTOIPage.records.length>0)
            el.userGroupGoodsDTOIPage.records=el.userGroupGoodsDTOIPage.records.splice(0,3)
        })

        this.teamwork=data.group

      }
      if(data?.listFullReduction?.length>0){
        data.listFullReduction.forEach(el=>{
          // 将时间字符串转换为 Date 对象
          let endTime = new Date(this.$getTimeZoneOffset(el.endTime));
          let nowTime = new Date(this.$getTimeZoneOffset(el.nowTime));

// 计算时间差（单位：毫秒）
          el.timeDiff = endTime - nowTime;
          if(el.applicationFullReductionGoodsDTOList.records.length>0)
            el.applicationFullReductionGoodsDTOList.records=el.applicationFullReductionGoodsDTOList.records.splice(0,3)
        })

        this.fullReductionFullGift=data.listFullReduction

      }

    }
  }
}
</script>

<style scoped lang="scss">
.weixinBox {
  position: relative;

  .mask {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 17;
  }

  .weixinJt {
    position: fixed;
    z-index: 18;
    right: 0.1rem;
    top: 0.2rem;
    // position: absolute;
    img {
      width: 1rem;
      height: 1rem;
    }

    .labe {
      margin: 0.1rem 0 0;
      font-size: 0.16rem;
      color: #fff;
    }
  }
}

.home {
  background: #f5f5f5;
  .limitedTimeDiscount{
    background: #fff;
    padding: .1rem 0.19rem;

    .top{
      .block{
        //width: .2rem;
        //height: .2rem;
        display: inline-block;
        //background: #FF4545;
        border-radius: .04rem;
        //color: ;
        text-align: center;
      }
      .colon{
        display: inline-block;
        margin: 0 4px;
        //color: #ee0a24;
      }
      .label{
        font-size: .16rem;
        color: #2e3845;
        font-weight: bold;
        position: relative;
        z-index: 1;
      }
      .line{
        bottom: .02rem;
        height: .06rem;
        width:100%;
        position: absolute;
        background: linear-gradient(90deg, #E4BFFD, #8D4BBB);
        z-index: 0;
      }
      .txt{
        color: #909090;
      }
    }
  }
  .swipeBox {
    background: #fff;
    padding: 0 0.19rem;

    .labe {
      font-size: 0.16rem;
      color: #2e3845;
      font-weight: bold;
      margin-bottom: 0.12rem;
    }

    .my-swipe {
      /deep/ .van-swipe__indicators .van-swipe__indicator {
        background: rgba(187, 148, 215, 0.4);
      }
    }

    .my-swipe .van-swipe-item {
      // padding: 0 0 0.2rem;

      .list {
        flex-wrap: wrap;

        .li {
          margin-right: 0.1rem;
          margin-bottom: 0.1rem;

          img {
            width: 0.8rem;
            height: 0.68rem;
            border-radius: 0.08rem;
            margin-bottom: 0.02rem;
            border: .01rem solid #F3F3F3;
          }

          .txt {
            min-height: .3rem;
            max-width: 80px;
            text-align: center;
            font-size: 0.11rem;
            color: #777879;
              overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          -webkit-line-clamp: 2; // 超出多少行
          }
        }

        .li:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }

  .swipePromo {
    background: #fff;
    padding: 0 0.19rem;

    .labe {
      font-size: 0.16rem;
      color: #2e3845;
      font-weight: bold;
      margin-bottom: 0.12rem;
    }

    .my-swipe {
       padding: 0 0 0.3rem;
      .van-swipe-item {
        // padding: 0 0 0.3rem;
        // margin-right: 0.16rem;
      }

      /deep/ .van-swipe__indicators .van-swipe__indicator {
        background: rgba(187, 148, 215, 0.4);
      }

      img {
        // width: 2.68rem;
        width: 100%;
        height: 2rem;
        border-radius: 0.06rem;
      }
    }
  }


  .NewProducts {
    background: #fff;
    padding: 0 0.19rem 0;

    .labe {
      font-size: 0.16rem;
      color: #2e3845;
      font-weight: bold;
      margin-bottom: 0.12rem;
    }

    .list {
      .li {
        position: relative;
        width: 3rem;
        height: 3.23rem;
        margin-right: 0.16rem;

        //&:nth-child(4n) {
        //  margin-right: 0;
        //}

        img {
          width: 3rem;
          height: 3.23rem;
          border-radius: 0.08rem;
          border: .01rem solid #F3F3F3;
          position: absolute;
        }

        .productsBox {
          border-radius: 0.08rem;
          background: #fff;
          padding: 0.16rem;
          position: absolute;
          width: 2.68rem;
          left: 0.16rem;
          bottom: 0.16rem;

          .productsName {
            width: 2.3rem;
            font-size: 0.16rem;
            color: #3e4462;
            font-weight: 500;
          }

          .productsDele {
            font-size: 0.12rem;
            color: #7e7e7e;
            font-weight: 400;
            margin: 0.04rem;
          }

          .money {
            font-size: 0.16rem;
            color: #ff4545;
            font-weight: 500;

            span {
              font-size: 0.12rem;
              color: #cacaca;
              font-weight: 400;
              margin-left: 0.08rem;
              text-decoration: line-through; /* 添加划线 */
            }
          }
        }
      }
    }
  }
  .Hotcommodity {
    background: #fff;
    padding: 0.1rem 0.19rem 0;

    .labe {
      font-size: 0.16rem;
      color: #2e3845;
      font-weight: bold;
      margin-bottom: 0.12rem;
    }

    .text {
      font-size: 0.14rem;
      color: #ff9a02;
      margin-bottom: 0.12rem;
    }
  }
  .moreBox{
    padding: .1rem .19rem;
    .btn-more{
      flex: 1;
      border: .01rem  solid #8d4bbb;
      text-align: center;
      height: .5rem;
      line-height: .5rem;
      color: #8d4bbb;
      font-size: .14rem;
    }
  }

  .platform {
    background: #8d4bbb;
    padding: 0.18rem 0.18rem 0.2rem;

    .logoBox {
      margin: 0 0 0.2rem;
      padding-bottom: 0.08rem;
      border-bottom: 0.01rem solid #f2f3f7;

      img {
        width: 1.24rem;
        height: 0.4rem;
      }
    }

    .contactenos {
      .labe {
        font-size: 0.18rem;
        color: #fff;
        font-weight: 600;
        margin-bottom: 0.13rem;
      }

      .label {
        margin-bottom: 0.15rem;
        font-size: 0.16rem;
        color: rgba(255, 255, 255, 0.6);
      }
    }

    .RedesSociales {
      // padding: .26rem 0 0;
      .labe {
        font-size: 0.18rem;
        color: #fff;
        font-weight: 600;
        margin-bottom: 0.23rem;
      }

      .sharList {
        padding: 0;
        // display: flex;
        // align-items: center;
        // justify-content: flex-start;
        flex-wrap: wrap;

        .sharli {
          margin-right: 0.2rem;

          img {
            width: 0.25rem;
            height: 0.25rem;
          }

          .title {
            color: #646566;
            font-size: 12px;
            margin-top: 8px;
          }
        }
      }
    }
  }

  .version-warp {
    //padding-bottom: 0.2rem;

    .cell {
      padding: 0.128rem 0.12rem;
      font-size: 0.12rem;
      text-align: center;
      color: #fff;
      background-color: #2e3845;

      // &:last-child {
      //   padding: 0 0 0.128rem;
      // }
    }
  }

  .ewm {
    .mask {
      background: rgba(0, 0, 0, 0.5);
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 17;
    }

    background: #fff;

    .pop2 {
      background: #fff;
      border-radius: 20px;
      min-width: 280px;
      box-shadow: 0 2px 10px 0 rgba(10, 5, 74, 0.3);

      .content {
        padding: 22px;

        .spTitle {
          // width: 380px;
          font-size: 15px;
          color: #384358;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box; //作为弹性伸缩盒子模型显示。
          -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
          -webkit-line-clamp: 2; //显示的行
        }

        .tip {
          font-size: 13px;
          color: #999999;
        }

        .ewmBox {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 10px 0;
        }

        .btnBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 20px;

          .btn {
            width: 90px;
            height: 31px;
            text-align: center;
            border: 1px solid #8d4bbb;
            border-radius: 8px;
            line-height: 31px;
            color: #6e4faa;
          }

          .btn2 {
            width: 90px;
            height: 31px;
            text-align: center;
            border-radius: 8px;
            line-height: 31px;
            background: #6e4faa;
            color: #fff;
          }
        }
      }
    }

    .pop {
      background: #fff;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 999;
      border-radius: 20px;
      min-width: 280px;
      box-shadow: 0 2px 10px 0 rgba(10, 5, 74, 0.3);

      .content {
        padding: 22px;

        .spTitle {
          // width: 380px;
          font-size: 15px;
          color: #384358;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box; //作为弹性伸缩盒子模型显示。
          -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
          -webkit-line-clamp: 2; //显示的行
        }

        .tip {
          font-size: 13px;
          color: #999999;
        }

        .ewmBox {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 10px 0;
        }

        .btnBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 20px;

          .btn {
            width: 90px;
            height: 31px;
            text-align: center;
            border: 1px solid #8d4bbb;
            border-radius: 8px;
            line-height: 31px;
            color: #6e4faa;
          }

          .btn2 {
            width: 90px;
            height: 31px;
            text-align: center;
            border-radius: 8px;
            line-height: 31px;
            background: #6e4faa;
            color: #fff;
          }
        }
      }
    }
  }

  // height: 100%;

  /* 组件局部样式 */
  .swipe {
    width: 100%;
    height: 2rem;

    .swipe-img {
      width: 100%;
      height: 100%;
    }
  }

  .liTitle {
    background: #fff;
    height: 0.45rem;
    padding: 0 0.14rem;

    .line {
      width: 0.03rem;
      height: 0.15rem;
      background: #6e4faa;
      border-radius: 0.105rem;
      margin-right: 0.08rem;
    }

    .labe {
      font-size: 0.16rem;
      color: #384358;
      font-weight: bold;
    }
  }
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .block {
    width: 80%;
    height: 120px;
    background-color: #fff;
    line-height: 120px;
    text-align: center;
    font-size: 16px;
  }
}


</style>
